import { Person } from "../feed-wall/data";
import { AVATARS_CACHE, currentUser } from "../service/userService";

 
const bgs = ["red", "blue", "orange", "purple", "pink", "green" ];

export function Avatar(props:any) {
    let user : Person = props.user;
    if(!user && props.connectedUser) {
        user = { name :  currentUser().fullName|| '', email: currentUser().email || '', avatarUrl : currentUser().avatarUrl };
    }
    if(user.email) {
        user.avatarUrl = AVATARS_CACHE[user.email]; 
    }
    let sizeCss = (props.size|| 'medium') + "-avatar";
    let initials = (user.name|| "").trim().split(/\s+/g).map(w=>w.substring(0,1).toUpperCase()).join("");
    initials = initials.replace("(", "");
    let bg = bgs[initials.length%bgs.length]
    return (
        (initials || user.avatarUrl  ) ? <div className={ "share-box-feed-img-user "+ sizeCss }  >
           {user.avatarUrl &&  <img src={user.avatarUrl} alt="" /> }
           {!user.avatarUrl &&  <span className="avatar-initials" style={{backgroundColor:bg}}>{initials}</span> }
        
        </div> :
        <div></div>
    
              
    );
}