 
import { LoremIpsum } from "lorem-ipsum"; 
import { AssessmentInstance } from "../assessment/assessment-type";
import { generateRandomDateTime, generateRandomDateTxt, generateRandomTitle, randomInt } from "./help";


export function generateRandomAssessment(aNq : boolean):AssessmentInstance {
    const lorem = new LoremIpsum({
        sentencesPerParagraph: {  max: 5,    min: 2   },
        wordsPerSentence: {   max: 8,    min: 3   }
      });
      let toss1 = randomInt()%2 ==0;
    let toss2 = toss1 &&  randomInt()%2 ==0;   
    let assessment : AssessmentInstance  = {
        definition : { 
            title:lorem.generateWords(2),  
            moduleName: lorem.generateWords(2), 
            description: lorem.generateParagraphs(1), 
            status: toss1? 'OPEN' : 'CLOSED',
            type: aNq? 'POLLS' : 'QUIZ',
            questions:[],
            publicationDate : new Date(),
            closingDate : new Date(),
            weight: randomInt(0, 10),
            passingScore :  randomInt(50, 100),
        },
        instanceStatus :  toss1? 'STARTED' : 'CLOSED',
        stepName: lorem.generateWords(2), 
        startTime : toss1? new Date(generateRandomDateTime()) : undefined,
        endTime : toss2?  new Date(generateRandomDateTime()): undefined,
        score : toss2 ? randomInt(30, 90) : 0,
        acheivedQuestionNumber : randomInt(0, 10), 
       } ;
    
    let qn = 5;
    for(let i = 1; i<=qn; i++) {
        let on = randomInt(2, 5);
        let options: {id:number, text:string, key: string, checked:boolean}[] = [];
        for(let j=1; j<on; j++) {
            options.push({checked: false, id:j, key:i+"_"+j, text: generateRandomTitle(lorem)});
        }
        assessment.definition.questions.push({
            weight:  randomInt(10, 30),
            contextDescription : lorem.generateParagraphs(1),
            question: lorem.generateSentences(1).replace(".", ""),
            type: [ 'QCU' , 'QCM' , 'FREE_TEXT'][i%3] as ( 'QCU' | 'QCM' | 'FREE_TEXT'),
            options: options,
            correctAnswer: {optionId: [0] , comment: lorem.generateParagraphs(1)}
            
        })
    }
    return assessment;
}

 
export const  ASSESSMENTS_DATA : AssessmentInstance[] =  [];
export const  QUIZZES_DATA : AssessmentInstance[] =  [];
[1,2,3,4,5,6,7,8,9,10].forEach(()=> {ASSESSMENTS_DATA.push(generateRandomAssessment(true))});
[1,2,3,4,5,6,7,8,9,10].forEach(()=> {QUIZZES_DATA.push(generateRandomAssessment(false))}); 



