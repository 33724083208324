import { IndicatorData } from "../dashboard/dashboard-data";

const Video1Url = "https://www.openbeelden.nl/files/06/39/639282.639266.1__Geluid_van_Nederland_02-07_NL-BeeldenvoordeToekomst-Apple_ProRes_422-H.264.mp4";
const Video2Url = "https://player.vimeo.com/video/205512857?title=0&portrait=0&byline=0&autoplay=1";
const image1Url = "https://live.staticflickr.com/2040/2503202501_ffea96ab41_c.jpg";
export interface Person {
    name: string;
    email: string;
    title?: string;
    avatarUrl?: string;
}
export interface Channel {
    name?:string;
    label?:string;
    title?:string;
}

export interface Comment {
    reference? : string;
    author?: Person ;  
    publicationDate?:Date; 
    like?: boolean; 
	contentData?: string;
	type?:string  ;
}
export interface Attachment {
    link: string;
    name?: string;
}
export interface Post {
    forAll?:boolean;
    observers?: string[];
    reference?:string;
    actor: Person;
    feeder?: Person;
    contentData?: string;
    textIndex?: string;
    contentType?: 'text' | 'photo' | 'video' | 'kpi' | 'quiz' | 'challenge' | 'training' | 'assessment' ;
    postAge?: string;
    feedReason?: string;
    video?: string;
    photo?: string;
    attachments?: Attachment[];
    kpi?: IndicatorData;
    challengeTop10?: any;
    text: string;
    sharingText?:string;
    commentsCount: number;
    likesCount: number;
    reshareCount: number;
    channels?:Channel[];
    initialChannels?:Channel[];
    comments?:Comment[];
    publicationDate?: Date; 
};
export const POSTS: Post[] = [];
export const TENDENCES: Post[] = [];
/*
POSTS.push({
    actor: {
        email: "abdel.jabbar@socgen.com",
        name: "Abdel Jabbar", title: "CEO, COO, CFC et CTO",
        avatarUrl: "https://i.pravatar.cc/150?img=4",
    },
    reference: "099X78999",
    postAge: "3 Mois",
    photo: "/photos/uncl-sam.jpg",
    commentsCount: 315,
    likesCount: 20,
    reshareCount: 30,
    text: "Depuis plus de 35 ans, l'AFM nous rassemble autour de valeurs communes : partage et valorisation des recherches et idées, entraide et solidarité, émulation collective et convivialité.<br/> C’est donc un honneur pour moi,  en tant que nouveau président, de prendre les rênes de cette entreprise de premier plan qui compte aujourd'hui plus de 600 employers."
});


POSTS.push({
    actor:
    {
        email: "abdel.jabbar@socgen.com",
        name: "Hamid Bounou", title: "Network Engineer",
        photoLink: "https://i.pravatar.cc/150?img=3",
    },
    postAge: "5 Heures",
    video: Video1Url,
    commentsCount: 315,
    likesCount: 20,
    reshareCount: 30,
    text: "Retour deux ans en arrière !<br/>Alors que je présentais la toute nouvelle solution d'éducation en ligne Kezakoo BIOF en 2019, l'idée tout à fait improvisée de réciter les cinquante premiers chiffres de PI me vint à l'esprit. Ce fut clairement un moment inoubliable."
});

POSTS.push({
    actor:
    {
        email: "abdel.jabbar@socgen.com",
        name: "Ramidi Rayane", title: "Controleur de Gestion",
        //photoLink:  "https://i.pravatar.cc/150?img=5",
    },
    postAge: "2 semaines",
    video: Video2Url,
    commentsCount: 315,
    likesCount: 20,
    reshareCount: 30,
    text: "C'est mon premier jour à Taipei pour m'attaquer au plus grand marché de semi-conducteurs d'asie..."
});

POSTS.push({
    actor:
    {
        email: "abdel.jabbar@socgen.com",
        name: "Essami Nabil", title: "Data Scientist | Project Manager",
        photoLink: "https://i.pravatar.cc/150?img=12",
    },
    feeder: {
        name: "Reda noushi",
        email: "reda.noushi@gmail.com"
    },
    feedReason: "soutient ceci",

    postAge: "3 Jours",
    photo: image1Url,
    commentsCount: 315,
    likesCount: 20,
    reshareCount: 30,
    text: "Très belle Visualisation de la façon d'opérer d'un Algorithme #ML"
});
*/

let KPI_SHARED_JSON = localStorage.getItem("KPI_SHARED");
if (KPI_SHARED_JSON) {
    let kpis = JSON.parse(KPI_SHARED_JSON);
    console.log("shared kpis ", kpis);
    for (let kpi of kpis) {
        POSTS.push(kpi);
    }
}

