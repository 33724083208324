
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { OrganizationCascalde } from '../dashboard/organization-cascade';
import { PostEdition } from '../feed-wall/post-edition';
import { toYYYMMDD, toYYY_MM_DD } from '../help/dates';
import { round2 } from '../help/formating';
import { t } from '../i18n';
import { getChallengeConfigById, recomputeChallengeResulat } from '../service/challengeService';
import { appConfig } from '../service/userService';
import { CHALLENGES } from '../test-data/challenge-data';
import { Avatar } from '../ui/avatar';
import { ChallengeConfig, ChallengeData } from "./challenge-type";

export function ChallengeResult(props: any) {
  let { id: challengeIdParam } = useParams() as any;
  let challengeId = props.challengeReference || challengeIdParam;
  let [cc, setCc] = useState(props.cc as ChallengeConfig);
  let [data, setData] = useState([] as ChallengeData[]);
  let [dispalMode, setDispalMode] = useState("list");
  let [loading, setLoading] = useState(false);
  let [ready, setReady] = useState(false);
  let [shareTop10, setShareTop10] = useState(false);

  let [targetEntity, setTargetEntity] = useState({ name: "", label: t(appConfig().ouLevels[0].label || "Siège") });
  let resetTargetEntity = (evt: any) => {
    setTargetEntity(evt);
    console.log("resetTarget evnt =", evt);
    console.log("resetTarget cc=", cc);
    if (cc.data) {
      setData(cc.data.filter(d => {
        console.log("reset filter", d);
        try {

          return !evt.qn || d.entityQN.startsWith(evt.qn) || d.name == evt.name;
        } catch (err) {
          console.log("error", err);
          return false;
        }

      }));
    }

  }
  useEffect(() => {
    setReady(false);
    reloadData();
  }, [challengeId]);
  let reloadData = () => {
    getChallengeConfigById(challengeId, "FETCH-RESULT").then(r => {
      if (r.body) {
        cc = r.body;
        setCc(cc);
        setData(cc.data);
      }
      setReady(true);
    }, err=> {setReady(true)});
  }
  let resyncResults = () => {
    setLoading(true);
    recomputeChallengeResulat(challengeId).then(r => {
      setLoading(false);
      if (r == "ok") {
        reloadData();
      }
    },
      err => {
        setLoading(false);
      })
  }
  if(!ready) {
    return <div className='no-ready'>
      <i className='fas fa-spinner fa-spin'></i>
    </div>
  }
  return (<div className="challenges quizzes-list" >

    {cc && cc.data && <>
      <div className="charllenge-results card card-table-one indicator-card "  > 
        {challengeId && <div className="flex-sb">
        <div className='card-title-container'>
        <h6 className="card-title">Resultats du challenge <strong>{cc.title}</strong>  </h6>
        <p className="az-content-text mg-b-20">{cc.description}</p>
        </div>
      <span onClick={resyncResults}
        className="tlb-btn tex-btn neg">
        {!loading && <i className="fa fa-sync"></i>}
        {loading && <i className="fas fa-spinner fa-spin"></i>}
        {("Recalculer les resultats")}</span>
      <span onClick={()=>setShareTop10(true)}
      
      className="tlb-btn tex-btn bold" ><i className="fa fa-share"></i> {t("Top 10")} </span>
    </div>
    }

        <OrganizationCascalde
          onSelectEntity={(e: any) => resetTargetEntity(e)}
          targetEntity={targetEntity} ></OrganizationCascalde>


        <div className='challenge-display-block-container'>
          <hr></hr>
          <div className={"disp-md-label"} >{t("Mode d'Affichage")}</div>
          <div onClick={() => setDispalMode("list")}
            className="disp-md"         >
            {dispalMode == "list" && <i className='fa fa-circle red'></i>}
            <i className='fa fa-list'></i> {t("Liste")}</div>
          <div onClick={() => setDispalMode("table")} className="disp-md " >
            {dispalMode == "table" && <i className='fa fa-circle red'></i>}
            <i className='fa fa-table'></i>{t("Table")}</div>
        </div>
        {dispalMode == "table" && <ChallengesResultAsTable cc={cc} data={data}></ChallengesResultAsTable>}
        {dispalMode == "list" && <ChallengesResultAsList cc={cc} data={data}></ChallengesResultAsList>}
      </div>
    </>}
 {shareTop10 &&   <PostEdition onClose={() => setShareTop10(false)}
  challengeTop10={getTopTenData(cc, data)} ></PostEdition>}

  </div>)
}
function ChallengesResultAsTable(props) {
  let cc = props.cc;
  return <div className="table-responsive">
    <table className="table">
      <thead>
        <tr>
          <th rowSpan={2} className="wd-5p nbsp">&nbsp;</th>
          <th rowSpan={2} className="wd-45p pl">{t(mapAudience(cc.challengeLevel))}</th>
          <th rowSpan={2} className="wd-45p pl ">{t("Points")}</th>
          <th rowSpan={2} className="wd-45p pl ">{t("Rang")}</th>
          <th rowSpan={2} className="pl" >{t("Gain")}</th>
          {(cc.indicatorsContribution || []).map((x, ii) => {
            return <th key={"th-"+ii} className="pl" colSpan={2} > <strong>{t(x.indicator?.name)}</strong></th>
          })}

          {
            cc.allowAllQuiz && <th className="pl" colSpan={2} > <strong>{t("Quiz")}</strong></th>
          }
          {(cc.trainingContribution || []).map((x, ii) => {
            return <th key={"thx-"+ii} className="pl" colSpan={2} > <strong>{t(x.training?.name)}</strong></th>
          })}

        </tr>
        <tr>

          {(cc.indicatorsContribution || []).map((x) => {
            return < >
              <th className="pl"   ><strong>{t(mapTRO_Acheived(x.input))}</strong></th>
              <th className="pl"   ><strong>{t("Pts")}</strong></th>
            </>
          })}


          {
            cc.allowAllQuiz && <>
              <th className="pl"   ><strong>{t("Q.C")}</strong></th>
              <th className="pl"   ><strong>{t("Pts")}</strong></th>
            </>

          }
          {(cc.trainingContribution || []).map((x) => {
            return <>
              <th className="pl"   ><strong>{t("Q.C")}</strong></th>
              <th className="pl"   ><strong>{t("Pts")}</strong></th>
            </>
          })}



        </tr>
      </thead>
      <tbody>
        {props.data.map((d: ChallengeData, i) => {
          return (
            <tr key={"tr-"+i} className="ind-row">
              <td><i className=""></i></td>
              <td className="pl"  ><strong> {d.employeeName} </strong></td>
              <td className="pl"  > {d.points} </td>
              <td className="pl " > {d.rank}  </td>
              <td className="pl" > <strong>{round2(d.rewarding)}</strong> <small>{d.rewardingUnit}</small>   </td>
              {d.indicatorMeasures.map(m => {
                return <>
                  <td className="pl"  > {round2(m.tro)} </td>
                  <td className="pl " > {round2(m.points)}  </td>
                </>
              })}
            </tr>);

        })}

      </tbody>
    </table>
  </div>
    ;
}
function mapAudience(challengeLevel: number | undefined): string {
  try {
    if (!challengeLevel) {
      return "Collaborateur";
    }
    let ret = appConfig().ouLevels.filter(x => x.name == ("" + challengeLevel))[0].label;
    return ret || "Collaborateur";
  } catch (error) {
    console.error(error);
    return "Collaborateur";
  }

}






export function ChallengeTop10(props) { 
  let data = props.top10.data;
  let cc: ChallengeConfig=props.top10.challengeConfig;
  let date: ChallengeConfig=props.top10.date;
  if(!data ) {
    return <div>No data</div>
  }
  return <div className="list-responsive">

<div className='top10'>
        <div className='tp10-title'>{cc?.title}</div>
        <div className='tp10-date'>{t("Classement au") +" " +date}</div>
        </div>
    {data.map((d: ChallengeData, i) => {
      return (
        <div key={"dcx-"+i} className="list-row-tp10">
          <div className='tp10-cell top-cell-rank'><span className='rankNumber'>{d.rank}</span><small className='rankieme'>{d.rank==1? "er": "ème"}</small></div>
          <div className='tp10-cell  top-cell-collab'>
            {d.employeeId && <Avatar  user={{email: d.employeeId, name: d.employeeName }}></Avatar> } 
          <span className='collab-name'>{d.employeeName} </span></div>
          <div className='tp10-cell  top-cell-point '>{d.points} <small>{t('Point')}</small></div>
          <div className='tp10-cell  top-cell-rewar '> {round2(d.rewarding)}  <small>{d.rewardingUnit}</small></div>
      
        </div>);

    })}


  </div>
    ;
}


function ChallengesResultAsList(props) {
  let cc: ChallengeConfig = props.cc;
  return <div className="list-responsive">

    {props.data.map((d: ChallengeData)=>mapDataForList(d, cc)).map((d: ChallengeData, i) => {
      return (
        <div key={"nfox-"+i} className="list-row">


          <ColA label={t(mapAudience(cc.challengeLevel))} value={d.employeeName} ></ColA>
          <ColA label={t("Points")} value={d.points} ></ColA>
          <ColA label={t("Rang")} value={d.rank} ></ColA>
          <ColA label={t("Gain")} value={<><span>{round2(d.rewarding)}</span> <small>{d.rewardingUnit}</small></>} ></ColA>
          <div className='col-sep'></div>

          {d.indicatorMeasures.map((m, ii) => {
            
           return   <ColB key={"clb-"+ii} label={t(m["name"] || "-")} 
              lab1={t(mapTRO_Acheived(m["input"] || "Q.C"))} val1={round2(m.tro)} 
              lab2={t("Pts")} val2={round2(m.points)} 
            
            ></ColB> 
          })}
        </div>);

    })}


  </div>
    ;
}

function ColA(props) {
  return <div className='colA'>
    <div className='lbl' >{props.label}</div>
    <div className='val' >{props.value}</div>
  </div>
}

function ColB(props) {
  return <div className='colB'>
    <div className='lbl' >{props.label}</div>
    <div className='vals' >
      <div className='g1'>
        <span className='lbl1' >{props.lab1}</span>
        <span className='val1' >{props.val1}</span>
      </div>
      <div className='g2'>
        <span className='lbl2' >{props.lab2}</span>
        <span className='val2' >{props.val2}</span>
      </div>

    </div>
  </div>
}

function mapTRO_Acheived(input: string | undefined): string {
  if (input == "acheived") {
    return "Réalisé"
  }
  if (input == "target") {
    return "T.R.O"
  }
  return "T.R.O.P";
}
function mapTROUnit(input: string | undefined): string {
  if (input == "acheived") {
    return ""
  }
  return "%";
}

function mapDataForList(d: ChallengeData, cc: ChallengeConfig) : ChallengeData {
  let x = cc.indicatorsContribution;
  let y =cc.trainingContribution;
  let trOffset = x.length + (cc.allowAllQuiz? 1:0);
  d.indicatorMeasures.forEach((m,i)=> {
    if( i<x.length ) {
      m["name"] = (x[i].indicator?.name || "-");
      m["input"] = x[i].input;
    } else if (i==x.length && cc.allowAllQuiz) {
      m["name"] ="Quiz"; 
    } else if (y) {
      m["name"] = (y[i-trOffset].training?.name || "-");
    }
  })
  return d;
}

function getTopTenData(cc: ChallengeConfig, data: ChallengeData[]) {
  let ret:  ChallengeData[] = [];
  for(let i=0; i<10 && i<data.length; i++) {
    ret.push({...data[i]});
  }
  return {data:ret, challengeConfig: {title:cc.title}, date: toYYYMMDD(new Date())};
}

