export function round2(s) {
    return  (Math.round(s*100)/100).toLocaleString("fr");
  }

export function round(s, d=0) {
  if(!s) {return 0};
  let x = Math.pow(10, d);
  return (Math.round(s*x)/x).toLocaleString("fr");
  
}

export function computeFontSize(n:any="0", max) {
    let s = (""+n).length;
   if(s<4) {
     return Math.round(max/4)+"px"; 
   }
   return Math.round(max/s)+"px"; 
}