import './feed-wall.css'
import { POSTS, Post, Comment, TENDENCES } from './data';
import { useEffect, useState } from 'react';
import KpiBoxGauge from '../perf-indicators/KpiBoxGauge';
import { PostEdition } from './post-edition';
import { Avatar } from '../ui/avatar';
import { addComment, getPost, markPost, searchPost, searchPostDebounced, updatePost } from '../service/postService';
import ReactQuill from 'react-quill';
import { quillFormats, quillModules } from './text-editor';
import { currentUser } from '../service/userService';
import { printShortDate, printSmartDate } from '../help/dates';
import { useHistory, useParams } from 'react-router';
import { t } from '../i18n';
import KpiBoxA from '../perf-indicators/KpiBoxA';
import { KpiTrend } from '../perf-indicators/KpiTrend';
import KpiChartBarsVertical from '../perf-indicators/KpiChartBarsVertical';
import KpiCardTable from '../perf-indicators/KpiCardTable';
import { hasPermission } from '../types/permissions';
import { computeFontSize } from '../help/formating';
import { ChallengeTop10 } from '../challenge/challenges-result';
const image2Url = "";
const PAGE_SIZE = 5;
function SharePostEntry(props: any) {
    let [postVstt, setPostVstt] = useState(null as any);
    let startPost = (vstt: any) => {
        setPostVstt(vstt);
    }

    let endPostEdition = (post: Post) => {
        setPostVstt(null);
        if (props.onEndPostEdition) {
            props.onEndPostEdition(post);
        }
    }
    useEffect(() => {
        if (props.dndFile) {
            setPostVstt({ addPhoto: true, dndFile: props.dndFile })
        }
    }, [props.dndFile]);
    return (
        <div className="share-box-feed-entry-card  box-card">
            <div className="share-box-feed-entry-card-top"> 
                <button className="input-like-button fa fa-plus " onClick={() => startPost({})}>
                    
                </button>
            </div>
            <div className="subtitle-add-post">{t("Ajouter un nouveau post")}</div>
            
            {postVstt && <PostEdition vstt={postVstt} onClose={endPostEdition} ></PostEdition>}
  
        </div>);
}

function OrderByBlock(props: any) {
    let [order, setOrder] = useState(t("Pertinence"));
    let [expanded, setExpanded] = useState(false);
    let setSortBy = (by: string) => {
        if (by == 'pertinence') {
            setOrder(t("Pertinence"));
        } else {
            setOrder(t("Récent"));
        }
        setExpanded(false);
        props.onSetSrotBy(by);
    }
    let activateFiter=(fn)=> {
        if(fn==="myComment") {
            GSS.criteria.myComment = !GSS.criteria.myComment;
        } else if(fn==="mines") {
            GSS.criteria.mines = !GSS.criteria.mines;
        } else if(fn==="marked"){
            GSS.criteria.marked = !GSS.criteria.marked;
        } 
        GSS.criteria.userId = currentUser().email;
        props.reload();
        setExpanded(false);
    }
    return (
        <div className="order-by-block"> 
            <div className="my-channels" >
            {currentUser().groups?.map((g,ii) => {
                let active = GSS.criteria.channelName == g.value ;
                return (
                <span key={g.id+"-"+ii} className={"activable-link " + (active && "active")}
                    onClick={() => { GSS.criteria.channelName = active ? '' : (g.value + ''); props.reload() }}
                > 
                {active && <i className='fa fa-check' ></i>} {g.label} </span> 
                
               
                );
            })}
            </div> 
            <span onClick={() => setExpanded(!expanded)} style={{cursor:'pointer'}}> 
                <span className="order-by-value" >{order}</span>
                <i className="typcn typcn-arrow-sorted-down"></i>
            </span>
            {expanded && <div className="drop-lst">
                <div onClick={() => setSortBy('date')} className="drop-lst-opt">
                <i className="fa fa-clock"></i> <span>{t("Récent")}</span></div>
                <div onClick={() => setSortBy('pertinence')} className="drop-lst-opt">
                <i className="fa fa-star"></i> <span> {t("Pertinence")}</span></div>
               
                <div className={"activable-link " + (GSS.criteria.mines && "active")} 
                  onClick={() => {   activateFiter("mines");   }}
                ><i className="fa fa-at"></i><span>{t("Mes posts")} </span></div>
                <div className={"activable-link " + (GSS.criteria.myComment && "active")}
                    onClick={() => {   activateFiter("myComment");   }}
                ><i className="fa fa-comment"></i><span>{t("Mes commentaires")} </span></div>

                <div className={"activable-link " + (GSS.criteria.marked && "active")}
                     onClick={() => {   activateFiter("marked");   }}

                ><i className="fa fa-bookmark"></i><span>{t("Eléments enregistrés")} </span></div>
           
            </div>
            }
        </div>)
}

function PostVideo(props: any) {
    let [controls, setControls] = useState(false);
    return (
        <video className="feed-embedded-video"
            onMouseEnter={() => setControls(true)}
            onMouseOut={() => setControls(false)}
            controls={controls} autoPlay={false} muted >
            <source src={props.url} type="video/mp4" />
        </video>)
}

function PostPhoto(props: any) {
    return (
        <img className="feed-phot" src={props.url} style={{ width: "100%", display: "block" }}>

        </img>)
}

function PostText(props: any) { 
    let max = 200;
    let text: string = props.text;
    let [showResume, setShowResume]= useState(text?.length> max);
    
    return    <div className={ showResume ? "resume-wrapper":  "full-text-wrapper"}    >
        <div  className={"feed-text " + (props.sharingText ? " sharing-text " : "")  } 
    dangerouslySetInnerHTML={{ __html:  props.text }}></div>
    { showResume &&   <button className='read-more' onClick={()=>setShowResume(false)}>... {t("Voir plus")}</button>}
    </div>  
    
       
  
    
}



export function SharedFeed(props: any) {
    let history = useHistory();
    let [addCollab, setAddCollab] = useState(false);
    let [confirmDeletePost, setConfirmDeletePost] = useState(false);
    let [like, setLike] = useState(false);
    let [startShare, setStartShare] = useState(false);
    let [commentText, setCommentText] = useState('');
    let [showComments, setShowComments] = useState(false);
    let [startReeditPost, setStartReeditPost] = useState(false);
    let postCommentText = "";
    let [post, setPost] = useState(props.post as Post);
    let mylikes = post.comments?.filter(c => c.like && c.author?.email == currentUser().email);
    let [likedByMe, setLikedByMe] = useState(mylikes && mylikes.length > 0);
    let embedded = props.embedded;
    let handleCommentTextChange = (content: any, delta: any, source: any, editor: any) => {
        postCommentText = editor.getText(content);
        console.log({ postCommentText });
        setCommentText(content);
    }
    let addCollaboration = (type: 'Like' | 'Comment') => {
        if (type === 'Like') {
            let lk = likedByMe;
            like = !lk;
            setLike(!lk);
            setLikedByMe(!lk);
            postComment('like', !lk);
            let delta: number = like ? 1 : -1;
            post.likesCount = post.likesCount + delta;
        } else {
            setAddCollab(true);
        }

    }
    let postComment = (type, lk: boolean) => {
        let comment: Comment = {
            author: { name: currentUser().fullName, email: currentUser().email },
            publicationDate: new Date(),
            like: type == 'like',
            reference: '', 
            contentData: type == 'comment' ? commentText : '',
            type: type
        };
        addComment(post.reference || "", comment, (type == 'like' && !lk) ? "delete-comment" : 'add-comment').then(
            (r: Comment) => {
                console.log("add comment result", r);
                if (!post.comments) { post.comments = [] }
                post.comments?.push(r);
                if (type == "comment") {
                    post.commentsCount = post.commentsCount + 1;
                }

                setPost(post);
                setAddCollab(false);
                setCommentText("");

            }
        );
    }
    let deletePost = () => {
        console.log("delete post", post);
        GSS.criteria.requestTime = new Date().getTime();
        updatePost(post, 'delete').then((r) => { history.push("/feed/" + GSS.criteria.requestTime) });
    }
    let markCurrentPost = () => {
        markPost(post).then((r) => {
            console.log("mark result", r);
            if (r && r.observers) {
                post.observers = r.observers;
                setPost({ ...post })
            }
        });

    }

    let toggleTendencePost = ()=> {
        post.forAll = !post.forAll;
        updatePost(post, "toggle-for-all").then((r) => {
            console.log("toggle-for-all", r);
            if (r ) { 
                post.forAll = r.forAll;
                setPost({ ...post })
            }
        });
    }
    let sfa = !props.asTendence || hasPermission("post", "ww");
    return (
        <div className={"shared-feed-card box-card " +( props.asTendence? 'asTendence' : '')} >
           {sfa && <div className="feeding-actions">
                <i className="fa fa-ellipsis-h" ></i>
                {(hasPermission("post", "ww") || post.actor.email == currentUser().email) &&!props.asTendence &&
                    <div onClick={() => setConfirmDeletePost(true)} className="inner" title={t("Supprimer")}>
                        <i className="fa fa-times"></i>
                    </div>
                }

                {post.actor.email == currentUser().email && <div onClick={() => setStartReeditPost(true)} className="inner" title={t("Editer")}>
                    <i className="fa fa-edit"></i>
                </div>

                }
                {!props.asTendence && <div onClick={markCurrentPost}
                    className={"inner " + (post.observers && post.observers.includes(currentUser().email) && "marked-post")} title={t("Enregistrer")}>
                    <i className="fa fa-bookmark"></i>
                </div>}
                {hasPermission("post", "ww")  && <div 
                onClick={toggleTendencePost} className="inner" title={t("Ajouter à la une")}>
                    <i className={"fa fa-newspaper "+ (post.forAll? "for-all" : "")}></i>
                </div>

                }
            </div>}
            {startReeditPost && <PostEdition onClose={(p) => { setStartReeditPost(false); props.onEndPostEdition(p); }} reedit={true} post={post} ></PostEdition>}

            {post.feeder && <div className="shared-feed-header">

                <div className="feeder">
                    <span className="feeder-name">{post.feeder.name} </span>
                    {post.channels && <>  <span className="feed-reason"> {" " + t("a partagé ceci avec") + " "} </span>
                        {post.channels.map((cnl, ii) => <span key={"snl-"+ii} className="pcnl">{ii > 0 && ", "}{cnl.title}</span>)}</>}

                </div>
            </div>}
            <div className="shared-feed-core">
                <div className="shared-feed-actor">
                    <Avatar user={post.actor}  ></Avatar>
                    <div className="shared-feed-actor-info">
                        <div className="actor-name">{post.actor?.name}  </div>
                        <div className="actor-title">{post.actor?.title}</div>
                        {post.postAge && <div className="actor-age">{post.postAge}</div>}
                        {post.initialChannels && <div className="post-channels">{post.initialChannels.map((cnl, ii) => <span key={"cnl-"+ii} className="cnl">{ii > 0 && ", "}{cnl.title}</span>)}</div>}
                        {post.publicationDate && <div className="actor-age">{printSmartDate(post.publicationDate)}</div>}
                    </div>
                </div>
                <div className="shared-feed-description"></div>
                <div className="shared-feed-content">
                    {post.sharingText && <PostText text={post.sharingText} sharingText={true}></PostText>}
                    {post.text && <PostText text={post.text}></PostText>}
                    {post.video && <PostVideo url={post.video}></PostVideo>}
                    {post.photo && <PostPhoto url={post.photo}></PostPhoto>}
                    {post.attachments && post.attachments.length > 0 &&
                        post.attachments.map((a,ii) => {
                            return <div key={"att-"+ii} className="attachment-block" >
                                <a href={a.link}><i className="typcn typcn-attachment-outline"></i> <span>{a.name}</span></a>  </div>
                        })}
                    {post.kpi && post.kpi.kpiRenderer == 'KpiBoxGauge' && <KpiBoxGauge embedded={true} indicatorData={post.kpi}></KpiBoxGauge>}
                    {post.kpi && post.kpi.kpiRenderer == 'KpiBoxA' && <KpiBoxA embedded={true} indicatorData={post.kpi}></KpiBoxA>}
                    {post.kpi && post.kpi.kpiRenderer == 'KpiTrend' && <KpiTrend embedded={true} kpi={post.kpi}></KpiTrend>}
                    {post.kpi && post.kpi.kpiRenderer == 'KpiChartBarsVertical' && <KpiChartBarsVertical embedded={true} kpi={post.kpi}></KpiChartBarsVertical>}
                    {post.kpi && post.kpi.kpiRenderer == 'KpiCardTable' && <KpiCardTable embedded={true} kpi={post.kpi}></KpiCardTable>}
                    {post.challengeTop10 &&   <ChallengeTop10 embedded={true} top10={post.challengeTop10}></ChallengeTop10>}



                </div>

            </div>
            {!embedded && !props.asTendence && 
                <div className="shared-feed-activity">
                    <div className="shared-feed-activity-counts">
                        <div>{post.likesCount > 0 && <> <i className="fa fa-thumbs-up"></i><span> {post.likesCount}  </span></>}   </div>
                        {post.commentsCount > post.likesCount && <span className="comment-count" onClick={() => setShowComments(!showComments)}>
                            {mapCommentCountToLabel(post.commentsCount - post.likesCount)}
                        </span>}
                    </div>
                  { !props.asTendence && <div className="shared-feed-activity-actions">
                        <div className={likedByMe ? "active" : ""} onClick={() => addCollaboration('Like')}>
                            <i className="fa fa-thumbs-up"></i><span> J'aime</span>
                        </div>
                        <div onClick={() => addCollaboration('Comment')} >
                            <i className="fa fa-comment"></i><span> Commenter</span>
                        </div>
                        <div onClick={() => setStartShare(true)} >
                            <i className="fa fa-share"></i><span>Partager</span>
                        </div>

                        
                    </div>}

                </div>
            }
            {!embedded && startShare && <PostEdition onClose={() => { setStartShare(false); if (props.onEndPostEdition) { props.onEndPostEdition(); } }} sharing={true} post={post} ></PostEdition>}
            {!embedded && addCollab && <div className="shared-feed-activity-collab">

                <div className="new-collab">
                    <Avatar connectedUser ></Avatar>
                    <ReactQuill modules={quillModules} formats={quillFormats}
                        value={commentText} placeholder={t("Ajouter un commentaire")}
                        onChange={handleCommentTextChange} />
                </div>
                {commentText &&
                    <div className="push-comment-line">
                        <div className="push-comment-btn" onClick={() => postComment("comment", false)} >
                            <i className="fa fa-share"></i><span> Publier</span>
                        </div>
                    </div>}
            </div>}
            {!embedded && showComments && post.comments && post.comments.filter(cmt => cmt.contentData).map((cmt, ii) => {
                return <CommentPanel key={ii} comment={cmt} post={post} onDelete={()=>setPost({...post})} ></CommentPanel>
            })}

            {confirmDeletePost && <div className="modal-screen">
                <div className="popup">
                    <i className="fa fa-times" onClick={() => setConfirmDeletePost(false)}></i>
                    <p>
                        Etes-vous sûr de vouloir supprimer ce post?
                    </p>
                    <a onClick={deletePost} className="btn btn-purple delete-btn">
                        <i className="fa fa-trash"></i>
                        <span>Confirmer la suppression</span>
                    </a>

                </div>
            </div>}
        </div>);
}

function CommentPanel(props: any) {
    let [cmtText, setCmtText] = useState(props.comment.contentData);
    let [confirmDeleteComment, setConfirmDeleteComment] = useState(false);
    let [startReeditComment, setStartReeditComment] = useState(false);
    
    let cmt = props.comment;
    let post = props.post;

    let deleteComment=()=>{
        addComment(post.reference || "", cmt,  "delete-comment"  ).then(
            (r: Comment) => {
                console.log("add comment result", r);
                post.comments = post.comments.filter(c=>c!=cmt);
                props.onDelete();

            }
        );
    }
    let handleCommentTextChange = (content: any, delta: any, source: any, editor: any) => { 
        setCmtText(content);
    }
    let saveComment =  () => {
        cmt.contenData = cmtText;
        cmt.publicationDate = new Date();
        addComment(post.reference || "", cmt,  "save-comment"  ).then(
            (r: Comment) => {
                console.log("dave comment result", r); 
                setStartReeditComment(false);

            }
        );
    }
    return (<div className="shared-feed-activity-comment">
        <Avatar user={cmt.author} />
        { !startReeditComment ?
        <div className="comment-content" >
            <div className="comment-content-head">
                <span className='comment-author'>{cmt.author?.name}</span>
                <span>{printSmartDate(cmt.publicationDate)}</span>
                <div className="feeding-actions comment-actions">
                    <i className="fa fa-ellipsis-h" ></i>
                    {(hasPermission("post", "ww") || cmt.author?.email == currentUser().email) &&
                        <div onClick={() => setConfirmDeleteComment(true)} className="inner" title={t("Supprimer")}>
                            <i className="fa fa-times"></i>
                        </div>
                    }

                    {post.actor.email == currentUser().email && <div onClick={() => setStartReeditComment(true)} className="inner" title={t("Editer")}>
                        <i className="fa fa-edit"></i>
                    </div>

                    }
                </div>
            </div>
            <div className="comment-content-text" dangerouslySetInnerHTML={{ __html: cmt.contentData || '' }} ></div>
        </div>
        :  
        <div className='comment-reedit'>
            <ReactQuill modules={quillModules} formats={quillFormats}
                        value={ cmtText} placeholder={t("Ajouter un commentaire")}
                        onChange={handleCommentTextChange} />
            {cmtText &&
                    <div className="push-comment-line">
                        <div className="push-comment-btn" onClick={() => saveComment()} >
                            <i className="fa fa-share"></i><span> Publier</span>
                        </div>
                    </div>}
        </div>
        }

        {confirmDeleteComment && <div className="modal-screen">
                    <div className="popup">
                        <i className="fa fa-times" onClick={()=>setConfirmDeleteComment(false)}></i>
                        <p>
                            Etes-vous sûr de vouloir supprimer ce commantaire? 
                        </p>
                    <a  onClick={deleteComment}   className="btn btn-purple delete-btn">
                      <i className="fa fa-trash"></i> 
                      <span>Confirmer la suppression</span>
                      </a> 

                    </div>
                </div>}
        </div>); 
}
export const GSS = {
    criteria: {
        channelName: '',
        keyWord: '',
        sortBy: '',
        sortDir: '',
        first: 0,
        size: 0,
        max: PAGE_SIZE,
        requestTime: 0,
        mines: false,
        myComment: false,
        marked: false,
        userId: currentUser()?.email,
    }
};
function mapDtoToPost(dto: Post): Post {
    let p: Post = { ...dto };
    try {
        let data = JSON.parse(dto.contentData || "");
        if (data.textHtml) {
            p.text = data.textHtml;
        }
        if (data.photoLink) {
            p.photo = data.photoLink;
        }
        if (data.videoLink) {
            p.video = data.videoLink;
        }
        if (data.sharingHtml) {
            p.sharingText = data.sharingHtml;
        }

        if (data.kpiData) {
            p.kpi = data.kpiData;
        }
        if (data.attachments) {
            p.attachments = data.attachments;
        }
        if(data.challengeTop10) {
            p.challengeTop10 = data.challengeTop10;
        }


    } catch (error) {
        console.log("Error in mapDtoToPost", error);
    }

    return p;
}
export default function TimeLine(props: any) {
    let { reference: reference } = useParams() as any;
    let { timestamp } = useParams() as any;
    let [ssx, setSsx] = useState(1); 
    const [posts, setPosts] = useState(POSTS);
    const [tendences, setTendences] = useState(TENDENCES);
    window["setPosts"] = setPosts;
    let [loading, setLoading] = useState(false); 
    let [dndFile, setDndFile] = useState(null);

    let loadPosts = () => {
        if (loading) {
            return;
        }
        loading = true;
        setLoading(loading);
        console.log("loading=" + loading + ", GSS1=", JSON.stringify(GSS));
        GSS.criteria.first = GSS.criteria.first + Math.ceil(GSS.criteria.size / PAGE_SIZE);
        console.log("GSS2=", JSON.stringify(GSS));
        searchPostDebounced(preprocessPostRequest(GSS.criteria)).then(
            (resp: any) => {
                console.log("DEBOUNCED RESULT", resp);
                if (resp.result) {
                    resp.result.map(mapDtoToPost).forEach(pst => {
                        posts.push(pst);
                    });
                    //  posts = [...posts, ...resp.result.map(mapDtoToPost)];
                    GSS.criteria.size = resp.result.length;
                    setPosts(posts);
                }
                loading = false;
                setLoading(loading);
            },
            err => {
                loading = false;
                setLoading(loading);
            }
        )
    }



    useEffect(()=>{
        if(!tendences || !tendences.length) {
            searchPost({ 
                "keyWord": "",
                "sortBy": "creationTime",
                "sortDir": "DESC",  
                "first": 0,
                "size": 0,
                "max": 3,
                "requestTime": 0,
                "forAll": true, 
              }).then(
                (resp: any) => {
                    console.log("TENDENCE RESULT", resp);
                    if (resp.result) {
                        resp.result.map(mapDtoToPost).forEach(pst => {
                            tendences.push(pst);
                        });
                       
                        setTendences(tendences);
                    } 
                },
              );
        }
        loadPosts();
    }, []);
    useEffect(() => { 
        let scrollHandler = (evt) => {  
            if(loading) return;
            let scrollTop = evt.srcElement.scrollTop;
            let scrollHeight = evt.srcElement.scrollHeight;
            let clientHeight = document.documentElement.clientHeight; 
            if (scrollTop + clientHeight >= scrollHeight - 25) {
                console.log("load with scrol for scroll");
                loadPosts();
            }
        }
        //".main-content-scrollable"
        (document as any).querySelector( ".main-content-scrollable .feed-column" ).addEventListener("scroll", scrollHandler);
        return (() => {
            let sd = (document as any).querySelector(".main-content-scrollable  .feed-column");
            if (sd) {
                sd.removeEventListener("scroll", scrollHandler);
            }
        });
    }, [ ]);
   /* useEffect(() => {
        console.log(">>loading " + loading + " props.timestamp=", props.timestamp);
        GSS.criteria.size = 0;
        GSS.criteria.first = 0;
        posts.length = 0; 
        setPosts([]);
        loading = false;
        setLoading(false);
        loadPosts();
    }, [timestamp, props.timestamp])*/
    let onSetSrotBy = (by: string) => {
        console.log("onSetSrotBy xx " + ssx, by);

        posts.length = 0;
        //posts = [];
        setPosts([]);
        if (by == "pertinence") {
            GSS.criteria.sortBy = "likesCount";
            GSS.criteria.sortDir = "DESC";
        } else {
            GSS.criteria.sortBy = "";
        }
        GSS.criteria.size = 0;
        GSS.criteria.first = 0;
       reload();
    }
    let reload = () => { 
        GSS.criteria.size = 0;
        GSS.criteria.first = 0;
        posts.length = 0;
        // posts = [];
        setPosts(posts);
        console.log("reload posts.length=" + posts.length)
        loadPosts();
    }
    window["reloadPosts"] = reload;
   


    useEffect(() => {
        if (reference) {
            getPost(reference).then(dto => {
                if (dto) {
                    let pst = mapDtoToPost(dto);
                    if (pst) {
                        posts.unshift(pst);
                        //posts = [pst, ...posts];
                        setPosts(posts);
                    }
                }
            })
        }
    }, [reference]);

    let onDropFile = (ev) => {
        console.log("onDropFile", ev);
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    console.log('... file[' + i + '].name = ' + file.name);
                    setDndFile(file);
                    break
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                setDndFile(ev.dataTransfer.files[i]);
                console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
                break;
            }
        }
    }
    let dragOverHandler = (ev) => {
        ev.preventDefault();
    }
    return ( 
        <div onDrop={(evt) => onDropFile(evt)} onDragOver={evt => dragOverHandler(evt)} 
        className={"time-line " }>
            
            <div className="feed-column">
            <div className="column-title">
                   {t("Fil d'actualité")}
               </div>
                <SharePostEntry dndFile={dndFile} onEndPostEdition={reload}></SharePostEntry>
                <OrderByBlock onSetSrotBy={onSetSrotBy} reload={reload} ></OrderByBlock>
                {posts.map((post: Post, indx) => {
                    return (<SharedFeed key={post.reference + "-" + indx} post={post} onEndPostEdition={reload}></SharedFeed>)
                })}


            </div>

            <div className="tendence-column">
               <div className="column-title">
                   {t("à la une")}
               </div>
                {tendences.map((post: Post, indx) => {
                    return (<SharedFeed key={post.reference + "-" + indx} asTendence={true} post={post} onEndPostEdition={()=>{}}></SharedFeed>)
                })}


            </div>
          
        </div>



    )
}


function FilterControl(props) {
    return (
        <div className="channel-card box-card" >
            <div className="box-header">
                <h6 className="box-title">{t("Filtres")}</h6>
                <a className="box-ctrl-btns">
                    <i onClick={() => props.setShowRightControl(false)} className="icon ion-md-arrow-back"></i>
                </a>
            </div>
            <div className="box-detail">
                <div className={"activable-link " + (GSS.criteria.mines && "active")}
                    onClick={() => {
                        GSS.criteria.mines = !GSS.criteria.mines;
                        GSS.criteria.userId = currentUser().email; props.reload()
                    }}
                ><i className="fa fa-at"></i><span>{t("Mes posts")} </span></div>
                <div className={"activable-link " + (GSS.criteria.myComment && "active")}
                    onClick={() => {
                        GSS.criteria.myComment = !GSS.criteria.myComment;
                        GSS.criteria.userId = currentUser().email; props.reload()
                    }}
                ><i className="fa fa-comment"></i><span>{t("Mes commentaires")} </span></div>

                <div className={"activable-link " + (GSS.criteria.marked && "active")}
                    onClick={() => {
                        GSS.criteria.marked = !GSS.criteria.marked;
                        GSS.criteria.userId = currentUser().email; props.reload()
                    }}

                ><i className="fa fa-bookmark"></i><span>{t("Eléments enregistrés")} </span></div>
            </div>
        </div>);
}
function GroupControl(props) {
    return (<div className="channel-card box-card" >
        <div className="box-header">
            <h6 className="box-title">{t("Mes groupes")}</h6>
            <a className="box-ctrl-btns">
                <i onClick={() => props.setShowLeftControl(false)} className="icon ion-md-arrow-back"></i>
            </a>
        </div>
        <div className="box-detail" >
            {currentUser().groups?.map((g,ii) => {
                return (<div key={g.id+"-"+ii} className={"activable-link " + (GSS.criteria.channelName == g.value && "active")}
                    onClick={() => { GSS.criteria.channelName = (GSS.criteria.channelName == g.value) ? '' : (g.value + ''); props.reload() }}
                ><i className="fa fa-users-cog"></i><span>{g.label} </span></div>
                );
            })}
        </div>
    </div>)
}

function preprocessPostRequest(criteria: { channelName: string; keyWord: string; sortBy: string; sortDir: string; first: number; size: number; max: number; requestTime: number; mines: boolean; myComment: boolean; marked: boolean; userId: string; }): any {
    let ret = { ...criteria };
    if (!ret.channelName) {
        let groups = currentUser().groups || [];
        ret.channelName = groups.map(x => x.value).filter(x => !!x).join(",");
    }
    return ret;
}


function mapCommentCountToLabel(count: number): string {
    if (!count) return "";
    if (count === 1) return "1 commentaire";
    return "" + count + " commentaires";
}