import { useState } from "react";
import './dropdown-list.css';


function DropdownOption(props:any) {
    let childrenPath = props.childrenPath || "children";
    let valuePath = props.valuePath || "name";
    let onSelect=props.onSelect;
    let   filter = props.filter;
    let data = props.data;
    let label = data.label || data[valuePath] || data.value || data.name || '-';
    let keyOf=(s:string)=> {return s && s.toLowerCase().replace(/\W/g, "_")}
    let children:any[] = (data[childrenPath] || []).filter((c: any)=>matchOption(c, filter, childrenPath, valuePath)) ;
    let showSubtree = children && props.showSubtree;
  // console.log("options data =", data, "label=", label);
        return  <>  <li key={keyOf(label)} id={keyOf(label)} onClick={(evt)=>onSelect(data)} className="dd-lst-option" >
            {props.optionRender? props.optionRender(data) : label}</li>
        { showSubtree  &&  <ul  className="dd-lst-options-ul" > 
           { children.map((c,i)=> 
            <DropdownOption key={i} filter={filter} data={c} valuePath={valuePath} 
            showSubtree={showSubtree}
            childrenPath={childrenPath}   onSelect={onSelect}>

            </DropdownOption>)} 
           
             </ul>    }
        
         </>;
     

}
export function DropdownList(props:any) {
    let data:any[]  = props.data || [];
    let enableSearch :boolean = props.enableSearch;
    let childrenPath = props.childrenPath || "children";
    let valuePath = props.valuePath || "name";
    let readOnly = props.readOnly;
    let  showValueIfMatchOnly: boolean =props.showValueIfMatchOnly;
    
    let value=props.value;
    let  selectedValue  = findElementByValue(value, data, props.showSubtree, valuePath) ;
    if(!selectedValue && !showValueIfMatchOnly) {
        selectedValue = value;
    }
   
    let [optionFilterText, setOptionFilterText] = useState('');
    let [showPropositions, setShowPorposition] = useState(props.showPropositions);
    let onSelect = (d:any)=> {
        console.log("on select ",d);
        selectedValue = d;
        setShowPorposition(false);
        if(props.onSelect) {
            props.onSelect(d);
        }
    };
    let label = selectedValue && (selectedValue.label ||selectedValue.title || selectedValue[valuePath] || selectedValue.name || selectedValue.value || selectedValue.description ) || ''; 
    return (
       
        <div className={ "dd-lst-container "+ props.className+"  " + (readOnly? "dd-readonly": "")+" " +(showPropositions? " show-propositions ": "")}>
         <div className="dd-lst-disp" onClick={()=>!readOnly && setShowPorposition(!showPropositions)}>
                <span className={"dd-lst-text "+(selectedValue? "filled": "empty")}> {label || props.placeholder || "" }</span> 
                <span className="dd-lst-caret" ><i className=" fa fa-caret-down" ></i></span>
            </div>
            {!readOnly && <div className="dd-lst-propositions">
                {enableSearch && 
                    <div className="dd-lst-search">
                        <input type="text" onChange={(evt)=>setOptionFilterText(evt.target.value)} />
                        <i className="fa fa-search"></i>
                    </div>
                }
                <div className="dd-lst-options">
                    <ul  className="dd-lst-options-ul">
                    {
                        data.filter((c: any)=>matchOption(c, optionFilterText, childrenPath, valuePath))
                        .map((d,i)=>{ 
                        return <DropdownOption key={i}
                        optionRender={props.optionRender} showSubtree={props.showSubtree}
                        filter={optionFilterText} data={d} valuePath={valuePath} childrenPath={childrenPath}   onSelect={onSelect}></DropdownOption> } )
                       
                    }
                    </ul>
                </div>
            </div>
            } 
        </div>
        
        )
}

function matchOption(d: any, optionFilterText: string, childrenPath: string, valuePath: string): boolean {
  //  console.log("matchOption ", optionFilterText, d);
    if(!d) {
        return false;
    }
    if(!optionFilterText) {
        return true;
    }
    let pattern = optionFilterText.toLowerCase();
    if(valuePath && d[valuePath] && d[valuePath].toLowerCase().indexOf(pattern)>-1) {
        return true;
    }
    if(d.name && d.name.toLowerCase().indexOf(pattern)>-1) {
        return true;
    }
    if(d.label && d.label.toLowerCase().indexOf(pattern)>-1) {
        return true;
    }
    if(d.value && d.value.toLowerCase().indexOf(pattern)>-1) {
        return true;
    }

    if(d.toLowerCase && d.toLowerCase().indexOf(pattern)>-1) {
        return true;
    }  
    let children: any[] = d[childrenPath];
    if(children) {
        for(let c of children) {
            if(matchOption(c, optionFilterText, childrenPath, valuePath)) {
                return true;
            }
        }
    } 
   // console.log("rejected option ", d);
    return false;
}
function findElementByValue(value: any, data: any[], subtree:boolean, valuePath: string): any {
    if(!value) {
        return null;
    } 
    if(data && data.length) {
     //   console.log("TBX data.length"+data.length, data);
     for(let d of  data ) {
        if(d==value) { 
            return d;
        }
        if(valuePath) {
            let xx = value[valuePath];
            let yy = d[valuePath];
            if(xx && xx==yy) { 
                return d;
            }  
            
        }
    }
    for(let d of  data ) {
       
        if(valuePath) {
            let xx = value[valuePath];
            let yy = d[valuePath];
            if(xx && (xx==yy || xx.startsWith(yy+"/"))) { 
                return d;
            }   
        }
        if(   value.name &&( d.name==value.name || value.name.startsWith(d.name+"/"))) {
            return d;
        }
        if(subtree && d.children) {
            let sd = findElementByValue(value, d.children, subtree, valuePath);
            if(sd) {
                return sd;
            }
        }
    } 
    }  
}

