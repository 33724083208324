import { useEffect, useState } from "react";
import { getSubKpi, IndicatorData } from "../dashboard/dashboard-data";
import { round2 } from "../help/formating";
import { t } from "../i18n";


function KpiCardTable(props: any) {
  let [data , setData] = useState([] as IndicatorData[]);
    let kpi : IndicatorData = props.kpi;
    useEffect(()=> { 
      if(!kpi) {return} 
      getSubKpi(kpi).then(r=> {
      console.log("POST get SubKpi", r);
      setData(r);
    })}, [props.kpi]) ;
    if(!kpi) {
      return (<></>);
    }
    return (
        <>
                  <div className={ "card card-table-one indicator-card " +props.className }>  
              <h6 className="card-title"><strong>{t(kpi.title || '')}</strong>  {t('pour')} {kpi.resource.label}  {t('au')}  {kpi.timeScope}  </h6>               
              <div className="table-responsive">
               <br/>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="wd-5p">&nbsp;</th>
                      <th className="wd-45p pl"> </th> 
                      <th className="wd-45p pl"> {t("Performance") + " " + mapPariodSfx(kpi.kpiDefinition?.seedPeriodTime)}  </th>
                      <th className="wd-45p pl">{t("Tendance")}</th>
                      <th className="wd-45p pl">{t("Objectif Proraté")}</th>
                      <th className="wd-45p pl">{t("TRO Proraté")}</th>
                      <th className="wd-45p pl">{t("Ecart")}</th>
                      <th className="wd-45p pl">{t("Objectif") + " " + mapPariodToLibelle(kpi.kpiDefinition?.targetPeriodTime)}</th>

{/*
                      <th className="wd-45p pl">{t("Réalisé Cumulé")}</th>
                      <th className="wd-45p pl">{t("Cible Cumulé")}</th>
                      <th className="wd-45p pl">{t("Cible Global")}</th>
*/}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((d: IndicatorData)=> {
                      let avance =  (d.globalAchievment || 0) - 0.01 * (d.targetProrataPercent || 1) * (d.target || 0);
                      return(
                    <tr className="ind-row">
                    <td><i className=""></i></td>
                    <td className="pl"  ><strong> {d.resource.label || d.resource.name} </strong></td>
                  
                    <td  className="pl" > <strong>{round2(d.measure)}</strong> <small>{d.unit}</small></td>
                    <td  className="pl"> {round2(d.deltaPercent)}%</td>
                    <td  className="pl">{round2(0.01 * (d.targetProrataPercent || 1) * (d.target || 0))} <small>{d.unit}</small></td>
                    <td  className="pl">{round2(d.achievmentPercent)}%</td>
                    <td  className="pl">{round2(avance)}<small>{d.unit}</small> </td>
                    <td  className="pl">{round2(d.target)} <small>{d.unit}</small> </td>
                  </tr>);

                    })}
                    
                  </tbody>
                </table>
              </div>  

        </div>
                </>
    );
}

export default KpiCardTable;




export function mapPariodSfx(periodTime: string | undefined) {
  let exp = "";
  if (periodTime == 'week') {
    exp = "de la semaine";
  } else if (periodTime == 'month') {
    exp = "du mois";
  } else if (periodTime == 'year') {
    exp = "de l'année";
  } else if (periodTime == 'day') {
    exp = "du jour";
  }
  return exp;
}


export function mapPariodToLibelle(periodTime: string | undefined) {
  let exp = "";
  if (periodTime == 'week') {
    exp = "Hebdomadaire";
  } else if (periodTime == 'month') {
    exp = "Mensuel";
  } else if (periodTime == 'year') {
    exp = "Annuel";
  } else if (periodTime == 'day') {
    exp = "Journalier";
  }
  return exp;
}
export function mapPariodToLabel(periodTime: string | undefined, label: string) {

  return <div className="timeperiod-label">{label} <strong>{mapPariodToLibelle(periodTime)}</strong></div>
}
