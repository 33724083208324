
import { defaultDico } from "./i18n-default"
import { enDico } from "./i18n-en";
import { frDico } from "./i18n-fr";
import { appConfig, currentUser } from "./service/userService";
const lng = (appConfig().language || navigator.language || "fr").substr(0, 2);
let dico = {};
if (lng == 'fr') {
    dico = { ...defaultDico, ...frDico };
} else if (lng == 'en') {
    dico = { ...defaultDico, ...enDico };
} else {
    dico = { ...defaultDico };
}
window["dico"] = dico;
export function t(s: string, p1 = '', p2 = '', p3 = '') {
    try {
    if (!s) { return s };
    let k = "_" + (s.trim().toLowerCase().replace(/\s+/g, "_"));
    let ret: string = dico[k];
    if (!ret) {
     //   console.log("i18n no key for \"" + k + "\":\"" + s + "\",");
        ret = s;
    }
    if (p1) {
        ret = ret.replace(/\{p1\}/g, p1);
    }
    if (p2) {
        ret = ret.replace(/\{p2\}/g, p2);
    }
    if (p3) {
        ret = ret.replace(/\{p3\}/g, p3);
    }
    return ret;
}  catch(err) {
    console.log("Error for translate ", s, err);
    return "";
}

}