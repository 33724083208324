 
import  ReactQuill , {Quill} from 'react-quill'; 

import quillEmoji from 'react-quill-emoji'; 
import 'react-quill/dist/quill.snow.css';
import "react-quill-emoji/dist/quill-emoji.css";
 

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;
Quill.register({
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji
  }, true); 
export  const quillModules = {
    toolbar: [ 
      ['bold', 'italic', 'underline',   ], 
      [ { 'color': [] }, { 'list': 'bullet' } ], 
      ['emoji']
    ],
    'emoji-toolbar': true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  }


  export  const quillModulesBasic = {
    toolbar: [ 
      ['bold', 'italic', 'underline',   ], 
      [ { 'color': [] }, { 'list': 'bullet' } ],  
    ] 
  }


export const  quillFormats = ['font', 'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'color', 'background', 'list', 'indent', 'align', 'link', 'image', 'clean', 'emoji']
 