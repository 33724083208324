
import '../admin/admin.css';
import ReactQuill from "react-quill";

import { quillFormats, quillModulesBasic } from "../feed-wall/text-editor";
import { t } from '../i18n';
import { ChallengeConfig } from './challenge-type';
import { ForwardRefRenderFunction } from 'react';
import { uploadFile } from '../service/file.service';





export function AssDefMessageTab({ entity, setEntity, readonly }:{
    entity:ChallengeConfig, setEntity:Function , readonly: boolean}) {

    let handleIntroChange = (content: any, delta: any, source: any, editor: any) => {

        setEntity({ ...entity, rules: {...entity.rules,note: content} })
    }
    let onFileChange = (evt: any) => {
        let fileName:string = evt.target.files[0].name ;
        uploadFile(evt, false).then((url: any) => {
            setEntity({ ...entity, rules: {...entity.rules,fileUrl: url, fileName: fileName} })
        });
    }
    
    return <div className="app-form kpi-form b1c" >
        <div className="form-group-fullwidth ">
            <label>{t("Remarques")}</label> 
            {!readonly && <ReactQuill bounds="#quill-ex" modules={quillModulesBasic}
                formats={quillFormats} value={entity.rules.note || ""} onChange={handleIntroChange} />}
            {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.rules.note || '' }} ></div>}
 
        </div>

        <div className="flex-sa"> 
                    <label htmlFor="rules-file" >
                        <div className="btn generate-template">
                            <i className="fa fa-paperclip"></i>
                            <span>Attacher le fichier du règlement</span>
                        </div>  <input type="file" id="rules-file"  onChange={onFileChange}
                            className="h-file-input" accept=".pdf" ></input>
                     </label>
                    {entity.rules.fileName && <span className="file-name">{entity.rules.fileName}</span> }
                  
                </div>

                {entity.rules.fileUrl && <iframe   className="rule-file-fram" src={entity.rules.fileUrl}></iframe>
                       
                    }

    </div>
}