export const enDico  = {  
      "_acteur": "Actor",
"_action": "Action",
"_activer": "Enable",
"_activer_la_forrmation": "Enable Training",
"_activer_le_kpi": "Enable KPI",
"_administration": "Administration",
"_agence": "Agency",
"_ajouter_un_commentaire": "Add Comment",
"_annuler": "Cancel",
"_aperçu": "Preview",
"_autoriser_la_pause": "Allow the break",
"_autoriser_la_reprise": "Allow recovery,",
"_challenges": "Challenges",
"_chargement_des_données": "Loading Data",
"_cible_cumulé": "Target Cumulative",
"_cible_global": "Global Target",
"_cliquez_ici_pour_charger_la_bannière": "Click here to load the banner",
"_cliquez_ici_pour_charger_la_bannière_de_l'application_mobile": "Click here to load the banner of the mobile application",
"_cliquez_ici_pour_charger_un_visuel": "Click here to load a visual",
"_clôture": "Closure",
"_collaborateur": "Collaborator",
"_conclusion": "Conclusion",
"_configuration": "Configuration",
"_confirmer": "Confirm",
"_confirmer_la_suppression": "Confirm Delete",
"_context": "Background",
"_contexte": "Background",
"_correctes": "correct",
"_créé_par": "created by",
"_date_clôture": "Closing Date",
"_date_création": "Creation Date",
"_date_fin": "End Date",
"_date_publication": "Published",
"_date_début": "Start Date",
"_description": "Description",
"_description_du_kpi": "Description of the KPI",
"_direction_régionale": "Regional Directorate",
"_distribution_des_scores": "Distribution of scores",
"_document": "Document",
"_document_pdf": "PDF Document",
"_durée": "Duration",
"_durée_ (min)": "Duration (min)",
"_durée_en_minutes": "Duration (min)",
"_début": "Start",
"_début / end": "Start / End",
"_définition": "Definition",
"_définition_des_formations": "Training settings,",
"_définition_des_kpis": "Settings KPIs",
"_définition_des_quiz": "Settings Quiz",
"_définition_des_sondages": "Polls Settings",
"_délégation": "Delegation",
"_editer": "Edit",
"_email": "Email",
"_employee": "Employees",
"_enité_cible_bottom": "Low Level",
"_enité_cible_top": "Max Level",
"_enter_your_email": "Enter your email",
"_enter_your_password": "Type your CDM",
"_entité": "Entity",
"_erreurs": "Errors",
"_espagnole": "Spanish",
"_etapes_&_modules": "Stages & Modules",
"_explication": "Explanation",
"_fait_le": "Dated",
"_famille": "Family",
"_famille_du_kpi": "Family KPI",
"_fermer": "Close",
"_fichier_de_données": "Data File",
"_fichier_des_objectifs": "Targets File",
"_fin": "End",
"_formation": "Training",
"_formations": "Training",
"_gain": "Gain",
"_groupe": "Group",
"_groupes": "Groups",
"_groupes_de_suivi": "Monitoring Groups",
"_générer_la_template": "Generate template",
"_initializer_mdp": "Initializer CDM",
"_introduction": "Introduction",
"_kpi": "KPI",
"_langue": "Language",
"_lié_à_une_formation": "Linked to training",
"_message": "Messages",
"_message_d'encouragement": "Incentive message",
"_message_de_félicitation": "Message of congratulations",
"_module": "module",
"_niveau": "Level",
"_nom": "Name",
"_nom_complet": "Full Name",
"_nom_de_l'unité": "Unit Name",
"_nom_du_kpi": "Name of the KPI",
"_nom / first name": "Name / Surname",
"_nombre_de_réponse": "Number of Responses",
"_nombre_de_points": "Number of points",
"_nombre_de_question": "Number of questions",
"_nombre_de_réponses": "Total Answer",
"_note_de_passage": "Score Validation",
"_nouveau_post": "New Post",
"_nouveau_quiz": "New Quiz",
"_nouveau_sondage": "New Poll",
"_nouvelle_formation": "New Training",
"_option": "Option",
"_organisation": "Organization",
"_par": "For",
"_parcours": "Course",
"_passing_score": "Score Validation",
"_password": "Password",
"_performances": "Performance",
"_permissions": "Permissions",
"_photo": "Picture",
"_poid": "Weight",
"_points": "Points",
"_poster": "Poster",
"_profil": "Profile",
"_profils": "Profiles",
"_profils_concorerés": "relevant profiles",
"_prénom": "first name",
"_presentation_power_point": "Power Point presentation",
"_pts": "Pts",
"_publication": "Publication",
"_période_d'objectif": "Period of objectification",
"_période_d'objectifs": "objectivation period",
"_période_d'affichage": "Display period",
"_période_d'import": "Import Period",
"_période_de_suivi": "Tracking period",
 


"_que_souhaitez-vous_partager?": "Share an idea, advice, good practice ...?",
"_question": "question",
"_questions": "Questions",
"_quiz": "Quiz",
"_rang": "rank",
"_rechercher_un_quiz": "Find a quiz",
"_rechercher_un_sondage": "search for a survey",
"_rechercher_un_kpi": "search for kpi",
"_rechercher_un_profil": "search for a profile",
"_rechercher_un_sxtupator": "Find a user",
"_rechercher_une_sxformation": "search for training",
"_rechercher_une_unity_organizational": "Find an organizational unit",
"_ressource_youtube": "YouTube resource",
"_review": "Review",
"_revu_des_reponsses": "Review of answers",
"_realized": "realized",
"_realized_cumulative": "carried out cumulative",
"_réponse": "Answer",
"_réponses": "Answers",
"_résultas": "Results",
"_saisir_une_nouvelle_unité": "Enter a new unit",
"_score": "score",
"_search_for_anything...": "Search ...",
"_siège": "Seat",
"_sondage_anonyme": "Anonymous Survey",
"_sondages": "Polls",
"_sous_famille_du_kpi": "Under KPI's family",
"_sous_famille": "under Funny",
"_statistique_des_reponsses": "Response Statistics",
"_statistics_des_reponsses_correct": "Statistics of the correct answers",
"_statistics": "Statistics",
"_statut": "Status",
"_supprimer": "Delete",
"_tro": "Objective realization rate",
"_temps_(min)": "Time (min)",
"_temps_restant": "Remaining time",
"_tendance": "Trend",
"_texte_de_la_bannière": "Text of the banner",
"_text_de_la_banniere_mobile": "Text of the mobile banner",
"_time_limitation": "Time limitation",
"_titre": "Title",
"_titre_de_la_formation": "Title of the training",
"_type": "Type",
"_type_de_la_ressource": "Type of resource",
"_type_de_meure": "Type of measurement",
"_u.o": "u.o",
"_url_youtube": "URL YouTube",
"_unité_commercial": "Commercial Unit",
"_unité_mère": "Mother unit",
"_unité_organisationnelle": "Organizational Unit",
"_unité_de_mesure": "Unit of measure",
"_utilisateurs": "Users",
"_vidéo": "Video",
"_vour_score": "your score",
"_vour_temps": "Your time",
"_étape": "Step", 
"_définition_des_challenges": "Definition of challenges",
"_précision_d'affichage": "Display accuracy",
"_nombre_de_chiffres_apès/avant_la_virgule...": "Digits after / before the comma ...",
"_arrondir_l'objectif_proraté": "Rounding probeled goal",  
"_mot_de_passe": "Password", 
"_règlement" : "Rules", 



"_français": "French",
"_anglais": "English",
"_gains": "Gains",
"_rechercher_un_challenge": "search for a challenge",
"_nounce_challenge": "New Challenge",
"_challenge": "Challenge",
"_valider": "validate",
"_postes": "Posts",
"_pilotage": "Monitoring",
"_data_du_pilotage": "KPI data",
"_ancien_mot_de_passe": "Old password",
"_nounce_mot_de_passe": "New password",
"_comnfirmer_le_nounce_mot_de_passe": "confirm the new password",
 "_mon_profil": "My Profile",
 "_me_déconnecter" : "Disconnect",
 
"_mentions_et_réactions": "Mentions et reactions",
"_eléments_enregistrés": "Marked posts",
"_filtres": "Filters",
"_mes_groupes": "My channels",

"_mes_commentaires": "My comments",
"_mes_posts": "My posts",
"_commencer_un_post": "Start a new post", 
"_classer_par": "Sort by",
"_pertinence" : "Relevance",
"_récent": "Recent",


"_notification":"Notification",
"_avatar":"Avatar", 

"_audience":"Audience", 

"_t.r.o":"T.R.O",
"_q.c":"Q.C"

}