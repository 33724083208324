import { request } from "http";

 
const dataCache = {}
const dataCheTimestamps={}
export async function post(url: string, data:any, useCache=0) { 
    if(useCache>0) {
        let k = buildKey(url, data); 
        if(dataCache[k] && (new Date().getTime()-dataCheTimestamps[k]< useCache)) {
            return dataCache[k];
        }else {
           delete dataCache[k];
           delete dataCheTimestamps[k];
        }
    }
    let response:Response = await fetch(url,  
        {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json', 
                'Accept': 'application/json',
            },
            body: data ? JSON.stringify( data) : null
        });
    if(response.status==401) {
        document.location.href = "/login"
        return;
    } 
    let ret =  await response.json();
    if(useCache) {
        let k = buildKey(url, data);
        dataCache[k]=ret;
        dataCheTimestamps[k]= new Date().getTime();
    }
    return ret;
}

export async function get(url: string ) {
    let response:Response = await fetch(url );
    if(response.status==401) {
        document.location.href = "/login";
        return;
    }
    return await response.json();
}

function buildKey(url: string, request: any ) : string {
   return url+"___"+JSON.stringify(request || "");
}
