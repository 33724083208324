 
import { useEffect, useState } from "react";
import { toYYYMMDD, toYYY_MM_DD } from "../help/dates";
import { Entry, Group } from "../types/people";
import { MultItemField } from "../ui/mutli-item-field";
import '../admin/admin.css';
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { searchGroups } from "../service/userService";  
import { Resource } from "../types/kpi-model"; 
import ReactQuill from "react-quill";

import { quillFormats } from "../feed-wall/text-editor"; 
import { TrainingDefinition } from "./training-type";
import { uploadFile } from "../service/file.service";
import { t } from "../i18n";

let ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT") || "[]");
 



export function TrainingDefinitionTab({entity , setEntity, readonly } : {entity:TrainingDefinition, setEntity: Function, readonly: boolean}) {
    
    
    let [resourceList, setResourceList] = useState(ALL_ENTITIES_FLAT as Resource[]);
      let [modules, setModules] = useState([{ reference: "ref1", name: "Mollit commodo duis velit", }]);
    
    let [allGroups, setAllGroups] = useState([]);  
    useEffect(() => { 

        searchGroups({}).then(r => {
            allGroups = (r.result.map((x: Group) => { let e: Entry = { value: x.groupQN, label: x.label, id: x.id }; return e; }))
            setAllGroups(allGroups);
        })
    } , ["entity"]);
    console.log("rendereing traininf def tab");
    let onWebBannerChange = (evt: any) => {
        uploadFile(evt).then((link: any) => {
            entity.photoUrl = link;
            setEntity({...entity}); 
        });
    }
    return  <div className="app-form kpi-form b1c" >
        
     
        <div className="form-group-fullwidth  ">
             <label>{t("Titre ")}</label>
             <input readOnly={readonly} onChange={e => setEntity({ ...entity, title: e.target.value})} value={entity.title}
                 type="text" className="form-control" placeholder={t("Titre de la formation")}/>
         </div>
         <div className="form-group-fullwidth">
                    <label>{t("Description")}</label> 
                    {!readonly &&  <ReactQuill
                        bounds="#quill-ex"
                        
                        formats={quillFormats}
                        value={entity.description || ''} placeholder={t("")}
                        onChange={(content:any, delta:any, source:any, editor:any) => {  
                            setEntity({ ...entity, description: ""+content , descriptionTxt:  editor.getText(content)}) }} />
                    }

                    {readonly && <div className="paragraph" dangerouslySetInnerHTML={{__html: entity.description|| '...'}} ></div>} 

                </div>
                <br/>
         <div className="form-group-fullwidth  ">
                    <label htmlFor="bannerFile" >
                        {!readonly && <div className="banner-placeholder">{t("Cliquez ici pour charger un visuel")} </div>}
                        {readonly && <div className="label">Image</div>}
                        {entity.photoUrl && <div className="client-banner-edition">
                           {!readonly && <i onClick={() => setEntity({ ...entity, photoUrl: '' })} className="fa fa-times" ></i>}
                            <img src={entity.photoUrl}></img>
                        </div>
                        }
                        {!entity.photoUrl && <i className="nobanner fa fa-image" ></i>}
                     </label>
                    {!readonly && <input onChange={onWebBannerChange} type="file" id="bannerFile" className="h-file-input" accept="image/*"></input>}
                </div>
              

       
       
     <div className="form-g2c" > 
        


         <div className="form-group ">
             <label>{t("Date Publication ")}</label>
             <input readOnly={readonly} type="date"
                 onChange={e => setEntity({ ...entity, publicationDate: new Date(e.target.value) })}
                 value={toYYY_MM_DD(entity.publicationDate)}
                 className="form-control" />
         </div>
         <div className="form-group ">
             <label>{t("Date Clôture ")}</label>
             <input readOnly={readonly} type="date"
                 onChange={e => setEntity({ ...entity, closingDate: new Date(e.target.value) })}
                 value={toYYY_MM_DD(entity.closingDate)}
                 className="form-control" />
         </div>



         <div className="form-group ">
             <label>{t("Note de passage")}</label>
             <input readOnly={readonly} type="number"
                 onChange={e => setEntity({ ...entity, passingScore: parseInt(e.target.value) })}
                 value={entity.passingScore + ""}
                 className="form-control" />
         </div>



         <div className="form-group ">
             <label>{t("Durée (min)")}</label>
             <input readOnly={readonly} type="number"
                 onChange={e => setEntity({ ...entity, durationMin: parseInt(e.target.value) })}
                 value={entity.durationMin + ""}
                 className="form-control" />
         </div>


         <div className="form-group ">
             <label>{t("Entité")}</label>
             <DropdownList
                 optionRender={d => (<><div>{d.title}</div><div><small>{d.qn}</small></div></>)}
                 data={resourceList} value={entity.targetEntity}
                 onSelect={(d) => { setEntity({ ...entity, targetEntity: d }) }} enableSearch={true}
             ></DropdownList>
         </div>
         <div className="form-group ">
             <label>{t("Profils concernés")}</label>
             <MultItemField data={entity.monitoringGroups}
                 setData={(d) => { console.log("set monitoringGroups",d) ;entity.monitoringGroups = d; setEntity({...entity}); }}
                 items={allGroups} noDataMessage="Aucun groupe" mode={readonly ? 'display' : 'edit' }  ></MultItemField>

         </div>

         <div className="form-group ">
                    <label>{t("Activer la forrmation")}</label>
                    <div onClick={() => setEntity({ ...entity, activated: !entity.activated })}
                        className={"az-toggle az-toggle-success " + (entity.activated ? 'on' : 'off')}><span></span></div>
                </div>
</div>
</div>
}