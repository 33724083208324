import {   useEffect, useState } from "react"
import { setSyntheticLeadingComments } from "typescript";
import { DropdownList } from "./dropdown-list/dropdown-list";

function isMatched(a,b) {
  console.log("isMatched ", a, b);
  if(a.reference && !a.id) {
    a.id=a.reference;
  }
  if(b.reference && !b.id) {
    b.id=b.reference;
  }
  return a===b || (a.id && a.id==b.id) || (a.name && a.name == b.name) 
  || (a.value && a.value==b.value) || (a.label && a.label==b.label);
}
function addToSet(set:any[], d):any[] {
  let nSet:any[]= (set || []).filter(x=> { let m = isMatched(x, d); console.log(m); return !m});
  console.log("nset", nSet);
  nSet.push(d);
  return nSet;
}
export function MultItemField(props: any) { 
    let [data, setSttData] = useState((props.data as any[]) || []);
    useEffect(()=>{
      setSttData(props.data);
    }, [props.data]); 
    let [select, setSelect] = useState(false);
    
    let setData = (d:any[])=> {
      console.log("setData ", d);
      setSttData(d); 
       if(props.setData) { props.setData(d); }  
      } ;
     
    let items:any[] = props.items;
    let mode  = props.mode;
    console.log("MultItemField>> data=", data, props.data);
    let noDataMessage = props.noDataMessage;
    return (
      <div>
        <div className="multival-bloc" onClick={()=>{if(mode=='edit' && (!data || data.length==0)) {setSelect(true)}}}>
          <div   className="multival-bloc-vals">
            {data && data.map(p =>
              <div key={p.id || p.reference || p.value || p.name} className="multival-item profile-badge">
                <div className='multival-val' >{p.label || p.value|| p.title}</div> 
                {mode == 'edit' && <i 
                onClick={()=>{setData(data.filter(x=>x!=p))}} className="close-btn fa fa-times"></i>}
              </div>
            )}
            {(!data || !data.length) &&
              <div className="no-item-message">{noDataMessage}</div>
            }

          </div>
          {mode == 'edit' && <div className="multival-bloc-bag">
            <i onClick={() => setSelect(!select)} 
            className={""+ (select? "fas  fa-minus-square": "fas  fa-plus-square")}></i>
          </div>}

        </div>
        <div> 
          {select &&
            <DropdownList className="multival-bloc-droplist" data={items}
            showPropositions={true}
              onSelect={(d: any) => { setData(addToSet(data,d)); setSelect(false)}}
              enableSearch ></DropdownList>
          }
        </div>
      </div>)
}