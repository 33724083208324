import { IndicatorData } from "../dashboard/dashboard-data";
import { Collaborator } from "../test-data/entities";
import { Entry } from "../types/people";


export interface KpiContribution {
    indicator?: {reference, name, unit}
    formulaType : 'LINEAR' | 'LMM' |'STEPS' |'LINEAR-STEPS',
    formulCoefs: {  x?:number, y?:number} [], 
    input?: 'target' | 'acheived', 
    maxPoints:number,

}
export interface TrainingContribution {
    training?: {reference, name}
    formulaType : 'LINEAR' | 'LMM' |'STEPS' |'LINEAR-STEPS',
    formulCoefs: {ratio?: number, x?:number, y?:number}  [],
    quiz?: string[], 
    maxPoints:number,
    bonus?:number;

}

export interface Reward {
    input: 'score' | 'rank',
    rankingScope?: string;
    rewardType: 'remuneration' | 'points' | 'prize' ,
    rewardAmount? : number,
    rewardNature? : string,
    rewardUnit: string,
    rewardDescription? : string,
    formulaType : 'LINEAR' | 'LMM' |'STEPS' |'LINEAR-STEPS',
    formulCoefs: {ratio?: number, x?:number, y?:number}  [],
    rankingFrom?: number,
    rankingTo?: number,
    exclusive?: boolean;

}
export interface ChallengeConfig {
    name: string,
    title: string,
    description: string,
    reference?: string,
    points:number, 
    status: 'OPEN' | 'CLOSED' | 'CANCELED';
    inicatorsName: string[],
    indicatorsContribution : KpiContribution [],
    trainingContribution :TrainingContribution [],
    rewards: Reward[];
    rewarding : number,
    rewardingUnit? : string  ,
    rewardingType? : string  ,
    publicationDate?: Date,
    closeDate?: Date,
    rules : {
        fileUrl?:string,
        fileName?: string,
        note?: string;
    } 

    monitoringGroups?: Entry[]; 
    targetEntity?:Entry;
    challengeLevel?:number;
    owner?:Entry;

    activated?: boolean,
    enabled?: boolean,
    changeTime?:Date;
    creationTime?:Date;
    createdBy?:String;
    modifiedBy?:string;
    data: ChallengeData[];
    allowAllQuiz?:boolean;
    allQuizPointByQuestion?:number;

}

export interface ChallengeData { 
    employeeName: string;
    employeeId: string;
    entityQN: string,
    name: string,
    title: string,
    points:number,
    rank:number,
    indicatorMeasures: {tro: number, points:number}[],
    rewarding : number,
    rewardingUnit : string ,
    rewardName?;

}


export function evaluatePoints(  input: number,  { formulaType,   formulCoefs} ) {
    if(!formulCoefs  || formulCoefs.length==0) {
        return 0;
    }
    let prev = formulCoefs[0];
    if(input < prev.x) {
        return prev.y;
    }
    for( let  i=1; i<formulCoefs.length; i++) {
        const next = formulCoefs[i];
        if( input <next.x || i==(formulCoefs.length-1) ) {
            if("STEPS"==(formulaType) || (next.x==prev.x)) {
                return prev.y;
            }
            return prev.y + (input-prev.x)*(next.y-prev.y)/(next.x-prev.x);
        }
        prev =next;
    }
     
    return 0;
}