import { AssessmentDefinition, AssessmentInstance } from "../assessment/assessment-type"; 
import { post } from "./http.service";
import { currentUser } from "./userService";
const urlBase = '/api/assessment/definition';//'http://localhost:11002';
const instanceUrlBase =  '/api/assessment/quiz-instance';
export async function addAssessmentDefinition(p:AssessmentDefinition) : Promise<any> {
    let data = await post(  urlBase+ "/create", { payload: p}) ;  
     
    if (!data.success || !data.body) {
        return null;
    } 
    return data.body;
}

export async function updateAssessmentDefinition(p:AssessmentDefinition, action: string) : Promise<any> {
  let data = await post(  urlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

export async function getAssessmentDefinitionById(id: string)   { 
  let data = await post(    urlBase+ "/get",{action: "load", reference: id}) ;   
      
  return data;
}
  

let quizzesMenu:any[] ;
export function getQuizzsMenu()  {
  if(!quizzesMenu) {
    quizzesMenu = JSON.parse(localStorage.getItem("QUIZZES_MENU")||"[]");
  }
  return quizzesMenu;
} 

export function persistQuiz( ai:AssessmentInstance)  {
  let all = getQuizzsMenu();
  all = all.filter(x=>ai.definition.reference !=x.definition.reference);
  all.push(ai);
  if(all) {
   localStorage.setItem("QUIZZES_MENU" , JSON.stringify(all));
  }
  
} 







export async function updateAssessmentInstance(p:AssessmentInstance, action: string) : Promise<any> {
  let data = await post(  instanceUrlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}
export async function searchAssessmentInstance(request: any)   {   
  let data = await post(  instanceUrlBase+ "/search",request) ;   
  console.log("searchAssessmentDefinition ", data)
  return data;
}    

export async function searchAssessmentForMenu()   {   
  let data = await post(  instanceUrlBase+ "/search",{action: "MY-ELIGIBLES", actor: currentUser().email}) ;   
  console.log("searchAssessmentForMenu ", data)
  if(data.result) {
    localStorage.setItem("QUIZZES_MENU", JSON.stringify(data.result));
    quizzesMenu=data.result;
  }
  return data?.result;
}    



export async function searchAssessmentDefinition(request: any)   {   
  let data = await post(  urlBase+ "/search",request) ;   
  console.log("searchAssessmentDefinition ", data)
  return data;
}    
export async function searchAssessmentDefinitionDebounced(request: any)   {  
     return debounce1(searchAssessmentDefinition, 300)(request);
}

let timer1:any = null;
function debounce1(f, interval:number) {  
    return (...args) => {
      clearTimeout(timer1);
      return new Promise((resolve) => {
        timer1 = setTimeout(
          () => resolve(f(...args)),
          interval,
        );
      });
  };
}


