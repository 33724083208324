import { useEffect, useState } from "react";
import { VictoryAxis, VictoryChart, VictoryCursorContainer, VictoryLabel, VictoryLine } from "victory";
import { t } from "../i18n";
import { searchKpiDef } from "../service/kpiService";
import { searchTrainingDefinition } from "../service/trainingService";
import { appConfig } from "../service/userService";
import { KpiDefinition } from "../types/kpi-model";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { ChallengeConfig, evaluatePoints, Reward } from "./challenge-type";

export function ChallengeReward(props: any) {
    let readonly = props.readonly;
    let [expanded, setExpanded] = useState(0);
    let [entity, setEntity] = [props.entity as ChallengeConfig, props.setEntity];
    let onDeleteContribution = (q: any, indx: number) => {
        console.log("onDeleteContribution", indx);
        entity.rewards.splice(indx - 1, 1);
        setEntity({ ...entity });
        //    submit('update');
        console.log("onDeleteContribution quiz", entity);

    }
    let onSaveContribution = (q: any, indx: number) => {
        console.log("onSaveContribution", q);
        entity.rewards[indx - 1] = q;
        setEntity({ ...entity });

        console.log("onSaveContribution quiz", entity);
        //  submit('update');

    }
    return <div>
        {!readonly && <div className="flex-pr">
            <span onClick={() => {
                entity.rewards.push({
                    exclusive:false, rankingScope:"0",
                    input: 'rank', rewardUnit: appConfig().currency|| 'MAD', formulaType: 'LMM', rewardAmount: 0, rewardType: 'remuneration',
                    formulCoefs: [{ x: 0, y: 0 }, { x: 100, y: 100 }]
                }); setEntity({ ...entity })
            }}
                className="tlb-btn tex-btn"><i className="fa fa-plus-square"></i> Ajouter un Gain</span>
        </div>
        }
        {entity.rewards.map((q, id) => <RewardBlock
            onExpand={(ix) => setExpanded(ix)}
            expanded={id == expanded}
            onSave={onSaveContribution} onDelete={onDeleteContribution} key={id} indx={id + 1} contrib={q} readOnly={readonly}
            setEntity={setEntity} entity={entity} ></RewardBlock>)
        }
    </div>
}

function mapFormulaTypeLabel(tx: string): string {
    if (tx == 'LINEAR') { return t("Linéaire"); }
    if (tx == 'LMM') { return t("Linéaire avec Min/Max"); }
    if (tx == 'STEPS') { return t("Paliers"); }
    if (tx == 'LINEAR-STEPS') { return t("Linéaire par morceaux"); }
    return tx;
}
function mapFormulaInput(tx: string): string {
    if (tx == 'score') { return t("En fonction du score (Points)"); }
    if (tx == 'rank') { return t("En fonction du classement"); }
    return tx;
}
function mapRewardType(tx: string): string {
    if (tx == 'remuneration') { return t("Rémuneration"); }
    if (tx == 'prize') { return t("Prix"); }
    if (tx == 'points') { return t("Points"); }
    return tx;
}
export function RewardBlock(props) {

    let readOnly = props.readOnly;
    let tnp = props.tnp;
    let [contrib, setContribIn] = useState((props.contrib) as Reward);

    let [entity, setEntity] = [props.entity as ChallengeConfig, props.setEntity];
    let indx = props.indx;
    let setContrib = (x) => {
        entity.rewards[indx - 1] = x;
        setContribIn(x);
        setEntity(entity);
    }


    let removeCoefBlock = (c, i) => {
          contrib.formulCoefs.splice(i, 1);
        console.log("removeCoefBlock", contrib.formulCoefs);
        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
    }
    let addCoefBlock = (c, i) => {
        let nc = { ...c };
        nc.x = c.x + 1;
        contrib.formulCoefs.splice(i + 1, 0, nc);
        console.log("addCoefBlock", contrib.formulCoefs);
        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
    }
    let postSelectFormulaType = () => {

        contrib.formulCoefs = [{ x: 0, y: 0 }, { x: 100, y: contrib.rewardAmount || 0 }]


        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
        console.log("postSelectFormulaType", contrib);
    }

    let ouLevels = appConfig().ouLevels;
    return (
        <div className={"question-block " + (props.expanded ? "expanded" : "collapsed")} >
            <div className="app-form kpi-form" >

                <div className="block-header question-block-header" onClick={() => props.onExpand(indx - 1)}>
                    <h5>
                        <span>{indx} - </span>
                        <span> {contrib.rewardDescription}</span>
                    </h5>
                    <span>
                        <span> <i className="fa fa-trophy"></i>  {mapRewardType(contrib.rewardType)}  </span>

                        {!readOnly && <>
                            <span onClick={() => props.onDelete(contrib, indx)} className="tlb-btn"><i className="fa fa-ban"></i></span>
                        </>
                        }
                    </span>
                </div>

                <div className="flex-c2  hidable-panel-body">

                    <div className="form-group ">
                        <label>{t("Type de récompense")}</label>
                        <DropdownList
                            onSelect={e => {
                                contrib.rewardType = e.name;
                                contrib.rewardUnit = e.name == 'remuneration' ? (appConfig().currency || 'MAD') : e.name == 'remuneration' ? 'Points' : 'Unité';
                                setContrib({ ...contrib })
                                }
                            }
                            data={['remuneration', 'points', 'prize'].map(w => { return { name: w, label: mapRewardType(w) } })} 
                            value={{ name: contrib.rewardType }} 
                            className="flex-child-11" ></DropdownList>
                    </div>
                    <div className="form-group ">
                        <label>{t("Description")}</label>
                        <input onChange={e => {
                            contrib = ({ ...contrib, rewardDescription: e.target.value });
                            setContrib(contrib);
                        }}
                            value={contrib.rewardDescription}
                            className="form-control" placeholder={t("Description")} />
                    </div>
                    <div className="form-group ">
                        <label>{t("Référence du calcul")}</label>
                        <DropdownList onSelect={e => setContrib({ ...contrib, input: e.name })}
                            data={['score', 'rank'].map(w => { return { name: w, label: mapFormulaInput(w) } })} value={{ name: contrib.input }} className="flex-child-11" ></DropdownList>
                    </div>
                    <div className="form-group ">
                        <label>{t("Valeur")} (en {t(contrib.rewardUnit)})</label>
                        <input onChange={e => {
                            contrib = ({ ...contrib, rewardAmount: parseInt(e.target.value) });
                            postSelectFormulaType();
                        }}
                            value={contrib.rewardAmount}
                            type="number" className="form-control" placeholder={t("Nombre de points")} />
                    </div>
                    {contrib.input == 'rank' && <>
                        <div className="form-group ">
                            <label>{t("Classement Haut/Bas (inclus)")}</label>
                            <div className="flex-c2">
                                <input onChange={e => {
                                    contrib = ({ ...contrib, rankingFrom: parseInt(e.target.value) });
                                    postSelectFormulaType();
                                }} readOnly={readOnly}
                                    value={contrib.rankingFrom}
                                    type="number" className="form-control" placeholder={t("Premier classement concérné")} />
                                <input onChange={e => {
                                    contrib = ({ ...contrib, rankingTo: parseInt(e.target.value) });
                                    postSelectFormulaType();
                                }}
                                    readOnly={readOnly}
                                    value={contrib.rankingTo}
                                    type="number" className="form-control" placeholder={t("Dernier classement concérné")} />

                            </div>
                        </div>
                        <div className="form-group ">
                            <label>{t("Entité de classement")}</label>
                            <DropdownList readOnly={readOnly} data={ouLevels} value={{ name: "" + contrib.rankingScope }}
                                onSelect={(d) => setContrib({ ...contrib, rankingScope: parseInt(d.name) })}
                            ></DropdownList>
                        </div>


                    </>}

                    <div className="form-group ">
                    <label>{t("Exclusive")}</label>
                    <div onClick={() => setContrib({ ...contrib,exclusive : !contrib.exclusive })}
                        className={"az-toggle az-toggle-success " + (contrib.exclusive ? 'on' : 'off')}><span></span></div>
                </div>

                </div>
                {contrib.input == 'score' && contrib.rewardAmount && <div className="coefficient-block  hidable-panel-body">
                    <div className="coefficient-block-left" >
                        <div className="form-group ">
                            <label>{t("Type de formule")}</label>
                            <DropdownList onSelect={e => {
                                contrib = { ...contrib, formulaType: e.name };
                                postSelectFormulaType();
                            }}
                                data={['LINEAR', 'LMM', 'STEPS', 'LINEAR-STEPS'].map(w => { return { name: w, label: mapFormulaTypeLabel(w) } })} value={{ name: contrib.formulaType }} className="flex-child-11" ></DropdownList>
                        </div>
                        {contrib.formulCoefs.map((c, indx) => <div key={indx} className="coef-block">
                            <div className="form-group">
                                <label>{t("Seuil")}</label>
                                <div className="flex-container">
                                    <input
                                        onChange={e => { c.x = parseFloat(e.target.value); setContrib({ ...contrib }) }}
                                        value={c.x || 0} step="1" type="number" className="form-control" />
                                    <span className="unit-badge"> {t("Points")}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t("Valeur")}</label>
                                <div className="flex-container">
                                    <input
                                        onChange={e => { c.y = parseFloat(e.target.value); setContrib({ ...contrib }) }}
                                        value={c.y || 0} step="1" type="number" className="form-control" />
                                    <span className="unit-badge">{t("Points")}</span>
                                </div>
                            </div>
                            <div className="form-btn">
                                <label>&nbsp;</label>
                                {(contrib.formulaType == 'STEPS' || contrib.formulaType == 'LINEAR-STEPS') && <div className="flex-pr">
                                    <span className="tlb-btn"><i onClick={() => removeCoefBlock(c, indx)} className="fa fa-ban"></i></span>

                                    <span className="tlb-btn"><i onClick={() => addCoefBlock(c, indx)} className="fa fa-plus-square"></i></span>

                                </div>}
                            </div>

                        </div>)}

                    </div>
                    <GraphFunction contrib={contrib} ></GraphFunction>

                </div>
                }
            </div>
        </div>
    )
}

function GraphFunction({ contrib }: { contrib: Reward }) {
    let data: { x, y }[] = [{ x: 0, y: 0 }];
    let pre = { x: 0, y: 0 }
    for (let c of contrib.formulCoefs) {
        let x = Math.max(pre.x, c.x || pre.x);
        let y = Math.max(pre.y, c.y || pre.y);
        if (contrib.formulaType == 'STEPS') {
            data.push({ x: x, y: pre.y })
        }
        data.push({ x, y });
        pre = { x, y }
    }
    if (contrib.formulaType == 'STEPS') {
        data.push({ x: pre.x + 10, y: pre.y })
    }
    return <div className="graph-function-box">
        <VictoryChart
            containerComponent={
                <VictoryCursorContainer cursorDimension="x" cursorLabel={({ datum }: any) => {
                    console.log(">>>", datum)
                    return `${Math.round(datum.x)} Points , ${Math.round(evaluatePoints(datum.x, contrib))} Points`

                }} />}
        >
            <VictoryAxis label={t("Score")} />
            <VictoryAxis dependentAxis axisLabelComponent={<VictoryLabel dy={-12} />} label="Nombre de points" />
            <VictoryLine
                style={{
                    data: { stroke: "#c43a31" },
                    parent: { border: "1px solid #ccc" }
                }}
                data={data}
            />
        </VictoryChart>
    </div>
}


