import { t } from "../i18n";
import { Period } from "../types/kpi-model";

export function timeSpanOf(p:Period):string {
  if(p.type=='day' ) {
    return toYYYMMDD(new Date(p.start|| ""));
  }
  if(p.type=='month' ) {
    return toYYYMMDD(new Date(p.start|| "")).substr(0, 7);
  }
  if(p.type=='year' ) {
    return toYYYMMDD(new Date(p.start|| "")).substr(0, 4);
  }
  let s =  toYYYMMDD(new Date(p.start|| ""));
  let ed = new Date(new Date(p.end || "").getTime() -60*60*1000) ;
  let e = toYYYMMDD( ed );
  let i = 0
  for(  i=0; i<s.length; i++) {
    if(e.charAt(i)!=s.charAt(i) && s.charAt(i)!='-' ) {
      break;
    }
  }
  if(i<4) {
    i=0;
  } else if(i <8) {
    i=5;
  } else {
    i = 8;
  }
  return  s+"/"+ e.substring(i);
 
}

export function printShortDate(d:Date | undefined ):string {
  try {
    if(!d) {
        return '';
    }
    if(d instanceof Date) {
  
    } else {
      d= new Date(d);
    }
    return (""+(100+d.getDate())).substring(1) +"/"+(""+(100+d.getMonth()+1)).substring(1)+"/"+d.getFullYear();
    } catch(err) {
      console.log("Error in date formatter ",d);
    
      console.error("Error in date formatter ",d);
      return '';
    }
}




export function toYYY_MM_DD(d:Date | undefined ):string {
  try {
  if(!d) {
      return '';
  }
  if(d instanceof Date) {

  } else {
    d= new Date(d);
  }
  return   d.getFullYear()+"-"+(""+(100+d.getMonth()+1)).substring(1)+"-"+   (""+(100+d.getDate())).substring(1)    ;
  } catch(err) {
    console.log("Error in date formatter ",d); 
    return '';
  }
  
}


export function toYYYMMDD(d:Date | undefined ):string {
  try {
  if(!d) {
      return '';
  }
  if(d instanceof Date) {

  } else {
    d= new Date(d);
  }
  return    (""+(100+d.getDate())).substring(1)   +"/"+(""+(100+d.getMonth()+1)).substring(1)+"/"+  d.getFullYear();
  } catch(err) {
    console.log("Error in date formatter ",d);
  
    console.error("Error in date formatter ",d);
    return '';
  }
  
}


export function toYYYMMDDHHMM(d:Date | undefined ):string {
  try {
  if(!d) {
      return '';
  }
  if(d instanceof Date) {

  } else {
    d= new Date(d);
  }
  return toYYYMMDD(d) +" "+(""+(100+d.getHours())).substring(1)+":"+(""+(100+d.getMinutes())).substring(1);
  } catch(err) {
    console.log("Error in date formatter ",d);
  
    console.error("Error in date formatter ",d);
    return '';
  }
  
}


export function printSmartDate(date:Date | undefined, inverse=false ):string {
    if(!date ) {
        return '';
    }
    if(!date.getTime) {
        date = new Date(date);
    }
    let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    if(inverse) {
      seconds = -seconds;
    }

  let interval = seconds / 31536000;

  if (interval > 1) {
    return printShortDate(date);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
   return printShortDate(date); // return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " "+t("Jours");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " "+t("heures");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " "+t("minutes");
  }
  if(seconds>20) {
    return Math.floor(seconds) + " "+t("seconds");
  }
   return t("Maintenant")
}