import { useState } from "react";

export function Popup(props) {
    let [show, setShow]=useState(true); 
     let onClose=()=> {
         if(props.onClose) {
            props.onClose();
         } else {
             setShow(false);
         }
     }
     let onAction=(name)=>{
        if(props.onAction) {
            props.onAction(name);
        } else {
            setShow(false);
        }
     }
    return <>
    {show &&  <div className="modal-screen">
            <div className={"popup-inner "+ (props.medium && " medium ")+ (props.large && " large ")+ (props.center && " center ")+" "+(props.small && "small")}>
                <i className="fa fa-times" onClick={onClose}></i>
                {props.header && <div className="popup-header">{props.header} </div>}
                <div className="popup-body">
                  {props.children}
                </div>
                <div className="popup-footer">
                    {props.actions && props.actions.map(a=>   <a onClick={()=>onAction(a.name)} 
                    className={"btn btn-purple "+ a.name+"-btn"}>
                    {a.icon && <i className={"fa fa-"+a.icon}></i>}
                    {a.label && <span>{a.label}</span>}
                </a>)}
              
                </div>

            </div>
        </div>
   }
    </>
}