import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router"; 
import { printShortDate } from "../help/dates";
import { t } from "../i18n";
import { getOUById, updateOU } from "../service/userService";
import { ROOT_ENTITIES } from "../test-data/entities";
import { OrganizationUnit, UserProfile } from "../types/people";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { MultItemField } from "../ui/mutli-item-field";
import './admin.css';
const ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT") || "[]");
 
export function OU(props:any) {
    let {id:id, mode:inMode} = useParams() as any; 
    let [entity  , setEntity]  = useState(({} as OrganizationUnit ));
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode);
    let [error, setError] = useState(''); 
    let history = useHistory();
    let onDelete=()=> {
        entity = { ...entity, enabled:false}
        updateOU(entity, "delete").then((u)=>{ 
            setShowPopup(false);
            history.push("/admin/ou");
         })
    }
    let submit= (action:string)=> {
        if(action=='update' || action=='create') {
            if(!entity.name) {
                setError("Le nom est obligatoire")
                return;
            }
            if(!entity.label) {
                setError("La description   est obligatoire")
                return;
            }
            if(!entity.entityQN) {
                setError("Le nom complet est obligatoire")
                return;
            }
             
        }
        updateOU(entity, action).then((u)=>{ 
            if(u.body)  {  
                setEntity(u.body);
                setMode('display');
                id=u.body.id;
             } 
            });
    }
    useEffect(()=> { 
            if(id && id!="00") {
                getOUById(id).then((u)=>u.body && setEntity(u.body));
            }
        }
        , [id]);
    let actions:any[] = [];
    let readonly=mode!='create' && mode!='edit';
    if(mode=='display') {
     actions.push({
         label: t("Editer"),
         icon: "fa fa-edit",
         handler: ()=> { setMode('edit')}
     });
    
     
    actions.push({
        label: t("Fermer"),
        icon: "fa fa-times",
        handler: ()=> { history.push("/admin/ou")}
    });
    } else if (mode=='edit' || mode=='create') {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: ()=> { submit('update')}
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: ()=> { setMode('display')}
        });
    }
    return (<div className="config-form">
        
        <div className="assessment" > 
    <div className="assmt-header">
    <h3 className="assmt-title">{entity.name}</h3>
    <div className="assmt-dates az-content-header-right" > 
           {entity.creationTime && <div className="media">
              <div className="media-body">
                <label>{t("Par")} <strong> { (entity.modifiedBy || entity.createdBy)}</strong> </label> 
                <h6> {printShortDate(entity.changeTime || entity.creationTime)}</h6>
              </div>
            </div>}
            <div className="media noextramarge">
              <div className="media-body actions-toolbar">
                  {actions.map(a=>  <a  onClick={a.handler}   className="btn btn-purple">
                      <i className={a.icon}></i> 
                      <span>{a.label}</span>
                      </a>
               )}
              </div>
            </div>
    </div>
    </div>
    </div>

            <div className="app-form flex-sb" >
                <div className="col-left" >
                <div className="form-group e"> 
                    <label>{t("Nom de l'unité")}</label>
                    <input readOnly={readonly}   onChange={e=> setEntity({...entity, name : e.target.value})} value={entity.name} 
                     type="text" className="form-control" placeholder={t("Nom")} />
                   
                    
                </div> 
                
              
                <div className="form-group">
                    <label>{t("Description ")}</label>
                    <input  readOnly={readonly}     onChange={e=> setEntity({...entity, label : e.target.value})} value={entity.label} 
                     type="text" className="form-control" placeholder={t("Description")} />
                    </div>
                
                <div className="form-group  ">
                {readonly && <>    <label>{t("Nom Complet")}</label>
                   <input   readOnly={readonly}     value={entity.entityQN} 
                     type="text" className="form-control"   />
                     </>
                    }
                   {!readonly && <>    <label>{t("Unité Mère")}</label>
                      <DropdownList className="user-entity-droplist" data={ALL_ENTITIES_FLAT} value={{name: entity.name, label: entity.label}}  onSelect={(d:any)=>setEntity ({ ...entity, entityQN : d.entityQN+"/"+entity.name})} enableSearch ></DropdownList>
                    </>
                    }

                    {!readonly &&  <a  onClick={()=>setShowPopup(true)}   className="btn btn-purple delete-btn">
                      <i className="fa fa-trash"></i> 
                      <span>{t("Supprimer cette U.O")}</span>
                      </a> 
                    }
                </div> 

              
              
              
                </div>
                <div className="col-right" >
                    {error && <p className="form-error">{error}</p>}
                </div>
                
            </div>
            {showPopup && <div className="modal-screen">
                    <div className="popup">
                        <i className="fa fa-times" onClick={()=>setShowPopup(false)}></i>
                        <p>
                            Etes-vous sûr de vouloir supprimer cette Unité? 
                        </p>
                    <a  onClick={onDelete}   className="btn btn-purple delete-btn">
                      <i className="fa fa-trash"></i> 
                      <span>Confirmer la suppression</span>
                      </a> 

                    </div>
                </div>}
    </div>);
}