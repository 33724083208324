import { useEffect, useState } from 'react'; 
import { t } from '../i18n';
import { addMessage, searchMessage, updateMessage } from '../service/messageService';
import { currentUser, searchUsers } from '../service/userService'; 
import './message.css';
import { Message, MessageBlock } from './message-type';
import { Popup } from '../ui/popup';
import { DropdownList } from '../ui/dropdown-list/dropdown-list';
import ReactQuill from 'react-quill';
import { quillFormats, quillModulesBasic } from '../feed-wall/text-editor'; 
import { uploadFile } from '../service/file.service'; 
const ALL_USERS: { email, name }[] = JSON.parse(localStorage.getItem("ORGANIZATION_TREE") || "{\"people\": []}").people;
 





function newMessage():Message {
let message:Message =  { blocks: [], title: '', messageType: 'p2p', messageStatus: 'open', actor: { name: currentUser().fullName,  email: currentUser().email } }  ;
return message;
}
function preprocessForAnswer(m:Message):Message{
  let message:Message = JSON.parse(JSON.stringify(m));
  message.blocks[0].actor= message.actor;
  message.messageStatus='open';
  message.receiver = {...m.actor};
  message.actor = {name: currentUser().fullName,  email: currentUser().email}
  return message;
}
export function EditMessage(props: any) {
  let [message, setMessage] = useState( newMessage());
  useEffect(()=>{
    if(props.message) {
      setMessage(preprocessForAnswer(props.message))
    }
  }, [props.message&&props.message.reference]);
  let [htmlContent, setHtmlContent] = useState("");
  let [textContent, setTextContent] = useState("");
  let [error, setError] = useState("");
  let [attachments, setAttachments] = useState([] as { url, fileName }[]);
  let [loading, setLoading] = useState(false);
  let onAttachChange = (evt: any) => {
    let file = evt.target.files[0];
    setLoading(true);
    uploadFile(evt).then((link: any) => {
      setLoading(false);
      setAttachments([...attachments, { url: link, fileName: file.name }]);
    }).catch(e => { setLoading(false); });
  }
  let onAction = (name) => {
    message.contentData = JSON.stringify([{
      id: 1,
      htmlContent: htmlContent,
      textContent: textContent,
      actor: message.actor,
      creationTime: new Date(),
      attachments: attachments,
    }, ...message.blocks]);

    setMessage(message);

    if (!textContent) {
      setError("Veuillez saisir le corp du message");
      return;
    }
    if (!message.receiver || !message.receiver.email) {
      setError("Veuillez saisir le destinataire");
      return;
    }
    if(message.reference) { 
      updateMessage(message, "reply").then((r) => { props.onClose() });
    } else {

      addMessage(message).then((r) => { props.onClose() });
    }

  }
  return <Popup onAction={onAction} onClose={props.onClose} actions={[{ name: 'send', label: ' Envoyer', icon: 'paper-plane' }]}
    medium >
    <div className="message-editor">
      {error && <div className="post-error-message">{error}</div>}
      <div className="flex-sb message-dest-block">
        <label>{t("Destination")}</label>
        <DropdownList className="user-entity-droplist" data={ALL_USERS}
          value={message.receiver}
          onSelect={(d: any) => { setMessage({ ...message, receiver: d }) }} enableSearch ></DropdownList>

      </div>
      <div className="form-group ">
        <input onChange={e => setMessage({ ...message, title: e.target.value })} value={message.title}
          type="text" className="form-control" placeholder={t("Objet")} />
      </div>

      <div className="form-group ">
        <ReactQuill bounds="#quill-ex" modules={quillModulesBasic} placeholder="Message"
          style={{ height: 200 + "px" }}
          formats={quillFormats} value={htmlContent}
          onChange={(content: any, delta: any, source: any, editor: any) => {
            setError("");
            setHtmlContent(content);
            setTextContent(editor.getText(content))
          }} />


      </div>
      <div className="attachments">
        <label htmlFor="attachFile" tabIndex={-1} className="share-box-feed-entry-toolbar-block" >
          <i className="typcn typcn-attachment-outline" />
          <span  >
            {t("Document")}
          </span>
          {loading && <i className="fas fa-spinner fa-spin"></i>}
        </label>
        <input onChange={onAttachChange} type="file" id="attachFile" className="h-file-input"  ></input>


        {attachments && attachments.length > 0 &&
          attachments.map(a => {
            return <div className="attachment-block" >
              <a href={a.url}><i className="typcn typcn-attachment-outline"></i> <span>{a.fileName}</span></a>
              <i onClick={() => setAttachments(attachments.filter(x => x != a))} className="remove-btn fa fa-trash"></i> </div>
          })}
      </div>

    </div>
  </Popup>
}
 
