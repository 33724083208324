import { useState } from "react";
import ReactQuill from "react-quill";
import { useHistory } from "react-router";
import { quillFormats, quillModules } from "../feed-wall/text-editor";
import { toYYYMMDD, toYYY_MM_DD } from "../help/dates";
import { t } from "../i18n";
import { uploadFile } from "../service/file.service";
import { appConfig, submitConfig } from "../service/userService";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { TabsBlock } from "../ui/tabs";
import './admin.css';

const TABS = [{ name: "general", label: t("Configuration") },
{ name: "banner", label: t("Bannière") },
{ name: "licence", label: t("Licence") }]
const ALL_LANG: any[] = [{ name: "fr", label: t("Français") }, { name: "en", label: t("Anglais") }, { name: "es", label: t("Espagnole") }];
function mapLang(s) {
    if (s == "fr") {
        return "Français";
    } if (s == "en") {
        return "Anglais";
    } if (s == "es") {
        return "Espagnole";
    }
    return s;
}

export function AppConfigEditor(props: any) {
    let [config, setConfig] = useState(appConfig());
    let [readonly, setReadonly] = useState(true);
    let [error, setError] = useState('');
    let [currentTab, setCurrentTab] = useState("general")
    let history = useHistory();
    let onMobileBannerChange = (evt: any) => {
        uploadFile(evt).then((link: any) => {
            config.mobileBannerUrl = link;
            setConfig(config);
            setReadonly(true);
            setReadonly(false);
        });
    }
    let onWebBannerChange = (evt: any) => {
        uploadFile(evt).then((link: any) => {
            config.webBannerUrl = link;
            setConfig(config);
            setReadonly(true);
            setReadonly(false);
        });
    }

    let onEdit = () => { setReadonly(false); }
    let onSubmit = () => {
        setError("")
        submitConfig(config).then(r => {
            console.log("body", r);
            setConfig(appConfig());
            setReadonly(true);  
            setError(t((r.error|| "").replace(/_/g, " ")));

        })
    }
    let onCancel = () => { setReadonly(true); }
    let removeOUI = (i: number) => {
        config.ouLevels.splice(i, 1);
        setConfig({ ...config });
    }
    let addOUI = (i: number) => {
        config.ouLevels.push({ name: "" + (i + 1), label: "" })
        setConfig({ ...config });
    }

    let generateLicenceRequest = ()=>{
        setError("");
        let request = {...config.licence};
        if(!request.start) {
            setError(t("Erreur de date début"));
            return;
        }
        if(!request.end) {
            setError("Erreur de date d'expiration");
            return;
        }
        let ems = new Date(request.end).getTime();
        let sms = new Date(request.start).getTime();
        if(ems<=sms) {
            setError(t("Erreur de date début/expiration"));
            return;
        }
        if(!request.client) {
            setError(t("Veuillez saisir l'identifiant client"));
            return;
        }
        if(!request.instanceId) {
            setError(t("Erreur Technique E-9855. Veuillez consulter votre support PERFANI"));
            return;
        }
        delete request.licenceRequestB64;
        delete request.licenceTextB64;
        delete request.licenceTerms;
        config.licence.licenceRequestB64 = btoa( JSON.stringify(request));
        setConfig({...config});
        console.log("generateLicenceRequest", config.licence.licenceRequestB64);
    }
    return (<div className="config-form">

        <div className="assessment" >
            <div className="assmt-header">
                <h3 className="assmt-title">Configuration Globale</h3>
                <div className="assmt-dates az-content-header-right" >
                    <div className="media">
                        <div className="media-body"></div>
                    </div>
                    <div className="media noextramarge">
                        <div className="media-body actions-toolbar">
                            {readonly && <a onClick={onEdit} className="btn btn-purple">
                                <i className="fa fa-edit"></i>
                                <span>Editer</span>
                            </a>
                            }
                            {!readonly && <a onClick={onSubmit} className="btn btn-purple">
                                <i className="fa fa-check"></i>
                                <span>Temriner</span>
                            </a>
                            }
                            {!readonly && <a onClick={onCancel} className="btn btn-purple">
                                <i className="fa fa-times"></i>
                                <span>Annuler</span>
                            </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <TabsBlock tabs={TABS}
            onSelect={(name: string) => setCurrentTab(name)}
            defSelected="general"></TabsBlock>
        {error && <p className="form-error">{error}</p>}
        {currentTab == "general" && <div className="app-form flex-sb" >
            <div className="col-left" >
                <div className="form-group  form-group-x500">
                    <label>{t("Langue")}</label>
                    {readonly && <input readOnly={readonly} value={mapLang(config.language)}
                        type="text" className="form-control" />
                    }
                    {!readonly &&
                        <DropdownList className="user-entity-droplist" data={ALL_LANG} value={{ name: config.language }}
                            onSelect={(d: any) => setConfig({ ...config, language: d.name })}
                        ></DropdownList>
                    }
                </div>

                <div className="form-group  form-group-x500">
                    <label>{t("Unité monétaire")}</label>
                    <input readOnly={readonly} value={config.currency} onChange={(evt) => setConfig({ ...config, currency: evt.target.value })}
                        type="text" className="form-control" />

                </div>
                <div className="fieldset-description">{t("Définir les libellés des différents niveaux de l'organisation interne")}</div>
                {config.ouLevels.map((oui, indx) => {
                    let stp = " " + (indx + 1)
                    return <div key={oui.name + "-" + indx} className="form-group  form-group-x500">
                        <label className="flex-sb">
                            <span>{t("Libellé du Niveau") + stp}</span>
                            <span className="btn-add-remove">
                                {!readonly && (indx == config.ouLevels.length - 1) && <i onClick={() => addOUI(indx)} className="fa fa-plus-square"></i>}
                                {!readonly && (indx > 0) && <i onClick={() => removeOUI(indx)} className="fa fa-minus-square"></i>}
                            </span></label>
                        <input readOnly={readonly} value={oui.label}
                            onChange={(evt) => { oui.label = evt.target.value; setConfig({ ...config }) }}
                            type="text" className="form-control" />

                    </div>
                })}

            </div>
        </div>}

        {currentTab == "licence" &&
            <div className="app-form flex-sb" >
                <div className="col-left" >
                    <div className="form-group  form-group-x500">
                        <label>{t("Nombre d'utilisateurs")}</label>
                        <input readOnly={readonly} value={config.licence.maxUsers}
                            onChange={d => setConfig({ ...config, licence: { ...config.licence, maxUsers: parseInt(d.target.value) } })}
                            type="number" className="form-control" />
                    </div>
                    <div className="form-group ">
                        <label>{t("Date Début")}</label>
                        <input readOnly={readonly} type="date"
                            onChange={e => setConfig({ ...config, licence: { ...config.licence, start: new Date(e.target.value) } })}
                            value={toYYY_MM_DD(config.licence.start)}
                            className="form-control" />
                    </div>
                    <div className="form-group ">
                        <label>{t("Date Expiration")}</label>
                        <input readOnly={readonly} type="date"
                            onChange={e => setConfig({ ...config, licence: { ...config.licence, end: new Date(e.target.value) } })}
                            value={toYYY_MM_DD(config.licence.end)}
                            className="form-control" />
                    </div>

                    <div className="form-group  form-group-x500">
                        <label>{t("ID Client")}</label>
                        <input readOnly={readonly} value={config.licence.client}
                            onChange={d => setConfig({ ...config, licence: { ...config.licence, client:d.target.value } })}
                            type="text" className="form-control" />
                    </div>
                    <div className="form-group licence-request">
                        <label className="flex-sb"><span className="title">{t("Demande de clé")}</span>
                        <div onClick={generateLicenceRequest} className="btn-in-label"><i className="fa fa-cogs"></i> {t("Générer une demande de licence")} </div>
                        </label>
                        <textarea readOnly={true} 
                             value={config.licence.licenceRequestB64}
                            className="form-control" />
                            <i  onClick={()=>{navigator.clipboard.writeText(config.licence.licenceRequestB64)}}  className="copy-btn fa fa-copy"></i> 
                    </div>

                    <div className="form-group ">
                        <label>{t("Clé de licence")} </label>
                        <textarea readOnly={readonly} rows={5}
                            onChange={e => setConfig({ ...config, licence: { ...config.licence, licenceTextB64:  e.target.value } })}
                            value={config.licence.licenceTextB64}
                            className="form-control" /> 
                    </div>
                </div>
            </div>
        }
        {currentTab == "banner" &&
            <div className="app-form flex-sb" >
                <div className="col-left" >

                    <div className="form-group-fullwidth  ">
                        <label htmlFor="bannerFile" >
                            {!readonly && <div className="banner-placeholder">{t("Cliquez ici pour charger la bannière")} </div>}
                            {readonly && <div className="label">{t("Bannière")}</div>}
                            {config.webBannerUrl && <div className="client-banner-edition">
                                {!readonly && <i onClick={() => setConfig({ ...config, webBannerUrl: '' })} className="fa fa-times" ></i>}
                                <img src={config.webBannerUrl}></img>
                            </div>
                            }
                            {!config.webBannerUrl && <i className="nobanner fa fa-image" ></i>}
                        </label>
                        {!readonly && <input onChange={onWebBannerChange} type="file" id="bannerFile" className="h-file-input" accept="image/*"></input>}
                    </div>
                    <div className="form-group">
                        <label>{t("Texte de la bannière")}</label>
                        {!readonly && <ReactQuill
                            bounds="#quill-ex"
                            modules={quillModules}
                            formats={quillFormats}
                            value={config.webBannerTitle || ''} placeholder={t("")}
                            onChange={(content: any, delta: any, source: any, editor: any) => {
                                setConfig({ ...config, webBannerTitle: "" + content })
                            }} />
                        }

                        {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: config.webBannerTitle || '...' }} ></div>}

                    </div>
                    <div className="form-group-fullwidth  ">
                        <label htmlFor="mobileBannerFile" >
                            {!readonly && <div className="banner-placeholder">{t("Cliquez ici pour charger la bannière de l'application mobile")} </div>}
                            {readonly && <div className="label">{t("Bannière de l'application mobile")}</div>}
                            {config.mobileBannerUrl && <div className="client-banner-edition">
                                {!readonly && <i onClick={() => setConfig({ ...config, mobileBannerUrl: '' })} className="fa fa-times" ></i>}

                                <img src={config.mobileBannerUrl}></img>
                            </div> 
                            }
                            {!config.mobileBannerUrl && <i className="nobanner fa fa-image" ></i>}
                        </label>
                        {!readonly && <input onChange={onMobileBannerChange} type="file" id="mobileBannerFile" className="h-file-input" accept="image/*"></input>}
                    </div>

                    <div className="form-group">
                        <label>{t("Texte de la bannière Mobile")}</label>
                        {!readonly && <ReactQuill
                            bounds="#quill-ex"
                            modules={quillModules}
                            formats={quillFormats}
                            value={config.mobileBannerTitle || ''} placeholder={t("")}
                            onChange={(content: any, delta: any, source: any, editor: any) => { setConfig({ ...config, mobileBannerTitle: "" + content }) }} />
                        }
                        {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: config.mobileBannerTitle || '...' }} ></div>}


                    </div>
                </div>
            </div>
        }
    </div>);
}