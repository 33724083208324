import React, { Props, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CHALLENGES } from "../test-data/challenge-data";
import { QUIZZES_DATA } from "../test-data/assessments-data";
import { getKpiMenu } from "../service/kpiService";
import { getQuizzsMenu } from "../service/quizService";
import { getTrainingsMenu } from "../service/trainingService";
import { t } from "../i18n";
import { getChallengesMenu } from "../service/challengeService";
import { canUseAdminMenu, hasPermission } from "../types/permissions";

const homeMenu: any = { name: "accueil", label: t("Accueil"), icon: "typcn typcn-home", link: "/" };
const kpiMenu: any = { name: "performance", label: t("Performances"), icon: "typcn typcn-chart-line-outline" };

const fromationMenu: any = { name: "formation", label: t("Formations"), icon: "typcn typcn-mortar-board", subMenus: [] };
const quizMenu: any = { name: "quiz", label: t("Quiz"), icon: "typcn typcn-stopwatch", subMenus: [] };
const sondageMenu: any = { name: "sondage", label: t("Sondages"), icon: "typcn typcn-microphone-outline", subMenus: [] };
const challengeMenu: any = { name: "challenge", label: t("Challenges"), icon: "typcn typcn-gift", subMenus: [] };
const configMenu: any = { name: "config", label: t("Configurations"), icon: "typcn typcn-spanner-outline", subMenus: [] };

const menus = [
    homeMenu,
    kpiMenu,
    fromationMenu,
    quizMenu,
    sondageMenu,
    challengeMenu,
    configMenu
]


configMenu.subMenus = [
    { name: "Utilisateurs", label: t("Utilisateurs"), icon: "mnbtn fa fa-users", link: "/admin/user", per: ['users', 'rr'] },
    { name: "Organisation", label: t("Organisation"), icon: "mnbtn fa fa-building", link: "/admin/ou", per: ['organization', 'rr'] },

    { name: "Groupes", label: t("Groupes"), icon: "mnbtn fa fa-share-alt", link: "/admin/group", per: ['group', 'rr'] },
    { name: "Profils", label: t("Profils"), icon: "mnbtn fa fa-puzzle-piece", link: "/admin/profile", per: ['profile', 'rr'] },
    { name: "audit", label: t("Taux d'activité"), icon: "mnbtn fa fa-stethoscope", link: "/admin/explorAudit", per: ['users', 'ww'] },
    { name: "Configuration", label: t("Configuration"), icon: "mnbtn fa fa-cogs", link: "/admin/app-config", per: ['configuration', 'rr'] },

]




export function AppAsideMenu(props) {
    let location = useLocation();
    let [selectedRoot, setSelectedRoot] = useState(menus[0]);


    let collapseAside = () => {

    }

    useEffect(() => {
        kpiMenu.subMenus = getKpiMenu().map(ind => { return { name: ind.name, label: ind.label, link: "/dashboard/" + ind.name } });
        if (hasPermission('kpi', 'ww')) {
            kpiMenu.subMenus.push({ name: "defKpi", label: t("Définition des KPIs"), link: "/kpi/definition" });
        }

        fromationMenu.subMenus = getTrainingsMenu().map((trng, indx) => {
            return {
                name: "trng-" + (indx + 1),
                label: trng.training?.title, link: "/training-info/" + (indx + 1)
            }
        });
        if (hasPermission('training', 'ww')) {
            fromationMenu.subMenus.push({ name: "defTrng", label: t("Définition des Formations"), link: "/training/definition" });
        };



        sondageMenu.subMenus = getQuizzsMenu().filter(x => x && x.definition && x.definition.type == 'POLLS')
        .filter(x=>x.instanceStatus!='CLOSED')
            .map((q, i) => { return { name: "poll-" + i, label: q.definition.title, link: "/assessment/quiz-" + q.definition.reference } });
        if (hasPermission('poll', 'ww')) {
            sondageMenu.subMenus.push({ name: "defPoll", label: t("Définition des Sondages"), link: "/polls/definition" });
        };


        quizMenu.subMenus = getQuizzsMenu().filter(x => x && x.definition && x.definition.type != 'POLLS')
            .map((q, i) => { return { name: "quiz-" + i, label: q.definition.title, link: "/assessment/quiz-" + q.definition.reference } });
        if (hasPermission('quiz', 'ww')) {
            quizMenu.subMenus.push({ name: "defQuiz", label: t("Définition des Quiz"), link: "/quiz/definition" });
        };





        challengeMenu.subMenus = getChallengesMenu().map((cc, ii) => { return { name: "challenge-" + ii, label: cc.title, link: "/challenge/" + cc.reference } });
        if (hasPermission('challenge', 'ww')) {
            challengeMenu.subMenus.push({ name: "defChallenge", label: t("Définition des challenges"), link: "/challenge/definition" });
        };

        console.log("useEffect Apside Menu ", props.kpiMenuLastUpdate)

    }, [props.kpiMenuLastUpdate]);

    useEffect(() => {
        console.log("location ", location);
        let path = location.pathname;
        if (path.includes("training")) {
            setSelectedRoot(fromationMenu);
        } else if (path.includes("dashboard")) {
            setSelectedRoot(kpiMenu);
        } else if (path.includes("challenge")) {
            setSelectedRoot(challengeMenu);
        } else if (path.includes("quiz")) {
            setSelectedRoot(quizMenu);
        } else if (path.includes("polls")) {
            setSelectedRoot(sondageMenu);
        } else if (path.includes("config")) {
            setSelectedRoot(configMenu);
        } else {
            setSelectedRoot(homeMenu);
        }

    }, [""]);
    useEffect(() => {
        if (window["innerWidth"] < 760) {
            props.setShowSideMenu(false);
        } 
    }, [location.pathname])
    return (<>
        <div className={"az-iconbar "}>
            <a className="az-iconbar-toggle-menu" >

                <i onClick={() => props.setShowSideMenu(false)} className="icon ion-md-close"></i>
            </a>
            <div className="az-iconbar-header">
                <Link to="/" className="az-logo menu-expanded-logo-link">
                    <img className="menu-expanded-logo" src="/photos/logo-perfani-F-monochrome.png"></img>
                </Link>

            </div>
            {menus.map((m, ii) => {
                return <RootMenuItem key={m.name+"-"+ii} menu={m} onSelectRoot={setSelectedRoot}
                    selected={selectedRoot == m}  ></RootMenuItem>
            })}
        </div>
    </>);


}
function RootMenuItem(props: any) {
    let menu = props.menu;
    let [selected, setSelected] = useState(!!props.selected);
    let [selectedSubMenu, setSelectedSubMenu] = useState(null as any);

    let onSelectRoot = () => {
        setSelected(true);
        props.onSelectRoot(menu);
    }
    useEffect(() => { setSelected(props.selected) }, [props.selected])
    return <div className="root-menu-item">

        <div className={"root-menu-header " + (selected ? "selected" : "")} onClick={onSelectRoot}>
            {menu.link ? 
                <Link className="root-menu-link"  to={menu.link}> 
                    <i className={"root-menu-icon " + menu.icon}></i>
                    <span className="root-menu-label">{menu.label}</span>
                </Link>
                :
                <>  
                    <i className={"root-menu-icon " + menu.icon}></i>
                    <span className="root-menu-label">{menu.label}</span>
                </>}
        </div>
        {selected && menu.subMenus && <div className="submenu-block">
            {menu.subMenus.map((m, ii) => <SubMenuItem key={menu.name+"-"+ii} selected={selectedSubMenu == m} onSelect={setSelectedSubMenu}
                menu={m} ></SubMenuItem>)}
        </div>}
    </div>
}


function SubMenuItem(props: any) {
    let menu = props.menu;
    let [selected, setSelected] = useState(!!props.selected);

    let onSelect = () => {
        setSelected(true);
        props.onSelect(menu);
    }
    useEffect(() => { setSelected(props.selected) }, [props.selected])
    return <div className="sub-menu-item">
        <div className={"sub-menu-header " + (selected ? "selected" : "")} onClick={onSelect}>
            <Link className="sub-menu-label" to={menu.link} >
                {menu.label}
            </Link>
        </div>

    </div>
}

export default AppAsideMenu;