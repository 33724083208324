import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { printShortDate } from "../help/dates";
import { t } from "../i18n";
import { getProfileById, updateProfile } from "../service/userService";
import { ROOT_ENTITIES } from "../test-data/entities";
import { Profile } from "../types/people";
import { PERMISSION_DROPDOWNLIST_DATA } from "../types/permissions";
import { MultItemField } from "../ui/mutli-item-field";
import './admin.css';

export function ProfileView(props: any) {
    let { id: id, mode: inMode } = useParams() as any;
    let [entity, setEntity] = useState(({} as Profile));
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode);
    let [error, setError] = useState('');
    let history = useHistory();
    let onDelete = () => {
        entity = { ...entity, enabled: false }
        updateProfile(entity, "delete").then((u) => {
            setShowPopup(false);
            history.push("/admin/ou");
        })
    }
    let submit = (action: string) => {
        if (action == 'update' || action == 'create') {
            if (!entity.name) {
                setError("Le nom est obligatoire")
                return;
            }
            if (!entity.label) {
                setError("La description   est obligatoire")
                return;
            }


        }
        updateProfile(entity, action).then((u) => {
            if (u.body) {
                setEntity(u.body);
                setMode('display');
                id = u.body.id;
            }
        });
    }
    useEffect(() => {
        if (id && id != "00") {
            getProfileById(id).then((u) => u.body && setEntity(u.body));
        }
    }
        , [id]);
    let actions: any[] = [];
    let readonly = mode != 'create' && mode != 'edit';
    if (mode == 'display') {
        actions.push({
            label: t("Editer"),
            icon: "fa fa-edit",
            handler: () => { setMode('edit') }
        });


        actions.push({
            label: t("Fermer"),
            icon: "fa fa-times",
            handler: () => { history.push("/admin/profile") }
        });
    } else if (mode == 'edit' || mode=='create') {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: () => { submit('update') }
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: () => { setMode('display') }
        });
    }
    return (<div className="config-form">

        <div className="assessment" >
            <div className="assmt-header">
                <h3 className="assmt-title">{entity.name}</h3>
                <div className="assmt-dates az-content-header-right" >
                    {entity.creationTime && <div className="media">
                        <div className="media-body">
                            <label>{t("Par")} <strong> {(entity.modifiedBy || entity.createdBy)}</strong></label>
                            <h6> {printShortDate(entity.changeTime || entity.creationTime)}</h6>
                        </div>
                    </div>
                    }
                    <div className="media noextramarge">
                        <div className="media-body actions-toolbar">
                            {actions.map(a => <a onClick={a.handler} className="btn btn-purple">
                                <i className={a.icon}></i>
                                <span>{a.label}</span>
                            </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="app-form flex-sb" >
            <div className="col-left" >
                <div className="form-group ">
                    <label>{t("Profil")}</label>
                    <input readOnly={readonly} onChange={e => setEntity({ ...entity, name: e.target.value })} value={entity.name}
                        type="text" className="form-control" placeholder={t("Profil")} />

                </div>
                <div className="form-group ">
                    <label>{t("Description ")}</label>
                    <input readOnly={readonly} onChange={e => setEntity({ ...entity, label: e.target.value })} value={entity.label}
                        type="text" className="form-control" placeholder={t("description")} />
                </div>
                <div className="form-group  ">
                    <label>{t("Permissions")}</label>
                    <MultItemField 
                    items = {PERMISSION_DROPDOWNLIST_DATA} 
                    data={entity.permissions?.split(";")
                        .map((p: string) => { return { value: p, label: mapPermissionAsLabel(p) } })}
                        setData={(d: string[]) => {
                            console.log("set permission", d);
                            entity.permissions = d.map((x: any) => x.value).filter(x => !!x && x != "null").join(";"); setEntity({ ...entity });
                        }}

                        noDataMessage="Aucune permission" mode={mode}  ></MultItemField>

                </div>

            </div>
            <div className="col-right" >
                {error && <p className="form-error">{error}</p>}
            </div>

        </div>
        {showPopup && <div className="modal-screen">
            <div className="popup">
                <i className="fa fa-times" onClick={() => setShowPopup(false)}></i>
                <p>
                    Etes-vous sûr de vouloir supprimer ce profil?
                </p>
                <a onClick={onDelete} className="btn btn-purple delete-btn">
                    <i className="fa fa-trash"></i>
                    <span>Confirmer la suppression</span>
                </a>

            </div>
        </div>}
    </div>);
}


function mapPermissionAsLabel(p: string) {
    try {
        if (p.includes(":")) {
            let tkns = p.split(":");
            return tkns[0].toUpperCase() + " [" + tkns[1].toUpperCase() + "]";
        } else {
            return p;
        }
    } catch (e) {
        return p;
    }
}