export const defaultDico  = { 
    "_acteur": "Acteur",
    "_action": "Action",
    "_activer": "Activer",
    "_activer_la_forrmation": "Activer la formation",
    "_activer_le_kpi": "Activer le KPI",
    "_administration": "Administration",
    "_agence": "Agence",
    "_ajouter_un_commentaire": "Ajouter un commentaire",
    "_annuler": "Annuler",
    "_aperçu": "Aperçu",
    "_autoriser_la_pause": "Autoriser la pause",
    "_autoriser_la_reprise": "Autoriser la reprise",
    "_challenges": "Challenges",
    "_chargement_des_données": "Chargement des données",
    "_cible_cumulé": "Cible Cumulée",
    "_cible_global": "Cible Globale",
    "_cliquez_ici_pour_charger_la_bannière": "Cliquez ici pour charger la bannière",
    "_cliquez_ici_pour_charger_la_bannière_de_l'application_mobile": "Cliquez ici pour charger la bannière de l'application mobile",
    "_cliquez_ici_pour_charger_un_visuel": "Cliquez ici pour charger un visuel",
    "_clôture": "Clôture",
    "_collaborateur": "Collaborateur",
    "_conclusion": "Conclusion",
    "_configuration": "Configuration",
    "_confirmer": "Confirmer",
    "_confirmer_la_suppression": "Confirmer la suppression",
    "_context": "Contexte",
    "_contexte": "Contexte",
    "_correctes": "Correctes",
    "_créé_par": "Créé par ",
    "_date_clôture": "Date de clôture ",
    "_date_création": "Date de création",
    "_date_fin": "Date de Fin",
    "_date_publication": "Date de publication ",
    "_date_début": "Date de début",
    "_description": "Description",
    "_description_du_kpi": "Description du KPI ",
    "_direction_régionale": "Direction Régionale",
    "_distribution_des_scores": "Distribution des scores",
    "_document": "Document",
    "_document_pdf": "Document PDF",
    "_durée": "Durée",
    "_durée_(min)": "Durée estimée (min)",
    "_durée_en_minutes": "Durée estimée (min)",
    "_début": "Début",
    "_début/fin": "Début/Fin",
    "_définition": "Définition",
    "_définition_des_formations": "Paramètres des Formations",
    "_définition_des_kpis": "Paramètres des KPIs",
    "_définition_des_quiz": "Paramètres des Quiz",
    "_définition_des_sondages": "Paramètres des Sondages",
    "_délégation": "Délégation ",
    "_editer": "Editer",

    "_email": "Email",
    "_employee": "Employés",
    "_enité_cible_bottom": "Niveau Min",
    "_enité_cible_top": "Niveau Max",
    "_enter_your_email": "Rentrez votre email",
    "_enter_your_password": "Tapez votre MDP",
    "_entité": "Entité",
    "_erreurs": "Erreurs",
    "_espagnole": "Espagnol",
    "_etapes_&amp;_modules": "Étapes & amp; Modules",
    "_explication": "Explication",
    "_fait_le": "Fait le",
    "_famille": "Famille",
    "_famille_du_kpi": "Famille du KPI",
    "_fermer": "Fermer",
    "_fichier_de_données": "Fichier de données",
    "_fichier_des_objectifs": "Fichier des objectifs",
    "_fin": "Fin",
    "_formation": "Formation",
    "_formations": "Formations",
    "_gain": "Gains",
    "_groupe": "Groupe",
    "_groupes": "Groupes",
    "_groupes_de_suivi": "Groupes de Suivi ",
    "_générer_la_template": "Générer la template",
    "_initializer_mdp": "Réinitialiser le MDP",
    "_introduction": "Introduction",
    "_kpi": "KPI",
    "_langue": "Langue",
    "_lié_à_une_formation": "Lié à une formation",
    "_message": "Messages",
    "_message_d'encouragement": "Message d'encouragement ",
    "_message_de_félicitation": "Message de félicitation ",
    "_module": "Module",
    "_niveau": "Niveau",
    "_nom": "Nom ",
    "_nom_complet": "Nom Complet",
    "_nom_de_l'unité": "Nom de l'unité",
    "_nom_du_kpi": "Nom du KPI",
    "_nom/prénom": "Nom/Prénom",
    "_nombre_de_réponse": "Nb de Réponses ",
    "_nombre_de_points": "Nb de points",
    "_nombre_de_question": "Nb de questions ",
    "_nombre_de_réponses": "Nb de réponses",
    "_note_de_passage": "Score de validation",
    "_nouveau_post": "Nouveau post",
    "_nouveau_quiz": "Nouveau Quiz",
    "_nouveau_sondage": "Nouveau Sondage",
    "_nouvelle_formation": "Nouvelle Formation",
    "_option": "Option",
    "_organisation": "Organisation",
    "_par": "Par ",
    "_parcours": "Parcours",
    "_passing_score": "Score de validation",
    "_password": "Mot de passe",
    "_performances": "Performances",

    "_permissions": "Permissions",
    "_photo": "Photo",
    "_poid": "Poids",
    "_points": "Points ",
    "_poster": "Poster",
    "_profil": "Profil",
    "_profils": "Profils",
    "_profils_concernés": "Profils concernés ",
    "_prénom": "Prénom",
    "_présentation_power_point": "Présentation Power Point",
    "_pts": "Points",
    "_publication": "Publication",
    "_période_d'objectif": "Période d'Objectivation",
    "_période_d'objectifs": "Période d'Objectivation",
    "_période_d'affichage": "Période d'affichage",
    "_période_d'import": "Période d'import",
    "_période_de_suivi": "Période de Suivi",
    "_que_souhaitez-vous_partager?": "Partagez une idée, un conseil, une bonne pratique...?",
    "_question": "Question",
    "_questions": "Questions",
    "_quiz": "Quiz",
    "_rang": "Rang",
    "_rechercher_un_quiz": "Rechercher un Quiz",
    "_rechercher_un_sondage": "Rechercher un Sondage",
    "_rechercher_un_kpi": "Rechercher un KPI",
    "_rechercher_un_profil": "Rechercher un profil",
    "_rechercher_un_utilisateur": "Rechercher un utilisateur",
    "_rechercher_une_formation": "Rechercher une formation",
    "_rechercher_une_unité_organizationnelle": "Rechercher une unité organisationnelle",
    "_ressource_youtube": "Ressource Youtube",
    "_review": "Revue",
    "_revu_des_réponses": "Revue des réponses",
    "_réalisé": "Réalisé",
    "_réalisé_cumulé": "Réalisé Cumulé",
    "_réponse": "Réponse",
    "_réponses": "Réponses",
    "_résultats": "Résultats",
    "_saisir_une_nouvelle_unité": "Saisir une nouvelle Unité",
    "_score": "Score",
    "_search_for_anything...": "Rechercher...",
    "_siège": "Siège",
    "_sondage_anonyme": "Sondage Anonyme",
    "_sondages": "Sondages",
    "_sous_famille_du_kpi": "Sous famille du KPI",
    "_sous_famille": "Sous famille",
    "_statistique_des_réponses": "Statistiques des réponses",
    "_statistique_des_réponses_correctes": "Statistiques des réponses correctes",
    "_statistiques": "Statistiques",
    "_statut": "Statut ",
    "_supprimer": "Supprimer",
    "_tro": "Taux de réalisation de l'objectif",
    "_temps_(min)": "Temps (min)",
    "_temps_restant": "Temps restant (min)",
    "_tendance": "Tendance",
    "_texte_de_la_bannière": "Texte de la bannière",
    "_texte_de_la_bannière_mobile": "Texte de la bannière Mobile",
    "_time_limitation": "Limitation de temps",
    "_titre": "Titre ",
    "_titre_de_la_formation": "Titre de la formation",
    "_type": "Type",
    "_type_de_la_ressource": "Type de la ressource",
    "_type_de_mesure": "Type de mesure",
    "_u.o": "U.O",
    "_url_youtube": "URL Youtube",
    "_unité_commerciale": "Unité Commerciale",
    "_unité_mère": "Unité Mère",
    "_unité_organisationnelle": "Unité organisationnelle",
    "_unité_de_mesure": "Unité de mesure",
    "_utilisateurs": "Utilisateurs",
    "_vidéo": "Vidéo",
    "_votre_score": "Votre Score",
    "_votre_temps": "Votre temps",
    "_étape": "Etape",

    "_définition_des_challenges": "Paramètres des challenges",
    "_précision_d'affichage": "Précision d'affichage",
    "_nombre_de_chiffres_apès/avant_la_virgule...": "Nombre de chiffres après/avant la virgule...",
    "_arrondir_l'objectif_proraté": "Arrondir l'objectif proraté",
    "_mot_de_passe": "Mot de passe",
    "_français": "Français",
    "_anglais": "Anglais",
    "_gains": "Gains",
    "_rechercher_un_challenge": "Rechercher un challenge",
    "_nouveau_challenge": "Nouveau Challenge",
    "_challenge": "Challenge",
    "_valider": "Valider",
    "_postes": "Posts",
    "_pilotage": "Pilotage",
    "_données_du_pilotage": "Données du Pilotage",
    "_ancien_mot_de_passe": "Ancien Mot de passe",
    "_nouveau_mot_de_passe": "Nouveau mot de passe",
    "_comnfirmer_le_nouveau_mot_de_passe": "Comnfirmer le nouveau mot de passe",
    "_mon_profil": "Mon Profil",
    "_me_déconnecter": "Me déconnecter",
    "_mentions_et_réactions": "Mentions et réactions",
    "_eléments_enregistrés": "Éléments enregistrés",
    "_filtres": "Mon espace",
    "_mes_groupes": "Mes groupes",
    "_mes_commentaires": "Mes commentaires",
    "_mes_posts": "Mes posts",
    "_commencer_un_post": "Commencer un post",
    "_classer_par": "Classer par",
    "_pertinence": "Populaire",
    "_récent": "Récent",
    "_notification": "Notifications",
    "_avatar": "Avatar",
    "_règlement": "Règlement",
    "_audience": "Audience",
    "_t.r.o": "T.R.O",
    "_q.c": "Q.C",
    "_taux_d'activité": "Taux d'activité",
    "_rechercher_un_message": "Rechercher un message",
    "_seuil": "Seuil",
    "_valeur": "Valeur",
    "_indicateur": "Indicateur",
    "_référence_du_calcul": "Référence du calcul",
    "_en_fonction_de_l'objectif": "En fonction de l'objectif",
    "_en_fonction_du_réalisé": "En fonction du réalisé",
    "_type_de_formule": "Type de formule",
    "_linéaire": "Linéaire",
    "_paliers": "Paliers",
    "_linéaire_par_morceaux": "Linéaire par morceaux",
    "_linéaire_avec_min/max": "Linéaire avec Min/Max",
    "_remarques": "Remarques",
    "_licences": "Licences",
    "_imiter": "Imiter",
    "_jour": "Jour",
    "_semaine": "Semaine",
    "_mois": "Mois",
    "_activité_par_utilisateur": "Activité par utilisateur",
    "_bannière": "Bannière",
    "_licence": "Licence",
    "_accepter_tous_les_quiz": "Accepter tous les Quiz",
    "_point_par_question": "NB de Points par question",
    "_nombre_de_points_par_question_correcte": "Nb de points par question correcte",
    "_rémuneration": "Rémunération",
    "_type_de_récompense": "Type de récompense",
    "_prix": "Prix",
    "_en_fonction_du_score_(points)": "En fonction du score (Points)",
    "_en_fonction_du_classement": "En fonction du classement",
    "_mad": "MAD",
    "_classement_haut/bas_(inclus)": "Classement Haut/Bas (inclus)",
    "_premier_classement_concérné": "Premier classement concerné",
    "_dernier_classement_concérné": "Dernier classement concerné",
    "_entité_de_classement": "Entité de classement",
    "_exclusive": "Exclusive",
    "_enregistrer":"Enregistrer",
    "_eer": "Produits",



 
}