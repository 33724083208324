 
import { TrainingDefinition, TrainingInstance } from "../training/training-type";
import { post } from "./http.service";
import { currentUser } from "./userService";
const urlBase = '/api/assessment/training-definition';//'http://localhost:11002';
const instanceUrlBase =  '/api/assessment/training-instance';
export async function addTrainingDefinition(p:TrainingDefinition) : Promise<any> {
    let data = await post(  urlBase+ "/create", { payload: p}) ;  
     
    if (!data.success || !data.body) {
        return null;
    } 
    return data.body;
}

export async function updateTrainingDefinition(p:TrainingDefinition, action: string) : Promise<any> {
  let data = await post(  urlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

export async function getTrainingDefinitionById(id: string)   { 
  let data = await post(    urlBase+ "/get",{action: "load", reference: id}) ;   
      
  return data;
}
  

let trainingsMenu:any[] ;
export function getTrainingsMenu()  {
  if(!trainingsMenu) {
    trainingsMenu = JSON.parse(localStorage.getItem("TRAININGS_MENU")||"[]");
  }
  return trainingsMenu;
} 

export function persistTraining( ai:TrainingInstance)  {
  if(!ai || !ai.training || !ai.training.reference) {
    return;
  }
  let all = getTrainingsMenu();
  all = all.filter(x=>ai.training?.reference !=x.training?.reference);
  all.push(ai);
  if(all) {
   localStorage.setItem("TRAININGS_MENU" , JSON.stringify(all));
  }
  
} 







export async function updateTrainingInstance(p:TrainingInstance, action: string) : Promise<any> {
  let data = await post(  instanceUrlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}
export async function searchTrainingInstance(request: any)   {   
  let data = await post(  instanceUrlBase+ "/search",request) ;   
  console.log("searchTrainingDefinition ", data)
  return data;
}    

export async function searchTrainingForMenu()   {   
  let data = await post(  instanceUrlBase+ "/search",{action: "MY-ELIGIBLES", actor: currentUser().email}) ;   
  console.log("searchTrainingForMenu ", data)
  if(data.result) {
    localStorage.setItem("TRAININGS_MENU", JSON.stringify(data.result));
    trainingsMenu=data.result;
  }
  return data?.result;
}    



export async function searchTrainingDefinition(request: any)   {   
  let data = await post(  urlBase+ "/search",request) ;   
  console.log("searchTrainingDefinition ", data)
  return data;
}    
export async function searchTrainingDefinitionDebounced(request: any)   {  
     return debounce1(searchTrainingDefinition, 300)(request);
}

let timer1:any = null;
function debounce1(f, interval:number) {  
    return (...args) => {
      clearTimeout(timer1);
      return new Promise((resolve) => {
        timer1 = setTimeout(
          () => resolve(f(...args)),
          interval,
        );
      });
  };
}


