import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Layout from './layout/layout';
import { BrowserRouter as Router, Route, Switch , useHistory} from "react-router-dom";
import Dashboard from './dashboard/dashboard';
import TimeLine from './feed-wall/time-line';
import Assessment from './assessment/assessment';
import  { AssessmentDefinitionList } from './assessment/assessments';
import { TrainingInfo, TrainingInfoList, TrainingModulePlayer } from './training/training-viewer';
import { QuizzesList } from './assessment/quizzes-list';
import { ChallengeResult } from './challenge/challenges-result';
import { fetchLocalUserProfile, getOrganizationTree, searchOUs } from './service/userService';
import { Login } from './auth/login';
import { PwdReset } from './auth/raz';
import { SearchUsers } from './admin/user-list';
import { User } from './admin/user';
import { SearchOU } from './admin/ou-list';
import { OU } from './admin/ou';
import { GroupView } from './admin/group';
import { SearchGroup } from './admin/group-list';
import { ProfileView } from './admin/profile';
import { SearchProfile } from './admin/profile-list';
import { AppConfigEditor } from './admin/app.config.editor';
import { SearchKpiDefinition } from './kpi/kpi-def-list';
import { KpiDefinitionView } from './kpi/kpi-def';
import { loadCurrentPeriods, mapKpiDefToMenu, searchKpiDef } from './service/kpiService';
import { AssessmentDefinitionForm } from './assessment/assessmentForm';
import { searchAssessmentForMenu } from './service/quizService';
import { TrainingDefinitionList } from './training/trainings';
import { TrainingDefinitionForm } from './training/training-form';
import { searchTrainingForMenu } from './service/trainingService';
import { ChallengeConfigForm } from './challenge/challengeForm';
import { ChallengeConfigResults } from './challenge/challenge-def-results';
import { ChallengeConfigList } from './challenge/challenges';
import { searchChallengeForMenu } from './service/challengeService'; 
import { SearchUMessages } from './message/messages-list';
import { ExplorAudit  } from './admin/audit-list';
function AppHome() { 
    let [userProfile, setUserProfile] = useState(fetchLocalUserProfile());
    let [kpiMenuLastUpdate, setKpiMenuLastUpdate] = useState(null as any); 
    useEffect(()=> { 
        loadCurrentPeriods().then(r=> {console.log("loadCurrentPeriods result", r)});
        getOrganizationTree().then(r=> {
            console.log("getOrganizationTree", r);
        });
        searchKpiDef({first:0 , max:1000, criteria: {} }).then(r=> {
            if(r.result) {
                if(mapKpiDefToMenu(r.result.filter(d=>d.activated))) {
                    setKpiMenuLastUpdate(new Date());
                }
            }
        })
        searchAssessmentForMenu().then(r=>{
            console.log("fetched quizzes.menu" , r); 
            setKpiMenuLastUpdate(new Date());
        });
        searchTrainingForMenu().then(r=>{
            console.log("fetched training.menu" , r); 
            setKpiMenuLastUpdate(new Date());
        });

        searchChallengeForMenu().then(r=>{
            console.log("fetched challenge.menu" , r); 
            setKpiMenuLastUpdate(new Date());
        });

    }, [userProfile]);

    return  <Layout postSignOut={setUserProfile} kpiMenuLastUpdate={kpiMenuLastUpdate}     >
    <Switch>
        <Route path="/dashboard/:id" >
            <Dashboard></Dashboard>
        </Route>
        <Route path="/quizzes-list" >
            <QuizzesList></QuizzesList>
        </Route>
        <Route path="/quiz/definition/:id/:mode" >
            <AssessmentDefinitionForm></AssessmentDefinitionForm>
        </Route>
        <Route path="/quiz/definition" >
            <AssessmentDefinitionList type="quiz"></AssessmentDefinitionList>
        </Route>
        <Route path="/polls/definition" >
            <AssessmentDefinitionList type="polls"></AssessmentDefinitionList>
        </Route>
        <Route path="/training/definition/:id/:mode" >
            <TrainingDefinitionForm></TrainingDefinitionForm>
        </Route>
        <Route path="/training/definition" >
            <TrainingDefinitionList></TrainingDefinitionList>
        </Route>
      
        <Route path="/training-list" >
            <TrainingInfoList></TrainingInfoList>
        </Route>
        <Route path="/training-info/:id" >
            <TrainingInfo></TrainingInfo>
        </Route>
        <Route path="/training-module/:trId/:stId/:mdId" >
            <TrainingModulePlayer></TrainingModulePlayer>
        </Route>

        <Route path="/assessment/:id" >
            <Assessment></Assessment>
        </Route>
       
        <Route path="/challenge/definition/:id/:mode" >
            <ChallengeConfigForm></ChallengeConfigForm>
        </Route>
        <Route path="/challenge/definition" >
            <ChallengeConfigList></ChallengeConfigList>
        </Route>
        <Route path="/challenge/:id" >
            <ChallengeResult></ChallengeResult>
        </Route>
        <Route path="/admin/user/:id/:mode" >
            <User></User>
        </Route>
        <Route path="/admin/user" >
            <SearchUsers></SearchUsers>
        </Route>


        <Route path="/admin/ou/:id/:mode" >
            <OU></OU>
        </Route>
        <Route path="/admin/ou" >
            <SearchOU></SearchOU>
        </Route>

        <Route path="/admin/group/:id/:mode" >
            <GroupView></GroupView>
        </Route>
        <Route path="/admin/group" >
            <SearchGroup></SearchGroup>
        </Route>
        <Route path="/admin/profile/:id/:mode" >
            <ProfileView></ProfileView>
        </Route>
        <Route path="/admin/profile" >
            <SearchProfile></SearchProfile>
        </Route>
        <Route path="/kpi/definition/:id/:mode" >
            <KpiDefinitionView></KpiDefinitionView>
        </Route>
        <Route path="/kpi/definition" >
            <SearchKpiDefinition></SearchKpiDefinition>
        </Route>
        
        <Route path="/admin/app-config" >
            <AppConfigEditor></AppConfigEditor>
        </Route>
        
        <Route   path="/feed/:timestamp" >
            <TimeLine></TimeLine>
        </Route>
        <Route   path="/message/:reference" >
            <SearchUMessages></SearchUMessages>
        </Route>
        <Route   path="/message" >
            <SearchUMessages></SearchUMessages>
        </Route> 
        
        <Route   path="/admin/explorAudit" >
            <ExplorAudit></ExplorAudit>
        </Route>
        
        <Route exact path="/post/:reference" >
            <TimeLine></TimeLine>
        </Route>
        <Route exact path="/" >
            <TimeLine timestamp={new Date().getTime()}></TimeLine>
        </Route>
    </Switch>
</Layout>
}
function App() {
   
    let [userProfile, setUserProfile] = useState(fetchLocalUserProfile());
    return (

        <Router>
            <div className="App">
                <Switch>
                    <Route path="/reset-pwd/:activationId/:email" >
                        <PwdReset></PwdReset>
                    </Route>
                    <Route path="/login" >
                        <Login  postSignIn={setUserProfile}  ></Login>
                    </Route>
                    <Route path="/">
                        {userProfile && <AppHome></AppHome>  }
                        {!userProfile && <Login postSignIn={setUserProfile} ></Login>}
                    </Route>
                </Switch>

            </div>

        </Router>
    );
}

export default App;
