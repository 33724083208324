
import React, { useState } from "react";
import "./stop-watch.css"; 

function ControlButtons(props) {
    const StartButton = (
      <div className="btn btn-one btn-start"
           onClick={props.handleStart}>
        Start
      </div>
    );
    const ActiveButtons = (
      <div className="btn-grp">
        <div className="btn btn-two" 
             onClick={props.handleReset}>
          Reset
        </div>
        <div className="btn btn-one" 
             onClick={props.handlePauseResume}>
          {props.isPaused ? "Resume" : "Pause"}
        </div>
      </div>
    );
    
    return (
      <div className="Control-Buttons">
        <div>{props.active ? ActiveButtons : StartButton}</div>
      </div>
    );
  } 
function Timer(props) { 
    return (
      <div className="timer"> 
        <span className="digits">
          {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
        </span>
        <span className="digits">
          {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}.
        </span>
        <span className="digits mili-sec">
          {("0" + ((props.time / 10) % 100)).slice(-2)}
        </span>
      </div>
    );
  }

function StopWatch(props) {
  const [isActive, setIsActive] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(props.duration);
  
  React.useEffect(() => {
    let interval :any = null ;
  
    if (isActive && isPaused === false && time >-1) {
      interval = setInterval(() => { 
        setTime((time) => time - 50);
      }, 50);
    } else {
      clearInterval(interval);
      if(props.onEnd) {
        props.onEnd(time);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);
  
  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };
  
  const handlePauseResume = () => {
    setIsPaused(true);
    setIsActive(false);
    if(props.onPause) {
        props.onPause(time);
    }
  };
  
  
  
  return (
    <div className="stop-watch">
      {time>-1  && <><Timer time={time} />
      {isActive && <i onClick={handlePauseResume} className="fa fa-pause-circle"></i> }
      {isPaused && <i onClick={handleStart} className="fa fa-play-circle"></i> }
      </>
      }
      {time <=-1 && <Timer time={0} />} 
      {/*<ControlButtons
        active={isActive}
        isPaused={isPaused}
        handleStart={handleStart}
        handlePauseResume={handlePauseResume}
        handleReset={handleReset}
      />*/}
    </div>
  );
}
  
export default StopWatch;