import { Post } from "../feed-wall/data";
import { t } from "../i18n";
import { searchKpiDataByKpi } from "../service/kpiService";
import { Collaborator, Entity } from "../test-data/entities";
import { KpiDefinition } from "../types/kpi-model";

export interface IndicatorData {
    kpiRenderer?:string;
    key?:string;
    title?: string;
    subtitle?:string;
    measure?:number;
    unit?:string;
    achievmentPercent?:number;
    target?:number;
    globalAchievment?:number;
    targetProrataPercent?:number; 
    targetProrata?:number; 
    targetEcart?:number;

    trend?:'UP' | 'DOWN' | 'STABLE';
    deltaPercent?:number;
    
    timeScope?: string;
    resource:Entity | Collaborator;
    kpiReference?:string;
    period?:any;
    kpiDefinition?: KpiDefinition;
    changeTime?:Date;
}

export async function getSubKpi(kpi:IndicatorData): Promise<IndicatorData[]>  { 
    if(!kpi) {
        return [];
    } 
   let targets : any[]  = kpi.resource.children ||[] ;
   if( !targets.length) {
    targets = ( kpi.resource as Entity).people||[];
   }
   console.log(">>>targets ",targets)
   let promises  = targets.map(t=>searchKpiDataByKpi(kpi.kpiReference || '', kpi.period.name, t.qn));

   let res = await  Promise.all(promises);

   return res.map(r=>r[0]).filter(x=>!!x);

   // return randomData({children : [{ name: kpi.title || ""}]}, targets || []    );
}
