
import { Message } from "../message/message-type";
import { post } from "./http.service";
import { currentUser } from "./userService";
const urlBase = '/api/people/message';//'http://localhost:11001';
 
export async function getMessage(reference: string) : Promise<any> {
  let data = await post(  urlBase+ "/get", { userId: currentUser().email , reference }) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}
 
export async function addMessage(p:Message) : Promise<any> {
    let data = await post(  urlBase+ "/create", { payload: p}) ;  
     
    if (!data.success || !data.body) {
        return null;
    } 
    return data.body;
}

export async function markMessage(p:Message ) : Promise<any> {
  let data = await post(  urlBase+ "/mark", {reference: p.reference, userId: currentUser().email ,action : "mark", payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

export async function markAllMessage(refs ) : Promise<any> {
  let data = await post(  urlBase+ "/mark", {reference: refs, userId: currentUser().email ,action : "mark", payload: {}}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}
export async function updateMessage(p:Message, action: string) : Promise<any> {
  let data = await post(  urlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

export async function addComment(postRef: string, comment:Comment, action) : Promise<any> {
    let data = await post(  urlBase+ "/comment", {action, reference: postRef, payload: comment}) ;  
     
    if (!data.success || !data.body) {
        return false;
    } 
    return data.body;
} 
export async function searchMessage(request: any)   {  
     
    let data = await post(  urlBase+ "/search",request) ;   
    return data;
}
export async function searchMessageDebounced(request: any)   {  
     return debounce1(searchMessage, 300)(request);
}
let timer1:any = null;
function debounce1(f, interval:number) {
     
  
    return (...args) => {
      clearTimeout(timer1);
      return new Promise((resolve) => {
        timer1 = setTimeout(
          () => resolve(f(...args)),
          interval,
        );
      });
    };
  }