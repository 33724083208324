import { transcode } from "buffer";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toYYYMMDD } from "../help/dates";
import { round } from "../help/formating";
import { t } from "../i18n";
import { getTrainingsMenu, persistTraining, updateTrainingInstance } from "../service/trainingService";
import { TrainingDefinition, TrainingInstance, TrainingModule, TrainingStage } from "./training-type";
import './training.css';
const statusAsLabel: any = {
    'DISABLED': t("Annulée"), 'OPEN': t("Ouverte"),
    'STARTED': t("Démarrée"), 'PAUSED': t("En pause"), 'CANCELED': t("Abandonnée"), 'CLOSED': t("Términée")
}
function timeFromeMin(min: number) {
    if (!min) {
        return "";
    }
    min = 5 * Math.round(min / 5);
    if (min < 60) {
        return min + "min";
    } else {
        let q = Math.floor(min / 60);
        let r = min - 60 * q;

        console.log("timeFromeMin q=" + q + " r=" + r);
        return (q + "H") + (r ? r : "");
    }
}
function DateBlock(props: any) {
    return (<div className="media">
        <div className="media-body">
            <label>{props.label}</label>
            <h6>{toYYYMMDD(props.date)}</h6>
        </div>
    </div>)
}

export function TrainingInfoList(props: any) {
    let trs = getTrainingsMenu();
    return (<div className="TrainingList">
        {trs.map((tr, i) => <TrainingInfo mode="summary" index={i + 1} training={tr}></TrainingInfo>)}
    </div>);
}

export function MetricBlock(props: any) {
    let holder = props.holder;
    let status = holder.status;
    let stepNumberLabel = holder.stagesNumber ? (holder.stagesNumber + " Etapes")
        : holder.modulesNumber ? (holder.modulesNumber + " Modules") : '';
    let time = timeFromeMin(holder.durationMin);
    let media = holder.media ? holder.media.type : "";
    return (
        <div className={"trn-metric " + props.className} >
            <div className="trn-me">
                <span className={"trn-me-statut trn-me-" + holder.status}>  {statusAsLabel[status]}</span>
                {stepNumberLabel && <> <i className="fa fa-layer-group"></i>    {stepNumberLabel}   </>}
                {media == 'PDF' && <i className="fa fa-file-pdf"></i>}
                {media == 'VIDEO' && <i className="fa fa-video"></i>}
                {media == 'YOUTUBE' && <i className="fab fa-youtube"></i>}
            </div>
            <div className="trn-me"  >
                <i className="fa fa-trophy"></i> <span> {holder.weightBadge || holder.weight }Pts </span>
                {holder.passingScore &&<><i className="fa fa-medal"></i> <span>{holder.passingScoreBadge  || holder.passingScore }%</span></>}
                <i className="fas fa-stopwatch"></i> <span>{time}</span>

            </div>

        </div>);
}
export function TrainingInfo(props: any) {
    let { id: id } = useParams() as any;
    let mode: string = props.mode;
    let indx = id || props.index;
    let ti: TrainingInstance = props.training ||  getTrainingsMenu().filter((x: TrainingInstance, ii)=>x.training.reference==id || (ii+1+"")==id)[0];
    let tr = ti.training;
    tr["weightBadge"] =  ""+(ti.score||0)+"/"+tr.weight;
    tr["passingScoreBadge"]  = tr.passingScore &&( round(100*(ti.score||0)/(tr.weight|| 1))+"/"+tr.passingScore);

    return (
        <div className="Training">
            <div className="trn-header">
                <div className="trn-title"><h3>{tr.title}</h3></div>
                <div className="assmt-dates az-content-header-right" >
                    <DateBlock date={tr.publicationDate} label="Publication" />
                    <DateBlock date={tr.closingDate} label="Clôture" />
                </div>
            </div>
            <div className="trn-info">
                <div className="trn-image">
                    <img src={tr.photoUrl} ></img>
                </div>
                <div className="trn-right">
                    <div className="trn-description">
                        <div className="trn-desc-cont" dangerouslySetInnerHTML={{ __html: tr.description || "" }}>
                        </div>
                        <MetricBlock holder={tr} />
                    </div>
                    {mode != 'summary' &&
                        <div className="trn-stages-ladder">
                            {tr.stages.map((s, i) => <StagePanel key={i} mode={mode} stage={s} training={tr} index={i + 1} path={indx + "/" + (i + 1)}></StagePanel>)}
                        </div>
                    }
                    {mode == 'summary' && <div className="trn-show-detail-btn ">
                        <Link to={"/training-info/" + props.index} className="btn btn-purple"><i className="fa fa-arrow-right
"></i> <span> Détails... </span></Link>
                    </div>}
                </div>
            </div>



        </div>
    );

}

export function StagePanel(props: any) {
    let tr: TrainingDefinition = props.training;
    let index: TrainingDefinition = props.index;
    let path: string = props.path;
    let st: TrainingStage = props.stage;
    let mode = props.mode;
    let [showBody, setShowBody] = useState(false);
    st["weightBadge"]=(st.score||0)+"/"+st.weight; 
    return (<div className="StagePanel">
        <div className="stg-header" onClick={() => setShowBody(!showBody)}  >
            <div className="stg-img"><img src={st.photoUrl}></img></div>
            <div className="stg-title">Etape {index} - {st.title}</div>
            <MetricBlock holder={st} />
            <div className="stg-collapse" >
                <i onClick={() => setShowBody(!showBody)} className={"fa  " + (showBody ? "fa-minus-circle" : "fa-plus-circle")} ></i>
            </div>
        </div>

        {showBody && <div className="stg-body">
            <div className="trn-description">
                <div className="paragraph" dangerouslySetInnerHTML={{ __html: st.description }}></div>
            </div>
            <div className="trn-modules-ladder">
                {st.modules.map((m, i) => <ModulePanel key={i} mode={mode} module={m} stage={st} training={tr} index={i + 1} path={path + "/" + (i + 1)} ></ModulePanel>)}
            </div>
        </div>
        }

    </div>)
}



export function ModulePanel(props: any) {
    let tr: TrainingDefinition = props.training;
    let index = props.index;
    let modulePath: string = props.path;
    let st: TrainingStage = props.stage;
    let md: TrainingModule = props.module;
    
    md["weightBadge"]=(md.score||0)+"/"+md.weight; 
    let mode = props.mode;
    let [showBody, setShowBody] = useState(false);
    let canStart = mode != "preview" && (md.status == 'OPEN' || md.status == 'STARTED');
    
    console.log("PREREQUIS "+md.prerequisite, md.title);
    if(md.prerequisite) {
        let pmd = tr.stages.flatMap(s=>s.modules).filter(m=>m.status!='OPEN').filter(m=>m.title==md.prerequisite)[0];
        
        console.log("PREREQUIS pmd "+pmd);
        if(!pmd) {
            canStart=false;
        }
    }
    return (<div className="ModulePanel">
        <div className="stg-header" onClick={() => setShowBody(!showBody)} >
            <div className="mdl-img"><img src={md.photoUrl}></img></div>
            <div className="stg-title">Module {index} - {md.title}</div>
            <MetricBlock holder={md} />
            <div className="stg-collapse" >
                <i onClick={() => setShowBody(!showBody)} className={"fa  " + (showBody ? "fa-minus-circle" : "fa-plus-circle")} ></i>
            </div>
        </div>

        {showBody && <div className="stg-body">
            <div className="trn-description">
                <div className="paragraph" dangerouslySetInnerHTML={{ __html: md.description }}></div>
            </div>
            {canStart && <div className="trn-show-detail-btn ">
                <Link to={"/training-module/" + modulePath} className="btn btn-purple"><i className="fa fa-play">
                </i> <span> Démarrer le module </span></Link>
            </div>}
        </div>
        }

    </div>)
}



export function TrainingModulePlayer(props: any) {
    let { trId, stId, mdId } = useParams() as any;
    let ti: TrainingInstance = getTrainingsMenu()[parseInt(trId) - 1];
    let tr: TrainingDefinition = ti.training;
    let st: TrainingStage = tr.stages[parseInt(stId) - 1];
    let index = mdId;
    let md: TrainingModule = st.modules[parseInt(mdId) - 1];
    let modulePath: string = "" + trId + "/" + stId + "/" + mdId;
    let mode = props.mode;
    let [showBody, setShowBody] = useState(false);
    let history = useHistory();
    if (mode != "preview") {
        if (!md.startTime) {
            md.startTime = new Date();
            md.score = 0;
        }
        st.status = 'STARTED';
        md.status = 'STARTED';
    }
    let saveTI = (action: string) => {
        updateTrainingInstance(ti, action).then(r => {
            console.log("return of start-module", r);
            if (!r) { return; }
            ti = r;
            persistTraining(ti);
        },
            err => { console.log("Erreur of start-module ", err) });
    }
    useEffect(() => {
        if (mode != "preview") {
            saveTI("start-module")

        }
    }, [mode])
    let closeModule = () => {
        md.status = 'CLOSED';
        md.endTime = new Date();
        if (st.modules.filter(m => m != md).filter(m => m.status != 'CLOSED').length == 0) {
            st.status = 'CLOSED';
            if (tr.stages.filter(s => s != st).filter(s => s.status != 'CLOSED').length == 0) {
                tr.status = 'CLOSED';

            }
        }
        saveTI("close-module");
    }
    let onStartQuiz = () => {
        closeModule();
        history.push("/assessment/quiz-" + md.assessment?.definition.reference);
    }
    return (<div className="ModulePanel">
        <div className="stg-header">
            <div className="mdl-img"><img src={md.photoUrl}></img></div>
            <div className="stg-title">Module {index} - {md.title}</div>
            <MetricBlock holder={md} />

        </div>

        <div className="stg-body">
            <div className="trn-description" >
                <div className="paragraph" dangerouslySetInnerHTML={{ __html: md.description }}></div>
            </div>
            <div className="mdl-media ">
                {md.media?.type == 'VIDEO' &&
                    <video className="feed-embedded-video" controls autoPlay muted >
                        <source src={md.media?.contentUrl} type="video/mp4" />
                    </video>
                }
                {md.media?.type == 'YOUTUBE' && md.media?.contentUrl && <iframe src={mapYoutubeUrl(md.media.contentUrl)}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>

                }
                {md.media?.type != 'VIDEO' && md.media?.type != 'YOUTUBE' && <iframe src={md.media?.contentUrl} ></iframe>}
                <div className="trn-show-detail-btn ">
                <Link to={"/training-info/" + trId} className="btn btn-purple">
                        <i className="fa fa-arrow-left">    </i> <span> Retour </span>
                    </Link>
                    {md.assessment && md.assessment.definition &&    <div onClick={onStartQuiz} className="btn btn-purple"><i className="fa fa-play">
                        </i> <span> Quizz </span></div>

                    }
                    {!md.assessment || !md.assessment.definition &&     <div onClick={closeModule} className="btn btn-purple"><i className="fa fa-play">
                        </i> <span> Terminer le module </span>
                        </div>
                  
                    }
                   

                </div>
                </div>
            </div>


        </div>)
}



        function mapYoutubeUrl(s:string) {

            let vk = s.replace("https://youtu.be/", "").replace(/.*watch\?v=/g, "");

        return "https://www.youtube-nocookie.com/embed/"+vk;
}