import { useParams } from 'react-router';
import './assessment.css';

import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { printShortDate } from '../help/dates';
import { Pagination } from '../ui/pagination/search-paging';
import '../admin/admin.css';
import { AssessmentDefinition, AssessmentInstance, Option } from './assessment-type';
import { searchAssessmentDefinition, searchAssessmentInstance } from '../service/quizService';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryStack, VictoryTheme } from 'victory';
import { removeListener } from 'process';
import { verifyQuestionResponse } from '../help/question';
import { t } from '../i18n';

export function AssessmentDefinitionStat(props: any) {
  console.log("AssessmentDefinitionStat renderer");
  let entity: AssessmentDefinition = props.entity;
  let tnp: boolean = props.tnp;
  let [request, setRequest] = useState({ first: 0, max: 5000, criteria: { assessmentReference: entity.reference }, time: new Date() });
  let [data, setData] = useState([] as AssessmentInstance[]);
  let [scores, setScores] = useState(initScores(entity) as { score: number, count: number, delta: number }[]);
  let [questionStat, setQuestionStat] = useState(initQuestionSlots(entity));
  let [pass, setPass] = useState([]);
  useEffect(() => {
    console.log("AssessmentDefinitionStat useEffect");
    if (entity.reference) {
      searchAssessmentInstance(request).then(resp => {
        console.log("searchAssessmentDefinition response", resp);
        if (resp && resp.result) {
          data = resp.result;
          setData(data);
          console.log("searchAssessmentDefinition setData done");
          for (let s of scores) {
            s.count = data.filter(x => (x.score || 0) >= s.score && (x.score || 0) < (s.score + s.delta)).length;
          }
          setScores([...scores]);

          for (let qs of questionStat) {
            let qr = data.map(r => r.definition.questions[qs.id - 1]).filter(x => !!x);
            let qr0 = qr[0];
            qs.correct = qr.filter(q => verifyQuestionResponse(q)).length;
            qs.wrong = qr.filter(q => !verifyQuestionResponse(q)).length;
            qs.attempted = qs.wrong + qs.correct;
            console.log("qr0", qr0);
            for (let op of qr0.options || []) {
              qs.stats[op.text + ""] = qr.filter(q =>
                (q.options || []).filter(x => x.id == op.id && !!x.checked).length > 0

              ).length;
            }
            console.log("qs.stat:", qs.stats);
          }

          questionStat = questionStat.sort((x, y) => y.correct - x.correct)
          setQuestionStat([...questionStat]);
          console.log("searchAssessmentDefinition setScores done");

        }
      })
    }
  }, [request]);
  let reload = (p: number) => {
    setRequest({ ...request, time: new Date(), first: p });
  }

  return (
    <div className="app-form">
      {entity.reference && <div className="ass-def-stat">
        {tnp && <>  <h5 className="shart-tile">{t("Distribution des scores")}</h5>
          <ChartScores entity={entity} data={scores} ></ChartScores>
          <h5 className="shart-tile">{t("Statistique des réponses correctes")}</h5>
          <ChartResponses entity={entity} data={questionStat} ></ChartResponses>
        </>}
        <h5 className="shart-tile">{t("Statistique des réponses")}</h5>
        <TableStat entity={entity} data={questionStat} tnp={tnp} ></TableStat>
      </div>
      }
    </div>
  );
}
export function TableStat(props) {

  let data = props.data;
  let entity = props.entity;
  return <div className="table-stat">
    {
      data.map((d, i) => {
        return <div key={i} className="table-stat-block">
          <div className="qh">
            <div className="qb"><span className="qn">{d.id}</span> - <span className="ql" >{d.yeslabel}</span></div>
            <div className="qs">
              <div className="qsi"> {d.attempted} <span> {t("Réponses")} </span></div>
              {props.tnp && <>  <div className="qsi green">{d.correct} <span> {t("Correctes")} </span> </div>
                <div className="qsi red">{d.wrong} <span>{t("Erreurs")} </span> </div>
              </>
              }
            </div>
          </div>
          <div className="qos">
            {Object.keys(d.stats).map((s, ii) => <div className="qo" key={ii}>
              <span className="qn" >{ii + 1} - </span>
              <span className="ql" >{s}</span>
              <span className="qv" >{d.stats[s]}</span></div>)}

          </div>
        </div>
      })
    }

  </div>
}
export function ChartScores(props) {
  const chartContainer = useRef(null);
  let data = props.data;
  let entity = props.entity;
  let [chartWidth, setChartWidth] = useState(Math.max(600, data.length * 20));

  console.log("AssessmentDefinitionStat chartScores");
  useEffect(() => {
    console.log("chartContainer", chartContainer.current);
    setChartWidth(Math.max((chartContainer.current as any).offsetWidth, data.length * 20))
  },
    [chartContainer.current]);

  return <div className="ass-stat-chart-container" ref={chartContainer}>
    <VictoryChart
      colorScale={"qualitative"}
      theme={VictoryTheme.material}
      domainPadding={{ x: 25 }}
      height={350} width={chartWidth - 50}
      containerComponent={<VictoryContainer responsive={false} />}
    >
      <VictoryAxis
        tickValues={data.map(d => " "+d.score)}
        label="scores"
        style={{
          axis: { stroke: "#756f6a" },
          axisLabel: { fontSize: 15, padding: 30 }
        }}  
      />
      <VictoryAxis
        dependentAxis
        tickFormat={(x) => (`${x / 1}`)}
        label="Occurence"
        style={{
          axis: { stroke: "#756f6a" },
          axisLabel: { fontSize: 15, padding: 30 },
          ticks: { stroke: "grey", size: 5 },
          tickLabels: { fontSize: 12, padding: 2 }
        }}
      />
      <VictoryStack colorScale={"qualitative"}     >
        <VictoryBar
          labels={({ datum }) => datum.count} 
          labelComponent={<VictoryLabel renderInPortal dy={-4} />}
          style={{ data: { fill: "#c43a31", width: 15, margin: 0, padding: 0 } }}
          data={data}
          x={(d)=>" "+d.score}
          y="count"
        />
      </VictoryStack>
    </VictoryChart>
  </div>
}




export function ChartResponses(props) {
  const chartContainer = useRef(null);
  let data = props.data;
  let entity = props.entity;
  let [chartWidth, setChartWidth] = useState(Math.max(600, data.length * 20));

  console.log("AssessmentDefinitionStat chartScores");
  useEffect(() => {
    console.log("chartContainer", chartContainer.current);
    setChartWidth(Math.max((chartContainer.current as any).offsetWidth, data.length * 20))
  },
    [chartContainer.current]);

  return <div className="ass-stat-chart-container" ref={chartContainer}>
    <VictoryChart
      colorScale={"qualitative"}
      theme={VictoryTheme.material}
      domainPadding={50}
      height={350} width={chartWidth - 50}
      containerComponent={<VictoryContainer responsive={false} />}
    >
      <VictoryAxis
        tickValues={data.map(d => "Q-" + d.id)}
        label="Questions"
        style={{
          axis: { stroke: "#756f6a" },
          axisLabel: { fontSize: 15, padding: 30 }
        }}

      />
      <VictoryAxis
        dependentAxis

        label="Réponses Correcte"
        style={{
          axis: { stroke: "#756f6a" },
          axisLabel: { fontSize: 15, padding: 30 },
          ticks: { stroke: "grey", size: 5 },
          tickLabels: { fontSize: 12, padding: 2 }
        }}
      />
      <VictoryStack
        colorScale={"qualitative"}
        domainPadding={{ x: [30, 30] }}
      >
        <VictoryBar
          labels={({ datum }) => datum.correct + '/' + datum.attempted}
          labelComponent={<VictoryLabel renderInPortal dy={-4} />}
          style={{ data: { fill: "#c43a31", width: 15, margin: 0, padding: 0 } }}
          data={data}
          x="id"
          y="correct"
        />
      </VictoryStack>
    </VictoryChart>
  </div>
}



function initScores(en: AssessmentDefinition): { score: number; count: number; delta: number }[] {
  let max = en.questions.map(q => q.weight).reduce((a, b) => a + b, 0);
  let incr = Math.ceil(max / 10);
  if (incr < 1) {
    incr = 1;
  }

  let ret: { score: number, count: number, delta: number }[] = [];
  for (let i = 0; i <= max; i = i + incr) {
    ret.push({ score: i, count: 0, delta: incr });
  }
  return ret;
}



function initQuestionSlots(en: AssessmentDefinition): {
  id: number,
  yeslabel: string,
  code: string,
  correct: number,
  wrong: number,
  attempted: number,
  stats: any
}[] {

  return en.questions.map((q, i) => {
    return {
      id: i + 1,
      yeslabel: q.question,
      code: '',
      correct: 0,
      wrong: 0,
      attempted: 0,
      stats: {}
    }
  });
}
