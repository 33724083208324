import { Children, useEffect, useState } from "react";
import { timeSpanOf, toYYYMMDD } from "../help/dates";
import { t } from "../i18n";
import { uploadFile } from "../service/file.service";
import { feedKpiByFileId, searchPeriod } from "../service/kpiService";
import { currentUser } from "../service/userService";
import { orga_tree_root, ROOT_ENTITIES } from "../test-data/entities";
import { KpiDefinition, Period, Resource } from "../types/kpi-model";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import "./kpi.css";

let ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT" ) || "[]");

function mapPeriodName(period: Period) {
    let n = period.name;
    if(period.type=="week") {
        return n+ " "+timeSpanOf(period);
    }
    return n;
}
let fetchEntityFromTree=(  qn , parent, minLevel: number,  maxLevel:number ) => {
    if(parent.qn==qn) {
        return parent;
    }
    if(parent.children) {
        for(let c of parent.children) {
            let ret = fetchEntityFromTree(  qn, c, minLevel, maxLevel);
            if(ret) {
                 return ret;
            }
        }
    }
    return null;
}
let fetchSubEntities=(cumul : any[], qn, parent, minLevel, maxLevel, level) =>{ 
    console.log(">>>qn="+qn+" parent=", parent);
    if(parent.qn==qn || parent.qn.startsWith(qn)) {
        cumul.push({email: "", qn: parent.qn, name: parent.name})  ;
     
    }
    if(parent.children) {
        for(let c of parent.children) {
           fetchSubEntities( cumul, qn, c, minLevel, maxLevel, level);
             
        }
    }
    console.log("<<<<qn="+qn+" cumul=", cumul);
}
const CSV_DATA_HEADER =   ["INDICATEUR","ENTITE","COLLABORATEUR", "PERIODE TOTALE",	"PERIODE D'ENREGISTREMENT",	"UNITE", 	"PERFORMANCE", "CUMUL"];
const CSV_TARGET_HEADER =   ["INDICATEUR","ENTITE","COLLABORATEUR", "PERIODE TOTALE", "UNITE",  "CIBLE"];
    
async function generateTargetCSV(kpiDef: KpiDefinition, period: Period, entity: Resource) {
    const rows = [ CSV_TARGET_HEADER  ];
    const dataRows: any[] = [];
    let seedPeriods: Period[] = await searchPeriod({criteria: {type: kpiDef.seedPeriodTime, strat: period.start, end: period.end}}); 
    let subEntities :any[] = [];
     fetchSubEntities(subEntities, entity.qn, orga_tree_root,kpiDef.ouLevelMin, kpiDef.ouLevelMax, 0);
    let collabs: any[]  = fetchEntityFromTree(entity.qn,  orga_tree_root, kpiDef.ouLevelMin, kpiDef.ouLevelMax)?.people || [];
    console.log("collabs=", collabs); 
        for(let se of subEntities ) { 
            dataRows.push([
                kpiDef.name || "", se.qn ,   "",   mapPeriodName(period),    kpiDef.unit=='Autre'? kpiDef.otherUnit: kpiDef.unit,  "0.0",  

            ])
        }
        for(let cl of collabs ) { 
            dataRows.push([
                kpiDef.name || "",  cl.qn.replace("/"+cl.email, ""),   cl.email,  mapPeriodName(period),      kpiDef.unit=='Autre'? kpiDef.otherUnit: kpiDef.unit,     "0.0",     

            ])
        }
   
    dataRows.sort((a,b)=>a.qn>b.qn? 1 : a.qn==b.qn? 0 : -1 ).forEach(x=>rows.push(x));
    downloadAsCSV(rows, kpiDef.name?.replace(/\s+/g, "-")+"-"+period.name+"-target.csv" );
}
async function generateDataCSV(kpiDef: KpiDefinition, period: Period, seedPeriod: Period | null, entity: Resource) {
    const rows = [ CSV_DATA_HEADER  ];
    const dataRows: any[] = [];
    
    let seedPeriods: Period[] =seedPeriod? [seedPeriod] : await searchPeriod({criteria: {type: kpiDef.seedPeriodTime, start: period.start, end: period.end}}); 
    let subEntities :any[] = [];
     fetchSubEntities(subEntities, entity.qn, orga_tree_root,kpiDef.ouLevelMin, kpiDef.ouLevelMax, 0);
    let collabs: any[]  = fetchEntityFromTree(entity.qn,  orga_tree_root, kpiDef.ouLevelMin, kpiDef.ouLevelMax)?.people || [];
    console.log("collabs=", collabs);
    for(let sp of seedPeriods) {
        for(let se of subEntities ) { 
            dataRows.push([ kpiDef.name || "", se.qn , "", mapPeriodName(period), mapPeriodName(sp),   kpiDef.unit=='Autre'? kpiDef.otherUnit: kpiDef.unit,  "0.0",  "0.0"])
        }
        for(let cl of collabs ) { 
            dataRows.push([kpiDef.name || "",  cl.qn.replace("/"+cl.email, ""),  cl.email, mapPeriodName( period),  mapPeriodName(sp), kpiDef.unit=='Autre'? kpiDef.otherUnit: kpiDef.unit, "0.0", "0.0"])
        }
    }
    dataRows.sort((a,b)=>a.qn>b.qn? 1 : a.qn==b.qn? 0 : -1 ).forEach(x=>rows.push(x));
    downloadAsCSV(rows, (kpiDef.name+"-"+(seedPeriod? seedPeriod.name : period.name)+"-data.csv")?.replace(/\s+/g, "-") );
   
}
let downloadAsCSV=(rows: any[], fileName:string) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function(rowArray) {
        let row = rowArray.join(";");
        csvContent += row + "\r\n";
    });
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);   
    link.click();
    link.remove();
}
export function KpiFeederPopup(props: any) {
    let cu = currentUser();
    console.log("cu", cu);
    let kpiDef: KpiDefinition = props.kpiDefinition;
    let [errorData, setErrorData] = useState('');
    let [errorTarget, setErrorTarget] = useState('');
    let [periodList, setPeriodList] = useState([] as Period[])
    let [seedPeriodList, setSeedPeriodList] = useState([] as Period[])
    let [resourceList, setResourceList] = useState(ALL_ENTITIES_FLAT as Resource[]);
    let [resource, setResource] = useState({name: cu.entity?.value, label: cu.entity?.label ,
         qn: cu.entity?.value});
    let [loading, setLoading] = useState(false);
    
    console.log("resource", resource);
    let [targetPeriod, setTargetPeriod] = useState({} as Period);
    let [seedPeriod, setSeedPeriod] = useState(null);
    let [dataFileId, setDataFileId] = useState('');
    let [dataFileName, setDataFileName] = useState('');
    let [targetFileId, setTargetFileId] = useState('');
    let [targetFileName, setTargetFileName] = useState('');
    let closePopup = () => {
        props.onClose();
    }
    let onSelectPeriod = (d) => {
         console.log("set period ", d) ;
         if(d!=targetPeriod) {
         targetPeriod = d;
         seedPeriod = null;
         setTargetPeriod(d);
         setSeedPeriod(seedPeriod);

          searchPeriod({criteria: {type: kpiDef.seedPeriodTime, start: targetPeriod.start, end: targetPeriod.end }}).then(
            (e)=> {
                setSeedPeriodList(e);
            }
        );
         }
        };
    let onSelectSeedPeriod = (d) => { console.log("set seed period ", d) ; setSeedPeriod(d)};
    let onSelectResource = (d) => { console.log("set resource ", d); setResource(d) };
    let onDataFileChange = (evt: any) => { 
        setErrorData("")
        let fileName:string = evt.target.files[0].name ;
        if(!fileName.endsWith("-data.csv")) {
                setErrorData("L'extension du fichier doit être '-data.csv'")
                return;
        }
        uploadFile(evt, true).then((fileId: any) => {
            setDataFileId(fileId);
            setDataFileName(evt.target.files[0].name); 
        });
    }
    let onTargetFileChange= (evt: any) => { 
        setErrorTarget("")
        let fileName:string = evt.target.files[0].name ;
        console.log("TBDC"+fileName);
        if(!fileName.endsWith("-target.csv")) {
                setErrorTarget("L'extension du fichier doit être '-target.csv'")
                return;
        }
        
        console.log("TBDC uploading");
        uploadFile(evt, true).then((fileId: any) => {
            setTargetFileId(fileId);
            setTargetFileName(evt.target.files[0].name); 
        });
    }
    let generateDataTemplate = ()=> {
        setLoading(true);
        generateDataCSV(kpiDef, targetPeriod, seedPeriod, resource);
        setLoading(false);
    }
    let generateTargetTemplate = ()=> {
        setLoading(true);
        generateTargetCSV(kpiDef, targetPeriod, resource);
        setLoading(false);
    }
    let confirmData = ()=> {
        feedKpiByFileId(dataFileId, "feed-data", kpiDef)
        closePopup();
    }
    let confirmTargets = ()=> {
        feedKpiByFileId(targetFileId, "feed-target", kpiDef)
        closePopup();
    }
    useEffect(()=> {
        searchPeriod({criteria: {type: kpiDef.targetPeriodTime}}).then(
            (e)=> {
                setPeriodList(e);
            }
        );
    }, [kpiDef.targetPeriodTime])
    return (<div className="popup-container">
        <div className="popup-inner popup-inner-large">
            <i onClick={closePopup} className="fa fa-times"></i>
            <div className="popup-header">
                <h4><strong>Import de données pour {kpiDef.name}</strong></h4>
            </div>
            
            {loading && <div className="loading">  <i className="fas fa-spinner fa-spin"></i></div>}
            <div className="fieldset">
                <h5>{t("Générer la template")}</h5>
                <div className="kpi-form" >
                    <div className="flex-c3 ">
                    <div className="form-group ">
                        <label>{t("Période d'Objectif ")}</label>
                        <DropdownList data={periodList} value={targetPeriod}
                            onSelect={onSelectPeriod} enableSearch={true}
                        ></DropdownList>
                    </div>
                    <div className="form-group ">
                        <label>{t("Période d'import ")}</label>
                        <DropdownList data={seedPeriodList} value={seedPeriod}
                            onSelect={onSelectSeedPeriod} enableSearch={true}
                        ></DropdownList>
                    </div>
                    <div className="form-group ">
                        <label>{t("Entité")}</label>
                        <DropdownList 
                        optionRender={d=>(<><div>{d.title}</div><div><small>{d.qn}</small></div></>)} 
                        data={resourceList} value={resource}
                            onSelect={onSelectResource} enableSearch={true}
                        ></DropdownList>
                    </div>
                    </div>
                    {resource && resource.qn  && targetPeriod && targetPeriod.name && 
                    <div className="flex-c2 ">
                        <div onClick={generateDataTemplate} className="btn generate-template">
                            <i className="fa fa-file-download"></i>
                            <span>Modèle d'import de données</span>
                        </div>
                        <div onClick={generateTargetTemplate} className="btn generate-template">
                            <i className="fa fa-file-download"></i>
                            <span>Modèle d'import d'objectifs</span>
                        </div>
                    </div>
                   }
                 
                </div>

            </div>

            <div className="fieldset">
                <h5>{t("Fichier de données")}</h5>
                <div className="flex-sa">
                    <label htmlFor="data-csv-file" >
                        <div className="btn generate-template">
                            <i className="fa fa-upload"></i>
                            <span>Charger le fichier CSV</span>
                        </div>  <input type="file" id="data-csv-file" name="data-csv-file" onChange={onDataFileChange}
                            className="h-file-input" accept=".csv" ></input>
                     </label>
                    {dataFileName && <span className="file-name">{dataFileName}</span> }
                    {dataFileId && <div onClick={confirmData} className="btn generate-template btn-confirm">
                        <i className="fa fa-check"></i>
                        <span>Confirmer</span>
                    </div>
                    }
                </div>
                
                {errorData && <p className="form-error">{errorData}</p>}

            </div>

            
            <div className="fieldset">
                <h5>{t("Fichier des objectifs")}</h5>
                <div className="flex-sa">
                    <label htmlFor="target-csv-file" >
                        <div className="btn generate-template">
                            <i className="fa fa-upload"></i>
                            <span>Charger le fichier CSV</span>
                        </div>  <input type="file" id="target-csv-file" name="target-csv-file" 
                        onChange={onTargetFileChange}
                            className="h-file-input" accept=".csv" ></input>
                     </label>
                    {targetFileName && <span className="file-name">{targetFileName}</span> }
                    {targetFileId && <div onClick={confirmTargets} className="btn generate-template btn-confirm">
                        <i className="fa fa-check"></i>
                        <span>Confirmer</span>
                    </div>
                    }
                </div>
                {errorTarget && <p className="form-error">{errorTarget}</p>}

            </div>
        </div>

    </div>)
}