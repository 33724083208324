import { LoremIpsum } from "lorem-ipsum";

export function randomInt(min: number = 0 , max:number=10): number {
    return Math.round (min+ Math.random()*(max-min));
}
export function generateRandomTitle(lorem: LoremIpsum): string {
    return lorem.generateSentences(1).replace(".", "") ;
}

export function generateRandomDateTxt(year :string = '2021' ) {
    return (""+(100+randomInt(1,28))).substring(1)+"-"+ (""+(100+randomInt(1,12))).substring(1)+"-"+year;
} 

export function generateRandomDateTime(year :string = '2021' ) {
    return generateRandomDateTxt()+ " " + (""+(100+randomInt(0,23))).substring(1)+":"+ (""+(100+randomInt(0,59))).substring(1);
}


const imagesLinks = ["https://images.unsplash.com/photo-1631645785115-a1ce4aac6e12?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEzfF9oYi1kbDRRLTRVfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
"https://images.unsplash.com/photo-1614518920881-532c6bc24802?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDE5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
"https://images.unsplash.com/photo-1613999941326-6edd09ada2af?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDExfE04alZiTGJUUndzfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
"https://images.unsplash.com/photo-1632504123823-dd3d9ad2b9ea?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=436&q=80",
"https://images.unsplash.com/photo-1633096524526-fe38681d6e4a?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDExfGhtZW52UWhVbXhNfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
"https://images.unsplash.com/photo-1632265345980-acae0fe74e12?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDc2fHhIeFlUTUhMZ09jfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
]
export function generateRandomPhotoUrl(  ) {
    let i = randomInt(0, 100);
    return  imagesLinks[i%imagesLinks.length];
}



export function generateRandomVideoUrl(  ) {
    let i = randomInt(0, 100);
     
    return i%2==0 ?  "http://enterprizy.io:8080/videos/fondamentaux-marketing-720p.mp4" : "http://enterprizy.io:8080/videos/Tutoriel-marketing-digital-720p.mp4" ;
}

export function generateRandomYoutubeUrl(  ) {
    let i = randomInt(0, 100); 
    return  i%2==0 ? "https://www.youtube.com/embed/6Ey30liqepI" : "https://www.youtube.com/embed/eeeMA_keyRc" ;
}
 

export function generateRandomPDFUrl(  ) {
    let i = randomInt(0, 100);
    return  "http://web.engr.oregonstate.edu/~traylor/ece474/beamer_lectures/state_mach_mealy.pdf" ;
}



