
import { getSubKpi, IndicatorData } from "../dashboard/dashboard-data";
import { VictoryLabel, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryContainer, VictoryGroup, VictoryLine, VictoryStack, VictoryTooltip } from 'victory';
import { useState, useCallback, useEffect, useRef } from "react";
import { t } from "../i18n";
import { round } from "../help/formating";


const legends = {  "Cumulé" :["#3C5BA3", 2],  "Réalisé": ["#3C5BA3", 0], 
 "Objectif" :["#EE3C25", 0 ],  "Objectif Proraté"  :["#EE3C25", 2]};

function KpiChartBarsVertical(props: any) {
  let kpi: IndicatorData = props.kpi;

  let [data, setData] = useState([] as IndicatorData[]);
  const [chartWidth, setCharWidth] = useState(600);
  const resizeChart =   ()=> { 
    let w = ( cardInd.current as any)?.getBoundingClientRect().width || 0; 
    let cw = Math.max(w - 10, data.length * 60); 
    setCharWidth(cw)} ; 
  
  useEffect(() => {
    if (!kpi) { return }
    getSubKpi(kpi).then(r => {
      console.log("POST get SubKpi", r);
      if (r && r.length) { 
        data = r.filter(x=>!!x && x.resource && x.resource.name);
        console.log("data "+data.length, data);
        setData(data); 
        resizeChart();
      } else{
        setData([]);
      }
    })
  }, [props.kpi ]);

  


  data.forEach(i => {
    try {i.targetEcart = Math.max(0, (i.target || 0) - (i.targetProrata || 0));
    i["exCumul"] = Math.max(0, (i.globalAchievment || 0) - (i.measure || 0)); 
    } catch(e) {
      console.error("error in ", e)
    }
  })
  let cardInd = useRef(null);

  useEffect(()=> { 
    if(!cardInd.current) {    return;   } 
    const ro = new ResizeObserver(resizeChart);
    ro.observe(cardInd.current);
    return ()=> ro.disconnect();
  }, [cardInd] )
  let ch = 410;//   75* data.length+ 100;
  
  let dfactor = 1;
  let dfactorUnit = kpi.unit;
  if (!kpi) {
    return (<></>);
  }
  return ( <>
    <div className={"card  card-table-one  card-bars indicator-card " + props.className} ref={cardInd}>
      <h6 className="card-title"><strong>{t(kpi.title || '')}</strong> {t('pour')} <strong>{kpi.resource.label}</strong> sur <strong>{kpi.timeScope}</strong> </h6>

      <VictoryChart

        colorScale={"qualitative"}
        theme={VictoryTheme.grayscale}
        domainPadding={30}
        height={ch}
        width={chartWidth }
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          tickValues={data.map(d => d.resource.name)}
          tickFormat={x=>shortName(x)}
          style={{
            axis: { stroke: "#fff" },
            axisLabel: { fontSize: 15, padding: 30 }
          }}

        />
        <VictoryAxis
          dependentAxis
          tickFormat={(x) => (`${x / dfactor}`)}
          style={{
            axis: { stroke: "#fff" },
            axisLabel: { fontSize: 15, padding: 30 },
            ticks: { stroke: "#fff", size: 0 },
            tickLabels: { fontSize: 12, padding: 2 }
          }}
        />
        <VictoryGroup offset={16}
          style={{
            data: {
              fillOpacity: 0.8, stroke: "black", width: 10, strokeWidth: 0,
            }
          }}
          
          colorScale={"qualitative"} >
          <VictoryStack
            colorScale={"qualitative"}
          >
            <VictoryBar
              data={data}
              x="resource.name"
              y="exCumul"
              style={{
                data: {
                    stroke: "black",   strokeWidth: 1
                }
              }}
              colorScale={["#3C5BA3"]}
            />
            <VictoryBar
              labels={({ datum }) => round(datum.exCumul) + "+" + round(datum.measure)+" "+datum.resource.name}
              labelComponent={<VictoryTooltip renderInPortal dy={-4} />}
              data={data}
              x="resource.name"
              y="measure"
              colorScale={["#3C5BA3"]}
            />

          </VictoryStack>
          <VictoryStack
            colorScale={"qualitative"}
           
          >
            <VictoryBar
              data={data}
              x="resource.name"
              y="targetProrata"
              colorScale={["#EE3C25"]}
              style={{
                data: {
                    stroke: "black",   strokeWidth: 1
                }
              }}
            />
            <VictoryBar
            
            labels={({ datum }) => round(datum.targetProrata, 0) + " / " + round(datum.target)+" "+datum.resource.name}
            labelComponent={<VictoryTooltip renderInPortal dy={-4} />}
              data={data}
              x="resource.name"
              y="targetEcart"
              colorScale={["#EE3C25"]}
            />

          </VictoryStack>
        </VictoryGroup>

      </VictoryChart>
      
      <div className="chart-legend" >
        {Object.keys(legends).map((k:string)=><div className="legend-block flex-pl">
          <div style={{boxSizing: "border-box",  background:legends[k][0],  border: legends[k][1]+"px solid #000", width: "40px", height: "5px", marginTop: "8px"}}></div><div>{k}</div> 
        </div>)  }
    </div>
      </div>
    </>);
}

    export default KpiChartBarsVertical;

function shortName(x: any): string  { 
  if(!x) {
    return x;
  }
  let xx=(""+x).trim();
  if(xx.length>10) {
    if(xx.indexOf(" ")>0) {
      return xx.split(" ").filter((t,i)=>i < 3)
      .map((t:string)=>t.substring(0,1).toUpperCase()).filter(t=>t!="(").join(".");
    }
  return xx.substring(0,10)+".";
  }
  return xx;
}

