import { Comment, Post } from "../feed-wall/data";
import { KpiDefinition, KpiFamily, Period } from "../types/kpi-model";
import { Group, OrganizationUnit, Profile, UserProfile } from "../types/people";
import { post } from "./http.service";
import { currentUser } from "./userService";
const urlBase = '/api/kpi/kpi-def';//'http://localhost:11002';
 

 
export async function addKpiDef(p:KpiDefinition) : Promise<any> {
    let data = await post(  urlBase+ "/create", { payload: p}) ;  
     
    if (!data.success || !data.body) {
        return null;
    } 
    return data.body;
}

export async function updateKpiDef(p:KpiDefinition, action: string) : Promise<any> {
  let data = await post(  urlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}


export async function feedKpiByFileId(fileId: string, action:string,p:KpiDefinition )   { 
  let data = await post(    urlBase+ "/feeder",{action, fileId, payload: p}) ;   
      
  return data;
}


export async function getKpiDefById(id: string)   { 
  let data = await post(    urlBase+ "/get",{action: "load", reference: id}) ;   
      
  return data;
}

export async function createFamily(  familiy:KpiFamily) : Promise<any> {
    let data = await post(  urlBase+ "/family", {action: 'CREATE',  userId: currentUser().email , payload: familiy}) ;  
     
    if (!data.success || !data.body) {
        return false;
    } 
    return data.body;
} 
export async function updateFamily(reference: string, familiy:KpiFamily) : Promise<any> {
  let data = await post(  urlBase+ "/family", {action: 'UPDATE', reference,userId: currentUser().email ,  payload: familiy}) ;  
   
  if (!data.success || !data.body) {
      return false;
  } 
  return data.body;
} 

export async function searchPeriod(request: any)   {  
     
  let data = await post(  urlBase+ "/search-period",request) ;   
  return data.result;
}
let _currentPeriodsMap ;
export   function currentPeriodsMap() {
  console.log("currentPeriodsMap", _currentPeriodsMap)
  if(!_currentPeriodsMap) {
      loadCurrentPeriods();
      return JSON.parse(localStorage.getItem("CURRENTS_PERIODS")||"{}");
  }
  return _currentPeriodsMap;
}
export async function loadCurrentPeriods()   {  
  let now = new Date(1000*3600*Math.round(new Date().getTime()/(1000*3600))); 
  let data = await post(  urlBase+ "/search-period",{toDate: now, criteria: {}}, 1000*3600) ;  
  let r = data.result;
  if(r) {
    _currentPeriodsMap = {'day': r.filter((x: Period)=>x.type=='day')[0],
  'week': r.filter((x: Period)=>x.type=='week')[0],
  'month': r.filter((x: Period)=>x.type=='month')[0],
  'year': r.filter((x: Period)=>x.type=='year')[0], }
  localStorage.setItem("CURRENTS_PERIODS", JSON.stringify(_currentPeriodsMap));
  }
  return data.result;
}
export async function loadPeriodsByType(type: string, toDate: Date) {
  
  let data = await post(  urlBase+ "/search-period",{ toDate,  criteria: {type:type}}, 1000*3600) ;  
  return data.result;
}
export async function searchKpiDataByKpi(kpiReference:string, periodQn:string, resourceQn:string)   { 
  let data = await post( urlBase+ "/search-kpi-data", {action: "fetch-kpi-data-single", criteria : {kpiReference, period: {name: periodQn}, resource:{ qn:resourceQn}}  , userId : currentUser().email }) ;   
  return data.result;
}

export async function searchKpiDataByTargetPeriod(kpiReference:string,   resourceQn:string)   { 
  let data = await post( urlBase+ "/search-kpi-data", {action: "fetch-kpi-data-for-target-period", criteria : {kpiReference,  resource:{ qn:resourceQn}}  , userId : currentUser().email }) ;   
  return data.result;
}

export async function searchKpiDataByKpiFamily(familyReference:string, periodQn:string, resourceQn:string)   { 
  let data = await post(  urlBase+ "/search-kpi-data", {action: "fetch-kpi-data-by-family", criteria : {familyReference, period: {name: periodQn},  resource:{ qn:resourceQn}}  , userId : currentUser().email }) ;   
  return data.result;
}
export async function searchLastKpiDataByKpiFamily(familyReference:string,  resourceQn:string)   { 
  let data = await post(  urlBase+ "/search-kpi-data", {action: "fetch-kpi-data-by-family", criteria : {familyReference,  resource:{ qn:resourceQn}}  , userId : currentUser().email }) ;   
  return data.result;
}
export async function searchFamilies(request: any)   {  
     
  let data = await post(  urlBase+ "/search-family",request) ;   
  return data.result;
}
export async function searchKpiDef(request: any)   {  
     
    let data = await post(  urlBase+ "/search",request) ;   
    return data;
}
let kpiMenu:any[] ;
export function getKpiMenu()  {
  if(!kpiMenu) {
    kpiMenu = JSON.parse(localStorage.getItem("KPI_MENU")||"[]");
  }
  return kpiMenu;
} 
export function mapKpiDefToMenu(kpis:KpiDefinition[]):boolean {
  let root: any[] = [];
  for(let kp of kpis) {
    if(root.filter(x=>x.name==kp.family.parent?.name).length) {
      continue;
    }
    root.push({reference: kp.family.parent?.reference,name: kp.family.parent?.name || "", label:  kp.family.parent?.title ||  kp.family.parent?.name || "", children:[] })
  }

  for(let kp of kpis) {
    let rl=root.filter(x=>x.name==kp.family.parent?.name);
    if(rl.length) {
      let r = rl[0];
      let done = false;
      for(let sf  of r.children ) {
        if(sf.name==kp.family.name ) {
          sf.children.push(kp);
          done=true;
          break;
        }
      }
      if(!done) {
        r.children.push({reference: kp.family.reference, name: kp.family.name, label: kp.family.title || kp.family.name, children:[kp]});
      }
    }
   }
  let KPI_MENU_NEW = JSON.stringify(root);
  if(KPI_MENU_NEW==localStorage.getItem("KPI_MENU")) {
    return false;
  }
  localStorage.setItem("KPI_MENU", KPI_MENU_NEW);
  kpiMenu=root;
  return true;
}
export async function searchPostDebounced(request: any)   {  
     return debounce1(searchKpiDef, 300)(request);
}
let timer1:any = null;
function debounce1(f, interval:number) {
     
  
    return (...args) => {
      clearTimeout(timer1);
      return new Promise((resolve) => {
        timer1 = setTimeout(
          () => resolve(f(...args)),
          interval,
        );
      });
    };
  }