import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryStack, VictoryTheme, VictoryTooltip } from 'victory';
import { OrganizationCascalde } from '../dashboard/organization-cascade';
import { printShortDate, printSmartDate, toYYY_MM_DD, toYYYMMDDHHMM } from '../help/dates';
import { round2 } from '../help/formating';
import { t } from '../i18n';
import { appConfig, currentUser, listingAudit, searchAudit, searchUsers } from '../service/userService';
import { ALL_COLLABS } from '../test-data/entities';
import { Avatar } from '../ui/avatar';
import { Pagination } from '../ui/pagination/search-paging';
import './admin.css';

const domains = [
  ["/quiz-instance", "Quiz & Sondages"],
  ["/training", "Formations"],
  ["/kpi", "KPI"],
  ["/challenge", "Challenges"],
  ["/message", "Messages"],
  ["/post", "Posts"],
  ["", "Tous"],
]


const DayAsMs = 24 * 60 * 60 * 1000;
export function ExplorAudit(props: any) {
  let [data, setData] = useState([] as { period: string, count: number, pc: number }[]);
  let [paket, setPacket] = useState("day");
  let [to, setTo] = useState(new Date());
  let [from, setFrom] = useState(new Date(new Date().getTime() - 11 * DayAsMs));
  let [targetEntity, setTargetEntity] = useState({ qn: "", label: t(appConfig().ouLevels[0].label || "Siège"), people: ALL_COLLABS });
  let resetTargetEntity = (evt: any) => {
    console.log("resetTargetEntity", evt);
    
    setTargetEntity(evt);
  }

  useEffect(()=>{
    let request = { from: from,to:new Date(to.getTime()+DayAsMs), criteria: {packet: paket},   userId: currentUser().email };
    if ( targetEntity &&  targetEntity.qn && !targetEntity.qn.includes("@")) {
      request.criteria["entity"] =  targetEntity.qn;
    }  
    if ( targetEntity &&  targetEntity.qn && targetEntity.qn.includes("@")) {
      request.criteria["userId"] =  targetEntity.qn.replace(/.*\//g, '');
    }   
    let totUser = targetEntity.people? targetEntity.people.length : !targetEntity.qn? ALL_COLLABS.length: 1;
    console.log("totUser="+totUser+" for "+targetEntity);
    searchAudit(request).then(resp => {
      if (resp.result) {
        setData(resp.result.map(x=>mapAuditToData(x, totUser)));
        
      }
    })
  }, [targetEntity, from, to, paket]);

  let onExport = ()=> {
    let request = { from: from,to:new Date(to.getTime()+DayAsMs), criteria: {packet: paket},  audience: (targetEntity.qn || "")};
   
    listingAudit(request).then(r=> {
      if(r.result) {
        let rows=[t("EMAIL;ACTIVE;DATE DEBUT;DATE FIN;"), ...r.result];
        downloadAsCSV(rows, "listing-activities-"+(new Date().getTime())+".csv")
      }
    })
  }
  return <div className="audit-explorer">
    <OrganizationCascalde
      onSelectEntity={(e: any) => resetTargetEntity(e)}
      targetEntity={targetEntity}
    ></OrganizationCascalde>
    <div className="flex-sb">
      <div className="audit-packet-block criteria-control actions-bar">
        <div onClick={() => setPacket("day")} className={" btn selected-dn audit-packet " + (paket == "day" ? "active" : "")}>{t("Jour")}</div>
        <div onClick={() => setPacket("week")} className={" btn selected-dn audit-packet " + (paket == "week" ? "active" : "")}>{t("Semaine")}</div>
        <div onClick={() => setPacket("month")} className={" btn selected-dn audit-packet " + (paket == "month" ? "active" : "")}>{t("Mois")}</div>
      </div>
      <div className="audit-time-filter flex-sb ">
        <input className="form-control"  type="date" value={toYYY_MM_DD(from)} onChange={(evt) => setFrom(new Date(evt.target.value))}
          placeholder={t("Date début")}></input>
        <div>&nbsp;&nbsp;</div>
        <input className="form-control" type="date" value={toYYY_MM_DD(to)} onChange={(evt) => setTo(new Date(evt.target.value))}
          placeholder={t("Date fin")}></input>
      </div>
      <div className="export-block">
        <div className="btn btn-purple"  onClick={onExport}><i className="fa fa-download"></i> <span className="btn-label"> {t("Activité par utilisateur")}</span></div>
      </div>
    </div>
    {data.length > 0  && <ChartAudit periodType={paket} data={data}></ChartAudit>}
  </div>
}

export function ChartAudit({ data, periodType }:
  { data: { period: string, count: number, pc: number }[], periodType: string }) {
    const chartContainer = useRef(null);
  let [chartWidth, setChartWidth] = useState(Math.max(600, data.length * 20));
  useEffect(() => {
    console.log("chartContainer", chartContainer.current);
    setChartWidth(Math.max((chartContainer.current as any).offsetWidth, data.length * 20))
  },
    [chartContainer.current]);
  return <div className="audit-explorer"  ref={chartContainer} >
    <VictoryChart
      colorScale={"qualitative"}
      theme={VictoryTheme.material}
      domainPadding={{ x: 25, y:90 }}
      height={350} width={chartWidth - 50}
      containerComponent={<VictoryContainer responsive={false} />}
    >
      <VictoryAxis
        tickValues={data.map(d => " " + d.period)}  
        tickFormat={d=>formatTick(d)}
        label={mapPeriodLabel(periodType)}
        
        style={{
          axis: { stroke: "#756f6a" },
          axisLabel: { fontSize: 15, padding: 30 },
          
          tickLabels: { fontSize: 12  }
        }}
      />
      <VictoryAxis
        dependentAxis
        label="%"
        style={{
          axis: { stroke: "#756f6a" },
          axisLabel: { fontSize: 15, padding: 30 },
          ticks: { stroke: "grey", size: 5 },
          tickLabels: { fontSize: 12, padding: 2 }
        }}
      />
      <VictoryStack colorScale={"qualitative"}     >
        <VictoryBar 
          
          labels={({ datum }) => datum.count+" ("+datum.pc+"%)"}  
          labelComponent={<VictoryTooltip renderInPortal dy={-4} />} 
          style={{ data: { fill: "#c43a31", width: 15, margin: 0, padding: 0 } }}
          data={data}
          x={(d) => " " + d.period}
          y="pc"
        />
      </VictoryStack>
    </VictoryChart>

  </div>
}



let downloadAsCSV=(rows: string[], fileName:string) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function(row) { 
        csvContent += row + "\r\n";
    });
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);   
    link.click();
    link.remove();
}


export function ListAudit(props: any) {
  let [omni, setOmni] = useState('');
  let [selectedDn, setSelectedDn] = useState("");
  let [request, setRequest] = useState({ first: 0, max: 20, criteria: {}, time: new Date(), userId: currentUser().email });
  let [data, setData] = useState([]);
  let [pagination, setPagination] = useState({ first: 0, pageSize: 20, total: 0 });
  useEffect(() => {
    if (props.targetEntity && props.targetEntity.qn) {
      request.criteria["entity"] = props.targetEntity.qn;
    } else {
      delete request.criteria["entity"];
    }
    searchAudit(request).then(resp => {
      if (resp.result) {
        setData(resp.result);
        setPagination({ first: request.first, pageSize: request.max, total: resp.total })
      }
    })
  }, [request, props.targetEntity]);
  let reload = (p: number) => {
    setRequest({ ...request, time: new Date(), first: p });
  }

  useEffect(() => {
    setRequest({ ...request, criteria: { 'endPoint': selectedDn } });
  }, [selectedDn])
  return (
    <div className="search-users">
      <div className="search-console">
        <div className="criteria">
          <div className="criteria-control actions-bar">
            {domains.map(([dn, dl], ii) => {
              return <div key={"dnx-" + ii} className={"btn selected-dn " + (selectedDn == dn ? "active" : "")}
                onClick={() => { setSelectedDn(dn) }}
              >
                {t(dl)}

              </div>
            })}
          </div>

        </div>

      </div>

      <div className="table-responsive">

        {data && data.map((d: any) => {
          return (
            <div className="ind-row audit-row">
              <Avatar user={{ ...d, name: d.userName }} ></Avatar>
              <div className="audit-info">
                <div className="audit-info-h1"  ><span className="audit-info-sp1"> {d.userName} </span>
                  <span className="audit-info-sp2" >{d.userId}</span></div>
                <div className="audit-info-h2"  ><span className="audit-info-sp1"> {t(extarctDDD(d))} </span>
                  <span className="audit-info-sp2" >{t(extractAction(d))}</span></div>
                <div className="audit-info-h3"  ><span className="audit-info-sp1"> {d.entity} </span>
                  <span className="audit-info-sp2" >{toYYYMMDDHHMM(d.time)}</span></div>
              </div>
              <div className="audit-info-r">
                {printSmartDate(d.time)}
              </div>

            </div>);

        })}

        {pagination.total > 0 &&
          <Pagination reload={reload} first={pagination.first} pageSize={pagination.pageSize} total={pagination.total} ></Pagination>
        }

      </div>
    </div>
  );
}


function extractAction(d: any): string {
  let u: string = d.endPoint;
  let domain = d.domain;
  if (!u) {
    return "";
  }
  if (u.includes("/training-definition/search")) {
    return "Recherche Formation";
  }
  if (u.includes("/training-definition/create")) {
    return "Nouvelle Formation";
  }
  if (u.includes("/training-definition/update")) {
    return "Modifier Formation";
  }
  if (u == "/training-instance/search") {
    return "Menu Formation";
  }

  if (u == "/training-instance/search") {
    return "Menu Formation";
  }
  if (u == "/challenge-definition/get") {
    return "Consulter un Challenge";
  }
  if (u == "/challenge-definition/search") {
    return "Recherche Challenges";
  }
  if (u == "/kpi-def/search-kpi-data") {
    return "Suivi de KPI";
  }
  if (u == "/message/search") {
    return "Recherche messages";
  }
  if (u == "/message/update") {
    return "Répondre à un message";
  }
  if (u == "message/create") {
    return "Ecrire à un message";
  }
  if (u == "/message/mark") {

    return "Consulter à un message";
  }

  if (u == "/quiz-instance/update") {
    return "Répondre à une question de Quiz/Sondage"
  }
  if (u == "/quiz-instance/search") {
    return "Menu Quiz/Sondage"
  }
  if (u == "/post/create") {
    return "Ajouter un Post"
  }
  if (u == "/post/comment") {
    return "Commenter/Liker un Post"
  }
  if (u == "/post/update") {
    return "Modifier un Post"
  }
  if (u == "/post/mark") {
    return "Enregistrer un Post"
  }
  if (u == "/post/search") {
    return "Perfani Timeline";
  }

  if (domain == "ASSESSMENT" && u.includes("/definition/search")) {
    return "Recherche condifguration Quiz/Sondage";
  }
  if (domain == "ASSESSMENT" && u.includes("/definition/create")) {
    return "Nouveau Quiz";
  }
  if (domain == "ASSESSMENT" && u.includes("/definition/update")) {
    return "Modifier Quiz";
  }
  if (u == "/kpi-def/search") {
    return "Recheche Configuration des KPI";
  }
  if (u == "/kpi-def/search") {
    return "Recheche Configuration des KPI";
  }
  return u;
}

function extarctDDD(d: any): string {
  return "";
  /*
  let u:string = d.endPoint;
  let domain = d.domain;
  return domain;*/
}


function mapAuditToData(x: any, totUSer:number) {
   if(!x.action) {
     x.action="0";
   }
   let count = 0;
   try {
      count = parseInt(x.action);
   } catch ( error) {
   }
   
   let pc = totUSer? round2(100*count/totUSer) : count; 
   return  {period: x.day, count: count, pc:pc };
}

function mapPeriodLabel(periodType: string ): string  {
  if(periodType=="week") {
    return t("Semaines");
  } 
  if(periodType=="month") {
    return t("Mois");
  } if(periodType=="year") {
    return t("Années");
  }
 return t("Jours");
}

function formatTick(d: any)  {
   if(!d) {
     return "";
   }
   if((""+d).split("/").length==3) {
      return   (""+d).split("/")[0]+"/"+(""+d).split("/")[1];
   }
   return d;
}

