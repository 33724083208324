import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { printShortDate } from "../help/dates";
import '../admin/admin.css';
import { currentUser } from "../service/userService";
import { getTrainingDefinitionById, updateTrainingDefinition } from "../service/trainingService";
import { TrainingDefinition, TrainingModule, TrainingStage  } from "./training-type";
import { TabsBlock } from "../ui/tabs"; 
import { Popup } from "../ui/popup";
import { TrainingDefinitionTab } from "./training-form-def-tab";
import { TrainingDefinitionResults } from "./training-def-results";
import { TrainingDefinitionStat } from "./training-def-stat";
import { TrainingStageTab } from "./training-form-stages-tab";
import { TrainingInfo } from "./training-viewer";
import { t } from "../i18n";

 
 

const TABS = [{name: "definition", label: t("Définition")},
{name: "stages", label: t("Etapes & Modules")},
{name: "preview", label: t("Aperçu")},
{name: "resultat", label: t("Résultats")},
{name: "stat", label: t("Statistiques")}]

export function TrainingDefinitionForm(props: any) {
    let { id: id, mode: inMode } = useParams() as any;
    let [entity, setEntity] = useState({
         title: '',
        description: '',
        stages: [], 
        status: 'OPEN',
        publicationDate: new Date(),
        closingDate: new Date(new Date().getTime()+364*24*3600*1000),
        weight: 1, 
        enabled: true,
        passingScore: 50,
        required: false,
        durationMin: 60,
        owner: { reference: currentUser().email },
        createdBy: currentUser().fullName,
        monitoringGroups: []
    } as TrainingDefinition); 
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode as string);
    let [error, setError] = useState('');
    let [acceptedWarning, setAcceptedWarning] = useState(false);
    let [warning, setWarning] = useState('');
    let [currentTab, setCurrentTab] = useState("definition");
   
    let history = useHistory();
    let onDelete = () => {
        entity = { ...entity, enabled: false }
        updateTrainingDefinition(entity, "delete").then((u) => {
            setShowPopup(false);
            history.push("/training/definition");
        })
    }
    let submit = (action: string) => {
        setError("");
        if (action == 'update' || action == 'create') {
            if (!entity.title) {
                setError("Le titre est obligatoire")
                return;
            }
            if (!entity.description) {
                setError("La description est obligatoire")
                return;
            }
            for(let stg of entity.stages) {
                if(!stg.title) {
                    setError("Vérifier que toutes les étapes ont un titre");
                    return ;
                }
                for(let module of stg.modules) {
                    if(!module.title) {
                        setError("Vérifier que tous les modules ont un titre");
                        return ;
                    }
                }
            }
            if(!entity.publicationDate) {
                setError("Vérifier que la date de publication est définie");
                return;
            }
            if(new Date(entity.publicationDate).getTime()< new Date().getTime() && !acceptedWarning) {
                setWarning("Les instances de formation qui sont encours, seront impactés.")
                return;
            }
        }
        let totPoint =0;
        let totTime =0;
        entity.stages.forEach((s: TrainingStage,i)=>{
            if(!s.modules) 
                return;
            let stagePoints = 0;
            let stageTime = 0;
            s.modules.forEach((m:TrainingModule, j)=> {
                if(!m.assessment  || !m.assessment.definition) {
                    return;
                }
                m.assessment.definition.moduleName= m.title; 
                m.assessment.definition.moduleReference=(i+1)+"/"+(j+1);
                m.assessment.definition.trainingName=entity.title;
                m.assessment.definition.trainingReference=entity.reference; 
                if(m.weight) {
                    stagePoints+=m.weight;
                }
                if(m.durationMin) {
                    stageTime +=m.durationMin;
                }
            })
            s.weight = stagePoints;
            s.durationMin = stageTime;
            totPoint += stagePoints;
            totTime += stageTime;

        })
        entity.weight=totPoint;
        entity.durationMin = totTime;
        updateTrainingDefinition(entity, action).then((u) => {
            if (u) {
                setEntity(u);
                mode = 'display';
                setMode('display');
                id = u.id;
            }
        });
    }
    useEffect(() => {
        if (id && id != "00") {
            getTrainingDefinitionById(id).then((u) => {
                if (u.body) {
                    entity = u.body;
                    setEntity({...entity});  
                    console.log("fetched entity ", entity);
                    setCurrentTab("definition" );
                }
            });
        }  
       
    }
        , [id]);

    let readonly = !mode.includes('create') && mode != 'edit';
    console.log("readonly="+readonly+" mode="+mode)

    let actions: any[] = [];
    if (mode == 'display') {
        actions.push({
            label: t("Editer"),
            icon: "fa fa-edit",
            handler: () => { setMode('edit') }
        });

        actions.push({
            label: t("Supprimer"),
            icon: "fa fa-trash",
            handler: () => { setShowPopup(true); }
        });
        actions.push({
            label: t("Fermer"),
            icon: "fa fa-times",
            handler: () => { history.push("/training/definition") }
        });
    } else if (!readonly) {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: () => { submit('update') }
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: () => { setMode('display') }
        });
        
    }

  
    return (<div className="config-form">

        <div className="training" >
            <div className="assmt-header">
                <h3 className="assmt-title">{entity.reference ? "Formation " + entity.title : "Nouvelle Formation"}</h3>
                <div className="assmt-dates az-content-header-right" >
                    <div className="media">
                        {entity.modifiedBy && <div className="media-body">
                            <label>{t("Par")} <strong> {(entity.modifiedBy || entity.createdBy)}</strong></label>
                            <h6> {printShortDate(entity.changeTime || entity.creationTime)}</h6>
                        </div>}
                    </div>
                    <div className="media noextramarge">
                        <div className="media-body actions-toolbar">
                            {actions.map((a, i) => <a key={i + "_" + a.label} onClick={a.handler} className="btn btn-purple">
                                <i className={a.icon}></i>
                                <span>{a.label}</span>
                            </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TabsBlock tabs={TABS} onSelect={(name: string)=>setCurrentTab(name)} defSelected="definition"></TabsBlock>
        {error && <p className="form-error">{error}</p>}
        
        {currentTab=="definition" && <TrainingDefinitionTab key={entity.reference}    entity={entity} setEntity={setEntity} readonly={readonly} ></TrainingDefinitionTab> }
              
        {currentTab=="stages" &&  <TrainingStageTab   key={entity.reference}      entity={entity} setEntity={setEntity} readonly={readonly} ></TrainingStageTab>  }
        {currentTab=="resultat" && <TrainingDefinitionResults  key={entity.reference}     entity={entity} setEntity={setEntity} readonly={readonly} ></TrainingDefinitionResults>}
        {currentTab=="stat" && <TrainingDefinitionStat   key={entity.reference}    entity={entity} setEntity={setEntity} readonly={readonly} ></TrainingDefinitionStat>}
        {currentTab=="preview" &&   <TrainingInfo mode="preview"  key={entity.reference} index={1}  training={{training: {...entity}}} ></TrainingInfo> }
       
        {showPopup &&  <Popup onAction={(action)=>action=='delete'&& onDelete()}
        actions={[{name:"delete", icon:"trash", label: t("Confirmer la suppression")}]} onClose={() => setShowPopup(false) }>    <p>
                    Etes-vous sûr de vouloir supprimer ce Quiz?
                </p>
                </Popup> }

                {warning &&  <Popup onAction={(action)=>{
                    if(action=='confirm'){
                        acceptedWarning=true;setAcceptedWarning(acceptedWarning); submit('update') ;} 
                        setWarning('');}}
        actions={[{name:"confirm", icon:"check", label: t("Confirmer")}, {name: 'cancel', icon: 'ban', label: 'Annuler'}]} onClose={() => setWarning('') }>    
        <p><br/>
           <h5>  <strong>   <i className="fa fa-exclamation-triangle"></i> </strong> {warning} </h5>
                   <br/>
                </p>
                </Popup> }
    </div>);
}

