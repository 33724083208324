import { ChallengeConfig, ChallengeData } from "../challenge/challenge-type"; 
import { get, post } from "./http.service";
import { currentUser } from "./userService";
const urlBase = '/api/assessment/challenge-definition';//'http://localhost:11002';
const instanceUrlBase =  '/api/assessment/challenge-instance';
export async function addChallengeConfig(p:ChallengeConfig) : Promise<any> {
    let data = await post(  urlBase+ "/create", { payload: p}) ;  
     
    if (!data.success || !data.body) {
        return null;
    } 
    return data.body;
}

export async function updateChallengeConfig(p:ChallengeConfig, action: string) : Promise<any> {
  let data = await post(  urlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

export async function getChallengeConfigById(id: string, action='load')   { 
  let data = await post(    urlBase+ "/get",{action:action, reference: id}) ;   
      
  return data;
}
  

export async function recomputeChallengeResulat(id: string )   { 
  let data = await get(    urlBase+ "/recompute-challenge-resulat?reference="+id) ;   
      
  return data;
}


let challengesMenu:any[] ;
export function getChallengesMenu()  {
  if(!challengesMenu) {
    challengesMenu = JSON.parse(localStorage.getItem("CHALLENGES_MENU")||"[]");
  }
  return challengesMenu;
} 








 
export async function searchChallengeData(request: any)   {   
  let data = await post(  instanceUrlBase+ "/search",request) ;   
  console.log("searchChallengeConfig ", data)
  return data;
}    

export async function searchChallengeForMenu()   {   
  let data = await post(  urlBase+ "/search",{action: "MY-ELIGIBLES", actor: currentUser().email}) ;   
  console.log("searchChallengeForMenu ", data)
  if(data.result) {
    localStorage.setItem("CHALLENGES_MENU", JSON.stringify(data.result));
    challengesMenu=data.result;
  }
  return data?.result;
}    



export async function searchChallengeConfig(request: any)   {   
  let data = await post(  urlBase+ "/search",request) ;   
  console.log("searchChallengeConfig ", data)
  return data;
}    
export async function searchChallengeConfigDebounced(request: any)   {  
     return debounce1(searchChallengeConfig, 300)(request);
}

let timer1:any = null;
function debounce1(f, interval:number) {  
    return (...args) => {
      clearTimeout(timer1);
      return new Promise((resolve) => {
        timer1 = setTimeout(
          () => resolve(f(...args)),
          interval,
        );
      });
  };
}


