import { setPriority } from 'os';
import { useEffect, useState } from 'react';
import './pagination.css';


export function Pagination(props:any) {

    let first:number = props.first;
    let pageSize:number = props.pageSize;
    let total:number = props.total;
    let [currentPage, setCurrentPage] = useState( 1);
    let nbrPage =  pageSize ? Math.floor(total/pageSize) + 1 : 0; 
    let from = first*pageSize+1;
    let to = Math.min(total, (first+1)*pageSize );
    let setPg= (i:number) => { 
        console.log("setPg "+i);
        if(i<0) {     i=0;  }
        setCurrentPage(i);
        props.reload(i-1);
      }
    useEffect(()=>{
        let nps = pageSize ?   Math.floor(first/pageSize) + 1 : 0;
        currentPage = nps;
        console.log("PAGIN USE EFFECT "+first, pageSize, nps);
        setCurrentPage(nps)}, [props.total]);
    return (
        <div className="paging-bar">
            <div className="left">
               <i onClick={()=>setPg(1)} className="first fa  fa-angle-double-left"></i>
               <i  onClick={()=>setPg(Math.max(1,currentPage -1))}  className="previous fa fa-chevron-left"></i>
               <span>Page</span>
               <input type="number"  className="currentPage" value={currentPage} 
               onChange={(e)=> {setCurrentPage(Math.max(1, parseInt(e.target.value)))}}
               onBlur={(e)=>setPg(currentPage)}   />
               <span>sur {nbrPage}</span> 
               <i onClick={()=>setPg(currentPage +1)} className="next fa fa-chevron-right"></i>
               <i onClick={()=>setPg(nbrPage)} className="last fa fa-angle-double-right"></i> 
               <i onClick={()=>setPg(currentPage)} className="last fa fa-sync"></i> 
            </div>
            <div className="left page-from-to" >
                <span>Page {from} - {to} / {total} </span>
            </div>
        </div>
    )
}