
import { getSubKpi, IndicatorData } from "../dashboard/dashboard-data";
import { VictoryLabel, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryContainer, VictoryGroup, VictoryLine, VictoryStack } from 'victory';
import { useState, useCallback, useEffect } from "react";
import { searchKpiDataByTargetPeriod } from "../service/kpiService";
import { t } from "../i18n";
import { mapResourceNameToLabel } from "./KpiBoxGauge";


function mapPeriodNameTitle(s:string ) {
  return {"month": " le mois ", "year": " l'année", "week": " la semaine "}[s]  || s;
}
function mapAxisLabel(s:string ) {
  //return {"month": "Les mois", "year": "Les années", "week": "Les semaines"}[s]  || s;
  return "";
}

function mapPeriodName(s:string ) {
  if(s.indexOf("W")) {
    return s.substring(s.indexOf("W")).replace(/W0/g, 'S').replace(/W/g, 'S');
  }
  if(s.startsWith("202")) {
    return s.substring(5);  
  }
  return s;
}

export function KpiTrendModal(props: any) {
  return ( <div className="popup-modal"   >
  <div style={{padding:0}} className={ "popup-inner "+props.className} >
    <i onClick={props.onClose} className="close-btn fa fa-times"></i>
      <KpiTrend {...props} ></KpiTrend>
</div>
</div>
)
}
export function KpiTrend(props: any) {
  let [data , setData] = useState([] as IndicatorData[]);
  let kpi: IndicatorData = props.kpi;  
  const [chartWidth, setCharWidth] = useState(600); 
  useEffect(()=> {
    if(!kpi) {return}
    searchKpiDataByTargetPeriod(kpi.kpiReference || "", kpi.resource.qn || "").then(r=> {
    console.log("POST get SubKpi", r);
    setCharWidth(Math.max(600, r.length*50));
    setData(r||[]);
  })}, [props.kpi]) ;
  const card = useCallback((node: any) => {
    if (node !== null) {
      console.log("charWidth", node.getBoundingClientRect().width)
      //setCharWidth(node.getBoundingClientRect().width);
    }
  }, []);
  if (!kpi || !data || !data.length) {
    return (<></>);
  }
  return ( 

    <div className={"card  card-table-one  card-bars indicator-card kpi-chart " + props.className} ref={card}>
    
      <h6 className="card-title">{t("Évolution de l'indicateur") +" "+t(kpi.title || '')+" "+t("pour") + " "+(mapResourceNameToLabel (kpi.resource.label || kpi.resource.name)) }</h6> 
   
      <VictoryChart
 
        domainPadding={50}
        height={400} width={chartWidth - 50}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          tickValues={data.map(d =>  mapPeriodName(d.period.name))}
          label={mapAxisLabel(kpi.kpiDefinition?.displayPeriodTime || "")}
          style={{
            axis: { stroke: "#fff" },
            axisLabel: { fontSize: 15, padding: 30 }
          }}

        />
        
        {/*label={"Réalisation en "+kpi.unit}*/}
        <VictoryAxis
          dependentAxis 
          tickFormat={(x) => (`${x/1}`)}
          label=""  
          style={{
            axis: { stroke: "#fff" },
            axisLabel: { fontSize: 15, padding: 30 },
            ticks: { stroke: "#fff", size: 0 },
            tickLabels: { fontSize: 12, padding: 0 }
          }}
        />
        <VictoryStack
          colorScale={"qualitative"}
        >

           
          <VictoryBar
           style={{
            data: {
              fillOpacity: 0.8, stroke: "#EE3C25", width: 10, strokeWidth: 0,
            }
          }}
          labels={({ datum }) => datum.measure }
          labelComponent={<VictoryLabel renderInPortal dy={-4} />}
            data={data.map(x=>{return {...x, lbl: mapPeriodName(x.period.name)   } })}
            x="lbl"
            y="measure"
            
            colorScale={["#EE3C25"]}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => {
                    return [{
                      target: "labels",
                      mutation: (props) => {
                        return props.text === "clicked" ?
                          null : { text: "clicked" }
                      }
                    }];
                  }
                }
              }
            ]}
          />

        </VictoryStack>

 
      </VictoryChart>
    </div>);
}
 

