import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 
import { printShortDate, printSmartDate } from "../help/dates";
import { t } from "../i18n";
import { getKpiMenu } from "../service/kpiService";
import { getQuizzsMenu } from "../service/quizService";
import { AssessmentDefinition, AssessmentInstance } from "./assessment-type";
const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
function getStartEnd(d: AssessmentDefinition) {
    if(d.closingDate && d.publicationDate) {
        return <span>  {printShortDate(  d.publicationDate )} <i className="fa fa-arrow-right"></i> { printShortDate( d.closingDate)} </span>;
    }
    if(  d.publicationDate) {
        return  printShortDate( d.publicationDate ) ;
    }
    if(  d.closingDate) {
        return   printShortDate( d.closingDate)  ;
    }
    return "";
}

function getRemainingTime(d: AssessmentDefinition)  {
    return printSmartDate(d.closingDate, true);
}


function getScore(d: AssessmentInstance)  {
    return <span><strong>{d.score} </strong>  /  { d.definition.passingScore}</span>
}


function getActions(d: AssessmentInstance, id:number)  {
    return  <span >
        {d.definition.status=='OPEN' && 
        <Link to={"/assessment/quiz-"+id}><i className="fa fa-play"></i></Link>  }
        {d.definition.status!='OPEN' && <i className="fa fa-lock"></i>}
    </span>
}
export function QuizzesList(props:any) {
    let [data, setData]  = useState([] as AssessmentInstance[] ) ;
    useEffect(()=> {setData(getQuizzsMenu());} , [{}])
    

    return (<div className="quizzes-list" >
               <div className="card card-table-one indicator-card "  >
              <h6 className="card-title"><strong>Liste des Quiz</strong>  </h6>
              <p className="az-content-text mg-b-20">Une petite description sommaire de cette page  </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="wd-5p">&nbsp;</th>
                      <th className="wd-45p pl">{t("Quiz")}</th>
                      <th className="wd-45p pl">{t("Parcours")}</th>
                      <th className="wd-45p pl">{t("Module")}</th>
                      <th className="pl" >{t("Début/Fin")}</th>
                      <th className="wd-45p pl">{t("Temps Restant")}</th>
                      <th className="wd-45p pl">{t("Score")}</th>
                      <th className="wd-45p pl">{t("Points")}</th>
                      <th className="wd-45p pl">{t("&nbsp; ")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((d:AssessmentInstance, i)=> {
                      return(
                    <tr className="ind-row">
                    <td><i className=""></i></td>
                    <td className="pl"  ><strong> {d.definition.title} </strong></td>
                    <td className="pl"  > {d.stepName} </td>
                    <td className="pl " > {d.definition.moduleName}  </td>
                    <td  className="pl" > {getStartEnd(d.definition)}  </td>
                    <td  className="pc"> {getRemainingTime(d.definition)}</td>
                    <td  className="pc">{getScore(d)}</td>
                    <td  className="pc"><strong>{d.definition.weight}</strong></td>
                    <td  className="pc">{getActions(d, i)}</td>
                  </tr>);

                    })}
                    
                  </tbody>
                </table>
              </div>  

        </div>
    </div>)
}

