import { useEffect, useState } from "react";
import '../admin/admin.css';
import { t } from "../i18n";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { AssessmentDefinition, Question } from "./assessment-type";


function mapQType(w) {
    //'QCM', 'QCU' , 'FREE_TEXT' ,'RATING' 
    if (w == 'QCM')
        return 'Questions aux choix multiples';
    if (w == 'QCU')
        return 'Question à Choix unique';
    if (w == 'FREE_TEXT')
        return 'Texte libre';
    if (w == 'RATING')
        return 'Notation';
    return w;
}



export function QuestionBlock(props: any) {
    let readOnly = props.readOnly;
    let tnp = props.tnp;
    let [question, setQuestion] = useState((props.question || {}) as Question);
    let [edit, setEdit] = useState(!question.question);
    let indx = props.indx;
    let toggleCorrect = (id) => {
        if (!question.correctAnswer) {
            question.correctAnswer = { comment: '', optionId: [] }
        }
        if (!question.correctAnswer.optionId) {
            question.correctAnswer.optionId = [];
        }
        if (question.correctAnswer.optionId.includes(id)) {
            question.correctAnswer.optionId = question.correctAnswer.optionId.filter(d => d != id);
        } else {
            question.correctAnswer.optionId.push(id);
        }
    }
    let isCorrect = (id) => {
        return question.correctAnswer?.optionId?.includes(id)
    }

    return (
        <div className="question-block">
            <div className="app-form kpi-form" >

                <div className="question-block-header">
                    <h5>
                        <span>{indx} - </span>
                        <span> {question.question}</span>
                    </h5>
                    <span>
                        <span className="question-type">{question.type} </span>
                        {tnp && question.weight && <span> <i className="fa fa-trophy"></i>  {question.weight} Pt{question.weight > 1 && "s"}</span>}
                        {tnp && question.durationMin && <span> <i className="fas fa-stopwatch"></i> {question.durationMin} Min</span>}

                        {!readOnly && <>
                            <span onClick={() => setEdit(true)} className="tlb-btn"><i className="fa fa-edit"></i></span>
                            <span onClick={() => props.onDelete(question, indx)} className="tlb-btn"><i className="fa fa-ban"></i></span>
                        </>
                        }
                    </span>
                </div>

                <div className="flex-c2">
                    <div>
                        <p className="txt-disp-block ">{question.contextDescription}</p>
                        <div className="options-block">
                            {question.type != 'RATING' && question.options?.map(o =>
                                <div className="option-block">
                                    <div><strong className="option-id">{o.id} - </strong> <span>{o.text}</span>
                                        {question.correctAnswer?.optionId?.includes((o.id || 0) - 1) && <i className="fa fa-check"></i>}</div>
                                </div>
                            )}
                            {question.type == 'RATING' && <div className="rating-config-block">
                                <div className="form-group ">
                                    <label>{t("Min")}</label>
                                    <input readOnly={true}
                                        value={question.min}
                                        className="form-control" placeholder={t("Min")} />
                                </div>
                                <div className="form-group ">
                                    <label>{t("Max")}</label>
                                    <input readOnly={true}
                                        value={question.max}
                                        className="form-control" placeholder={t("Max")} />
                                </div>
                                <div className="form-group ">
                                    <label>{t("Increment")}</label>
                                    <input readOnly={true}
                                        value={question.increment}
                                        className="form-control" placeholder={t("Increment")} />
                                </div>
                            </div>}
                        </div></div>
                    {tnp &&
                        <div className="response-block" >
                            <p className="txt-disp-block ">
                                <strong>Explication: </strong><span> {question.correctAnswer?.comment}</span>
                            </p>
                        </div>
                    }
                </div>
            </div>

            {edit && <div className="popup-container ">
                <div className="popup-inner popup-inner-large kpi-form">
                    <i onClick={() => setEdit(false)} className="fa fa-times"></i>
                    <div className="popup-header">
                        <h6><strong>Configurer de la Question {indx}</strong></h6>
                    </div>
                    <div className="flex-c2">
                        <div className="form-group ">
                            <label>{t("Question")}</label>
                            <input onChange={e => setQuestion({ ...question, question: e.target.value })}
                                value={question.question}
                                type="text" className="form-control" placeholder={t("Question")} />
                        </div>
                        <div className="form-group ">
                            <label>{t("Type")}</label>
                            <DropdownList onSelect={e => setQuestion({ ...question, type: e.name })}
                                data={['QCM', 'QCU', 'FREE_TEXT', 'RATING'].map(w => { return { name: w, label: mapQType(w) } })} value={{ name: question.type }} className="flex-child-11" ></DropdownList>
                        </div>

                        {tnp && <> <div className="form-group ">
                            <label>{t("Points")}</label>
                            <input onChange={e => setQuestion({ ...question, weight: parseInt(e.target.value) })}
                                value={question.weight}
                                type="number" className="form-control" placeholder={t("Nombre de points")} />
                        </div>

                            <div className="form-group ">
                                <label>{t("Durée (min)")}</label>
                                <input onChange={e => setQuestion({ ...question, durationMin: parseInt(e.target.value) })}
                                    value={question.durationMin}
                                    type="number" className="form-control" placeholder={t("Durée en minutes")} />
                            </div>
                        </>
                        }
                    </div>

                    <div className="form-group ">
                        <label>{t("Contexte")}</label>
                        <textarea rows={2}
                            onChange={e => setQuestion({ ...question, contextDescription: e.target.value })}
                            value={question.contextDescription}
                            className="form-control" placeholder={t("Context")} />
                    </div>

                    {question.type == 'RATING' && <div className="rating-config-block">
                        <div className="form-group ">
                            <label>{t("Min")}</label>
                            <input onChange={e => setQuestion({ ...question, min: parseFloat(e.target.value) })}
                                value={question.min}
                                className="form-control" placeholder={t("Min")} />
                        </div>
                        <div className="form-group ">
                            <label>{t("Max")}</label>
                            <input onChange={e => setQuestion({ ...question, max: parseFloat(e.target.value) })}
                                value={question.max}
                                className="form-control" placeholder={t("Max")} />
                        </div>
                        <div className="form-group ">
                            <label>{t("Increment")}</label>
                            <input onChange={e => setQuestion({ ...question, increment: parseFloat(e.target.value) })}
                                value={question.increment}
                                className="form-control" placeholder={t("Increment")} />
                        </div>
                    </div>}
                    {question.options?.map((op, id) => {
                        return <div key={id} className="form-group " >
                            <div className="flex-c2"><label>{t("Option")} {id + 1}</label>

                                <div className="flex-pr" >

                                    {tnp && <>   <span>Correcte</span>
                                        <div onClick={() => { toggleCorrect(id); setQuestion({ ...question }) }}
                                            className={"az-toggle az-toggle-success " + (isCorrect(id) ? 'on' : 'off')}><span></span></div>
                                    </>
                                    }
                                    <i onClick={() => { question.options?.splice(id, id + 1);; setQuestion({ ...question }) }}
                                        className="op-delete fa fa-trash"></i>
                                </div>

                            </div>
                            <textarea rows={1}
                                onChange={e => { op.text = e.target.value; setQuestion({ ...question }) }}
                                value={op.text}
                                className="form-control" placeholder={t("Option")} />
                        </div>
                    })}

                    {tnp && <div className="form-group ">
                        <label>{t("Explication")}</label>
                        <textarea rows={2}
                            onChange={e => setQuestion({
                                ...question,
                                correctAnswer: { ...question.correctAnswer, comment: e.target.value }
                            })}
                            value={question.correctAnswer?.comment}
                            className="form-control" placeholder={t("Context")} />
                    </div>}
                    <div className="foot-btn-bar flex-sa">
                        {question.type.includes('QC') && <div onClick={() => {
                            let options = question.options || [];
                            options.push({ id: options.length + 1, checked: false, text: '', key: "" + options.length })

                            setQuestion({ ...question, options: options })
                        }}
                            className="btn generate-template ">
                            <i className="fa fa-check"></i>
                            <span>Ajouter une Option</span>
                        </div>}

                        <div onClick={() => { setEdit(false); props.onSave(question, indx) }} className="btn generate-template btn-confirm">
                            <i className="fa fa-check"></i>
                            <span>Confirmer</span>
                        </div>

                    </div>

                </div>
            </div>
            }
        </div>
    )

}
