import { useParams } from 'react-router';
import './training.css';

import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { printShortDate } from '../help/dates';  
import { Pagination } from '../ui/pagination/search-paging';
import '../admin/admin.css';  
import { TrainingDefinition, TrainingInstance } from './training-type';
import { searchTrainingDefinition, searchTrainingInstance } from '../service/trainingService';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryLabel, VictoryStack, VictoryTheme } from 'victory';

export function TrainingDefinitionStat (props:any) {
  console.log("TrainingDefinitionStat renderer");
    let entity:TrainingDefinition = props.entity;
    let tnp :boolean =  props.tnp;
    let [request, setRequest] = useState({first:0 , max:5000, criteria: {trainingReference: entity.reference},  time: new Date() });
    let [data, setData] = useState( [] as TrainingInstance[] ) ;
    let [scores, setScores] = useState(initScores(entity) as {score: number, count:number, delta:number}[]);
     useEffect(()=> {

       console.log("TrainingDefinitionStat useEffect");
       if(entity.reference) {
      searchTrainingInstance(request).then(resp=> {
             console.log("searchTrainingDefinition response", resp);
            if(resp && resp.result) {
                data = resp.result;
                setData(data); 
                console.log("searchTrainingDefinition setData done");
                for(let s of scores) {
                  s.count = data.filter(x=> (x.score||0)>=s.score && (x.score||0) <(s.score+s.delta) ).length;
                }
                setScores([...scores]);
                
                console.log("searchTrainingDefinition setScores done");
            }
        }) }
    }, [request] );
    let reload =(p:number) => { 
        setRequest({...request, time: new Date(), first: p });
    } 
     
    return (
        <div className="app-form">
         {entity.reference &&   <div className="ass-def-stat">
              <h5 className="shart-tile">{("Distribution des score")}</h5>
              <ChartScores entity={entity} data={scores} ></ChartScores>
            
            </div>
        }
        </div>
    );
}
export function ChartScores(props) {
  const chartContainer = useRef(null);
  let data = props.data;
  let entity = props.entity;
  let [chartWidth, setChartWidth]=useState(Math.max(600, data.length*20));
  
  console.log("TrainingDefinitionStat chartScores");
  useEffect(()=> {
    console.log("chartContainer", chartContainer.current);
    setChartWidth(Math.max((chartContainer.current as any).offsetWidth   , data.length*20))},
     [chartContainer.current]);
      
  return <div className="ass-stat-chart-container" ref={chartContainer}>
     <VictoryChart 
        colorScale={"qualitative"}
        theme={VictoryTheme.material}
        domainPadding={50}
        height={350} width={chartWidth - 50}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          tickValues={data.map(d =>d.score)}
          label="scores"
          style={{
            axis: { stroke: "#756f6a" },
            axisLabel: { fontSize: 15, padding: 30 }
          }}

        />
        <VictoryAxis
          dependentAxis 
          tickFormat={(x) => (`${x/1}`)}
          label="Occurence"
          style={{
            axis: { stroke: "#756f6a" },
            axisLabel: { fontSize: 15, padding: 30 },
            ticks: { stroke: "grey", size: 5 },
            tickLabels: { fontSize: 12, padding: 2 }
          }}
        />
        <VictoryStack
          colorScale={"qualitative"}
        >

           
          <VictoryBar
          
          labels={({ datum }) => datum.count }
          labelComponent={<VictoryLabel renderInPortal dy={-4} />}
            data={data}
            x="score"
            y="count"
 
          />

        </VictoryStack>

 
      </VictoryChart>
  </div>
}
function initScores(en:TrainingDefinition): { score: number; count: number; delta:number}[] {
  let max = en.stages.map(q=>q.score).reduce((a=0,b=0)=>a+b, 0) ||0;
  let incr=Math.ceil(max/10);
  if(incr <1) {
    incr = 1;
  }

  let ret :  { score: number, count: number, delta:number }[]=[];
  for(let i=0; i<=max; i=i+incr) {
    ret.push({  score: i, count: 0, delta:incr });
  }
  return ret;
} 
