import { SetStateAction, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { printShortDate, toYYYMMDD } from "../help/dates";
import { Entry, Group, Profile } from "../types/people";
import { MultItemField } from "../ui/mutli-item-field";
import '../admin/admin.css';
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { currentUser, searchGroups } from "../service/userService";
import { getChallengeConfigById, updateChallengeConfig } from "../service/challengeService";
import { Resource } from "../types/kpi-model";
import ReactQuill from "react-quill";
import { quillFormats, quillModulesBasic } from "../feed-wall/text-editor";
import { TabsBlock } from "../ui/tabs";
import { DefinitionTab } from "./challenge-form-def-tab";
import { AssDefMessageTab } from "./challenge-form-message-tab";
import { Popup } from "../ui/popup";
import { t } from "../i18n";
import { ChallengeConfig } from "./challenge-type";
import { ChallengeConfigResults } from "./challenge-def-results";
import { ChallengeKpiContribution } from "./challenge-kpi-contrib";
import { ChallengeTrainingContribution } from "./challenge-training-contrib";
import { ChallengeReward } from "./challenge-rewardes";
import { ChallengeResult } from "./challenges-result";



const TABS = [{ name: "definition", label: t("Définition") },
{ name: "rules", label: t("Règlement") },
{ name: "kpis", label: t("KPI") },
{ name: "trainings", label: t("Quiz") },
{ name: "gains", label: t("Gains") },
{ name: "resultat", label: t("Résultats") }]

export function ChallengeConfigForm(props: any) {
    let { id: id, mode: inMode } = useParams() as any;
    let [entity, setEntity] = useState((props.challengeDefinition || {
        name: '', title: '',
        description: '',
        status: 'OPEN',
        points: 0,
        inicatorsName: [],
        data: [],
        indicatorsContribution: [],
        trainingContribution: [],
        rewards: [],
        rewarding: 0,
        owner: { reference: currentUser().email },
        createdBy: currentUser().fullName,
        monitoringGroups: [],
        rules: {}
    }) as ChallengeConfig);
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode as string);
    let [error, setError] = useState('');
    let [currentTab, setCurrentTab] = useState("definition");
    let history = useHistory();
    let onDelete = () => {
        entity = { ...entity, enabled: false }
        updateChallengeConfig(entity, "delete").then((u) => {
            setShowPopup(false);
            history.push("/challenge/definition");
        })
    }
    let submit = (action: string) => {
        setError("");
        if (action == 'update' || action == 'create') {
            if (!entity.title) {
                setError("Le titre est obligatoire")
                return;
            }
            if (!entity.description) {
                setError("La description est obligatoire")
                return;
            }
        }
        updateChallengeConfig(entity, action).then((u) => {
            if (u) {
                setEntity(u);
                mode = 'display';
                setMode('display');
                id = u.id;
                if (props.onConfirm) {
                    props.onConfirm(entity);
                }
            }
        });
    }
    useEffect(() => {
        if (id && id != "00") {
            getChallengeConfigById(id).then((u) => {
                if (u.body) {
                    entity = u.body;
                    setEntity(u.body);
                }
            });
        }

    }
        , [id]);

    let readonly = props.inpopup ? props.readonly : !mode.includes('create') && mode != 'edit';


    let actions: any[] = [];
    if (props.inpopup) {
        if (!readonly) {
            actions.push({
                label: t("Confirmer"),
                icon: "fa fa-check",
                handler: () => { submit('update') }
            });

        }
    } else if (mode == 'display') {
        actions.push({
            label: t("Editer"),
            icon: "fa fa-edit",
            handler: () => { setMode('edit') }
        });

        actions.push({
            label: t("Supprimer"),
            icon: "fa fa-trash",
            handler: () => { setShowPopup(true); }
        });
        actions.push({
            label: t("Fermer"),
            icon: "fa fa-times",
            handler: () => { history.push("/challenge/definition") }
        });
    } else if (!readonly) {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: () => { submit('update') }
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: () => { setMode('display') }
        });

    }


    return (<div className="config-form">

        <div className="challenge" >
            <div className="assmt-header">
                <h3 className="assmt-title">{entity.reference ? entity.name : t("Nouveau Challenge")}</h3>
                <div className="assmt-dates az-content-header-right" >
                    <div className="media">
                        {entity.modifiedBy && <div className="media-body">
                            <label>{t("Par")} <strong> {(entity.modifiedBy || entity.createdBy)}</strong> </label>
                            <h6> {printShortDate(entity.changeTime || entity.creationTime)}</h6>
                        </div>}
                    </div>
                    <div className="media noextramarge">
                        <div className="media-body actions-toolbar">
                            {actions.map((a, i) => <a key={i + "_" + a.label} onClick={a.handler} className="btn btn-purple">
                                <i className={a.icon}></i>
                                <span>{a.label}</span>
                            </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TabsBlock tabs={TABS.filter(t => t.name != "resultat" || entity.reference)} onSelect={(name: string) => setCurrentTab(name)} defSelected="definition"></TabsBlock>
        {error && <p className="form-error">{error}</p>}

        {currentTab == "definition" && <DefinitionTab inpopup={props.inpopup} entity={entity} setEntity={setEntity} readonly={readonly} ></DefinitionTab>}
        {currentTab == "rules" && <AssDefMessageTab entity={entity} setEntity={setEntity} readonly={readonly} ></AssDefMessageTab>}
        {currentTab == "trainings" && <ChallengeTrainingContribution readonly={readonly} entity={entity} setEntity={setEntity} ></ChallengeTrainingContribution>}
        {currentTab == "kpis" && <ChallengeKpiContribution readonly={readonly} entity={entity} setEntity={setEntity}></ChallengeKpiContribution>}
        {currentTab == "gains" && <ChallengeReward readonly={readonly} entity={entity} setEntity={setEntity}></ChallengeReward>}
        {currentTab == "resultat" && <ChallengeResult entity={entity} challengeReference={entity.reference}  ></ChallengeResult>}


        {showPopup && <Popup onAction={(action) => action == 'delete' && onDelete()}
            actions={[{ name: "delete", icon: "trash", label: t("Confirmer la suppression") }]} onClose={() => setShowPopup(false)}>    <p>
                Etes-vous sûr de vouloir supprimer ce Challenge?
            </p>
        </Popup>}

    </div>);
}


