import { useEffect, useState } from "react";
import { useParams } from "react-router";
import './dashboard.css';
import { ALL_COLLABS, orga_tree_root } from "../test-data/entities";
import { IndicatorData } from "./dashboard-data";
import KpiBoxA from "../perf-indicators/KpiBoxA";
import KpiBoxGauge from "../perf-indicators/KpiBoxGauge";
import KpiCardTable from "../perf-indicators/KpiCardTable";
import KpiChartBars from "../perf-indicators/KpiChartBars";
import { OrganizationCascalde } from "./organization-cascade";
import KpiChartBarsHorizontal from "../perf-indicators/KpiChartBarsHorizontal";
import { currentPeriodsMap, getKpiMenu, loadPeriodsByType, searchKpiDataByKpiFamily, searchLastKpiDataByKpiFamily } from "../service/kpiService";
import { Period } from "../types/kpi-model";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toYYYMMDD } from "../help/dates";
import KpiChartBarsVertical from "../perf-indicators/KpiChartBarsVertical";
import { t } from "../i18n";
import { currentUser } from "../service/userService";
import { KpiTrend } from "../perf-indicators/KpiTrend";
import { PostEdition } from "../feed-wall/post-edition";



function Dashboard(props: any) {
  let peridosMap = currentPeriodsMap();

  let { id: familyName } = useParams() as any;
  let indicatorFamily = getKpiMenu().filter(x => x.name == familyName)[0];
  let [indicatorSubFamily, setIndicatorSubFamily] = useState(indicatorFamily?.children[0] || {});
  let [targetEntity, setTargetEntity] = useState(getCurrentUserEntity());
  let [drilledKpi, setDrilledKpi] = useState();
  let [drilledView, setDrilledView] = useState('KpiChartBarsVertical');
  let [data, setData] = useState([] as IndicatorData[]);
  let [stt, setStt] = useState({ showPeriodsControl: false, renderAsGauge: true });
  let [indicatorToShare, setIndicatorToShare] = useState(null);
  let shareIndicator = (id, kpiRenderer) => { 
    console.log(">>shareIndicator "+kpiRenderer, id)
    setIndicatorToShare({ ...id, kpiRenderer })
  }
  let resetTargetEntity = (evt: any) => {
    setDrilledKpi(undefined);
    console.log("resetTargetEntity ", evt);
    targetEntity = evt;
    if(targetEntity.label=='Siège' && targetEntity.name=="" && !targetEntity.qn) {
      targetEntity = orga_tree_root; 
    }
    setTargetEntity(targetEntity);
    console.log("targetEntity " + targetEntity.label, targetEntity);
    // setData(  randomData(indicatorSubFamily, [targetEntity]));
    if (!targetEntity.children && !targetEntity.people) {
      setDrilledView('KpiTrend');
    }  
  }

  let setSubFamilyOfIndicator = (sfi: any) => {
    setIndicatorSubFamily(sfi);
    //setData(  randomData(indicatorSubFamily, [targetEntity]));
  }
 

  let drillableOf = (kd: IndicatorData) => {
    let k = { ...kd }
    k.resource = targetEntity;
    console.log(" setDrilledKpiXX   targetEntity=",targetEntity);
    console.log(" setDrilledKpiXX ", k, k.resource);
    if (k.resource.children && k.resource.children.length > 0) {
      return k;
    } else if ((targetEntity as any).people && (targetEntity as any).people.length > 0) {
      return k;
    }
    
    return null;
  }


  useEffect(() => {

    searchLastKpiDataByKpiFamily(indicatorSubFamily.reference, targetEntity.qn).
      then(r => {
        console.log("searchKpiDataByKpiFamily ", r);
        if (r.length) {
          setData(r);
        }
      });

  }, [targetEntity, indicatorSubFamily]);

  useEffect(() => {
    indicatorFamily = getKpiMenu().filter(x => x.name == familyName)[0];
    indicatorSubFamily = indicatorFamily?.children[0] || {};
    setIndicatorSubFamily(indicatorSubFamily);
    console.log(">>>", indicatorSubFamily.name);
  }
    , [familyName]);

  return (
    <div className="kpi-board">
      <div className="az-content-body">
        <div className="az-dashboard-one-title">
          <div>
            <h2 className="az-dashboard-title">{indicatorFamily.label}    {targetEntity.name && (' - ' + targetEntity.name)}</h2>
          </div>



          {/* <div className="action-btns-droplist">
              <a href="" className="btn btn-purple"><i className="fa fa-cogs"></i> <span>Configurer</span></a>
              <div className="extra-action">   </div>
            </div>*/
          }
        </div>
      </div>
      <OrganizationCascalde permissionDomain="kpi" onSelectEntity={(e: any) => resetTargetEntity(e)} targetEntity={targetEntity} ></OrganizationCascalde>
      <div className="az-dashboard-nav">
        <nav className="nav">
          {indicatorFamily.children.map((x: any, i) => {
            return <a
              key={x.name + "_" + i}
              className={"ind-tab-title nav-link  show " + (x == indicatorSubFamily ? "active" : "")}
              data-toggle="tab"
              onClick={() => { setSubFamilyOfIndicator(x) }}
            >{x.name}</a>
          })}


        </nav>

      <nav className="nav btn-group-parent">
         {/* <div className="btn-group">
            <i onClick={() => setStt({ ...stt, renderAsGauge: !stt.renderAsGauge })}
              className={"rendererToggle far fa-" + (stt.renderAsGauge ? "square" : "circle")} ></i>
        </div>*/}

        </nav>
      </div>
      <div className={"kpi-navigator " + (drilledKpi ? "drill-mode" : "overall-mode")} >
        <div className="card card-dashboard-one indicator-card">

          <div className="card-header">
           

          </div>
          <div className="indicator-group">
            {data.filter(id => !!id.key).map(id => {
              return <div className='kpi-row'><div className="kpi-resume">
                {stt.renderAsGauge && <KpiBoxGauge shareKpi={(x) => { shareIndicator(x, "KpiBoxGauge") }} key={id.key} className="kpi-box" indicatorData={id} onSelect={() => { }}  ></KpiBoxGauge>}
                {!stt.renderAsGauge && <KpiBoxA shareKpi={(x) => { shareIndicator(x, "KpiBoxA") }} key={id.key} className="kpi-box" indicatorData={id} onSelect={() => { }}   ></KpiBoxA>}
              </div>
                <div className="kpi-details">
                  <div className="drilled-kpi-toolbar" >
                    <span className={"select-view-button " + (drilledView == 'KpiTrend' && "active")} onClick={() => setDrilledView("KpiTrend")}>
                      <i className="far fa-clock"></i>
                    </span>

                    {drillableOf(id) && <>   <span className={"select-view-button " + (drilledView == 'KpiChartBarsVertical' && "active")} onClick={() => setDrilledView("KpiChartBarsVertical")}>
                      <i className="fas fa-chart-bar  "></i>
                    </span>

                      <span className={"select-view-button " + (drilledView == 'KpiCardTable' && "active")} onClick={() => setDrilledView("KpiCardTable")}>
                        <i className="fa fa-table"></i>
                      </span></>}
                      <span className={"select-view-button "} onClick={() =>  shareIndicator(drillableOf(id) || id, drilledView) }>
                      <i className="fa fa-share"></i>
                    </span>
                  </div>

                  {(drilledView == 'KpiTrend' || !drillableOf(id)) && <KpiTrend kpi={id}></KpiTrend>}
                  {drilledView == 'KpiChartBarsVertical' && <KpiChartBarsVertical kpi={drillableOf(id)}></KpiChartBarsVertical>}

                  {drilledView == 'KpiCardTable' && <KpiCardTable kpi={drillableOf(id)}></KpiCardTable>}

                </div>
              </div>
            })}

          </div>

        </div>
        <div className="drilled-kpi">

        </div>


      </div>
      {indicatorToShare && <PostEdition onClose={() => setIndicatorToShare(null)} kpi={indicatorToShare} ></PostEdition>}

    </div>)

}

export default Dashboard;

function getCurrentUserEntity(): any {
  let e = currentUser().entity;
  if(!e?.label) {
    let ret =  orga_tree_root; 
    return ret;
  }
  return { name: e?.label, label: e?.label || t("Siège"), qn: e?.value }
}
