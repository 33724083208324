 
 
import { AppConfig } from "../types/app.config.type";
import { Resource } from "../types/kpi-model";
import { Group, OrganizationUnit, Profile, UserProfile } from "../types/people";
import { post } from "./http.service";
const urlBase = '/api/people/auth';//'http://localhost:11002';
export const AVATARS_CACHE = (()=>{
  try {  let AVATARS_CACHE_JSON = localStorage.getItem("AVATARS_CACHE");
    if(AVATARS_CACHE_JSON) {
        return JSON.parse(AVATARS_CACHE_JSON);
    } 
    return {}
    } catch(e) {
        console.error(e);
        return {};
    }
})();
window["AVATARS_CACHE"]=AVATARS_CACHE;
export function currentUser(): UserProfile {
    if (!(window as any).currentUser) {
        (window as any).currentUser = fetchLocalUserProfile();
    }
    return (window as any).currentUser;
}

export function appConfig(): AppConfig {
    if (!(window as any).appConfig) {
        let act = localStorage.getItem("APPLICATION_CONFIG");
        if (!act) {
            (window as any).appConfig = {};
        } else {
            try {
                (window as any).appConfig = JSON.parse(act);
            } catch (error) {

                console.log("error in appConfig ", error);
                (window as any).appConfig = {};
            }

        }
        if (!(window as any).appConfig.ouLevels) {
          
            (window as any).appConfig.ouLevels = [ 
                { name: "0", label: ("SIÈGE") },
                { name: "1", label: ("DÉLÉGATION") },
                { name: "2", label: ("DIRECTION RÉGIONALE") },
                { name: "3", label: ("UNITÉ COMMERCIALE") },
          //      { name: "4", label: ("AGENCE") },
                { name: "4", label: ("COLLABORATEUR") },]
        }
        if(!(window as any).appConfig.currency) {
            (window as any).appConfig.currency = 'MAD';
        }
        if(!(window as any).appConfig.licence) {
            (window as any).appConfig.licence = {maxUsers:5};
        }
    }
    return (window as any).appConfig;
}

export async function submitConfig(config: AppConfig): Promise<any> {
    let data = await post("/api/people/user/update-app-config",
        {
            userId: currentUser().email,
            action: "update",
            payload: { client: "DEFAULT_CLIENT", tenantId: "DEFAULT_TENANT", content: JSON.stringify(config) }
        });
     
    if (data.body) {
        storeLocalAppConfig(data.body.content);
    }
    return data.body;
}


export function fetchLocalUserProfile(): UserProfile | null {
    let userPRofileText = localStorage.getItem("USER_PROFILE");
    if (!userPRofileText) {
        return null;
    }
    return JSON.parse(userPRofileText);
}

export function storeLocalUserProfile(userProfile: UserProfile | null) {
    if (userProfile) {
        localStorage.setItem("USER_PROFILE", JSON.stringify(userProfile));
    } else {
        localStorage.removeItem("USER_PROFILE");
        (window as any).currentUser = null;
    }
}

export async function signin(login: string, password: string): Promise<boolean> {
    let data = await post(urlBase + "/login", { login, password });
     
    if (!data.success || !data.user) {
        return false;
    }
    if (data.appConfigContent) {
        storeLocalAppConfig(data.appConfigContent);
    }
    storeLocalUserProfile(data.user);
    return true;
}



export async function loginAsHim(userId: string): Promise<boolean> {
    let data = await post(urlBase + "/him", { userId });
     
    if (!data.success || !data.user) {
        return false;
    }
    if (data.appConfigContent) {
        storeLocalAppConfig(data.appConfigContent);
    }
    storeLocalUserProfile(data.user);
    return true;
}

export function storeLocalAppConfig(content: string) {
    if (content) {
        try {
            JSON.parse(content);
            localStorage.setItem("APPLICATION_CONFIG", content);
            (window as any).appConfig = JSON.parse(content);
        } catch (error) {
            console.log("Error in storeLocalAppConfig " + content, error);
        }
    }
}

export async function signout(): Promise<boolean> {
    let data = await post(urlBase + "/logout", null);
     
    return data.success;
}


export async function razPwd(email: string): Promise<boolean> {
    let data = await post(urlBase + "/raz-pass", { email });
     
    return data.success;
}

export async function sendResetPwd(email: string, newPassword: string, activationId: string | undefined): Promise<boolean> {

    let data = await post(urlBase + "/activate", { email, activationId, newPassword, login: email });

     
    if (data.user) {
        storeLocalUserProfile(data.user);
    }
    return data.success;
}
export async function sendChangeMyPwd(email: string, newPassword: string, password: string): Promise<boolean> {

    let data = await post(urlBase + "/change-my-pwd", { email, password, newPassword, login: email });


    return data;
}



export async function searchUsers(request: any) {
    let data = await post("/api/people/user/search", request); 
    return data;
}

export async function searchAudit(request: any) {
    let data = await post("/api/people/audit/search", request); 
    return data;
}

export async function listingAudit(request: any) {
    let data = await post("/api/people/audit/listing", request); 
    return data;
}
export async function getUserById(id: string) {
    let data = await post("/api/people/user/get", { action: "load", reference: id }); 
    return data;
}

export async function updateUser(user: UserProfile, action: string = 'update') {
    let data = await post("/api/people/user/"+(user.id?"update": "create"), { action: action, reference: user.id, payload: user }); 
    return data;
}




/**** OU  *****/
export async function getOrganizationTree() {
    let data = await post("/api/people/ou/organization-tree", { action: "load", payload: {} }); 
    if (data.body) {
        let root = data.body.children[0];
        let agregateUsers = (e) => {
            if (e.children) {
                e.children.forEach(ce => agregateUsers(ce));
                e.children.forEach(ce => ce.people.forEach(u => e.people.push(u)));
            }
        }
        let flatEntities = (e, ret: Resource[]) => {
            ret.push({ name: e.name, collaborator: false, title: e.label, qn: e.qn });
            if (e.children) {
                e.children.forEach(ce => flatEntities(ce, ret));
            }
            if(e.people && e.people.length) {
                e.people.filter(p=>p.avatarUrl&& p.email).forEach(p=> {AVATARS_CACHE[p.email]=p.avatarUrl});
            }
        }
        agregateUsers(root);
        let allEntities = []
        flatEntities(root, allEntities);
        localStorage.setItem("ORGANIZATION_TREE", JSON.stringify(root));
        localStorage.setItem("ALL_ENTITIES_FLAT", JSON.stringify(allEntities));
        localStorage.setItem("AVATARS_CACHE", JSON.stringify(AVATARS_CACHE));
        return root;
    }
    return null;
}


export async function searchOUs(request: any) {
    let data = await post("/api/people/ou/search", request); 
    return data;
}

export async function getOUById(id: string) {
    let data = await post("/api/people/ou/get", { action: "load", reference: id });
     
    return data;
}

export async function updateOU(user: OrganizationUnit, action: string = 'update') {
    let data = await post("/api/people/ou/"+(user.id?"update": "create"), { action: action, reference: user.id, payload: user });
     
    return data;
}





/**** PROFILE  *****/

export async function searchProfiles(request: any) {
    let data = await post("/api/people/profile/search", request);
     
    return data;
}

export async function getProfileById(id: string) {
    let data = await post("/api/people/profile/get", { action: "load", reference: id });
     
    return data;
}

export async function updateProfile(user: Profile, action: string = 'update') {
    let data = await post("/api/people/profile/"+(user.id?"update": "create"), { action: action, reference: user.id, payload: user });
     
    return data;
}



/**** GROUP  *****/

export async function searchGroups(request: any) {
    let data = await post("/api/people/group/search", request);
     
    return data;
}

export async function getGroupById(id: string) {
    let data = await post("/api/people/group/get", { action: "load", reference: id });
     
    return data;
}

export async function updateGroup(user: Group, action: string = 'update') {
    let data = await post("/api/people/group/"+(user.id?"update": "create"), { action: action, reference: user.id, payload: user });
     
    return data;
}

