import { useParams } from 'react-router';
import './assessment.css';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { printShortDate } from '../help/dates';  
import { Pagination } from '../ui/pagination/search-paging';
import '../admin/admin.css';  
import { AssessmentDefinition, AssessmentInstance } from './assessment-type';
import { searchAssessmentDefinition, searchAssessmentInstance } from '../service/quizService';
import { t } from '../i18n';

export function AssessmentDefinitionResults (props:any) {
      
    let entity:AssessmentDefinition = props.entity;
    let tnp :boolean =  props.tnp;
    let [request, setRequest] = useState({first:0 , max:100, criteria: {assessmentReference: entity.reference},  time: new Date() });
    let [data, setData] = useState( [] as AssessmentInstance[] ) ;
    let [pagination, setPagination] = useState({first:0, pageSize:100, total:0});
    useEffect(()=> {
      if( entity.reference) {
      searchAssessmentInstance(request).then(resp=> {
             console.log("searchAssessmentDefinition response", resp);
            if(resp && resp.result) {
                setData(resp.result);
                setPagination({first:request.first, pageSize:request.max, total: resp.total})
            }
        }) 
      }
    }, [ entity.reference] );
    let reload =(p:number) => { 
        setRequest({...request, time: new Date(), first: p });
    } 
     
    return (
        <div className="app-form">
             <div className="ass-def-result">
            <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr> 
                      <th className="wd-45p pl">{t("Collaborateur")}</th> 
                      {tnp &&   <th className="wd-45p pl">{t("Score")}</th> }
                      <th className="wd-45p pl">{t("Début")}</th> 
                      <th className="wd-45p pl">{t("Fin")}</th> 
                      <th className="wd-45p pl">{t("Temps (min)")}</th>
                      <th className="wd-45p pl">{t("Nombre de Réponse ")}</th>
                      
                      {tnp && <th className="wd-45p pl">{t("Statut ")}</th>}
                      {tnp && <th className="wd-45p pl">{t("Action")}</th> }
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((d: AssessmentInstance) => {
                      return(
                    <tr className="ind-row" key={d.reference}> 
                    <td className="pl"  > {entity.anonymity ? <span><i className="fa fa-user-secret" ></i> <span>&nbsp; Anonyme</span></span> :<strong>  {d.trainee?.name} </strong> } </td>
                    {tnp &&   <td className="pl"  >{d.score } </td> }
                    <td className="pl"  >{printShortDate(d.startTime )} </td> 
                    <td className="pl"  >{printShortDate(d.endTime )} </td>  
                    <td  className="pc" > {d.durationMin} </td>
                    <td  className="pc" > <strong>{d.acheivedQuestionNumber }</strong> </td>
                    {tnp && <td  className="pc" > <i className={d.cleared? "ok fa fa-chek": "ko fa fa-ban"}></i> </td>}
                     {tnp &&<td className="action-btn">
                        <Link to={"/quiz/definition/"+d.reference+"/display"} >
                        <i className="fa fa-eye"></i>
                        </Link>
                        
                    </td>}
                 
                  </tr>);

                    })}
                    {(!data || !data.length ) && <tr><td align="center" colSpan={8} >{t("Aucun résultat toruvé")}</td></tr>
                    }
                    
                  </tbody>
                </table>
              </div>  
              { pagination.total>0 && 
                <Pagination reload={reload}  first={pagination.first} pageSize={pagination.pageSize} total={pagination.total} ></Pagination>
                }
            </div>


        </div>
    );
}