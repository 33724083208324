import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import './dashboard.css';
import { ALL_COLLABS, ROOT_ENTITIES } from "../test-data/entities";
import { t } from "../i18n";
import { appConfig } from "../service/userService";



export function OrganizationCascalde(props: any) {
   let targetEntity = props.targetEntity; 
   let permissionDomain = props.permissionDomain;
   let ouLevels = appConfig().ouLevels;
   let oulSize = ouLevels.length;
   let maxLevel = parseInt(ouLevels[oulSize-1].name);
   let siegeName = oulSize > 0 ? ouLevels[0].label : "";
   let l1Name = oulSize > 1 ? ouLevels[1].label : "";
   let l2Name = oulSize > 2 ? ouLevels[2].label : "";
   let l3Name = oulSize > 3 ? ouLevels[3].label : "";
   let l4Name = oulSize > 4 ? ouLevels[4].label : "";
   let l5Name = oulSize > 5 ? ouLevels[5].label : "";
   let data2:any[] = [];
   let data3:any[]  =[];
   let data4:any[]  =[];
   let dataCollab:any[] = ALL_COLLABS;
   let [data, setData] = useState({data1:ROOT_ENTITIES(permissionDomain), data2: data2, data3:data3, data4: data4, dataCollab:dataCollab });
   let onSelectData = (d:any, index: number) => {
        
        if(index==0) {
           data= ({data1:data.data1, data2: d.children, data3:[], data4: [], dataCollab:d.people })
        }
        if(index==1) {
           data= ({data1:data.data1, data2: data.data2, data3:d.children, data4: [], dataCollab:d.people })
        }
        if(index==2) {
          data= ({data1:data.data1, data2: data.data2, data3:data.data3, data4: d.children, dataCollab:d.people })
        }
        if(index==3) {
          data= ({data1:data.data1, data2: data.data2, data3:data.data3, data4: data.data4, dataCollab:d.people })
        }
        setData(data);
        props.onSelectEntity(d);
   }
   let resetAll = ()=> {

    setData({data1:ROOT_ENTITIES(permissionDomain), data2: data2, data3:data3, data4: data4, dataCollab:dataCollab });
    props.onSelectEntity({name: "", label: t("Siège")}); 

   }
   useEffect(()=>{
     if(!targetEntity || !targetEntity.qn) { return}
     
     let tkns = targetEntity.qn.split("/");
     console.log("prselect target ", tkns, data) ;
     for(let i=1; i< tkns.length; i++) {
       let ett = tkns[i];
       let selcted = (data["data"+i] ||[]).filter(d=>d.name==ett)[0];
       if(!selcted) {
        console.log("no selected for "+ett+" in ", data["data"+i]) ;
        break;
       }
       console.log("selected for "+ett+" "+ i+"->", selcted) ;
       onSelectData(selcted, i-1);
     }
   }, ["once"])
   
   return (<>
    <div className="entity-selection-block">
        <div className="row  az-content-header-right">
        
            <div className=" col-2 media">
              <div className="media-body target-entity">
                
                <div onClick={resetAll} className="entity-reset btn-purple">{siegeName}</div>
              </div>
            </div>
           { l1Name && <div className=" col-2 media">
              <div className="media-body target-entity">
                <label>{t(l1Name)}</label>
                <DropdownList tag="delegation" valuePath="qn" showValueIfMatchOnly={true} data={data.data1} value={targetEntity}  onSelect={(d:any)=>onSelectData(d, 0)} enableSearch ></DropdownList>
              </div>
            </div>
            }
              { l2Name &&      <div className=" col-2  media">
              <div className="media-body target-entity">
                <label>{t(l2Name)}</label>
                <DropdownList tag="direction" valuePath="qn" showValueIfMatchOnly={true}  data={data.data2} value={targetEntity}  onSelect={(d:any)=>onSelectData(d, 1)}  enableSearch ></DropdownList>
              </div>
            </div>  }
              { l3Name &&          <div className=" col-2  media">
              <div className="media-body target-entity">
                <label>{t(l3Name)}</label>
                <DropdownList tag="uc" valuePath="qn" showValueIfMatchOnly={true}  data={data.data3} value={targetEntity}  onSelect={(d:any)=>onSelectData(d, 2)}  enableSearch ></DropdownList>
              </div>
            </div>  }
              { l4Name &&         <div className=" col-2  media">
              <div className="media-body target-entity">
                <label>{t(l4Name)}</label>
                <DropdownList tag="agence" valuePath="qn" showValueIfMatchOnly={true}  data={data.data4} value={targetEntity}  onSelect={(d:any)=>onSelectData(d, 3)}  enableSearch ></DropdownList>
              </div>
            </div>  }
              { l5Name &&       <div className=" col-2  media">
              <div className="media-body target-entity">
                <label>{t(l5Name)}</label>
                <DropdownList tag="collab" valuePath="name"  
                showValueIfMatchOnly={true}  data={data.dataCollab} value={targetEntity}  onSelect={(d:any)=>onSelectData(d, 4)}  enableSearch ></DropdownList>
              </div>
            </div>
            }
        </div>
        </div>
    </>);
}