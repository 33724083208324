import { IndicatorData    } from "../dashboard/dashboard-data";
import { round2 } from "../help/formating";
import { t } from "../i18n";
import { mapPariodSfx, mapPariodToLibelle } from "./KpiBoxGauge";


function KpiBoxA(props: any) {
  let id: IndicatorData = props.indicatorData;
  let embedded = props.embedded;
  let setDrilledKpi = props.onSelect;
  

  let avance = (id.globalAchievment || 0) - 0.01 * (id.targetProrataPercent || 1) * (id.target || 0);
  return (
    <>
      <div className={"kpi-box-gauge indicator-box1 " + props.className}>
        <div className="box-card">
          <div className="card-head">
            <div className="ind-title">{t(id.title || '')}</div>
            {!embedded && <>
              <div onClick={() => { props.shareKpi(id) }} className="ind-share-nlb" title="Partager"><i className="fa fa-share"></i> <span> </span></div>
              {/*  <div onClick={() => setDrilledKpi(id)} title="Détails..." className="ind-drill"><i className="fa fa-users"></i> <span> </span></div> */}
            </>}
          </div>
          <div className="card-body">
            <div className="ind-ach">
              <span className="ind-val">{id.measure}</span>
              <span className="ind-unit">{id.unit} </span>
              <i className={"fa fa-arrow-" + id.trend?.toLocaleLowerCase() + " tx-success"}></i>
              <small>{id.deltaPercent}%</small>
            </div>
            <div className="ind-resource">{id.resource.name}</div>
            <div className="ind-time">{id.timeScope}</div>
          </div>
          <div className="ind-foot">
            <div className="ind-progress-outer">
              <div className="ind-progress-inner-prorata" style={{ width: id.targetProrataPercent + '%' }}></div>
              <div className="ind-progress-inner-achi" style={{ width: Math.min(100, id.achievmentPercent || 0) + '%' }}></div>
              <div className="ind-global-traget-val"><div>{id.target}</div><div>{id.unit}</div></div>
              <div className="ind-global-achi-percent" style={{ left: Math.min(100, id.achievmentPercent || 0) + '%' }} ><div>{Math.round(id.achievmentPercent || 0)}% </div><div> {id.globalAchievment}  {id.unit}</div> </div>
            </div>
          </div>

          <div className="ind-foot-gauge-2">
          <div className="ind-foot-line">
              <div className="ind-foot-lbl">{t("Performance") + " " + mapPariodSfx(id.kpiDefinition?.seedPeriodTime)}</div>
              <div className="ind-foot-val">{round2(id.measure)}<small>{id.unit}</small>
                <i className={"fa fa-arrow-" + id.trend?.toLocaleLowerCase() + " tx-success"}></i>
              </div>
            </div>
            <div className="ind-foot-line">
              <div className="ind-foot-lbl">{t("Objectif Proraté")}</div>
              <div className="ind-foot-val">{round2(0.01 * (id.targetProrataPercent || 1) * (id.target || 0))}<small>{id.unit}</small></div>
            </div>
         
            <div className={"ind-foot-line " + (avance < 0 ? " ind-foot-warn" : "")}>
              <div className="ind-foot-lbl">{t("Ecart sur la progression Idéale")}</div>
              <div className="ind-foot-val">{round2(avance)}<small>{id.unit}</small></div>
            </div>
            <div className="ind-foot-line">
              <div className="ind-foot-lbl">{t("Objectif") + " " + mapPariodToLibelle(id.kpiDefinition?.targetPeriodTime)}</div>
              <div className="ind-foot-val">{(id.target || 0).toLocaleString("fr")}<small>{id.unit}</small></div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default KpiBoxA;

