import { Comment, Post } from "../feed-wall/data";
import { Group, OrganizationUnit, Profile, UserProfile } from "../types/people";
import { post } from "./http.service";
import { currentUser } from "./userService";
const urlBase = '/api/event/post';//'http://localhost:11002';
 

export async function getPost(reference) : Promise<any> {
  let data = await post(  urlBase+ "/get", { action: "GET", reference: reference, userId: currentUser().email}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}
export async function addPost(p:Post) : Promise<any> {
    let data = await post(  urlBase+ "/create", { payload: p}) ;  
     
    if (!data.success || !data.body) {
        return null;
    } 
    return data.body;
}

export async function markPost(p:Post ) : Promise<any> {
  let data = await post(  urlBase+ "/mark", {reference: p.reference, userId: currentUser().email ,action : "mark", payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

 

export async function updatePost(p:Post, action: string) : Promise<any> {
  let data = await post(  urlBase+ "/update", {reference: p.reference, userId: currentUser().email ,action, payload: p}) ;  
   
  if (!data.success || !data.body) {
      return null;
  } 
  return data.body;
}

export async function addComment(postRef: string, comment:Comment, action) : Promise<any> {
    let data = await post(  urlBase+ "/comment", {action, reference: postRef, payload: comment}) ;  
     
    if (!data.success || !data.body) {
        return false;
    } 
    return data.body;
} 
export async function searchPost(request: any)   {  
     
    let data = await post(  urlBase+ "/search",request) ;   
    return data;
}
export async function searchPostDebounced(request: any)   {  
     return debounce1(searchPost, 200)(request);
}
let timer1:any = null;
function debounce1(f, interval:number) {
     
  
    return (...args) => {
      clearTimeout(timer1);
      return new Promise((resolve) => {
        timer1 = setTimeout(
          () => resolve(f(...args)),
          interval,
        );
      });
    };
  }