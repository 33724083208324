import { useEffect, useState } from 'react';
import { toYYYMMDDHHMM } from '../help/dates';
import { t } from '../i18n';
import { getMessage, markMessage, searchMessage, updateMessage } from '../service/messageService';
import { currentUser } from '../service/userService';
import { Pagination } from '../ui/pagination/search-paging';
import './message.css';
import { Message, MessageBlock } from './message-type';
import { Avatar } from '../ui/avatar'; 
import { EditMessage } from './message-edition';
import { useParams } from 'react-router';

export function SearchUMessages(props: any) {
  let {reference:reference} = useParams() as any;
  let [showMessageEditor, setShowMessageEditor] = useState(false);
  let [omni, setOmni] = useState('');
  let [request, setRequest] = useState({ userId: currentUser().email, first: 0, max: 10, messageType: "message",  criteria: {}, time: new Date() });
  let [data, setData] = useState([]);
  let [message, setMessage] = useState(undefined as any);
  let [messageToAnswer, setMessageToAnswer] = useState(undefined as any);
  let [pagination, setPagination] = useState({ first: 0, pageSize: 10, total: 0 });
  useEffect(() => {
    if(reference) {
      getMessage(reference).then(r=> {
        if(r) {
          message = extractMessageContent(r);
          setMessage({...message}); console.log("message for "+reference, message)}
      })
    }
    searchMessage(request).then(resp => {
     
      if (resp.result) {
        data = resp.result.map((d: Message) => extractMessageContent(d));
        setData(data);
        setPagination({ first: request.first, pageSize: request.max, total: resp.total })
        // setMessage(data[0]);
      }
    })
  }, [request, reference]);
  
  let reload = (p: number) => {
    setRequest({ ...request, time: new Date(), first: p });
  }
  let handleKeyPressEvent = (k: string) => {
    if (k === 'Enter') { launchSearch(); }
  }
  let launchSearch = () => setRequest({ ...request, criteria: { 'omni': omni } });
  let compose = () => {
    setShowMessageEditor(true);
  }
  let selectMessage = (d: Message) => {
    
    markMessage(d ).then(r => {
      setData([...data]);
    });
    setMessage(d);
  }
  let removeMessage=(d:Message) =>{
    if(d==message) {
          setMessage(undefined);
    }
    updateMessage(d, "delete").then(r=> {
      if(r) {
        reload(0);
      
      }
    })
  }
  return (
    <div className="search-users">
      {showMessageEditor && <EditMessage onClose={() => setShowMessageEditor(false)} ></EditMessage>}
      {messageToAnswer && <EditMessage message={messageToAnswer} onClose={() => setMessageToAnswer(undefined)} ></EditMessage>}
      <div className="search-console">
        <div className="criteria">
          <div className="criteria-control">
            <input type="text" className="form-control" placeholder={t("Rechercher un message")}
              onChange={(e) => setOmni(e.target.value)}
              onKeyPress={(e) => handleKeyPressEvent(e.key)}
            />
            <button className="btn"><i
              onClick={launchSearch}
              className="fas fa-search"></i></button>
          </div>

        </div>
        <div className="actions-bar">
          <div className="action-btns-droplist">
            <a onClick={compose} className="btn btn-purple"><i className="fa fa-edit"></i> <span>Nouveau Message</span></a>
            <div className="extra-action">   </div>
          </div>
        </div>
      </div>

      <div className="messages-browser">
        <div className="message-list">

          {data && data.map((d: Message, id) => {
            return (
              <div key={d.reference} className={"message-row " +
                ((message && message.reference == d.reference) ? " active " : " ") + (d.seen? " seen ": "")}
                onClick={() => selectMessage(d)} >
                  <div onClick={()=>removeMessage(d)} className="delete-message"><i className="fa fa-trash"></i></div>
                <div className="avatar-block">
                  <Avatar user={d.actor}></Avatar>
                </div>
                <div className="message-info">
                  <div className="flex-sb">
                    <span className="message-actor-name"> {d.actor.name}</span>
                    <div className="flex-xsb">
                      {d.hasAttachment && <i className='fa fa-paperclip'></i>}
                      <span>{toYYYMMDDHHMM(d.changeTime)}</span>
                    </div>
                  </div>
                  <h6>{d.title}</h6>
                  <div className="message-teaser">{d.textIndex}</div>
                </div>

              </div>);

          })}
          {data.length == 0 && <div className="no-data">Aucun message!</div>}
          {pagination.total > 0 &&
            <Pagination reload={reload} first={pagination.first} pageSize={pagination.pageSize} total={pagination.total} ></Pagination>
          }

        </div>
        {message &&
          <div className="massage-panel">
            <div className="message-header">
              <h5>{message.title}</h5>
              <div><span>{toYYYMMDDHHMM(message.changeTime)}</span>
                <span onClick={() => setMessage(undefined)}><i  className="back-to-list-btn fa fa-arrow-left"></i></span></div>

            </div>
            <div className="message-body">
              {message.blocks?.map((block: MessageBlock, indx) => {
                return <div key={message.reference + "-" + indx} className="message-block">

                  <div className="message-content" dangerouslySetInnerHTML={{ __html: block.htmlContent }}></div>
                  <div className='block-header flex-pr'>
                    <span>{block.actor.name} </span>
                    <span>&nbsp; {toYYYMMDDHHMM(block.creationTime)} </span>
                  </div>
                  {block.attachments && block.attachments.length > 0 &&
                    block.attachments.map(a => {
                      return <div className="attachment-block" >
                        <a href={a.url}><i className="typcn typcn-attachment-outline"></i> <span>{a.fileName}</span></a>
                      </div>
                    })}

                </div>
              })}


              <div className="block-actions">
                <div className="block-btn" onClick={() => { setMessageToAnswer(message) }}>
                  <i className="fa fa-reply"></i> <span>Répondre</span>
                </div>
              </div>

            </div>
          </div>
        }
      </div>



    </div>
  );
}









export function extractMessageContent(d: Message) {
  try {
    let blocks = JSON.parse(d.contentData || "[]");
    d.hasAttachment = blocks.filter(x => x.attachments?.filter(a => a.url).length > 0).length > 0;
    d.blocks = blocks;
    let mainBlock: MessageBlock = blocks[0];
    if (mainBlock) {
      d.textIndex = crop(mainBlock.textContent, 100) ;
    }
    if(!d.actor) {
      if(d.visualUrl?.startsWith("icon:")) {
        if("icon:kpi"==d.visualUrl) {
          d.icon = "typcn typcn-chart-line-outline"
        } 
        if("icon:kpidata"==d.visualUrl) {
          d.icon = "typcn typcn-chart-line-outline"
        }
        if("icon:challenge"==d.visualUrl) {
          d.icon = "typcn typcn-gift"
        }
      }
        d.actor = {name:'', avatarUrl:d.visualUrl, email: ''}
      
    }
    d.seen = d.observers && d.observers.includes(currentUser().email);
    return d;
  } catch (error) {
    console.error(error);
  }
}
function crop(t: string, n: number): string | undefined {
  if(!t) return '';
  if(t.length>n) {
    return t.substring(0, n-3)+"..."
  } 
  return t;

}

