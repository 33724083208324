import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router"; 
import { printShortDate, toYYYMMDD, toYYY_MM_DD } from "../help/dates"; 
import { ROOT_ENTITIES } from "../test-data/entities";
import { Entry, Group, Profile } from "../types/people"; 
import { MultItemField } from "../ui/mutli-item-field";
import '../admin/admin.css'; 
import { createFamily, getKpiDefById, searchFamilies, updateKpiDef } from "../service/kpiService";
import { KpiDefinition, KpiFamily } from "../types/kpi-model";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { KpiFeederPopup } from "./kpi-feeder";
import { appConfig, searchGroups } from "../service/userService";
import { t } from "../i18n";

const measureTypes =[{name: 'number', label:'Nombre' },
{name: 'rate', label:'Taux' },
{name: 'amount', label:'Montant' },
{name: 'percent', label:'Pourcentage' } ] ;
const periodTypes =[ {label: 'Jour', name:'day' } , 
{label: 'Semaine', name:'week' },
{label: 'Mois', name:'month' },
{label: 'Année', name:'year' }] ;
const measureUnits = [{name: '', label:'Sans Unité' },
{name: 'KMAD', label:'KMAD' },
{name: 'Client', label:'Client' },
{name: 'Contrat', label:'Contrat' },
{name: 'Opération', label:'Opération' },
{name: 'Jour', label:'Jour' },
{name: 'Heure', label:'Heure' },
{name: '%', label:'Percent (%)' },
{name: 'Autre', label:'Autre' } ] ;
export function SelectFamily(props:any) {
    let label = props.label;
    let [value, setValue] = useState(props.value as KpiFamily);
    let [addNew , setAddNew] = useState(false);
    useEffect(()=> {value=props.value; setValue(props.value)},[props.value]);
    let createNewFamil= ()=> {
        createFamily(value).then(r=> {
            console.log("create family ", r); 
            setValue(r); 
            setAddNew(false);
            props.onSelect(r);
        })
    }
    return (
        <div className="family-field">
            <div className="flex-container">
                <DropdownList  readOnly={props.readOnly}  onSelect={props.onSelect} data={props.options} value={value}  className="flex-child-11" ></DropdownList> 
                <div onClick={()=>setAddNew(!addNew)} className="button-new-family">
                    <i className={"fa "+ (addNew? "fa-times": "fa-plus")} >
                        </i><span className="button-title" >Nouvelle {label}</span></div>
            </div>
            { addNew && <div className="drop-down-block">
                <div className="form-group "> 
                    <label>{t("Nom")}</label>
                    <input onChange={e=>setValue({...value, name: e.target.value})} 
                        value={value.name} 
                        type="text" className="form-control" placeholder={t("Nom")} />  
                </div> 
                <div className="form-group "> 
                    <label>{t("Description")}</label>
                    <input onChange={e=>setValue({...value, title: e.target.value})} 
                        value={value.title} 
                        type="text" className="form-control" placeholder={t("Nom")} />  
                </div> 
                <div className="btns-bar"> 
                    <div onClick={createNewFamil}  ><i className="fa fa-times"></i> Créer</div>
                </div>
                </div>
            }

        </div>
    );
}
export function KpiDefinitionView(props:any) {
    let {id:id, mode:inMode} = useParams() as any; 
    let [entity  , setEntity]  = useState(({name: '', title: '', measureType :'number', unit: '', monitoringGroups: [],ouLevelMax:5, ouLevelMin:1,   family : {parent : {}}} as KpiDefinition ));
    let [pFamily, setPFamily] = useState({} as KpiFamily | undefined);
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode);
    let [error, setError] = useState(''); 
    
    let [allGroups, setAllGroups] = useState([]);

    let history = useHistory(); 
    let [families, setFamilies] = useState([{name: "RPI"},{name: "EER"} , {name: "Satisifaction"} ] as KpiFamily[]  );
    let [subFamilies, setSubFamilies] = useState([] as KpiFamily[]  );
    let [showFeederPopup, setShowFeederPopup] = useState(false);
    let onDelete=()=> {
        entity = { ...entity, enabled:false}
        updateKpiDef(entity, "delete").then((u)=>{ 
            setShowPopup(false);
            history.push("/kpi/definition");
         })
    }
    let submit= (action:string)=> {
        if(action=='update' || action=='create') {
            if(!entity.name) {
                setError("Le nom est obligatoire")
                return;
            }
            if(!entity.family) {
                setError("La sous-famille est obligatoire")
                return;
            }
             
             
        }
        updateKpiDef(entity, action).then((u)=>{ 
            if(u)  {  
                setEntity(u);
                mode = 'display';
                setMode('display');
                id=u.id;
             } 
            });
    }
    useEffect(()=> { 
            if(id && id!="00") {
                getKpiDefById(id).then((u)=>{
                    if(u.body) { 
                        entity = u.body;
                        setEntity(u.body);
                        if(entity.family) {
                            setPFamily(entity.family.parent);
                        } 
                        console.log("family", entity.family);
                    }});
            }
            searchFamilies({action:'Fetch-SubFamilies', criteria : {deep: 0}}).then(roots => setFamilies(roots))
            
            searchGroups({}).then(r=>{ 
                allGroups=(r.result.map((x:Group)=> { let e:Entry={value:x.groupQN, label:x.label, id: x.id}; return e; }))
                setAllGroups(allGroups);
            })
        }
        , [id]);
        
    let readonly=mode!='create' && mode!='edit';
    useEffect(()=> { 
        if(!readonly) {
            console.log("Set PFamily")
            searchFamilies({action:'Fetch-SubFamilies', criteria : {parent: pFamily}})
            .then(roots => setSubFamilies(roots));
            if(!entity.family ||!entity.family.parent ||
                 entity.family.parent.reference!=pFamily?.reference) { 
                     entity.family = {parent : pFamily };}
            setEntity(entity);
        }
        }
    , [pFamily]);
    let actions: any[] = [];
    if(mode=='display') {
     actions.push({
         label: t("Editer"),
         icon: "fa fa-edit",
         handler: ()=> { setMode('edit')}
     });
    
     actions.push({
        label: t("Supprimer"),
        icon: "fa fa-trash",
        handler: ()=> { setShowPopup(true);}
    });
    actions.push({
        label: t("Fermer"),
        icon: "fa fa-times",
        handler: ()=> { history.push("/kpi/definition")}
    });
    } else if (mode=='edit'  || mode=='create') {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: ()=> { submit('update')}
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: ()=> { setMode('display')}
        });
    }
    if(entity.reference) {
     
        actions.push({
            label: t("Chargement des données"),
            icon : "fa fa-upload",
            handler : ()=>{setShowFeederPopup(true)}
        })
    }
    let ouLevels = appConfig().ouLevels;
    return (<div className="config-form">
        
        <div className="assessment" > 
    <div className="assmt-header">
    <h3 className="assmt-title">{entity.id?  "KPI "+entity.name :  "Nouveau KPI"}</h3>
    <div className="assmt-dates az-content-header-right" > 
            <div className="media">
              {entity.modifiedBy && <div className="media-body">
                <label>{t("Par")} <strong> { (entity.modifiedBy || entity.createdBy)}</strong> </label> 
                <h6> {printShortDate(entity.changeTime || entity.creationTime)}</h6>
              </div>}
            </div>
            <div className="media noextramarge">
              <div className="media-body actions-toolbar">
                  {actions.map((a,i)=>  <a key={i+"_"+a.label} onClick={a.handler}   className="btn btn-purple">
                      <i className={a.icon}></i> 
                      <span>{a.label}</span>
                      </a>
               )}
              </div>
            </div>
    </div>
    </div>
    </div>

            <div className="app-form kpi-form" >
                
            {error && <p className="form-error">{error}</p>}
                <div className="form-g2c" >
                <div className="form-group "> 
                    <label>{t("Famille du KPI")}</label>
                    <SelectFamily  readOnly={readonly} 
                    onSelect={(d)=>setPFamily(d)} options={families} label="famille" value={pFamily} ></SelectFamily> 
                </div> 
                <div className="form-group "> 
                    <label>{t("Sous Famille du KPI")}</label>
                    <SelectFamily  readOnly={readonly || !pFamily?.name}  onSelect={(d)=>setEntity({...entity, family : d})}  options={subFamilies} label="sous-famille" value={entity.family} ></SelectFamily> 
                </div>
                

                <div className="form-group "> 
                    <label>{t("Nom du KPI")}</label>
                    <input readOnly={readonly}   onChange={e=> setEntity({...entity, name : e.target.value})} value={entity.name} 
                     type="text" className="form-control" placeholder={t("KPI")} />  
                </div> 
                <div className="form-group ">
                    <label>{t("Description du KPI ")}</label>
                    <input  readOnly={readonly}     onChange={e=> setEntity({...entity, title : e.target.value})} value={entity.title} 
                     type="text" className="form-control" placeholder={t("description")} />
                </div> 

                <div className="form-group ">
                    <label>{t("Type de mesure")}</label>
                    <DropdownList  readOnly={readonly} data={measureTypes} value={{name: entity.measureType}}
                        onSelect={(d)=>setEntity({...entity, measureType:d.name })}
                    ></DropdownList>
                </div> 
                
                <div className="form-group ">
                    <label>{t("Unité de mesure")}</label>
                    {entity.unit!="Autre" && <DropdownList  readOnly={readonly} data={measureUnits} value={{name: entity.unit}}
                        onSelect={(d)=>setEntity({...entity, unit:d.name })}
                    ></DropdownList> }
                    {entity.unit=="Autre" &&<div className="flex-container">
                    <input readOnly={readonly}   onChange={e=> setEntity({...entity, otherUnit : e.target.value})}
                     value={entity.otherUnit} 
                     type="text" className="form-control" placeholder={t("Saisir une nouvelle Unité")} />  
                     <div  className="button-new-family">
                     <i onClick={()=>setEntity({...entity, unit:"" })} className="fa fa-list"></i>
                     </div>
                        </div>}
                    
                </div> 
                <div className="form-group ">
                    <label>{t("Précision d'affichage")}</label>
                    <input  readOnly={readonly}     onChange={e=> setEntity({...entity, precision : parseInt(e.target.value)})} value={entity.precision} 
                     type="number"  step={1} className="form-control" placeholder={t("Nombre de chiffres apès/avant la virgule...")} />
                </div> 
                <div className="form-group ">
                    <label>{t("Arrondir l'objectif proraté")}</label>
                    <div onClick={()=>!readonly && setEntity({...entity, arroundProrata :!entity.arroundProrata})} 
                    className={"az-toggle az-toggle-success " +(entity.arroundProrata? 'on' : 'off')}><span></span></div>
              
                </div> 
                
                <div className="form-group ">
                    <label>{t("Période d'Objectifs")}</label>
                    <DropdownList  readOnly={readonly}  data={periodTypes} value={{name: entity.targetPeriodTime}}
                        onSelect={(d)=>setEntity({...entity, targetPeriodTime:d.name })}
                    ></DropdownList>
                </div> 
                <div className="form-group ">
                    <label>{t("Période de Suivi ")}</label>
                    <DropdownList  readOnly={readonly}  data={periodTypes} value={{name: entity.seedPeriodTime}}
                        onSelect={(d)=>setEntity({...entity, seedPeriodTime:d.name, displayPeriodTime:d.name  })}
                    ></DropdownList>
                </div> 
                {/*
                <div className="form-group ">
                    <label>{t("Période d'affichage ")}</label>
                    <DropdownList  readOnly={readonly}  data={periodTypes} value={{name: entity.displayPeriodTime}}
                        onSelect={(d)=>setEntity({...entity, displayPeriodTime:d.name })}
                    ></DropdownList>
                </div> 
                */ }
                
                <div className="form-group ">
                    <label>{t("Enité Cible Top")}</label>
                    <DropdownList  readOnly={readonly}  data={ouLevels} value={{name:""+ entity.ouLevelMin}}
                        onSelect={(d)=>setEntity({...entity, ouLevelMin:parseInt(d.name) })}
                    ></DropdownList>
                </div> 

                <div className="form-group ">
                    <label>{t("Enité Cible Bottom")}</label>
                    <DropdownList  readOnly={readonly}  data={ouLevels} value={{name:""+ entity.ouLevelMax}}
                        onSelect={(d)=>setEntity({...entity, ouLevelMax:parseInt(d.name) })}
                    ></DropdownList>
                </div> 
                
               
                <div className="form-group ">
                    <label>{t("Date début")}</label>
                     <input type="date" value={toYYY_MM_DD( entity.start)} 
                     className="form-control" placeholder={t("")}
                     onChange={(d)=>setEntity({...entity, start:new Date(d.target.value) })} ></input>
                </div> 
                
                <div className="form-group ">
                    <label>{t("Date Fin")}</label>
                     <input type="date" value={toYYY_MM_DD( entity.end)} 
                     className="form-control" placeholder={t("")}
                     onChange={(d)=>setEntity({...entity, end:new Date(d.target.value) })} ></input>
                </div>   


                <div className="form-group ">
                    <label>{t("Groupes de Suivi ")}</label>
                    <MultItemField data={entity.monitoringGroups} 
                    setData={(d)=>{entity.monitoringGroups=d;setEntity(entity);}} 
                    items={allGroups} noDataMessage="Aucun groupe" mode={mode=='create'? 'edit': mode}  ></MultItemField>
                  
                </div> 

                <div className="form-group ">
                    <label>{t("Activer le KPI")}</label>
                    <div onClick={()=>!readonly &&setEntity({...entity, activated :!entity.activated})} 
                    className={"az-toggle az-toggle-success " +(entity.activated? 'on' : 'off')}><span></span></div>
                </div> 
               
                </div> 
                
            </div>
            {showPopup && <div className="modal-screen">
                    <div className="popup">
                        <i className="fa fa-times" onClick={()=>setShowPopup(false)}></i>
                        <p>
                            Etes-vous sûr de vouloir supprimer ce KPI? 
                        </p>
                    <a  onClick={onDelete}   className="btn btn-purple delete-btn">
                      <i className="fa fa-trash"></i> 
                      <span>Confirmer la suppression</span>
                      </a> 

                    </div>
                </div>}

                {showFeederPopup && <KpiFeederPopup kpiDefinition={entity}  onClose={()=>setShowFeederPopup(false)}></KpiFeederPopup>}
    </div>);
}