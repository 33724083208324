
import { useEffect, useState } from "react";
import '../admin/admin.css';
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { Resource } from "../types/kpi-model";
import ReactQuill from "react-quill";

import { quillFormats } from "../feed-wall/text-editor";
import { TrainingDefinition, TrainingModule, TrainingStage } from "./training-type";
import { uploadFile } from "../service/file.service";
import { Popup } from "../ui/popup";
import { AssessmentDefinitionForm } from "../assessment/assessmentForm";
import { AssessmentDefinition } from "../assessment/assessment-type";
import { t } from "../i18n";

let ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT") || "[]");



const mediaTypes = [
    { name: "PDF", label: t("Document PDF") },
    { name: "PPT", label: t("Présentation Power Point") },
    { name: "VIDEO", label: t("Vidéo") },
    { name: "YOUTUBE", label: t("Ressource Youtube") }]
function mapLabelForUplaodBtn(type) {
    if (type == 'PDF') {
        return 'Charger le document PDF';
    }
    if (type == 'PPT') {
        return 'Charger le présentation';
    }
    if (type == 'VIDEO') {
        return 'Charger la Vidéo';
    }
    if (type == 'YOUTUBE') {
        return 'Saisir le lien de la Vidéo';
    }
    return "Charger la resource";
}

function mapUploadAcceptance(type) {
    if (type == 'PDF') {
        return 'application/pdf';
    }
    if (type == 'PPT') {
        return 'application/pdf';
    }
    if (type == 'VIDEO') {
        return 'video/mp4,video/x-m4v,video/*';
    }
    return "*.*";
}
function TrainingModuleBlock({ module, onSaveModule, onDeleteModule, readonly, index, stgIndex, training, stage, expanded, onExpand }) {
    let [entity, setEntityIn] = useState(module);
    let [displayQuiz, setDisplayQuiz] = useState(false);
    let setEntity = (x) => { setEntityIn(x); onSaveModule(x, index) }
    let [errorMedia, setErrorMedia] = useState('');
    let onPhotoChange = (evt: any) => {
        uploadFile(evt).then((link: any) => {
            entity.photoUrl = link;
            setEntity(entity);
        });
    }

    let onMediaFileChange = (evt: any) => {
        setErrorMedia("")
        let fileName: string = evt.target.files[0].name;


        uploadFile(evt, false).then((url: any) => {
            entity.media.contentUrl = url;
            entity.media.title = fileName;
            setEntity({ ...entity })
        });
    }
    let allModules = [...training.stages].flatMap(x => x.modules).map(x => { return { name: x.title, label: x.title } });
    allModules.push({name: "", label: 'Aucun'})
    let onAddQuiz = () => {
        entity.assessment = {
            instanceStatus: 'PAUSED',
            definition: {
                hasTraining: true, moduleName: entity.title,
                publicationDate: training.publicationDate, closingDate: training.closingDate, activated: true,
                type: 'QUIZ', title: entity.title || stage.title, description: entity.description,
                questions: [], status: 'OPEN',
                targetEntity: { ...training.targetEntity },
                monitoringGroups: [...training.monitoringGroups || []]
            }
        }
        setDisplayQuiz(true);
    }
    return <div className={"module-block "+(expanded==index?"expanded": "collapsed")}>

        <div  className="block-header flex-sb" onClick={()=>onExpand(index)} 
         >
            <h5>{t("Module") + " " + (index + 1) + "-" + entity.title}</h5>
            {!readonly && <div className="flex-pr">
                {!readonly && !entity.assessment && <span onClick={onAddQuiz} className="tlb-btn tex-btn"><i className="fa fa-plus-square"></i>{t("Ajouter un Quiz")}</span>}
                {entity.assessment && <span onClick={() => setDisplayQuiz(true)} className="tlb-btn tex-btn"><i className="fa fa-eye"></i> {t("Détail Quiz")}</span>}
                {!readonly && entity.assessment && <span onClick={() => { entity.assessment = undefined; setEntity({ ...entity }) }} className="tlb-btn tex-btn"><i className="fa fa-ban"></i> Supprimer le Quiz</span>}

                <span onClick={() => onDeleteModule(index)} className="tlb-btn  tex-btn"><i className="fa fa-ban"></i> {t("Supprimer le module")}</span>
            </div>
            }</div>
        <div className="form-g2c hidable-panel-body">
            <div className="form-group  ">
                <label>{t("Titre ")}</label>
                <input readOnly={readonly} onChange={e => setEntity({ ...entity, title: e.target.value })} value={entity.title}
                    type="text" className="form-control" placeholder={t("Titre de la formation")} />
            </div>
            <div className="form-g2c" >

                <div className="form-group ">
                    <label>{t("Poid")}</label>
                    <input readOnly={readonly} type="number"
                        onChange={e => setEntity({ ...entity, weight: parseInt(e.target.value) })}
                        value={entity.weight + ""}
                        className="form-control" />
                </div>
                <div className="form-group ">
                    <label>{t("Durée (min)")}</label>
                    <input readOnly={readonly} type="number"
                        onChange={e => setEntity({ ...entity, durationMin: parseInt(e.target.value) })}
                        value={entity.durationMin + ""}
                        className="form-control" />
                </div>
            </div>
            <div className="form-group">
                <label>{t("Description")}</label>
                {!readonly && <ReactQuill
                    bounds="#quill-ex"

                    formats={quillFormats}
                    value={entity.description || ''} placeholder={t("")}
                    onChange={(content: any, delta: any, source: any, editor: any) => {
                        setEntity({ ...entity, description: "" + content })
                    }} />
                }

                {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.description || '...' }} ></div>}

            </div>
            <div className="form-group  ">
                <label htmlFor={"mdlFile-" + stgIndex + "-" + index} >
                    {!readonly && <div className="banner-placeholder">{t("Cliquez ici pour charger un visuel")} </div>}
                    {readonly && <div className="label">Image</div>}
                    {entity.photoUrl && <div className="client-banner-edition">
                        {!readonly && <i onClick={() => setEntity({ ...entity, photoUrl: '' })} className="fa fa-times" ></i>}
                        <img src={entity.photoUrl}></img>
                    </div>
                    }
                    {!entity.photoUrl && <i className="nobanner fa fa-image" ></i>}
                </label>
                {!readonly && <input onChange={onPhotoChange} type="file" id={"mdlFile-" + stgIndex + "-" + index} className="h-file-input" accept="image/*"></input>}
            </div>

            <div className="form-group ">
                <label>{t("Type de la ressource")}</label>
                <DropdownList readOnly={readonly} onSelect={(d) => setEntity({ ...entity, media: { ...entity.media, type: d.name } })}
                    data={mediaTypes} value={{ name: entity.media.type }} className="flex-child-11" ></DropdownList>
            </div>
            <div className="form-group ">
                
            {['PPT', 'PDF', 'VIDEO'].includes(entity.media.type || 'x') && <div className="flex-sa media-resource">
                <label htmlFor={"media-file-" + stgIndex + "-" + index}  >
                    <div className="btn generate-template">
                        <i className="fa fa-upload"></i>
                        <span>{mapLabelForUplaodBtn(entity.media.type)}</span>
                    </div>  <input type="file" id={"media-file-" + stgIndex + "-" + index} name="media-file"
                        onChange={onMediaFileChange}
                        className="h-file-input" accept={mapUploadAcceptance(entity.media.type)} ></input>
                </label>
                {entity.media.title && <span className="file-name"><a target="_blank" href={entity.media.contentUrl} >{entity.media.title}</a></span>}

            </div>}
            {entity.media.type == 'YOUTUBE' && <div className="form-group ">
                <label>{t("URL Youtube")}</label>
                <input readOnly={readonly} type="text"
                    onChange={e => setEntity({ ...entity, media: { ...entity.media, contentUrl: (e.target.value) } })}
                    value={entity.media.contentUrl}
                    className="form-control" />
            </div>
            }

</div>
            <div className="form-group ">
                <label>{t("Module Prérequis")}</label>
                <DropdownList readOnly={readonly} onSelect={(d) => setEntity({ ...entity, prerequisite: d.name })}
                    data={allModules} value={{ name: entity.prerequisite }} className="flex-child-11" ></DropdownList>
            </div>

        </div>


        {displayQuiz && <Popup large onClose={() => setDisplayQuiz(false)}>
            <AssessmentDefinitionForm type="QUIZ" inpopup readonly={readonly}
                onConfirm={(def: AssessmentDefinition) => { entity.assessment!.definition = def; setEntity({ ...entity }) }} assessmentDefinition={entity.assessment?.definition} readOnly={readonly} ></AssessmentDefinitionForm>
        </Popup>
        }

    </div>
}

function TrainingStageBlock({ stage, onSaveStage, onDeleteStage, readonly, index, training, expandedStage, onExpandStage }
     ) {
    let [entity, setEntityIn] = useState(stage);
    let [expanded, setExpanded] = useState(0);
    let setEntity = (x) => { setEntityIn(x); onSaveStage(x, index) }

    let onPhotoChange = (evt: any) => {
        uploadFile(evt).then((link: any) => {
            entity.photoUrl = link;
            setEntity(entity);
        });
    }


    let onAddModule = () => {
        let lmt = '';
        if (entity.modules.length > 0) {
            lmt = entity.modules[entity.modules.length - 1].title || '';
        } else if (index >= 1) {
            let lst = training.stages[index - 1];
            if (lst && lst.modules.length > 0) {
                lmt = lst.modules[lst.modules.length - 1].title || '';
            }
        }
        entity.modules.push({ title: '', status: 'OPEN', description: '', weight: 1, media: { status: "OPEN" }, prerequisite: lmt, localId: index + "/" + entity.modules.length });
        setEntity({ ...entity })
        setExpanded(entity.modules.length-1);
    }
    let onDeleteModule = (q: TrainingModule, indx: number) => {
        console.log("onDeleteModule", indx);
        entity.modules.splice(indx, 1);
        setEntity({ ...entity });
        //    submit('update');
        console.log("onDeleteModule training", entity);

    }
    let onSaveModule = (q: TrainingModule, indx: number) => {
        console.log("onSaveModule", q, indx);
        entity.modules[indx] = q;
        setEntity({ ...entity });
        console.log("onSaveModule stage", entity);
    }
    return <div className={"stage-block app-form kpi-form " +(expandedStage==index? "expanded":"collapsed") }>
        <div className="flex-sb block-header" onClick={()=>onExpandStage(index)}>
            <h5>{t("étape")} {index + 1} - {entity.title}</h5>
            {!readonly && <div className="flex-pr">
                <span onClick={onAddModule} className="tlb-btn tex-btn"><i className="fa fa-plus-square"></i> Ajouter un module</span>
                <span onClick={() => onDeleteStage(index)} className="tlb-btn  tex-btn"><i className="fa fa-ban"></i> Supprimer l'étape</span>
            </div>
            }
        </div>

        <div className="form-g2c hidable-panel-body">
            <div className="form-group  ">
                <label>{t("Titre ")}</label>
                <input readOnly={readonly} onChange={e => setEntity({ ...entity, title: e.target.value })} value={entity.title}
                    type="text" className="form-control" placeholder={t("Titre de la formation")} />
            </div>
            <div className="form-g2c" >

                <div className="form-group ">
                    <label>{t("Poid")}</label>
                    <input readOnly={readonly} type="number"
                        onChange={e => setEntity({ ...entity, weight: parseInt(e.target.value) })}
                        value={entity.weight + ""}
                        className="form-control" />
                </div>
                <div className="form-group ">
                    <label>{t("Durée (min)")}</label>
                    <input readOnly={readonly} type="number"
                        onChange={e => setEntity({ ...entity, durationMin: parseInt(e.target.value) })}
                        value={entity.durationMin + ""}
                        className="form-control" />
                </div>
            </div>
            <div className="form-group">
                <label>{t("Description")}</label>
                {!readonly && <ReactQuill
                    bounds="#quill-ex"

                    formats={quillFormats}
                    value={entity.description || ''} placeholder={t("")}
                    onChange={(content: any, delta: any, source: any, editor: any) => {
                        setEntity({ ...entity, description: "" + content })
                    }} />
                }

                {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.description || '...' }} ></div>}

            </div>
            <div className="form-group  ">
                <label htmlFor={"stgFile-" + index} >
                    {!readonly && <div className="banner-placeholder">{t("Cliquez ici pour charger un visuel")} </div>}
                    {readonly && <div className="label">Image</div>}
                    {entity.photoUrl && <div className="client-banner-edition">
                        {!readonly && <i onClick={() => setEntity({ ...entity, photoUrl: '' })} className="fa fa-times" ></i>}
                        <img src={entity.photoUrl}></img>
                    </div>
                    }
                    {!entity.photoUrl && <i className="nobanner fa fa-image" ></i>}
                </label>
                {!readonly && <input onChange={onPhotoChange} type="file" id={"stgFile-" + index} className="h-file-input" accept="image/*"></input>}
            </div>

        </div>
        <div className="modules-block">
            
            {entity.modules.map((md, indx) => <TrainingModuleBlock
                expanded={expanded} 
                onExpand={(i)=>setExpanded(i)}
            stage={entity} training={training} stgIndex={index} key={indx} onDeleteModule={onDeleteModule} onSaveModule={onSaveModule}
                module={md} index={indx} readonly={readonly} ></TrainingModuleBlock>)
            }
        </div>


    </div>
}
export function TrainingStageTab({ entity, setEntity, readonly }: { entity: TrainingDefinition, setEntity: Function, readonly: boolean }) {

    let [expanded, setExpanded] = useState(0);


    let onAddStage = () => {
        entity.stages.push({ title: '', status: 'OPEN', description: '', weight: 1, modules: [] });
        setEntity({ ...entity })
        
        setExpanded(entity.stages.length-1);
    }
    let onDeleteStage = (q: TrainingStage, indx: number) => {
        console.log("onDeleteStage", indx);
        entity.stages.splice(indx, 1);
        setEntity({ ...entity });
        //    submit('update');
        console.log("onDeleteStage training", entity);

    }
    let onSaveStage = (q: TrainingStage, indx: number) => {
        console.log("onSaveStage", q);
        entity.stages[indx] = q;
        setEntity({ ...entity });
        console.log("onSaveStage training", entity);
    }
    return <div className="b1c" >
        {!readonly && <div className="media-body actions-toolbar flex-pr">
            <a onClick={onAddStage} className="btn btn-purple">
                <i className="fa fa-plus-square"></i>
                <span>{t("Ajouter une étape")}</span>
            </a>
        </div>}
        {entity.stages.map((st, indx) => <TrainingStageBlock
        expandedStage={expanded}
        onExpandStage={(i)=>setExpanded(i)}
        key={indx} training={entity} onDeleteStage={onDeleteStage} onSaveStage={onSaveStage} stage={st} index={indx} readonly={readonly} ></TrainingStageBlock>)
        }

    </div>
}