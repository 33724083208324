import { useEffect, useState } from "react";
import { VictoryAxis, VictoryChart, VictoryCursorContainer, VictoryLabel, VictoryLine } from "victory";
import { t } from "../i18n"; 
import { searchAssessmentDefinition } from "../service/quizService";
import { KpiDefinition } from "../types/kpi-model";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { ChallengeConfig, evaluatePoints, TrainingContribution } from "./challenge-type";

export function ChallengeTrainingContribution(props: any) {
    let readonly = props.readonly;
    let [entity, setEntity] = [props.entity as ChallengeConfig, props.setEntity];
    let onDeleteContribution = (q: any, indx: number) => {
        console.log("onDeleteContribution", indx);
        entity.trainingContribution.splice(indx - 1, 1);
        setEntity({ ...entity });
        //    submit('update');
        console.log("onDeleteContribution quiz", entity);

    }
    let onSaveContribution = (q: any, indx: number) => {
        console.log("onSaveContribution", q);
        entity.trainingContribution[indx - 1] = q;
        setEntity({ ...entity });

        console.log("onSaveContribution quiz", entity);
        //  submit('update');

    }
    return <div>
        {!readonly && <div className="flex-pr">
            <span onClick={() => { entity.trainingContribution.push({   formulaType: 'LMM', maxPoints: 100, formulCoefs: [{ x: 0, y: 0 }, { x: 100, y: 100 }] }); setEntity({ ...entity }) }}
                className="tlb-btn tex-btn"><i className="fa fa-plus-square"></i> Ajouter un Quiz</span>
        </div>
        }
           <div className="app-form kpi-form" >
           <div className="form-g2c" > 
         <div className="form-group ">
             <label>{t("Accepter tous les Quiz")}</label>    
             <div onClick={() => setEntity({ ...entity, allowAllQuiz: !entity.allowAllQuiz })}
                        className={"az-toggle az-toggle-success " + (entity.allowAllQuiz ? 'on' : 'off')}><span></span>
            </div> 
         </div>
         
         <div className="form-group ">
             <label>{t("Point par question")}</label>    
             <input onChange={(e) => setEntity({ ...entity, allQuizPointByQuestion: parseInt(e.target.value)})}
                            value={entity.allQuizPointByQuestion}
                            type="number" className="form-control" placeholder={t("Nombre de points par question correcte")} />
                  
         </div>
         </div>

           </div>
        
        {entity.trainingContribution.map((q, id) => <TrainingContributionBlock
            onSave={onSaveContribution} onDelete={onDeleteContribution} key={id} indx={id + 1} contrib={q} readOnly={readonly}
            setEntity={setEntity} entity={entity} ></TrainingContributionBlock>)
        }
    </div>
}

function mapFormulaTypeLabel(tx: string): string {
    if (tx == 'LINEAR') { return t("Linéaire"); }
    if (tx == 'LMM') { return t("Linéaire avec Min/Max"); }
    if (tx == 'STEPS') { return t("Paliers"); }
    if (tx == 'LINEAR-STEPS') { return t("Linéaire par morceaux"); }
    return tx;
}
function mapFormulaInput(tx: string): string {
    if (tx == 'target') { return t("En fonction de l'objectif"); }
    if (tx == 'acheived') { return t("En fonction du réalisé"); }
    return tx;
}
export function TrainingContributionBlock(props) {

    let readOnly = props.readOnly;
    let tnp = props.tnp;
    let [contrib, setContribIn] = useState((props.contrib) as TrainingContribution);
    let [allIndicators, setAllIndicators] = useState([]);
    
    let [entity, setEntity] = [props.entity as ChallengeConfig, props.setEntity];
    let indx = props.indx;
    let setContrib=(x)=> {
        entity.trainingContribution[indx-1] = x;
        setContribIn(x);
        setEntity(entity); 
    }
    useEffect(() => {
        searchAssessmentDefinition({ first: 0, max: 100, criteria: {type: "QUIZ"} }).then(resp => {
            if (resp.result) {
                setAllIndicators(resp.result.map(
                    (d: KpiDefinition) => { return { unit: d.unit, name: d.title, reference: d.reference } }));
            }
        })
    }, ["onetime"]);

    let removeCoefBlock = (c, i) => {
        contrib.formulCoefs.splice(i, 1);
        console.log("removeCoefBlock", contrib.formulCoefs);
        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
    }
    let addCoefBlock = (c, i) => {
        let nc = { ...c };
        nc.x = c.x + 1;
        contrib.formulCoefs.splice(i + 1, 0, nc);
        console.log("addCoefBlock", contrib.formulCoefs);
        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
    }
    let postSelectFormulaType = () => {

        contrib.formulCoefs = [{ x: 0, y: 0 }, { x: 100, y: contrib.maxPoints }]


        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
        console.log("postSelectFormulaType", contrib);
    }
    return (
        <div className="question-block">
            <div className="app-form kpi-form" >

                <div className="question-block-header">
                    <h5>
                        <span>{indx} - </span>
                        <span> {contrib.training?.name}</span>
                    </h5>
                    <span>
                        <span> <i className="fa fa-trophy"></i>  {contrib.maxPoints} Pt{(contrib.maxPoints > 1) && "s"}</span>

                        {!readOnly && <>
                            <span onClick={() => props.onDelete(contrib, indx)} className="tlb-btn"><i className="fa fa-ban"></i></span>
                        </>
                        }
                    </span>
                </div>

                <div className="flex-c2">
                    <div className="form-group ">
                        <label>{t("Formation")}</label>
                        <DropdownList onSelect={e => setContrib({ ...contrib, training: { name: e.name, reference: e.reference } })}
                            data={allIndicators} value={contrib.training} className="flex-child-11" ></DropdownList>
                    </div>
                    <div className="form-group ">
                        <label>{t("Points")}</label>
                        <input onChange={e => {
                            contrib =({ ...contrib, maxPoints: parseInt(e.target.value) });
                            postSelectFormulaType();
                        }}
                            value={contrib.maxPoints}
                            type="number" className="form-control" placeholder={t("Nombre de points")} />
                    </div>
                    <div className="form-group ">
                        <label>{t("Bonus de participation ")}</label>
                        <input onChange={e => {
                            contrib =({ ...contrib, bonus: parseInt(e.target.value) });
                            postSelectFormulaType();
                        }}
                            value={contrib.bonus}
                            type="number" className="form-control" placeholder={t("Bonus en nombre de points")} />
                    </div>
                   
                    <div className="form-group ">
                        <label>{t("Type de formule")}</label>
                        <DropdownList onSelect={e => {
                            contrib = { ...contrib, formulaType: e.name };
                            postSelectFormulaType();
                        }}
                            data={['LINEAR', 'LMM', 'STEPS', 'LINEAR-STEPS'].map(w => { return { name: w, label: mapFormulaTypeLabel(w) } })} value={{ name: contrib.formulaType }} className="flex-child-11" ></DropdownList>
                    </div>
                </div>
                {contrib.training && <div className="coefficient-block">
                    <div className="coefficient-block-left" >
                     {contrib.formulCoefs.map((c, indx) => <div key={indx} className="coef-block">
                        <div className="form-group">
                            <label>{t("Seuil")}</label>
                            <div className="flex-container">
                                <input
                                    onChange={e => { c.x = parseFloat(e.target.value); setContrib({ ...contrib }) }}
                                    value={c.x || 0} step="1" type="number" className="form-control" />
                                <span className="unit-badge"> {t("Points")}</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>{t("Valeur")}</label>
                            <div className="flex-container">
                                <input
                                    onChange={e => { c.y = parseFloat(e.target.value); setContrib({ ...contrib }) }}
                                    value={c.y || 0} step="1" type="number" className="form-control" />
                                <span className="unit-badge">{t("Points")}</span>
                            </div>
                        </div>
                        <div className="form-btn">
                            <label>&nbsp;</label>
                            {(contrib.formulaType == 'STEPS' || contrib.formulaType == 'LINEAR-STEPS') && <div className="flex-pr">
                                <span className="tlb-btn"><i onClick={() => removeCoefBlock(c, indx)} className="fa fa-ban"></i></span>

                                <span className="tlb-btn"><i onClick={() => addCoefBlock(c, indx)} className="fa fa-plus-square"></i></span>

                            </div>}
                        </div>

                    </div>)}

                    </div>
                    <GraphFunction contrib={contrib} ></GraphFunction>

                </div>
                }
            </div>
        </div>
    )
}

function GraphFunction({ contrib }: { contrib: TrainingContribution }) {
    let data: { x, y }[] = [{ x: 0, y: 0 }];
    let pre = { x: 0, y: 0 }
    for (let c of contrib.formulCoefs) {
        let x = Math.max(pre.x, c.x || pre.x);
        let y = Math.max(pre.y, c.y || pre.y);
        if (contrib.formulaType == 'STEPS') {
            data.push({ x: x, y: pre.y })
        }
        data.push({ x, y });
        pre = { x, y }
    }
    if (contrib.formulaType == 'STEPS') {
        data.push({ x: pre.x + 10, y: pre.y })
    } 
    return <div className="graph-function-box">
<VictoryChart  
    containerComponent={    
        <VictoryCursorContainer cursorDimension="x" cursorLabel={({datum}:any)=>{
            console.log(">>>", datum)
          return  `${Math.round(datum.x)} Points ,${Math.round(evaluatePoints(datum.x, contrib))} Points` 
     
        }}   />    }
  >
        <VictoryAxis   label={t("Score")} />
        <VictoryAxis dependentAxis    axisLabelComponent={<VictoryLabel dy={-12} />} label="Nombre de points" />
        <VictoryLine
              style={{
                data: { stroke: "#c43a31" },
                parent: { border: "1px solid #ccc" }
            }}
            data={data}
        />
    </VictoryChart>
    </div>  
}


