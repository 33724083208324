import { useState } from "react";

import './ui.css';


export function TabsBlock({tabs, onSelect, defSelected}) {
   let  [selected, setSelected] =  useState(defSelected || tabs[0].name);
    return <div className="tabs-block" >
        {tabs.map((tab: any, indx )=>   <div  key={indx} onClick={()=>{onSelect(tab.name); setSelected(tab.name)}}
             className={ "tab " +(selected==tab.name?"selected":"") }>{tab.label}</div>
        )}

    </div> 
}