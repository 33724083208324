import { group } from 'console';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill'; 
import { ChallengeTop10 } from '../challenge/challenges-result';
import { t } from '../i18n';
import KpiBoxA from '../perf-indicators/KpiBoxA';
import KpiBoxGauge from '../perf-indicators/KpiBoxGauge';
import KpiCardTable from '../perf-indicators/KpiCardTable';
import KpiChartBarsVertical from '../perf-indicators/KpiChartBarsVertical';
import { KpiTrend } from '../perf-indicators/KpiTrend';
import { uploadFile } from '../service/file.service';
import { post } from '../service/http.service';

import { addPost, updatePost } from '../service/postService';
import { currentUser, searchGroups } from '../service/userService';
import { Entity } from '../test-data/entities';
import { MultItemField } from '../ui/mutli-item-field';
import { Post } from './data';
import { quillFormats, quillModules } from './text-editor';
import { SharedFeed } from './time-line';


function ChannelSelect(props: any) {
    let [request, setRequest] = useState({});

    let selectedGroups = props.channels;

    return (
        <div className="channel-select">
           
            <MultItemField setData={props.setChannels} data={selectedGroups} items={currentUser().groups} noDataMessage={t("Spécifiez les cannaux de diffusion de ce post")} mode='edit'  ></MultItemField>

        </div>
    )

}
export function PostEdition(props: any) {
    let [postPlainText, setPostPlainText] = useState("");
    let sharing = props.sharing;
    let sharedPost: Post = props.post;
    let reedit = props.reedit;
    
     const inputPhotoFileRef = useRef(null);
     const inputVideoFileRef = useRef(null);
     const inputAttachFileRef = useRef(null);
    let [vstt, setVstt] = useState(props.vstt || {});
    let [photoLink, setPhotoLink] = useState(reedit ? sharedPost.photo : '');
    let [videoLink, setVideoLink] = useState(reedit ? sharedPost.video : '');
    let [kpi, setKpi] = useState(reedit ? sharedPost.kpi : props.kpi);
    let [challengeTop10, setChallengeTop10]= useState(reedit ? sharedPost.challengeTop10 : props.challengeTop10);
    console.log("post-edition, kpi "+reedit, kpi, sharedPost )
    let [attachments, setAttachments] = useState([] as any[]);
    let [postText, setPostText] = useState(reedit ? sharedPost.text : '');
    
    let [channels, setChannels] = useState(reedit ? sharedPost.channels : [] as any[]);
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState('');
    useEffect(()=>{
        if(props.vstt && props.vstt.dndFile) {
            let evt = {target:{files:[props.vstt.dndFile]}}
            console.log("onPhotoChange dndFile", evt) 
            if(props.vstt.dndFile.type.startsWith("image")) { 
                onPhotoChange(evt) ;
            } else if (props.vstt.dndFile.type.startsWith("video")) { 
                onVideoChange(evt) ;
            } else {
                onAttachChange(evt);
            }
        }
    }, [ props.vstt  ]);
    let onClose = props.onClose;
    let closeEdition = (evt: any) => {
        onClose(evt);
    }
    let onPhotoChange = (evt: any) => {
        setLoading(true);
        uploadFile(evt).then((link: any) => {
            setPhotoLink(link);
            setLoading(false);
        }).catch(e => { setLoading(false); });
    }
    let onVideoChange = (evt: any) => {
        setLoading(true);
        uploadFile(evt).then((link: any) => {
            setVideoLink(link);
            setLoading(false);
        }).catch(e => { setLoading(false); });
    }
    let onAttachChange = (evt: any) => {
        let file = evt.target.files[0];
        setLoading(true);
        uploadFile(evt).then((link: any) => {
            setLoading(false);
            setAttachments([...attachments, { link: link, name: file.name }]);
        }).catch(e => { setLoading(false); });
    }
    let handleTextChange = (content: any, delta: any, source: any, editor: any) => {
        console.log("handleTextChange", content);
        postPlainText = editor.getText(content);
        //setPostPlainText(postPlainText); 
        setPostText(content);
    }

    let submit = () => {
        setError("");
        let cu = currentUser();
        if (!postText && !videoLink && !photoLink && !sharing) {
            setError("Quel contenu souhaitez-vous partager? un texte, une image, une vidéo...")
            return;
        }
        if (!channels || !channels.length) {
            setError("Avec quel groupe souhaitez-vous partager?")
            return;
        }
        let post: Post = {
            contentType: photoLink ? 'photo' : videoLink ? 'video' : 'text',
            actor: { name: cu.fullName || '', email: cu.email || '' },
            commentsCount: 0,
            reshareCount: 0,
            likesCount: 0,
            channels: channels.map(mapChannel),
            
            textIndex: postPlainText.replace(/\s+/g, ' ').trim().toLowerCase(),
            text: ''
        };
        console.log("textIndex="+post.textIndex, postPlainText);
        if (sharing) {
            console.log('sharing post ', challengeTop10);
            let sharedContentData = JSON.parse(sharedPost.contentData || "{}");
            if (photoLink) {
                sharedContentData.photoLink = photoLink;
            }
            
            if (videoLink) {
                sharedContentData.videoLink = videoLink;
            }
            if (attachments) {
                sharedContentData.attachments = attachments;
            }
            if (kpi) {
                sharedContentData.kpiData = kpi;
            }
            if (challengeTop10) {
                sharedContentData.challengeTop10 = challengeTop10;
            }
            sharedContentData.sharingHtml = postText;
            post = {
                ...post, ...sharedPost, 
                feedReason: " a partagé ceci",
                feeder: { name: cu.fullName || '', email: cu.email || '' }, 
                channels: channels.map(mapChannel), 
            };
            post.contentData = JSON.stringify(sharedContentData);
        } else {
            console.log("CDJ", challengeTop10);
            post.initialChannels =  [...post.channels||[]]; 
            let cdj: any = { textHtml: postText, photoLink, videoLink, attachments , kpiData:kpi};
            if (kpi) {
                cdj.kpiData = kpi;
            }
            
            if (challengeTop10) {
                cdj.challengeTop10 = challengeTop10;
            }
            post.contentData = JSON.stringify(cdj);

        }
        if (reedit) {

            post = { ...sharedPost, ...post    };
            updatePost(post, "UPDATE").then(r => {
                console.log({ r });
                closeEdition(post);
            },
                e => console.log("ERROR", e))
        } else {

            addPost(post).then(
                r => {
                    console.log({ r });
                    closeEdition(post);
                },
                e => console.log("ERROR", e)
            )
        }
    }
    useEffect(()=>{
        if(props.vstt && props.vstt.dndFile) {
            return;
        }
        if(props.vstt && props.vstt.addPhoto) {
            if( inputPhotoFileRef.current ) {
                ( inputPhotoFileRef.current as any).click();
            }
        } else  if(props.vstt && props.vstt.addVideo) {
            if( inputVideoFileRef.current ) {
                ( inputVideoFileRef.current as any).click();
            }
        }  if(props.vstt && props.vstt.addDocument) {
            if( inputAttachFileRef.current ) {
                ( inputAttachFileRef.current as any).click();
            }
        } 
    }, [props.vstt])
    return (<div className="pe-outer">
        <div className="pe-inner">
            <i className="pe-close-btn fa fa-times" onClick={closeEdition}></i>
            <div className="pe-header">
                <h4>{sharing ? t("Partager le post") :  t("Nouveau post")}</h4>
            </div>
            <div className="pe-body" id="quill-ex">
                <ReactQuill
                    bounds="#quill-ex"
                    modules={quillModules}
                    formats={quillFormats}
                    value={postText || ''} placeholder={t("Que souhaitez-vous partager?")}
                    onChange={handleTextChange} />
                {sharing && <SharedFeed embedded post={sharedPost}></SharedFeed>}
                {loading && <div className="loading">  <i className="fas fa-spinner fa-spin"></i></div>}
                {kpi && kpi.kpiRenderer == 'KpiBoxGauge' && <KpiBoxGauge embedded={true} indicatorData={kpi}></KpiBoxGauge>}
                {kpi && kpi.kpiRenderer == 'KpiBoxA' && <KpiBoxA embedded={true} indicatorData={kpi}></KpiBoxA>}
                {kpi && kpi.kpiRenderer == 'KpiTrend' && <KpiTrend embedded={true} kpi={kpi}></KpiTrend>}
                {kpi && kpi.kpiRenderer == 'KpiChartBarsVertical' && <KpiChartBarsVertical embedded={true} kpi={kpi}></KpiChartBarsVertical>}
                {kpi && kpi.kpiRenderer == 'KpiCardTable' && <KpiCardTable embedded={true} kpi={kpi}></KpiCardTable>}

                {challengeTop10 &&   <ChallengeTop10 embedded={true} top10={challengeTop10}></ChallengeTop10>}



                {photoLink && <div className="photo-container">
                    <i onClick={() => setPhotoLink('')} className="remove-btn fa fa-trash"></i>
                    <img src={photoLink} />
                </div>}
                {videoLink && <video className="feed-embedded-video" controls={true} autoPlay={true} muted >
                    <source src={videoLink} type="video/mp4" />  </video>}
                {attachments && attachments.length > 0 &&
                    attachments.map(a => {
                        return <div className="attachment-block" >
                            <a href={a.link}><i className="typcn typcn-attachment-outline"></i> <span>{a.name}</span></a>
                            <i onClick={() => setAttachments(attachments.filter(x => x != a))} className="remove-btn fa fa-trash"></i> </div>
                    })}
            </div>

            <input ref={inputPhotoFileRef} onChange={onPhotoChange} type="file" id="photoFile" className="h-file-input" accept="image/*"></input>
            <input ref={inputVideoFileRef} onChange={onVideoChange} type="file" id="videoFile" className="h-file-input" accept="video/*"></input>
            <input  ref={inputAttachFileRef} onChange={onAttachChange} type="file" id="attachFile" className="h-file-input" accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"></input>
            <div className="pe-footer">
                <ChannelSelect setChannels={(x) => { setChannels(x) }} channels={channels} onSelectChannel={() => { }} ></ChannelSelect>
                {error && <div className="post-error-message">{error}</div>}
                <div className="share-box-feed-entry-toolbar display-flex ">
                    <label htmlFor="photoFile" tabIndex={-1} className="share-box-feed-entry-toolbar-block" onClick={() => setVstt({ ...vstt, addPhoto: true })}>
                        <i className="typcn typcn-image-outline" />
                        <span  >{t("Photo")}</span> </label>
                    <label htmlFor="videoFile" tabIndex={-1} className="share-box-feed-entry-toolbar-block" onClick={() => setVstt({ ...vstt, addVideo: true })}>
                        <i className="typcn typcn-video-outline" />
                        <span  >
                            {t("Vidéo")}
                        </span> </label>

                    <label htmlFor="attachFile" tabIndex={-1} className="share-box-feed-entry-toolbar-block" onClick={() => setVstt({ ...vstt, addDocument: true })}>
                        <i className="typcn typcn-attachment-outline" />
                        <span  >
                            {t("Document")}
                        </span> </label>
         
                    <span tabIndex={-1} className="share-box-feed-entry-toolbar-block post" onClick={submit}>
                        <i className="typcn typcn-arrow-forward" />
                        <span  >
                            {t("Poster")}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>)
}

function mapChannel (x) { return { name: x.value || x.name, title: x.label || x.title, label: x.label || x.title } }