

export async function  uploadFile (evt:any, asFileId:boolean=false) {
    let file =evt.target.files[0]
    console.log("uploadFile", file);
    let formData = new FormData();           
    formData.set("file",  file);         
    formData.set("app",  "perfani-web-post");
    let r = await fetch('/api/filestore/files', {
        method: "POST",  
         headers: { 
            'Accept': 'application/json',
        },
        body: formData
        });
    let p =  await r.json(); 
    console.log("upload response ",r);
    if(p.status==='success') {
        return  asFileId? p.identifier : "/api/filestore/files/"+p.identifier
       
    } else {
        return null;
    }
}
