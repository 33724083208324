
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { isEntityName } from 'typescript';
import { printShortDate } from '../help/dates';
import { verifyQuestionResponse } from '../help/question';
import { t } from '../i18n';
import { getQuizzsMenu, persistQuiz, updateAssessmentInstance } from '../service/quizService';
import { ASSESSMENTS_DATA, QUIZZES_DATA } from '../test-data/assessments-data';
import { Popup } from '../ui/popup';
import StopWatch from '../ui/stop-watch';
import { AssessmentInstance, Option, Question } from './assessment-type';
import './assessment.css';


function ResponseOption(props: any) {
    let [o, setO] = useState( props.option as  Option);
    let correct: boolean = props.correct;
    let wrong: boolean = props.wrong;
    let question: Question = props.question as Question;
    let type = question.type;
    let [checked, setCheked] = useState(o.checked);
    let onCheck =() => { 
        if(props.readOnly) return;
        setCheked(!checked); o.checked = !o.checked;
        if (question.type == 'QCU') {
            question.options?.forEach((qo, qoi) => {
                if (qo.key != o.key) {
                    qo.checked = false;
                }
            });
            props.setQuestion({...question});
        }
    }
    useEffect(()=>{setO(props.option)}, [props.option]);
    return (<li   onClick={onCheck} id={o.key} className="assmt-option"  >
        <i className={o.checked ? "far fa-check-square" : "far fa-square"}></i>
        <span>{o.text}</span>
        {correct && o.checked && <i className="correct-option fas fa-thumbs-up"> </i>}
        {correct && !o.checked && <i className="correct-option fa fa-arrow-left"> </i>}
        {wrong && o.checked && <i className="error-option fa fa-exclamation-triangle"> </i>}

    </li>)
}
function QuestionBody(props: any) {
    let ratingOuter = useRef(null);
    let verify = props.verify;
    let [question, setQuestion] = useState(props.question as Question);
    let ad: AssessmentInstance = props.assessmentData;
    let qkey = props.qkey;
    let currentQuestionIndex: number = props.currentQuestionIndex;
    console.log("DBGX QuestionBody " + currentQuestionIndex + " question=", question);
    let onRatingClick = (evt) => {
        
        if (props.readOnly ||  !question.max) {
            return;
        }
        console.log("onRatingClick", evt);
        console.log("(ratingOuter.current as any) =", (ratingOuter.current as any))
        let el = (ratingOuter.current as any);
        console.log(" getBoundingClientRect", el.getBoundingClientRect());
        console.log(" offsetLeft", el.offsetLeft);
        let w = el.getBoundingClientRect().width;
        let d = evt.clientX - el.getBoundingClientRect().x;
        question.min = question.min || 0;
        let v = question.min + Math.round(((question.max - question.min) * d / w) / (question.increment || 1)) * (question.increment || 1);
        console.log("onRatingClick v=" + v + "; d=" + d + " w=" + w + " question=", question)
        setQuestion({ ...question, response: { ...question.response, text: "" + (v) } });


    }
    useEffect(() => { setQuestion(props.question) }, [props.question])
    return (<div className="assmt-quesion-body" >

        <h5>{question.question}</h5>
        <p>{question.contextDescription}</p>
        {(question.type == 'QCM' || question.type == 'QCU') &&
            <ul>
                {
                    question.options?.map((o, ii) => {
                        o.key = qkey + "_" + ii;
                        return <ResponseOption verify={verify} key={o.key} option={o}
                            correct={verify && question.correctAnswer?.optionId?.includes(ii)}
                            wrong={verify && !question.correctAnswer?.optionId?.includes(ii)}
                            setQuestion={setQuestion}
                            readOnly={props.readOnly}
                            question={question} currentQuestionIndex={currentQuestionIndex}></ResponseOption>})
                }
            </ul>
        }
        {question.type == 'RATING' && <div className="rating-block">
            <div className="rating-min">{question.min}</div>
            <div ref={ratingOuter} className="rating-outer" onClick={(evt) => { onRatingClick(evt) }}>
                <div className="rating-inner" style={{ width: mapLeftShiftPC(question) }}></div>
                <div className="rating-cursor" style={{ left: mapLeftShiftPC(question) }} ></div>
               {question.response?.text&&  <div className="rating-value" style={{ left: mapLeftShiftPC(question) }} >{question.response?.text}</div> }
            </div>
            <div className="rating-max">{question.max}</div>
        </div>}
        {question.type == 'FREE_TEXT' && <div className="assmt-freetext">
            <textarea rows={4} value={question.response?.text} readOnly={props.readOnly} onChange={(evt) => {
                question.response = { id: -1, key: "x", checked: true, text: evt.target.value };
            }} >

            </textarea>
        </div>}
        {verify && question.correctAnswer && question.correctAnswer.comment && <ul>
            <div className="answer-comment" >  {question.correctAnswer.comment}    </div>
        </ul>
        }
    </div>);
}
function PreviousBtn(props: any) {
    let currentQuestionIndex: number = props.currentQuestionIndex;
    let showPrevious = currentQuestionIndex > 0;
    console.log("DBGX PreviousBtn " + currentQuestionIndex);
    return (<div className="assmt-nav-btn assmt-nav-btn-previous " >
        {showPrevious && <button onClick={() => props.setCurrentQuestionIndex(currentQuestionIndex - 1)} >Précédent</button>}
    </div>);
}
function NextBtn(props: any) {
    let totalQuestionNumber: number = props.totalQuestionNumber;
    let currentQuestionIndex: number = props.currentQuestionIndex;
    let showNext: boolean = currentQuestionIndex < totalQuestionNumber - 1;


    console.log("DBGX NextBtn=" + currentQuestionIndex, totalQuestionNumber);
    return (<div className={"assmt-nav-btn assmt-nav-btn-" + (showNext ? "next" : "finish")}>
        <button onClick={() => props.setCurrentQuestionIndex(currentQuestionIndex + 1)} >
            {showNext ? "Suivant" : "Terminer"}
        </button>
    </div>);
}

function Progress(props: any) {
    let currentQuestionIndex: number = props.currentQuestionIndex;
    let ad: AssessmentInstance = props.assessmentData;
    let totalWeight = ad.definition.questions.map(d => d.weight).reduce((a, c) => c + a, 0);
    let cummul = ad.definition.questions.filter((e, i) => i <= currentQuestionIndex).map(d => d.weight).reduce((a, c) => c + a, 0);
    let percent = totalWeight ? Math.round(100 * cummul / totalWeight) : 0;
    return (<div className="assmt-progress">
        <div className="assmt-progress-outer">

            <div className="assmt-progress-lbl">{percent}%</div>
            <div className="assmt-progress-middle">
                <div className="assmt-progress-inner" style={{ height: percent + "%" }}  >
                </div>
            </div>
        </div>

    </div>)
}
function Assessment(props: any) {
    let { id: assessmentId } = useParams() as any;
    let history = useHistory();
    let [ai, setAi] = useState({ definition: { questions: [{}] } } as AssessmentInstance);
    let [showClockDown, setShowClockDown] = useState(false);


    let [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    let [verify, setVerify] = useState(false);
    let [qn, setQn] = useState(1);
    let startQuiz = (d) => {
        if (d == "go") {
            currentQuestionIndex = 0;
            setCurrentQuestionIndex(0);
            if (!ai.id) {
                ai.remaining = (ai.definition.durationMin || 0) * 60 * 1000;
                ai.startTime = new Date();
            }
            ai.instanceStatus = 'STARTED';

            if (ai.endTime) {
                ai.endTime = undefined;
                ai.startTime = undefined;
            }
            if (!ai.startTime) {
                ai.startTime = new Date();

            }

            updateAssessmentInstance(ai, "start").then(r => {
                if (r) {
                    setAi(r);
                    if (ai.definition.timeLimitation) {
                        setShowClockDown(true);
                    }
                }
            });
            persistQuiz(ai);
        } else {
            history.push("/quizzes-list")
        }
    }
    useEffect(() => {
        setShowClockDown(false);
        ai = assessmentId.startsWith("quiz-") ? getQuizzsMenu().filter(q => q.definition.reference == assessmentId.replace('quiz-', ''))[0]
            :
            ASSESSMENTS_DATA[parseInt(assessmentId)];
        qn = ai.definition.questions.length;
        setQn(qn);
        setCurrentQuestionIndex(-1);
        setAi(ai);
        if (ai.instanceStatus == 'CLOSED' || ai.instanceStatus == 'CANCELED') {
            setCurrentQuestionIndex(-2);
        }
    }, [assessmentId]);


    let resetAllResponse =() => {
        ai.definition.questions.forEach(q=> {
            q.options?.forEach(o=>{
                o.checked=false; 
                if(q.type === "FREE_TEXT" || q.type === "RATING") {
                    o.text="";
                }
            })
        })
    }
    let postAnswerJob = () => {
        recomputeScore(ai);
        recomputeDuration(ai);
        ai.cleared = (ai.score || 0) >= (ai.definition.passingScore || 0);
        updateAssessmentInstance(ai, ai.instanceStatus == 'CLOSED' ? "done" : "on-going").then(
            r => {
                console.log("update", r);
            }
        );
        persistQuiz(ai);
    }
    let onSetNext = (qindex: number, forward: boolean) => {
        if (forward) {
            setVerify(false)
        }
        if (!ai.stepIndex || (qindex > ai.stepIndex)) {
            ai.stepIndex = qindex;
            ai.acheivedQuestionNumber = qindex;
        }
        currentQuestionIndex = qindex;
        setCurrentQuestionIndex(currentQuestionIndex);
        if (qn == qindex) {
            setShowClockDown(false);
            ai.endTime = new Date();
            ai.instanceStatus = "CLOSED";
        }
        postAnswerJob();

    }
    let onEndTime = (r: number) => {
        ai.remaining = r;
        if (ai.definition.timeLimitation) {
            ai.instanceStatus = "CLOSED";
            postAnswerJob();
        }
    }

    let onPauseTimer = (r: number) => {
        ai.remaining = r;
        if (ai.definition.timeLimitation) {
            ai.instanceStatus = "CLOSED";
            postAnswerJob();
        }
    }


    console.log("DBGX Assessment   currentQuestionIndex = " + currentQuestionIndex)
    return (
        <div className="assessment" >
            <div className="assmt-header">
                <h3 className="assmt-title">{ai.definition.title}</h3>
                <div className="assmt-dates az-content-header-right" >
                    <div className="media">
                        <div className="media-body">
                            <label>{t("Publication")}</label>
                            <h6>{printShortDate(ai.definition.publicationDate)}</h6>
                        </div>
                    </div>
                    {ai.definition.closingDate && <div className="media">
                        <div className="media-body">
                            <label>{t("Clôture")}</label>
                            <h6>{printShortDate(ai.definition.closingDate)}</h6>
                        </div>
                    </div>
                    }
                    {ai.startTime && <div className="media">
                        <div className="media-body">
                            <label>{t("Fait le")}</label>
                            <h6>{printShortDate(ai.startTime)}</h6>
                        </div>
                    </div>
                    }
                    {showClockDown && ai.definition.durationMin &&
                        <StopWatch onEnd={onEndTime} onPause={onPauseTimer} duration={ai.remaining}  ></StopWatch>}
                </div>
            </div>
            <div className="assmt-description">{ai.definition.description}</div>
            {currentQuestionIndex == -2 && <ResultPage assessment={ai} onReTake={() => {resetAllResponse() ; setCurrentQuestionIndex(-1)}}  ></ResultPage>}
            {currentQuestionIndex == -1 && <StartingPage assessment={ai} onStart={(d) => startQuiz(d)}   ></StartingPage>}
            {currentQuestionIndex == qn && <ConclustionPage assessment={ai} onShowResult={() => setCurrentQuestionIndex(-2)} ></ConclustionPage>}
            {currentQuestionIndex < qn && currentQuestionIndex >= 0 && <div className="assmt-scene">
                <div className="assmt-question">
                    <Progress currentQuestionIndex={currentQuestionIndex} assessmentData={ai}   ></Progress>
                    <QuestionBody qkey={currentQuestionIndex} verify={verify} question={ai.definition.questions[currentQuestionIndex]}
                        currentQuestionIndex={currentQuestionIndex} assessmentData={ai} ></QuestionBody>

                </div>
                <div className="assmt-nav">
                    <PreviousBtn setCurrentQuestionIndex={(x: any) => { onSetNext(x, false); }} totalQuestionNumber={qn} currentQuestionIndex={currentQuestionIndex} ></PreviousBtn>

                    <div className="row flex-row">
                        {ai.definition.type == "QUIZ" && ai.definition.canReview &&
                            <div className="assmt-nav-btn assmt-nav-btn-verify">
                                <button onClick={() => setVerify(true)} >
                                    Vérifier
                                </button>
                            </div>
                        }
                        <NextBtn setCurrentQuestionIndex={(x: any) => { onSetNext(x, true) }} totalQuestionNumber={qn} currentQuestionIndex={currentQuestionIndex} ></NextBtn>
                    </div>
                </div>


            </div>
            }

        </div>);
}

function ConclustionPage(props: any) {
    let ai: AssessmentInstance = props.assessment;
    return <div className="assmt-bravo-outer">
        {ai.definition.conclusion && <div className="ass-intro"
            dangerouslySetInnerHTML={{ __html: ai.definition.conclusion }} ></div>}


        {ai.definition.felicitation && ai.cleared && <div className="ass-intro"
            dangerouslySetInnerHTML={{ __html: ai.definition.felicitation }} ></div>}

        {ai.definition.hardluck && !ai.cleared && <div className="ass-intro"
            dangerouslySetInnerHTML={{ __html: ai.definition.hardluck }} ></div>}

       {ai.definition.type != 'POLLS' &&  <InstanceInfo assessment={ai}>
            <div className="assessment-info-cell">
                <div onClick={() => props.onShowResult()} className="btn" > <i className="fa fa-list"></i>
                    Détails des réponses </div>
            </div>
        </InstanceInfo>}
    </div>
}
function StartingPage(props: any) {
    let ai: AssessmentInstance = props.assessment;
    let quizTypeLabel = ai.definition.type == 'POLLS' ? "le sondage" : "le test";
    let goLabel = "Démarrer " + quizTypeLabel;
    let noGoLabel = "Plus tard";
    if (ai.instanceStatus == 'STARTED') {
        goLabel = "Reprendre " + quizTypeLabel;
        noGoLabel = "Annuler";
    }
    if (ai.instanceStatus == 'CANCELED') {
        goLabel = "Reprendre " + quizTypeLabel;
        noGoLabel = "Plus tard";
    }

    return (<div className="assessment-start-page">
        {(ai.definition.visualId || ai.definition.introduction) && <div className="ass-intro flex-sb"  >
            {ai.definition.visualId && <div className="ass-intro-img"  >
                <img src={ai.definition.visualId} ></img>
            </div>}
            {ai.definition.introduction && <div className="ass-intro-text"
                dangerouslySetInnerHTML={{ __html: ai.definition.introduction }} ></div>}
        </div>
        }

        <InstanceInfo assessment={ai}>
            <div className="assessment-info-cell">
                <div onClick={() => props.onStart("go")} className="btn" > <i className="fa fa-play"></i>  {goLabel} </div>
            </div>
        </InstanceInfo>
    </div>);
}


function InstanceInfo(props) {
    let ai: AssessmentInstance = props.assessment;

    return (<div className="assessment-info-resume">
        <div className="assessment-info-cell">
            <div className="ass-det" ><label>{t("Nombre de question")}</label> <strong>{ai.definition.questions.length}</strong> </div>
            {ai.acheivedQuestionNumber && <div className="ass-det" ><label>{t("Nombre de réponses")}</label> <strong>{ai.acheivedQuestionNumber}</strong> </div>}

        </div>
        <div className="assessment-info-cell">
            <div className="ass-det" >
                <label>{t("Durée")}</label> <strong>{ai.definition.durationMin} min</strong> </div>
            {((ai.durationMin || 0) > 0) && <div className="ass-det" ><label>{t("Votre temps")}</label> <strong>{ai.durationMin} min</strong> </div>}

        </div>
        <div className="assessment-info-cell">
            {ai.definition.passingScore ?
             <div className="ass-det" ><label>{t("Passing score")}</label> <strong>{ai.definition.passingScore}</strong> </div> 
            : ""}
            {ai.score != undefined && <div className="ass-det" ><label>{t("Votre Score")}</label> <strong>{ai.score}</strong> </div>}

        </div>
        {props.children}
    </div>);
}

function ResultPage(props) {
    let ai: AssessmentInstance = props.assessment;
    let [review, setReview] = useState(null as any);
    let asResponse = (q: Question) => {
        if (q.type == "FREE_TEXT" || q.type == "RATING") {
            return q.response?.text;
        }
        return q.options?.filter(x => x.checked).map(o => <div><strong>{o.id}</strong> - <span>{o.text}</span></div>)
    }
    return (<div className="as-result-page">

        <InstanceInfo assessment={ai}>
            {ai.definition.canRetry && <div className="assessment-info-cell">
                <div onClick={() => props.onReTake("retake")} className="btn" > <i className="fa fa-redo"></i>  Repasser </div>
            </div>
            }
        </InstanceInfo>
        <div className="ass-result-tab">

            <table className="table ">
                <thead>
                    <tr>
                        <th className="wd-45p pl">{t("#")}</th>
                        <th className="wd-45p pl">{t("Question")}</th>
                        {ai.definition.type == 'QUIZ' && <th className="wd-45p pl">{t("Points ")}</th>}
                        <th className="wd-45p pl">{t("Réponse")}</th>
                        <th className="wd-45p pl">{t("Statut")}</th>
                        <th className="wd-45p pl">{t("Review")}</th>
                    </tr>
                </thead>
                <tbody>
                    {ai.definition.questions.map((d: Question, indx) => {
                        d.id = indx;
                        return (
                            <tr key={indx} className="ind-row">
                                <td className="pl"  ><strong> {indx + 1} </strong></td>
                                <td className="pl"  >{d.question} </td>
                                {ai.definition.type == 'QUIZ' && <td className="pl"  >{d.weight} </td>}
                                <td className="pl"  >{asResponse(d)} </td>
                                <td className="pc"  >{verifyQuestionResponse(d) ?
                                    <i className="ok fa fa-check"></i> :
                                    <i className="ko fa fa-ban" ></i>}
                                </td>
                                <td className="pc" >
                                    <i onClick={() => setReview(d)} className="review fa fa-eye"></i>
                                </td>
                            </tr>)
                    })
                    }
                </tbody>

            </table>
        </div>
        {review && <Popup onClose={() => setReview(null)} header={"Question : " + review.question}>
            <QuestionBody qkey={review.id} question={review} verify={true} readOnly={true} ></QuestionBody>
        </Popup>}
    </div>)
}
export default Assessment;

function recomputeScore(ai: AssessmentInstance) {
    let ret = 0;
    for (let q of ai.definition.questions) {
        if (!q.weight) { continue; }
        if (verifyQuestionResponse(q)) {
            ret += q.weight;
        }

    }
    ai.score = ret;

    return ret;
}

function recomputeDuration(ai: AssessmentInstance) {
    if (!ai.startTime) {
        ai.durationMin = 0;
        return 0;
    }
    let now = ai.endTime ? new Date(ai.endTime).getTime() : new Date().getTime();
    let start = new Date(ai.startTime).getTime();
    ai.durationMin = Math.round((now - start) / (60000));
}

function mapLeftShiftPC(question: Question): string {
    if (!question || !question.response || !question.response.text) {
        return "0%";
    }
    if (question.min == null || !question.max || question.max == question.min) {
        return "0%";
    }
    let i = Math.abs((parseFloat(question.response?.text) - question.min )/ (question.max - question.min));
    return Math.min(100, Math.round(i * 100)) + "%";
}

