import { useParams } from 'react-router';
import './training.css';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { printShortDate } from '../help/dates';  
import { Pagination } from '../ui/pagination/search-paging';
import '../admin/admin.css';  
import { TrainingDefinition, TrainingInstance } from './training-type';
import { searchTrainingDefinition, searchTrainingInstance } from '../service/trainingService';
import { t } from '../i18n';

export function TrainingDefinitionResults (props:any) {
      
    let entity:TrainingDefinition = props.entity;
    let tnp :boolean =  props.tnp;
    let [request, setRequest] = useState({first:0 , max:100, criteria: {trainingReference: entity.reference},  time: new Date() });
    let [data, setData] = useState( [] as TrainingInstance[] ) ;
    let [pagination, setPagination] = useState({first:0, pageSize:100, total:0});
    useEffect(()=> {
      if(entity.reference){
      searchTrainingInstance(request).then(resp=> {
             console.log("searchTrainingDefinition response", resp);
            if(resp && resp.result) {
                setData(resp.result);
                setPagination({first:request.first, pageSize:request.max, total: resp.total})
            }
        }) 
      }
    }, [request] );
    let reload =(p:number) => { 
        setRequest({...request, time: new Date(), first: p });
    } 
     
    return (
        <div className="app-form">
             <div className="ass-def-result">
            <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr> 
                      <th className="wd-45p pl">{t("Collaborateur")}</th>
                      <th className="wd-45p pl">{t("Score")}</th> 
                      <th className="wd-45p pl">{t("Début")}</th> 
                      <th className="wd-45p pl">{t("Fin")}</th> 
                      <th className="wd-45p pl">{t("Temps (min)")}</th>
                      <th className="wd-45p pl">{t("Nombre de Réponse ")}</th>
                      
                      {tnp && <th className="wd-45p pl">{t("Statut ")}</th>}
                      {tnp && <th className="wd-45p pl">{t("Action")}</th> }
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((d: TrainingInstance) => {
                      return(
                    <tr className="ind-row" key={d.reference}> 
                    <td className="pl"  ><strong> {d.trainee?.name} </strong></td>
                    <td className="pl"  >{d.score } </td> 
                    <td className="pl"  >{printShortDate(d.startTime )} </td> 
                    <td className="pl"  >{printShortDate(d.endTime )} </td>  
                    <td  className="pc" > {d.durationMin} </td>
                    <td  className="pc" > <strong>{d.acheivedStagesNumber }</strong> </td>
                    {tnp && <td  className="pc" > <i className={d.cleared? "ok fa fa-chek": "ko fa fa-ban"}></i> </td>}
                     {tnp &&<td className="action-btn">
                        <Link to={"/training/definition/"+d.reference+"/display"} >
                        <i className="fa fa-eye"></i>
                        </Link>
                        
                    </td>}
                 
                  </tr>);

                    })}
                    
                  </tbody>
                </table>
              </div>  
              { pagination.total>0 && 
                <Pagination reload={reload}  first={pagination.first} pageSize={pagination.pageSize} total={pagination.total} ></Pagination>
                }
            </div>


        </div>
    );
}