import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { printShortDate } from '../help/dates';
import { t } from '../i18n';
import { searchUsers } from '../service/userService';
import { Pagination } from '../ui/pagination/search-paging';
import './admin.css';
export function SearchUsers(props: any) {
  let [omni, setOmni] = useState('');
  let [request, setRequest] = useState({ first: 0, max: 10, criteria: {}, time: new Date() });
  let [data, setData] = useState([]);
  let [authorized, setAuthorized] = useState(0);
  let [total, setTotal] = useState(0);
  let [pagination, setPagination] = useState({ first: 0, pageSize: 10, total: 0 });
  useEffect(() => {
    searchUsers(request).then(resp => {
      if (resp.result) {
        setData(resp.result);
        setPagination({ first: request.first, pageSize: request.max, total: resp.total })
      }
    })
  }, [request]);
  useEffect(() => {
    searchUsers({ audience: "COUNT_AND_LIMIT" }).then(resp => {
      if (resp) {
        setTotal(resp.total);
        setAuthorized(resp.authorized)
      }
    })
  }, ["once"]);
  let reload = (p: number) => {
    setRequest({ ...request, time: new Date(), first: p });
  }
  let handleKeyPressEvent = (k: string) => {
    if (k === 'Enter') { launchSearch(); }
  }
  let launchSearch = () => setRequest({ ...request, criteria: { 'omni': omni } });
  return (
    <div className="search-users">
      <div className="search-console">
        <div className="criteria">
          <div className="criteria-control">
            <input type="text" className="form-control" placeholder={t("Rechercher un utilisateur")}
              onChange={(e) => setOmni(e.target.value)}
              onKeyPress={(e) => handleKeyPressEvent(e.key)}
            />
            <div  ><i
              onClick={launchSearch}
              className="fas fa-search"></i></div>
          </div>

        </div>
        <div className="actions-bar">
        
          <div className="action-btns-droplist">
            <Link to={"/admin/user/00/create"} className="btn btn-purple"><i className="fa fa-user-plus"></i> <span>Nouvel Utilisateur</span></Link>
            <div className="extra-action">   </div>
          </div>
        </div>
      </div>
      {total>0 && <div className="count_limit">
            {total + " " + t("Utilisateurs") + " / " + authorized + " " + t("Licences")}
          </div>
          }
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="wd-45p pl">{t("Nom/Prénom")}</th>
              <th className="wd-45p pl">{t("Email")}</th>
              <th className="wd-45p pc">{t("U.O")}</th>
              <th className="wd-45p pl">{t("Date Création")}</th>
              <th className="wd-45p pl">{t("Créé par ")}</th>
              <th className="wd-45p pl">{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((d: any) => {
              return (
                <tr className="ind-row">
                  <td className="pl"  ><strong> {d.fullName} </strong></td>
                  <td className="pl"  >{d.email} </td>
                  <td className="pc " > {d.entity?.label}  </td>
                  <td className="pc" > {printShortDate(d.changeTime || d.creationTime)} </td>
                  <td className="pc" > <strong>{d.changedBy || d.createdBy}</strong> </td>
                  <td className="action-btn">
                    <Link to={"/admin/user/" + d.id + "/display"} >
                      <i className="fa fa-eye"></i>
                    </Link>
                    <Link to={"/admin/user/" + d.id + "/edit"} >
                      <i className="fa fa-edit"></i>
                    </Link>
                  </td>

                </tr>);

            })}

          </tbody>
        </table>
      </div>
      {pagination.total > 0 &&
        <Pagination reload={reload} first={pagination.first} pageSize={pagination.pageSize} total={pagination.total} ></Pagination>
      }


    </div>
  );
}