import { useEffect, useState } from "react";
import { VictoryAxis, VictoryChart, VictoryCursorContainer, VictoryLabel, VictoryLine } from "victory";
import { t } from "../i18n";
import { searchKpiDef } from "../service/kpiService";
import { KpiDefinition } from "../types/kpi-model";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { ChallengeConfig, evaluatePoints, KpiContribution } from "./challenge-type";

export function ChallengeKpiContribution(props: any) {
    let readonly = props.readonly;
    let [entity, setEntity] = [props.entity, props.setEntity];
    let [expanded, setExpanded]  = useState(0);
    let onDeleteContribution = (q: any, indx: number) => {
        console.log("onDeleteContribution", indx);
        entity.indicatorsContribution.splice(indx - 1, 1);
        setEntity({ ...entity });
        //    submit('update');
        console.log("onDeleteContribution quiz", entity);

    }
    let onSaveContribution = (q: any, indx: number) => {
        console.log("onSaveContribution", q);
        entity.indicatorsContribution[indx - 1] = q;
        setEntity({ ...entity });

        console.log("onSaveContribution quiz", entity);
        //  submit('update');

    }
    return <div>
        {!readonly && <div className="flex-pr">
            <span onClick={() => { entity.indicatorsContribution.push({ input: 'target', formulaType: 'LMM', maxPoints: 100, formulCoefs: [{ x: 0, y: 0 }, { x: 100, y: 100 }] }); setEntity({ ...entity }) }}
                className="tlb-btn tex-btn"><i className="fa fa-plus-square"></i> Ajouter un KPI</span>
        </div>
        }
        {entity.indicatorsContribution.map((q, id) => <IndicatorContributionBlock
            onSave={onSaveContribution} onDelete={onDeleteContribution} key={id}
            indx={id + 1} contrib={q} readOnly={readonly}
            onExpand={(ix)=>setExpanded(ix)}
            expanded={id==expanded}
            setEntity={setEntity} entity={entity}   ></IndicatorContributionBlock>)
        }
    </div>
}

function mapFormulaTypeLabel(tx: string): string {
    if (tx == 'LINEAR') { return t("Linéaire"); }
    if (tx == 'LMM') { return t("Linéaire avec Min/Max"); }
    if (tx == 'STEPS') { return t("Paliers"); }
    if (tx == 'LINEAR-STEPS') { return t("Linéaire par morceaux"); }
    return tx;
}
function mapFormulaInput(tx: string): string {
    if (tx == 'target') { return t("En fonction de l'objectif"); }
    if (tx == 'acheived') { return t("En fonction du réalisé"); }
    if (tx == 'tro') { return t("TRO"); }
    return tx;
}
export function IndicatorContributionBlock(props) {

    let readOnly = props.readOnly;
    let tnp = props.tnp;
    let [contrib, setContribIn] = useState((props.contrib) as KpiContribution);
    let [allIndicators, setAllIndicators] = useState([]);
    let [entity, setEntity] = [props.entity as ChallengeConfig, props.setEntity];
    let indx = props.indx;
    let setContrib = (x) => {
        entity.indicatorsContribution[indx - 1] = x;
        setContribIn(x);
        setEntity(entity);
    }
    useEffect(() => {
        searchKpiDef({ first: 0, max: 100 }).then(resp => {
            if (resp.result) {
                setAllIndicators(resp.result.map(
                    (d: KpiDefinition) => { return { unit: d.unit, name: d.title, reference: d.reference } }));
            }
        })
    }, ["onetime"]);

    let removeCoefBlock = (c, i) => {
         contrib.formulCoefs.splice(i, 1);
        console.log("removeCoefBlock", contrib.formulCoefs);
        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
    }
    let addCoefBlock = (c, i) => {
        let nc = { ...c };
        nc.x = c.x + 1;
        contrib.formulCoefs.splice(i + 1, 0, nc);
        console.log("addCoefBlock", contrib.formulCoefs);
        contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
        setContrib(contrib);
    }
    let postSelectFormulaType = () => {
        if (contrib.formulaType == 'LMM' || contrib.formulaType == 'LINEAR') {
            contrib.formulCoefs = [{ x: 0, y: 0 }, { x: 100, y: contrib.maxPoints }]  ;
            contrib = { ...contrib, formulCoefs: [...contrib.formulCoefs] };
          
        }
        setContrib(contrib);
        console.log("postSelectFormulaType", contrib);
    }
    return (
        <div className={"question-block "+(props.expanded?"expanded": "collapsed")}>
            <div className="app-form kpi-form" >

                <div className="block-header question-block-header" onClick={()=>props.onExpand(indx-1)}>
                    <h5>
                        <span>{indx} - </span>
                        <span> {contrib.indicator?.name}</span>
                    </h5>
                    <span>
                        <span> <i className="fa fa-trophy"></i>  {contrib.maxPoints} Pt{(contrib.maxPoints > 1) && "s"}</span>

                        {!readOnly && <>
                            <span onClick={() => props.onDelete(contrib, indx)} className="tlb-btn"><i className="fa fa-ban"></i></span>
                        </>
                        }
                    </span>
                </div>

                <div className="flex-c2 hidable-panel-body">
                    <div className="form-group ">
                        <label>{t("Indicateur")}</label>
                        <DropdownList readOnly={readOnly} onSelect={(e:KpiDefinition) => setContrib({ ...contrib, indicator: { name: e.name, reference: e.reference, unit: e.unit=="Autre"? e.otherUnit: e.unit } })}
                            data={allIndicators} value={contrib.indicator} className="flex-child-11" ></DropdownList>
                    </div>
                    <div className="form-group">
                        <label>{t("Points")}</label>
                        <input readOnly={readOnly}  onChange={e => {
                            contrib = { ...contrib, maxPoints: parseInt(e.target.value) }
                            setContrib(contrib);
                            //  console.log("e=>"+e, e, contrib.maxPoints);
                            postSelectFormulaType();
                            //console.log("p=>"+e, e, contrib.maxPoints);
                        }}
                            value={contrib.maxPoints}
                            type="number" className="form-control" placeholder={t("Nombre de points")} />
                    </div>
                    <div className="form-group ">
                        <label>{t("Référence du calcul")}</label>
                        <DropdownList  readOnly={readOnly}   onSelect={e => setContrib({ ...contrib, input: e.name })}
                            data={['target', 'acheived', 'tro'].map(w => { return { name: w, label: mapFormulaInput(w) } })} value={{ name: contrib.input }} className="flex-child-11" ></DropdownList>
                    </div>
                    <div className="form-group ">
                        <label>{t("Type de formule")}</label>
                        <DropdownList  readOnly={readOnly}   onSelect={e => {
                            contrib = { ...contrib, formulaType: e.name };
                            postSelectFormulaType();
                        }}
                            data={['LINEAR', 'LMM', 'STEPS', 'LINEAR-STEPS'].map(w => { return { name: w, label: mapFormulaTypeLabel(w) } })} value={{ name: contrib.formulaType }} className="flex-child-11" ></DropdownList>
                    </div>
                </div>
                {contrib.indicator && <div className="coefficient-block  hidable-panel-body ">
                    <div className="coefficient-block-left" >
                        {contrib.formulCoefs.map((c, indx) => <div key={indx} className="coef-block">
                            <div className="form-group">
                                <label>{t("Seuil")}</label>
                                <div className="flex-container">
                                    <input  readOnly={readOnly}  
                                        onChange={e => { c.x = parseFloat(e.target.value); setContrib({ ...contrib }) }}
                                        value={c.x || 0} step="1" type="number" className="form-control" />
                                    <span className="unit-badge"> {contrib.input == 'target' ? '%' : (contrib.indicator?.unit || '')}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t("Valeur")}</label>
                                <div className="flex-container">
                                    <input  readOnly={readOnly}  
                                        onChange={e => { c.y = parseFloat(e.target.value); setContrib({ ...contrib }) }}
                                        value={c.y || 0} step="1" type="number" className="form-control" />
                                    <span className="unit-badge">{t("Points")}</span>
                                </div>
                            </div>
                            <div className="form-btn">
                                <label>&nbsp;</label>
                                {!readOnly && (contrib.formulaType == 'STEPS' || contrib.formulaType == 'LINEAR-STEPS') && <div className="flex-pr">
                                    <span className="tlb-btn"><i onClick={() => removeCoefBlock(c, indx)} className="fa fa-ban"></i></span>

                                    <span className="tlb-btn"><i onClick={() => addCoefBlock(c, indx)} className="fa fa-plus-square"></i></span>

                                </div>}
                            </div>

                        </div>)}

                    </div>
                    <GraphFunction contrib={contrib} ></GraphFunction>

                </div>
                }
            </div>
        </div>
    )
}

function GraphFunction({ contrib }: { contrib: KpiContribution }) {
    let data: { x, y }[] = [{ x: 0, y: 0 }];
    let pre = { x: 0, y: 0 }
    for (let c of contrib.formulCoefs) {
        let x = Math.max(pre.x, c.x || pre.x);
        let y = Math.max(pre.y, c.y || pre.y);
        if (contrib.formulaType == 'STEPS') {
            data.push({ x: x, y: pre.y })
        }
        data.push({ x, y });
        pre = { x, y }
    }
    if (contrib.formulaType == 'STEPS') {
        data.push({ x: pre.x + 10, y: pre.y })
    }
    let unit = contrib.input == 'target' ? "%" : (contrib.indicator?.unit || '');
    return <div className="graph-function-box">
        <VictoryChart
            containerComponent={
                <VictoryCursorContainer cursorDimension="x" cursorLabel={({ datum }: any) => {
                    console.log(">>>", datum)
                    return `${Math.round(datum.x)} ${unit} , ${Math.round(evaluatePoints(datum.x, contrib))} Points`
                }} />}
        >
            <VictoryAxis label={contrib.input == 'target' ? "% de l'objectif" : "Réealisé en " + contrib.indicator?.unit} />
            <VictoryAxis dependentAxis axisLabelComponent={<VictoryLabel dy={-12} />} label="Nombre de points" />
            <VictoryLine
                style={{
                    data: { stroke: "#c43a31" },
                    parent: { border: "1px solid #ccc" }
                }}
                data={data}
            />
        </VictoryChart>
    </div>
}


