 
import { useEffect, useState } from "react";
import { toYYYMMDD, toYYY_MM_DD } from "../help/dates";
import { Entry, Group } from "../types/people";
import { MultItemField } from "../ui/mutli-item-field";
import '../admin/admin.css';
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { appConfig, searchGroups } from "../service/userService";
import { Resource } from "../types/kpi-model";
import { t } from "../i18n";
import { ChallengeConfig } from "./challenge-type";


let ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT") || "[]");
 



export function DefinitionTab({entity, setEntity, readonly,  inpopup}:{entity: ChallengeConfig, setEntity: Function,
     readonly:boolean,  inpopup:boolean}) {
    
      let [resourceList, setResourceList] = useState(ALL_ENTITIES_FLAT as Resource[]);
      
    let [allGroups, setAllGroups] = useState([]);  
    
    useEffect(() => {
       

        searchGroups({}).then(r => {
            allGroups = (r.result.map((x: Group) => { let e: Entry = { value: x.groupQN, label: x.label, id: x.id }; return e; }))
            setAllGroups(allGroups);
        })
    }
        , ["entity"]);
    console.log("rendering assessment def tab");
    let ouLevels = appConfig().ouLevels;
    return  <div className="app-form kpi-form b1c" >
        
   
     <div className="form-g2c" > 
         <div className="form-group ">
             <label>{t("Titre")}</label>
             <input readOnly={readonly} onChange={e => setEntity({ ...entity, title: e.target.value, name: e.target.value })} value={entity.title}
                 type="text" className="form-control" placeholder={t("Titre")} />
         </div>
         <div className="form-group ">
             <label>{t("Description ")}</label>
             <input readOnly={readonly} onChange={e => setEntity({ ...entity, description: e.target.value })} value={entity.description}
                 type="text" className="form-control" placeholder={t("Description")} />
         </div> 


         <div className="form-group ">
             <label>{t("Date Début")}</label>
             <input   type="date"
                 onChange={e => {
                     console.log(">>>>", e.target.value);
                     
                     console.log("<<<", new Date(e.target.value));
                      entity=({ ...entity, publicationDate: new Date(e.target.value) });
                     setEntity(entity);
                      console.log("<<<", toYYY_MM_DD(entity.publicationDate) );
                    }}
                      
                 value={toYYY_MM_DD(entity.publicationDate)}
                 className="form-control" />
         </div>
         <div className="form-group ">
             <label>{t("Date Clôture ")}</label>
             <input readOnly={readonly} type="date"
                 onChange={e => setEntity({ ...entity, closeDate: new Date(e.target.value) })}
                 value={toYYY_MM_DD(entity.closeDate)}
                 className="form-control" />
         </div>


 
       

          
         <div className="form-group ">
             <label>{t("Entité")}</label>
             <DropdownList
                 optionRender={d => (<><div>{d.title}</div><div><small>{d.qn}</small></div></>)}
                 data={resourceList} value={entity.targetEntity}
                 onSelect={(d) => { setEntity({ ...entity, targetEntity: d }) }} enableSearch={true}
             ></DropdownList>
         </div>
         <div className="form-group ">
             <label>{t("Profils concernés ")}</label>
             <MultItemField data={entity.monitoringGroups}
                 setData={(d) => { console.log("set monitoringGroups",d) ;entity.monitoringGroups = d; setEntity({...entity}); }}
                 items={allGroups} noDataMessage="Aucun groupe" mode={readonly ? 'display' : 'edit' }  ></MultItemField>

         </div>
         <div className="form-group ">
                    <label>{t("Audience")}</label>
                    <DropdownList  readOnly={readonly}  data={ouLevels} value={{name:""+ entity.challengeLevel}}
                        onSelect={(d)=>setEntity({...entity, challengeLevel:parseInt(d.name) })}
                    ></DropdownList>
                </div> 
         <div className="form-group ">
                    <label>{t("Activer")}</label>
                    <div onClick={() => setEntity({ ...entity, activated: !entity.activated })}
                        className={"az-toggle az-toggle-success " + (entity.activated ? 'on' : 'off')}><span></span></div>
                </div>
</div>
</div>
}