import { sign } from "crypto";
import { useState } from "react";
import { Router, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { t } from "../i18n";
import { fetchLocalUserProfile, razPwd, sendResetPwd, signin, storeLocalUserProfile } from "../service/userService";
import { UserProfile } from "../types/people";

 
export function PwdReset (props:any) { 
    let {activationId,  email } = useParams() as any; 
    let history = useHistory();
    let [errorMessage, setErrorMessage] = useState('');
    let [passwordShown, setPasswordShown] = useState(false);
    
    let [pwd, setPwd] = useState('');
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      }; 
 
    let resetPwd = ()=> {
        setErrorMessage('');
        if(!email) {
            setErrorMessage("Veuillez Saisir votre email pour recvoir!");
            return;
        } 
        if(!pwd) {
            setErrorMessage("Veuillez Saisir votre nouveau mot de passe!");
            return;
        }
        sendResetPwd(email,  pwd,   activationId).then(
            (r)=> {
                console.log("razPwd  r", r);
                if(fetchLocalUserProfile()) { 
                    history.push('/');
                } else { 
                    history.push('/login');
                }
            }, 
            (error) => {
                console.log("RAZ_PWD_ERROR  ", error);
                setErrorMessage("Oups! quelque chose s'est mal passé"); 
            }
        )
    }



    let activateMode=(md: string) => {
        setErrorMessage(''); 
    }
    return (<div className="login-page">
        <div className="az-signin-wrapper">
      <div className="az-card-signin"> 
        <div className="login-logo-container">
            <img className="login-logo" src="/photos/logo.png"></img>
        </div>
        <div className="az-signin-header">
            
          <div  >
            <div className="form-group">
              <label>{t("Email")}</label>
              <input readOnly={true}  type="text" className="form-control" placeholder={t("Enter your email")}  value={email}/>
            </div>   
            <div className="form-group form-group-pwd">
              <label>{t("Password")}</label>
              <input    onChange={e=> setPwd(e.target.value) }    type={passwordShown ? "text" : "password"} className="form-control" placeholder={t("Enter your password")}  />
              <i onClick={togglePasswordVisiblity} className="pwdToggler fa fa-eye"></i>
            </div> 
            <button onClick={resetPwd} className="btn btn-az-primary btn-block">Modifier le Mot de passe</button>
          
         
          </div>
        </div> 
        {errorMessage && <div className="login-error-message">{errorMessage}</div>}
        <div className="az-signin-footer">
            <p><Link to="/login" >Se connecter</Link></p>  
        </div> 
      </div> 
    </div>
    </div>);
}