import { sign } from "crypto";
import { useState } from "react";
import { useHistory } from "react-router";
import { t } from "../i18n";
import { fetchLocalUserProfile, razPwd, signin, storeLocalUserProfile } from "../service/userService";
import { UserProfile } from "../types/people";

 
export function Login (props:any) { 
    let history = useHistory();
    let [mode, setMode]=useState("signin"); 
    let [errorMessage, setErrorMessage] = useState('');
    let [email, setEmail] = useState('');
    let [pwd, setPwd] = useState('');
    let signIn = ()=>{ 
        setErrorMessage('');
        if(!email) {
            setErrorMessage("Veuillez Saisir votre email pour recvoir!");
            return;
        } 
         signin(email, pwd).then(
             (authenticated)=> {
                 if(authenticated) {
                    props.postSignIn(fetchLocalUserProfile());
                    history.push("/");
                 } else {
                     setErrorMessage("Email ou mot de passe incorrect");
                 }
             },
             (error => {
                 console.log("LOGIN_ERROR", error);
                 setErrorMessage("Erreur de connection");
              //   storeLocalUserProfile({email:email, fullName: email, })
               //  props.postSignIn(fetchLocalUserProfile());
             })
         );
         
    };
    let sendResetPassRequest = ()=> {
        setErrorMessage('');
        if(!email) {
            setErrorMessage("Veuillez Saisir votre email pour recvoir!");
            return;
        }
        razPwd(email).then(
            (r)=> {
                console.log("razPwd  r", r);
                if(r) {
                    setMode("postSendRazRequest") ;
                } else {
                    setErrorMessage("Oups! quelque chose s'est mal passé");
                }
            }, 
            (error) => {
                console.log("RAZ_PWD_ERROR  ", error);
                setErrorMessage("Oups! quelque chose s'est mal passé"); 
            }
        )
    }



    let activateMode=(md: string) => {
        setErrorMessage('');
        setMode(md);
    }
    return (<div className="login-page">
        <div className="az-signin-wrapper">
      <div className="az-card-signin"> 
        <div className="login-logo-container">
            <img className="login-logo" src="/photos/logo.png"></img>
        </div>
        <div className="az-signin-header">
            
          <div  >
            <div className="form-group">
              <label>{t("Email")}</label>
              <input  onChange={e=> setEmail(e.target.value)}  type="text" className="form-control" placeholder={t("Enter your email")} />
            </div> 
            {mode=='signin'  && <>   
            <div className="form-group">
              <label>{t("Password")}</label>
              <input  onChange={e=> setPwd(e.target.value) }  type="password" className="form-control" placeholder={t("Enter your password")}  />
            </div> 
            <button onClick={signIn} className="btn btn-az-primary btn-block">Connexion</button>
            </>}
            {mode=='forgotPwd'  && <>   
            <p>Cliquer sur envoyer pour recevoir le lien d'initialisation de votre mot de passe </p>
            <button onClick={sendResetPassRequest} className="btn btn-az-primary btn-block">Envoyer </button>
            </>}
            {mode=='postSendRazRequest' && <p>
                Un lien pour reinitiaiser le mot de passe vous sera envoyé, dans quelues instants,  à l'adresse <strong>{email}</strong> .

                </p>}
          </div>
        </div> 
        {errorMessage && <div className="login-error-message">{errorMessage}</div>}
        <div className="az-signin-footer">
         
         {mode=='signin' && <p><a onClick={()=>activateMode("forgotPwd")}  >Mot de passe oublié?</a></p>  }
         {(mode=='forgotPwd' || mode=='postSendRazRequest') && <p><a onClick={()=>activateMode("signin")}  >Se connecter</a></p>  }
        </div> 
      </div> 
    </div>
    </div>);
}