import { SetStateAction, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { printShortDate, toYYYMMDD } from "../help/dates";
import { Entry, Group, Profile } from "../types/people";
import { MultItemField } from "../ui/mutli-item-field";
import '../admin/admin.css';
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { currentUser, searchGroups } from "../service/userService";
import { getAssessmentDefinitionById, updateAssessmentDefinition } from "../service/quizService";
import { AssessmentDefinition, Question } from "./assessment-type";
import { Resource } from "../types/kpi-model";
import { QuestionBlock } from "./config-question";
import ReactQuill from "react-quill";
import { quillFormats, quillModulesBasic } from "../feed-wall/text-editor";
import { TabsBlock } from "../ui/tabs";
import { DefinitionTab } from "./assessment-form-def-tab";
import { AssDefMessageTab } from "./assessment-form-message-tab";
import { Popup } from "../ui/popup";
import { AssessmentDefinitionResults } from "./assessments-def-results";
import { AssessmentDefinitionStat } from "./assessments-def-stat";
import { t } from "../i18n";



const TABS = [{ name: "definition", label: t("Définition") },
{ name: "messages", label: t("Message") },
{ name: "questions", label: t("Questions") },
{ name: "resultat", label: t("Résultats") },
{ name: "stat", label: t("Statistiques") }]

export function AssessmentDefinitionForm(props: any) {
    let { id: id, mode: inMode } = useParams() as any;
    let [entity, setEntity] = useState(props.assessmentDefinition || {
        name: '', title: '',
        description: '',
        questions: [],
        type: (inMode || "").includes("polls") ? 'POLLS' : 'QUIZ',
        status: 'OPEN',
        publicationDate: new Date(),
        closingDate: new Date(new Date().getTime() + 365 * 24 * 3600 * 1000),
        weight: 1,
        moduleName: '',
        moduleReference: '',
        enabled: true,
        passingScore: 50,
        required: false,
        durationMin: 30,
        owner: { reference: currentUser().email },
        createdBy: currentUser().fullName,
        monitoringGroups: []
    } as AssessmentDefinition);
    let [tnp, setTnp] = useState(entity.type != 'POLLS');
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode as string);
    let [error, setError] = useState('');
    let [currentTab, setCurrentTab] = useState("definition");
    let [ typeLabel, setTypeLabel] = useState(entity.type=='POLLS'? 'Sondage' :  'Quiz');
    let history = useHistory();
    let onDelete = () => {
        entity = { ...entity, enabled: false }
        updateAssessmentDefinition(entity, "delete").then((u) => {
            setShowPopup(false);
            history.push("/quiz/definition");
        })
    }
    let submit = (action: string) => {
        setError("");
        if (action == 'update' || action == 'create') {
            if (!entity.title) {
                setError("Le titre est obligatoire")
                return;
            }
            if (!entity.description) {
                setError("La description est obligatoire")
                return;
            }
        }
        updateAssessmentDefinition(entity, action).then((u) => {
            if (u) {
                setEntity(u);
                mode = 'display';
                setMode('display');
                id = u.id;
                if (props.onConfirm) {
                    props.onConfirm(entity);
                }
            }
        });
    }
    useEffect(() => {
        if (id && id != "00") {
            getAssessmentDefinitionById(id).then((u) => {
                if (u.body) {
                    entity = u.body;
                    setEntity(u.body);
                    setTnp(entity.type != 'POLLS');
                    setTypeLabel ( entity.type != 'POLLS' ? 'Quiz' : 'Sondage');
                }
            });
        }

    }
        , [id]);

    let readonly = props.inpopup ? props.readonly : !mode.includes('create') && mode != 'edit';


    let actions: any[] = [];
    if (props.inpopup) {
        if (!readonly) {
            actions.push({
                label: t("Confirmer"),
                icon: "fa fa-check",
                handler: () => { submit('update') }
            });
            
        }
    } else if (mode == 'display') {
        actions.push({
            label: t("Editer"),
            icon: "fa fa-edit",
            handler: () => { setMode('edit') }
        });

        actions.push({
            label: t("Supprimer"),
            icon: "fa fa-trash",
            handler: () => { setShowPopup(true); }
        });
        actions.push({
            label: t("Fermer"),
            icon: "fa fa-times",
            handler: () => { history.push("/quiz/definition") }
        });
    } else if (!readonly) {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: () => { submit('update') }
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: () => { setMode('display') }
        });
     
    }
    let onDeleteQuestion = (q: Question, indx: number) => {
        console.log("onDeleteQuestion", indx);
        entity.questions.splice(indx - 1, 1);
        setEntity({ ...entity });
        //    submit('update');
        console.log("onDeleteQuestion quiz", entity);

    }
    let onSaveQuestion = (q: Question, indx: number) => {
        console.log("onSaveQuestion", q);
        entity.questions[indx - 1] = q;
        setEntity({ ...entity });

        console.log("onSaveQuestion quiz", entity);
        //  submit('update');

    }

    return (<div className="config-form">

        <div className="assessment" >
            <div className="assmt-header">
                <h3 className="assmt-title">{entity.reference ?   entity.name : t("Nouveau "+typeLabel)}</h3>
                <div className="assmt-dates az-content-header-right" >
                    <div className="media">
                        {entity.modifiedBy && <div className="media-body">
                            <label>{t("Par")} <strong> {(entity.modifiedBy || entity.createdBy)}</strong> </label>
                            <h6> {printShortDate(entity.changeTime || entity.creationTime)}</h6>
                        </div>}
                    </div>
                    <div className="media noextramarge">
                        <div className="media-body actions-toolbar">
                            {actions.map((a, i) => <a key={i + "_" + a.label} onClick={a.handler} className="btn btn-purple">
                                <i className={a.icon}></i>
                                <span>{a.label}</span>
                            </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TabsBlock tabs={TABS} onSelect={(name: string) => setCurrentTab(name)} defSelected="definition"></TabsBlock>
        {error && <p className="form-error">{error}</p>}

        {currentTab == "definition" && <DefinitionTab inpopup={props.inpopup} tnp={tnp} entity={entity} setEntity={setEntity} readonly={readonly} ></DefinitionTab>}
        {currentTab == "messages" && <AssDefMessageTab tnp={tnp} entity={entity} setEntity={setEntity} readonly={readonly} ></AssDefMessageTab>}

        {currentTab == "questions" && <div>
        {!readonly && <div className="flex-pr">
                <span onClick={() => { entity.questions.push({ type: 'QCM', weight: 1, question: '' }); setEntity({ ...entity }) }}
                 className="tlb-btn tex-btn"><i className="fa fa-plus-square"></i> Ajouter une question</span> 
            </div>
            }
            {entity.questions.map((q, idx) => <QuestionBlock tnp={tnp} onSave={onSaveQuestion} onDelete={onDeleteQuestion} key={id} indx={idx + 1} question={q} readOnly={readonly} mode="display" ></QuestionBlock>)
            }
        </div>}
        {currentTab == "resultat" && <AssessmentDefinitionResults tnp={tnp} entity={entity} setEntity={setEntity} readonly={readonly} ></AssessmentDefinitionResults>}
        {currentTab == "stat" && <AssessmentDefinitionStat tnp={tnp} entity={entity} setEntity={setEntity} readonly={readonly} ></AssessmentDefinitionStat>}


        {showPopup && <Popup onAction={(action) => action == 'delete' && onDelete()}
            actions={[{ name: "delete", icon: "trash", label: t("Confirmer la suppression") }]} onClose={() => setShowPopup(false)}>    <p>
                Etes-vous sûr de vouloir supprimer ce {typeLabel}?
            </p>
        </Popup>}

    </div>);
}

