import { currentUser } from "../service/userService";
import { hasPermission } from "../types/permissions";
import { ORGA_ARRAY } from "./orga-array";
export interface Entity {
    children? : Entity[],
    people?: Collaborator[],
     name: string, 
     label: string,
     type?: string;
     qn?: string; 

}
export interface Collaborator {
    
     name: string;
     qn?:string;
     email?:string;
     entity?:string;
     label: string;
     type?: string;
    children? : Entity[];
}
export   let   orga_tree_root : Entity = JSON.parse(localStorage.getItem("ORGANIZATION_TREE")|| "{}");  ;//= {name: "siege", label: t("Siège", children:[], people:ALL_COLLABS};
export const FNS = ["Thomas Mann", "Andrea Rhodes", "Edward Fleming", "Cynthia Hunt", "Steven Donaldson", "Jason Martin", "Wendy Donovan", "Tonya Williams", "Jacob Lloyd", "Tracy Jones", "Gregory Chang", "Carrie May", "Renee Clark", "Lori Brown", "Tara Estrada", "Ryan Carter", "Jason Miller", "Robert White", "Jenny Francis", "Amanda Johnson", "Angela Mendez", "Tiffany Campbell", "Jamie Hayes", "David Harper", "Audrey Bell", "Emma Rodriguez", "Elizabeth Stephens", "Andrea Taylor", "Chad Hancock", "Ronald Thompson", "Tammy Luna", "Kimberly Braun", "William Clay", "John Hall", "Dillon Fisher", "Scott Briggs", "Megan Atkins", "Vincent Reeves", "Mr. Cameron Ford", "Cynthia White", "Carolyn Crawford", "Mary Martinez", "Matthew Burke", "Dawn Frederick", "Brittney Bennett", "Adam Miller", "Christine Riddle", "Sara Romero", "Mark Bender", "Jeremy Williams", "Brenda Miller", "Kristen Ashley", "Anne Flowers", "Mitchell Pearson", "Stacy Massey", "Angela Cross", "Randall Harris", "Jesse Sloan", "Patrick White", "Anthony Clements", "Ryan Acosta", "Christopher Huynh", "Natalie Martinez", "Anna Burke", "Sarah Carr", "Angela Brooks", "Katherine Erickson", "Jamie Howard", "Benjamin Wallace", "Stephanie Watts", "Benjamin Sexton", "Ronald Charles", "Debra Lane", "Jason Rodriguez", "Christopher Hamilton", "Lisa Robinson", "Christopher Mcdaniel", "Cynthia Myers", "Jeremy Miller", "Daniel Austin", "Tammy Garrett", "Jessica Long", "George Barker", "Anna Howard", "Samuel Williams", "Bryan Nguyen", "Raymond Heath", "Heather Rodriguez", "Shannon Allen", "Brittany Stevens", "Michael Washington", "Lauren Lawson", "Jasmine Jones", "Patrick Hays", "Alicia Lucas", "Amy Farley", "Jennifer Winters", "Jaclyn Carpenter", "Bobby Murphy", "Timothy Sullivan"];

export const ALL_COLLABS:Collaborator[] = orga_tree_root.people ||[];
/*
function getHolder(parent : any , name : string, label: string, collab:any) : any { 
    let ret = parent.children.filter((x:any)=>x.name==name)[0];
    if(!ret) {
        ret = {name: name, label: label, children: [], people:[]}
        parent.children.push(ret);
    } 
    ret.people.push( collab);
    return ret;
}

ORGA_ARRAY.forEach((line, i)=> {
    let [org1, org2, org3,org4,org5] = line ; 
    let collabFullName=org1+"/"+ org2+"/"+org3+"/"+org4+"/"+org5;
    let collabLabel = FNS[i%FNS.length]+" ("+org5+")"; 
    let collab = {name: collabFullName, label: collabLabel, type: 'COLLABORATOR'}
    let org1Holder :any  = getHolder(orga_tree_root, org1, org1, collab);
    let org2Holder = getHolder(org1Holder, org1+"/"+ org2,  org2, collab);
    let org3Holder = getHolder(org2Holder, org1+"/"+ org2+"/"+org3,org3, collab);
    let org4Holder = getHolder(org3Holder,org1+"/"+ org2+"/"+org3+"/"+org4, org4, collab); 
    ALL_COLLABS.push(collab);
    //let org5Holder = getHolder(org4Holder, org5);


})

  
console.log("orga_tree_root", orga_tree_root);
*/
export function ROOT_ENTITIES(permissionDomain :  "configuration" | "profile" | "users" | "group" | "organization" | "post" | "training" | "quiz" | "poll" | "kpi" | "challenge" ) { 

    if(!permissionDomain  || hasPermission(permissionDomain, "wr")) {
        return orga_tree_root.children;
    }
 let cu = currentUser();
 if(!cu) {
     return orga_tree_root.children;
 }
 let e = cu.entity?.value;
 if(!e) { 
    return orga_tree_root.children;
 }
 return filterSubEntities (e, orga_tree_root.children);
    }
(window as any).TEST_ENTITIES=ROOT_ENTITIES("organization");
(window as any).ALL_COLLABS=orga_tree_root.people;
(window as any).ROOT_ENTITIES=ROOT_ENTITIES;


function filterSubEntities(e: string, children: Entity[] | undefined) : Entity[]{
   // console.log("filterSubEntities "+e, children);
    if(!children) {
        return [];
    }
    return children.filter(c=>{ return c.qn && (c.qn?.startsWith(e) || e.startsWith(c.qn))}).map(
        (c:Entity)=> {
            let cc:Entity = {...c, people :e.startsWith(c.qn|| '-0')? c.people:[], 
            children: filterSubEntities(e, c.children)};
            return cc;
        }
    );
}
