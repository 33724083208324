 
import { useEffect, useState } from "react";
import { toYYYMMDD, toYYY_MM_DD } from "../help/dates";
import { Entry, Group } from "../types/people";
import { MultItemField } from "../ui/mutli-item-field";
import '../admin/admin.css';
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { searchGroups } from "../service/userService";
import { Resource } from "../types/kpi-model";
import { t } from "../i18n";


let ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT") || "[]");
 



export function DefinitionTab({entity, setEntity, readonly, tnp, inpopup}) {
    
    let [training, setTraining] = useState({name: entity.trainingReference, label: entity.trainingName});
    let [trainingModule, setTrainingModule] = useState({name: entity.moduleReference, label: entity.moduleName});
    let [resourceList, setResourceList] = useState(ALL_ENTITIES_FLAT as Resource[]);
    let [trainings, setTrainings] = useState([{ name: "Aliqua magna sit" }, { name: "Incididunt adipisicing velit laborum" }]);
    let [modules, setModules] = useState([{ reference: "ref1", name: "Mollit commodo duis velit", }]);
    
    let [allGroups, setAllGroups] = useState([]);  
    useEffect(() => {
        if (entity.moduleReference) {
            setTraining({name: entity.trainingReference, label: entity.trainingName})
            setTrainingModule({ name: entity.moduleReference, label: entity.moduleName }); 
        } 

        searchGroups({}).then(r => {
            allGroups = (r.result.map((x: Group) => { let e: Entry = { value: x.groupQN, label: x.label, id: x.id }; return e; }))
            setAllGroups(allGroups);
        })
    }
        , ["entity"]);
    console.log("rendering assessment def tab");
    return  <div className="app-form kpi-form b1c" >
        
    {tnp && <div className="form-g2c" >
         
         <div className="form-group cquart ">
             <label>{t("Time limitation")}</label>
             <div onClick={() => setEntity({ ...entity, timeLimitation: !entity.timeLimitation })}
                 className={"az-toggle az-toggle-success " + (entity.timeLimitation ? 'on' : 'off')}><span></span></div>
         </div>

         <div className="form-group cquart">
             <label>{t("Revu des réponses")}</label>
             <div onClick={() => setEntity({ ...entity, canReview: !entity.canReview })}
                 className={"az-toggle az-toggle-success " + (entity.canReview ? 'on' : 'off')}><span></span></div>
         </div>
         <div className="form-group cquart">
             <label>{t("Autoriser la pause")}</label>
             <div onClick={() => setEntity({ ...entity, canPause: !entity.canPause })}
                 className={"az-toggle az-toggle-success " + (entity.canPause ? 'on' : 'off')}><span></span></div>
         </div>
         <div className="form-group cquart">
             <label>{t("Autoriser la reprise")}</label>
             <div onClick={() => setEntity({ ...entity, canRetry: !entity.canRetry })}
                 className={"az-toggle az-toggle-success " + (entity.canRetry ? 'on' : 'off')}><span></span></div>
         </div>
       
         <div className="form-group cquart">
             <label>{t("Lié à une formation")}</label>
             <div onClick={() => setEntity({ ...entity, hasTraining: !entity.hasTraining })}
                 className={"az-toggle az-toggle-success " + (entity.hasTraining ? 'on' : 'off')}><span></span></div>
         </div>
         {entity.hasTraining && !inpopup && 
         <>
           <div className="form-group ">
             <label>{t("Formation")}</label> 
                <DropdownList readOnly={readonly} onSelect={(d) => setTraining(d)}
                 data={trainings} value={training} className="flex-child-11" ></DropdownList> 
         </div>
         <div className="form-group ">
             <label>{t("module")}</label>
             <DropdownList readOnly={readonly} onSelect={(d) => setTrainingModule(d)}
                 data={modules} value={trainingModule} className="flex-child-11" ></DropdownList>
         </div>
         </>}  
     </div> }

     <div className="form-g2c" > 
         <div className="form-group ">
             <label>{t("Titre")}</label>
             <input readOnly={readonly} onChange={e => setEntity({ ...entity, title: e.target.value, name: e.target.value })} value={entity.title}
                 type="text" className="form-control" placeholder={t("Titre")} />
         </div>
         <div className="form-group ">
             <label>{t("Description ")}</label>
             <input readOnly={readonly} onChange={e => setEntity({ ...entity, description: e.target.value })} value={entity.description}
                 type="text" className="form-control" placeholder={t("Description")} />
         </div> 


         <div className="form-group ">
             <label>{t("Date Publication ")}</label>
             <input readOnly={readonly} type="date"
                 onChange={e => setEntity({ ...entity, publicationDate: new Date(e.target.value) })}
                 value={toYYY_MM_DD(entity.publicationDate)}
                 className="form-control" />
         </div>
         <div className="form-group ">
             <label>{t("Date Clôture ")}</label>
             <input readOnly={readonly} type="date"
                 onChange={e => setEntity({ ...entity, closingDate: new Date(e.target.value) })}
                 value={toYYY_MM_DD(entity.closingDate)}
                 className="form-control" />
         </div>



        {tnp &&  <div className="form-group ">
             <label>{t("Note de passage")}</label>
             <input readOnly={readonly} type="number"
                 onChange={e => setEntity({ ...entity, passingScore: parseInt(e.target.value) })}
                 value={entity.passingScore + ""}
                 className="form-control" />
         </div>}
         {!tnp &&  <div className="form-group ">
             <label>{t("Sondage Anonyme")}</label>
             <div onClick={() => setEntity({ ...entity, anonymity: !entity.anonymity })}
                        className={"az-toggle az-toggle-success " + (entity.anonymity ? 'on' : 'off')}><span></span></div>
              
         </div>}


         <div className="form-group ">
             <label>{t("Durée (min)")}</label>
             <input readOnly={readonly} type="number"
                 onChange={e => setEntity({ ...entity, durationMin: parseInt(e.target.value) })}
                 value={entity.durationMin + ""}
                 className="form-control" />
         </div>


         <div className="form-group ">
             <label>{t("Entité")}</label>
             <DropdownList
                 optionRender={d => (<><div>{d.title}</div><div><small>{d.qn}</small></div></>)}
                 data={resourceList} value={entity.targetEntity}
                 onSelect={(d) => { setEntity({ ...entity, targetEntity: d }) }} enableSearch={true}
             ></DropdownList>
         </div>
         <div className="form-group ">
             <label>{t("Profils concernés ")}</label>
             <MultItemField data={entity.monitoringGroups}
                 setData={(d) => { console.log("set monitoringGroups",d) ;entity.monitoringGroups = d; setEntity({...entity}); }}
                 items={allGroups} noDataMessage="Aucun groupe" mode={readonly ? 'display' : 'edit' }  ></MultItemField>

         </div>

         <div className="form-group ">
                    <label>{t("Activer")}</label>
                    <div onClick={() => setEntity({ ...entity, activated: !entity.activated })}
                        className={"az-toggle az-toggle-success " + (entity.activated ? 'on' : 'off')}><span></span></div>
                </div>
</div>
</div>
}