import { Question , Option} from "../assessment/assessment-type";

export function verifyQuestionResponse(q: Question): boolean {
    if(!q) {
        return false;
    }
    if (q.type == "FREE_TEXT") {
        return verifyQuestionResponseFreeText(q);
    }
    if (q.type == "QCM") {
        return verifyQuestionResponseQCM(q);
    }
    if (q.type == "QCU") {
        return verifyQuestionResponseQCU(q);
    }
    if (q.type == "RATING") {
        return verifyQuestionResponseRating(q);
    }


    return true;
}



function verifyQuestionResponseFreeText(q: Question): boolean {
    return !!q.response?.text?.length;
}

function verifyQuestionResponseQCM(q: Question): boolean {
    let coids: number[] = q.correctAnswer?.optionId || [];
    let coidTxt = coids.sort().join(";")
    console.log(">>>verifyQuestionResponseQCM coids", coids, coidTxt);
    let responses =  q.options?.filter((o: Option) => o.checked)
    .map(o=>parseInt(mapKeyToId(o.key)|| "0")).sort().join(";");

    let ret =  responses === coidTxt; 
    console.log(">>>verifyQuestionResponseQCM ret ", ret, responses, q.options);
    return ret;
}

function verifyQuestionResponseQCU(q: Question): boolean { 
    return verifyQuestionResponseQCM(q);
}

function verifyQuestionResponseRating(q: Question): boolean {
    return q.response?.text == q.correctAnswer?.comment;
}


function mapKeyToId (k: string |undefined) : string {
    if(!k) return "0";
    return k.replace(/.*_/g, "");
}