import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { printShortDate } from '../help/dates';
import { t } from '../i18n';
import { searchOUs, searchGroups } from '../service/userService'; 
import { Group } from '../types/people';
import { Pagination } from '../ui/pagination/search-paging';
import './admin.css';
export function SearchGroup (props:any) {
    let [omni, setOmni] = useState('');
    let [request, setRequest] = useState({first:0 , max:10, criteria: {},  time: new Date() });
    let [data, setData] = useState( [] as Group[] ) ;
    let [pagination, setPagination] = useState({first:0, pageSize:10, total:0});
    useEffect(()=> {
        searchGroups(request).then(resp=> {
            if(resp.result) {
                setData(resp.result);
                setPagination({first:request.first, pageSize:request.max, total: resp.total})
            }
        }) 
    }, [request] );
    let reload =(p:number) => { 
        setRequest({...request, time: new Date(), first: p });
    } 
    let handleKeyPressEvent= (k:string) => {
            if(k==='Enter') {   launchSearch();  }
    }
    let launchSearch=()=> setRequest({...request , criteria : {'omni': omni}});
    return (
        <div className="search-users">
            <div className="search-console">
                <div className="criteria">
                <div className="criteria-control">
                            <input type="text" className="form-control" placeholder={t("Rechercher un profil")}
                            onChange={(e)=>setOmni(e.target.value)}
                            onKeyPress={(e)=>handleKeyPressEvent(e.key)}
                             />
                            <button className="btn"><i
                            onClick={launchSearch}
                            className="fas fa-search"></i></button>
                </div>

                </div>
                <div className="actions-bar">
                <div className="action-btns-droplist">
                <Link to={"/admin/group/00/create"}  className="btn btn-purple"><i className="far fa-plus-square"></i> <span>Nouveau Groupe</span></Link>
              <div className="extra-action">   </div>
            </div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr> 
                      <th className="wd-45p pl">{t("Groupe")}</th>
                      <th className="wd-45p pl">{t("Nom Complet")}</th> 
                      <th className="wd-45p pl">{t("Description")}</th> 
                      <th className="wd-45p pl">{t("Date Création")}</th>
                      <th className="wd-45p pl">{t("Créé par ")}</th>
                      <th className="wd-45p pl">{t("Action")}</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((d: any) => {
                      return(
                    <tr className="ind-row"> 
                    <td className="pl"  ><strong> {d.name} </strong></td>
                    <td className="pl"  >{d.groupQN} </td> 
                    <td className="pl"  >{d.label} </td> 
                    <td  className="pc" > {printShortDate(d.changeTime || d.creationTime)} </td>
                    <td  className="pc" > <strong>{d.modifiedBy || d.createdBy }</strong> </td>
                    <td className="action-btn">
                        <Link to={"/admin/group/"+d.id+"/display"} >
                        <i className="fa fa-eye"></i>
                        </Link>
                        <Link to={"/admin/group/"+d.id+"/edit"} >
                          <i className="fa fa-edit"></i>
                        </Link>
                    </td>
                 
                  </tr>);

                    })}
                    
                  </tbody>
                </table>
              </div>  
              { pagination.total>0 && 
                <Pagination reload={reload}  first={pagination.first} pageSize={pagination.pageSize} total={pagination.total} ></Pagination>
                }


        </div>
    );
}