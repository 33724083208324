
import '../admin/admin.css';
import ReactQuill from "react-quill";

import { quillFormats, quillModulesBasic } from "../feed-wall/text-editor";
import { t } from '../i18n';
import { useState } from 'react';
import { uploadFile } from '../service/file.service';





export function AssDefMessageTab({ entity, setEntity, readonly, tnp }) {
    let [photoLink, setPhotoLink] = useState(entity.visualId);
    let handleIntroChange = (content: any, delta: any, source: any, editor: any) => {

        setEntity({ ...entity, introduction: content })
    }
    let handleConclusionChange = (content: any, delta: any, source: any, editor: any) => {
        setEntity({ ...entity, conclusion: content })
    }
    let handleFelicitationChange = (content: any, delta: any, source: any, editor: any) => {
        console.log("handleFelicitationChange");
        setEntity({ ...entity, felicitation: content })
    }
    let handleHardluckChange = (content: any, delta: any, source: any, editor: any) => {
        console.log("handleHardluckChange");
        setEntity({ ...entity, hardluck: content })
    }

    let onPhotoChange = (evt:any)=> {
        uploadFile(evt).then((link:any) => {
            setPhotoLink(link);
            entity.visualId=link; 
        }); 
    }
    return <div className="app-form kpi-form b1c" >
        <div className="form-g2c" >
            <div className="form-group ">
                <label>{t("Introduction")}</label>

                {!readonly && <ReactQuill bounds="#quill-ex" modules={quillModulesBasic}
                    formats={quillFormats} value={entity.introduction || ""} onChange={handleIntroChange} />}
                {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.introduction || '' }} ></div>}

            </div>
            <div className="form-group ">
                <label>{t("Conclusion")}</label>
                {!readonly && <ReactQuill bounds="#quill-ex" modules={quillModulesBasic}
                    formats={quillFormats} value={entity.conclusion || ""} onChange={handleConclusionChange} />}
                {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.conclusion || '' }} ></div>}
            </div>

            {tnp && <> <div className="form-group ">
                <label>{t("Message de félicitation ")}</label>
                {!readonly && <ReactQuill bounds="#quill-ex" modules={quillModulesBasic}
                    formats={quillFormats} value={entity.felicitation || ""} onChange={handleFelicitationChange} />}
                {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.felicitation || '' }} ></div>}
            </div>
                <div className="form-group ">
                    <label>{t("Message d'encouragement ")}</label>
                    {!readonly && <ReactQuill bounds="#quill-ex" modules={quillModulesBasic}
                        formats={quillFormats} value={entity.hardluck || ""} onChange={handleHardluckChange} />}
                    {readonly && <div className="paragraph" dangerouslySetInnerHTML={{ __html: entity.hardluck || '' }} ></div>}
                </div>
            </>
            }


            <div className="col-right" >
                <div className="visual-container">
                    <label htmlFor="avatarFile" className={!readonly ? 'canupload' : 'cantupload'}>
                        <div>Visuel</div>
                        {photoLink && <img className="avatar-img" src={photoLink}></img>}
                        {!photoLink && <i className="novisual fa fa-image" ></i>}

                    </label>
                    {!readonly && <input onChange={onPhotoChange} type="file" id="avatarFile" className="h-file-input" accept="image/*"></input>}
                </div> 
            </div>
        </div>
    </div>
}