import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { GSS } from '../feed-wall/time-line';
import { t } from '../i18n';
import AppAsideMenu from '../menu/app-aside-menu';
import ProfileMenu from '../menu/profile-menu';
import NotificationPanel from '../notification/notification-panel';
import { appConfig, currentUser } from '../service/userService';
import { Avatar } from '../ui/avatar';
import { Popup } from '../ui/popup';
import './layout.css';



function Layout(props: any) {
    let [showSideMenu, setShowSideMenu] = useState(false);

   
    let toggleMenuLateral = () => {
        console.log("toggleMenuLateral 1 " + showSideMenu);
        showSideMenu = !showSideMenu;
        console.log("toggleMenuLateral 2 " + showSideMenu);
        setShowSideMenu(showSideMenu);
        console.log("toggleMenuLateral 3 " + showSideMenu);
    }
    return (
        <div className={"Layout " + (showSideMenu ? "az-header-menu-show" : "")}>
            <AppAsideMenu kpiMenuLastUpdate={props.kpiMenuLastUpdate} setShowSideMenu={setShowSideMenu}  ></AppAsideMenu>
            <div className="az-content az-content-dashboard-three"> 
            <HorizontalHeader     toggleMenuLateral={toggleMenuLateral}  
             postSignOut={props.postSignOut}   ></HorizontalHeader>
                <div className="main-content-scrollable"> 
                    <div className="az-content-body az-content-body-dashboard-three"> 
                        {props.children}
                    </div>
                </div> 
            <VerticalHeader postSignOut={props.postSignOut}      ></VerticalHeader>
            </div>
          
        </div>)
}


function VerticalHeader (props) {
    
    let [notificationMark, setNotificationMark] = useState(false);
    let [showNotification, setShowNotification] = useState(false) as any;
    let [showProfile, setShowProfile] = useState(false) as any;
     
    return    <div className="vertical-header"> 
       
            <div className={ "dropdown az-profile-menu " + (showProfile&&"show")}>
                <a className="az-img-user" onClick={()=>setShowProfile(!showProfile)} >
                    <Avatar connectedUser size="small"></Avatar> 
                    </a>
               <ProfileMenu   onClose={()=>setShowProfile(false)} postSignOut={props.postSignOut}></ProfileMenu>
            </div>

            <div className="az-header-message">
                <Link to={"/message"}  ><i className="typcn typcn-messages"></i></Link>
            </div>
            <div className={"dropdown az-header-notification "+ ( showNotification&&"show")}>
                <a   className={ notificationMark? "new" :  ""}  onClick={()=>{setShowNotification(!showNotification);setShowProfile(false)}}  ><i className="typcn typcn-bell"></i></a>
                <NotificationPanel onClose={()=>setShowNotification(false)}  
                setCount={(d)=>setNotificationMark(d>0)} ></NotificationPanel>
            </div>
         
        
   
</div>
}

function HorizontalHeader (props) {
    
    let [notificationMark, setNotificationMark] = useState(false);
    let [showNotification, setShowNotification] = useState(false) as any;
    let [showProfile, setShowProfile] = useState(false) as any;
    const searchInputEl = useRef(null);
    let history = useHistory();
    let onSearch = () => {
        console.log("searchInputEl=>" + (searchInputEl.current as any).value);
        GSS.criteria.keyWord = (searchInputEl.current as any).value;
        GSS.criteria.requestTime = new Date().getTime();
        history.push('/feed/' + GSS.criteria.requestTime);
    }
    return    <div className="horizontal-header az-header az-header-dashboard-three">
    <div className="container-fluid">
        <div className="az-header-left"> 
            <a  onClick={props.toggleMenuLateral} id="azIconbarShow" className="az-header-menu-icon"><span></span></a>
        </div>
        
        <div className="az-header-center">
            <input ref={searchInputEl} type="text" className="form-control" placeholder={t("Search for anything...")} />
            <button onClick={onSearch} className="btn"><i className="fas fa-search"></i></button>
        </div>
        <div className="az-header-right">
            <div className="az-header-message">
                <Link to={"/message"}  ><i className="typcn typcn-messages"></i></Link>
            </div>
            <div className={"dropdown az-header-notification "+ ( showNotification&&"show")}>
                <a   className={ notificationMark? "new" :  ""}  onClick={()=>{setShowNotification(!showNotification);setShowProfile(false)}}  ><i className="typcn typcn-bell"></i></a>
                <NotificationPanel onClose={()=>setShowNotification(false)}  
                setCount={(d)=>setNotificationMark(d>0)} ></NotificationPanel>
            </div>
            <div className={ "dropdown az-profile-menu " + (showProfile&&"show")}>
                <a className="az-img-user" onClick={()=>setShowProfile(!showProfile)} >
                    <Avatar connectedUser size="small"></Avatar> 
                    </a>
               <ProfileMenu   onClose={()=>setShowProfile(false)} postSignOut={props.postSignOut}></ProfileMenu>
            </div>
        </div>
    </div>
</div>
}

export default Layout;