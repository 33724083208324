import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { convertTypeAcquisitionFromJson } from "typescript";
import { printShortDate } from "../help/dates";
import { t } from "../i18n";
import { uploadFile } from "../service/file.service";
import { getUserById, loginAsHim, razPwd, searchGroups, searchProfiles, updateUser } from "../service/userService";
import { ROOT_ENTITIES } from "../test-data/entities";
import { Entry, Group, Profile, UserProfile } from "../types/people";
import { hasPermission } from "../types/permissions";
import { DropdownList } from "../ui/dropdown-list/dropdown-list";
import { MultItemField } from "../ui/mutli-item-field";
import { Popup } from "../ui/popup";
import './admin.css';
 
const ALL_ENTITIES_FLAT = JSON.parse(localStorage.getItem("ALL_ENTITIES_FLAT") || "[]");
 
export function User(props:any) {
    let {id:id, mode:inMode} = useParams() as any; 
    let [user  , setUser]  = useState(({} as UserProfile ));
    let [postSendRazRequest, showPostSendRazRequest] = useState(false); 
    let [showPopup, setShowPopup] = useState(false);
    let [mode, setMode] = useState(inMode);
    let [error, setError] = useState('');
    let [allProfiles, setAllProfiles] = useState([]);
    let [allGroups, setAllGroups] = useState([]);
    let history = useHistory();
    let [avatarLink, setAvatarLink] = useState('');
     let setProfiles=(profiles:Entry[]) => {
        user.profiles=profiles;
        setUser(user);
        console.log("setProfiles ", profiles, user);
    }

    let onAvatarChange = (evt:any) => {
        uploadFile(evt).then((link:any) => {
            setAvatarLink(link);
            user.avatarUrl=link; 
        }); 
    }
    let setGroups=(groups:Entry[]) => {
        user.groups=groups;
        setUser(user);
    }
    let onDelete=()=> { 
        updateUser(user, "delete").then((u)=>{ 
            setShowPopup(false);
            history.push("/admin/user");
         })
    }
    let submit= (action:string)=> {
        if(action=='update' || action=='create') {
            if(!user.firstName) {
                setError("Le prénom est obligatoire")
                return;
            }
            if(!user.lastName) {
                setError("Le nom de l'utilisateur est obligatoire")
                return;
            }
            if(!user.email) {
                setError("L'adrese email de l'utilisateur est obligatoiree")
                return;
            }
            
            if(!user.entity) {
                setError("Unité Organisationnelle de l'utilisateur est obligatoiree")
                return;
            }
            user.fullName = user.firstName+' '+user.lastName;
        }
        updateUser(user, action).then((u)=>{ 
            if(u.body)  {  
                setUser(u.body);
                if(u.body.avatarUrl) { 
                    setAvatarLink(u.body.avatarUrl);
                }
                setMode('display');
                id=u.body.id;
             } 
            });
    }
    useEffect(()=> { 
            if(id && id!="00") {
                getUserById(id).then((u)=>{ 
                    if(u.body) { 
                    setUser(u.body);
                    setAvatarLink(u.body.avatarUrl);
                    }
                }
                );
            }
            searchGroups({}).then(r=>{ 
                allGroups=(r.result.map((x:Group)=> { let e:Entry={value:x.groupQN, label:x.label, id: x.id}; return e; }))
                setAllGroups(allGroups);
            })
            searchProfiles({}).then(r=>{
                let allProfiles = r.result.map((x:Profile)=> { let e:Entry={value:x.name, label:x.label, id: x.id}; return e; });
                setAllProfiles(allProfiles) ;
        })
        }
        , [id]);
    let actions: any[] = [];
    let readonly=mode!='create' && mode!='edit';
    if(mode=='display') {
     actions.push({
         label: t("Editer"),
         icon: "fa fa-edit",
         handler: ()=> { setMode('edit')}
     });
     actions.push({
        label: t("Initializer MDP"),
        icon: "fa fa-key",
        handler: ()=> {  
            if(user.email) { 
                razPwd(user.email).then(r=> {
                    if(r) {
                        showPostSendRazRequest(true);
                    } else {
                        setError("Oups! quelque chose s'est mal passé");
                    }
                })
            }
        }
    });
    actions.push({
        label: t("Fermer"),
        icon: "fa fa-times",
        handler: ()=> { history.push("/admin/user")}
    });
    if(hasPermission('users' , 'ww' ) &&  id) {
        actions.push({
            label: t("Imiter"),
            icon: "fas fa-theater-masks",
            handler: ()=> { loginAsHim( id).then(r=> {
                if(r) {
                    document.location="/";
                }
            })}
        });
    }
    } else if (mode=='edit'  || mode=='create') {
        actions.push({
            label: t("Confirmer"),
            icon: "fa fa-check",
            handler: ()=> { submit('update')}
        });
        actions.push({
            label: t("Annuler"),
            icon: "fa fa-ban",
            handler: ()=> { setMode('display')}
        });
    }
    return (<div className="config-form">
        
        <div className="assessment" > 
    <div className="assmt-header">
    <h3 className="assmt-title">{user.fullName}</h3>
    <div className="assmt-dates az-content-header-right" > 
            {user.creationTime && <div className="media">
              <div className="media-body">
                <label>{t("Par")} <strong> { (user.changedBy || user.createdBy)}</strong> </label> 
                <h6> {printShortDate(user.changeTime || user.creationTime)}</h6>
              </div>
            </div>
            }
            <div className="media noextramarge">
              <div className="media-body actions-toolbar">
                  {actions.map(a=>  <a  onClick={a.handler}   className="btn btn-purple">
                      <i className={a.icon}></i> 
                      <span>{a.label}</span>
                      </a>
               )}
              </div>
            </div>
    </div>
    </div>
    </div>

            <div className="app-form flex-sb" >
                <div className="col-left" >
                <div className="form-group form-group-line">
                    <div className="form-subgroub col-6">
                    <label>{t("Prénom")}</label>
                    <input readOnly={readonly}   onChange={e=> setUser({...user, firstName : e.target.value})} value={user.firstName} 
                     type="text" className="form-control" placeholder={t("Prénom")} />
                    </div>
                    <div className="form-subgroub col-6">
                    <label>{t("Nom ")}</label>
                    <input  readOnly={readonly}     onChange={e=> setUser({...user, lastName : e.target.value})} value={user.lastName} 
                     type="text" className="form-control" placeholder={t("Prénom")} />
                    </div>
                </div> 
                
                <div className="form-group  ">
                    <label>{t("Email")}</label>
                    <input  readOnly={readonly}     onChange={e=> setUser({...user, email: e.target.value})} value={user.email}
                      type="text" className="form-control" placeholder={t("Email")} />
                </div> 
                <div className="form-group  ">
                    <label>{t("Titre")}</label>
                    <input  readOnly={readonly}     
                    onChange={e=> setUser({...user, title : e.target.value})} value={user.title}
                      type="text" className="form-control" placeholder={t("Titre")} />
                </div> 
                <div className="form-group  ">
                    <label>{t("Unité Organisationnelle")}</label>
                 {readonly &&   <input   readOnly={readonly}     value={user.entity?.value} 
                     type="text" className="form-control"   />
                    }
                   {!readonly && 
                      <DropdownList className="user-entity-droplist" data={ALL_ENTITIES_FLAT } 
                      valuePath="qn" 
                      value={{qn: user.entity?.value, label: user.entity?.label}} 
                       onSelect={(d:any)=>{ console.log("selected entity ",d);setUser (
                           { ...user, entity : {label: d.title, name:d.qn,  value: d.qn}}
                           )}} enableSearch ></DropdownList>
                    
                    } 
                </div> 

                <div className="form-group  ">
                    <label>{t("Profils")}</label>
                   <MultItemField data={user.profiles} setData={setProfiles} items={allProfiles} noDataMessage="Aucun profil" mode={mode=='create'?'edit':mode}  ></MultItemField>
                  
                </div> 
                <div className="form-group  ">
                    <label>{t("Groupes")}</label>
                    <MultItemField data={user.groups} setData={setGroups} items={allGroups} noDataMessage="Aucun groupe" mode={mode=='create'?'edit':mode}  ></MultItemField>
                  
                </div> 
                {!readonly &&  <a  onClick={()=>setShowPopup(true)}   className="btn btn-purple delete-btn">
                      <i className="fa fa-trash"></i> 
                      <span>{t("Supprimer cet utilisateur")}</span>
                      </a> 
                    }
                </div>
                <div className="col-right" >
                    <div className="avatar-container">
                        <label htmlFor="avatarFile" className={!readonly? 'canupload': 'cantupload'}>
                            {avatarLink && <img className="avatar-img" src={avatarLink}></img>}
                            {!avatarLink && <i className="noavatar fa fa-user-slash" ></i>}

                        </label>
                    {!readonly &&    <input onChange={onAvatarChange}  type="file" id="avatarFile" className="h-file-input" accept="image/*"></input>}
                    </div>
                    {error && <p className="form-error">{error}</p>}
                </div>
                
            </div>
{postSendRazRequest && <Popup  onClose={()=>showPostSendRazRequest(false)}  actions={[{name: "ok", label:"Ok", icon: "check"}]}
onAction={()=>showPostSendRazRequest(false)}
>
        <div className="success-pwd-reset" >
            Un eamil d'initialisation du mot de passe est envoyé à l'utilisateur.
        </div>
    </Popup>}

    {showPopup && <div className="modal-screen">
                    <div className="popup">
                        <i className="fa fa-times" onClick={()=>setShowPopup(false)}></i>
                        <p>
                            Etes-vous sûr de vouloir supprimer cet utilisateur? 
                        </p>
                    <a  onClick={onDelete}   className="btn btn-purple delete-btn">
                      <i className="fa fa-trash"></i> 
                      <span>Confirmer la suppression</span>
                      </a> 

                    </div>
                </div>}
    </div>);
}