import { currentUser } from "../service/userService";

const permissionAssestWeight = {"ww": 4, "wr": 3, "wn": 2, "rr":  1, "rn": 0  }
export function hasPermission(
    domain:'configuration'  |'profile' | 'users' | 'group' | 'organization' |  'post' | 'training' | 'quiz' | 'poll' | 'kpi' | 'challenge'  
, asset: 'ww' | "wr"| "wn"|"rr" | "rn") : boolean {
    let cu =currentUser();
    if(!cu) {return false;}
    let ps =  currentUser().permissions;
    if(!ps) {
        console.log("hasPermission no ps");
        return false;
    }
    ps = ps.filter((x:any)=>x.value.startsWith(domain.toLowerCase()+":")).map((x:any)=>x.value.trim());
    
   // console.log("hasPermission ps for "+domain , ps);
    if(!ps || ps.length==0) {
        return false;
    }
    let mina = permissionAssestWeight[asset];
    
   // console.log("hasPermission mina "+mina);
    let ret =  ps.filter(p=> permissionAssestWeight[p.split(":")[1]]>=mina).length>0;
    //console.log("hasPermission ret "+ret);
    return ret;
}
 
export function canUseAdminMenu() { 
    return ['configuration'  ,'profile' ,'users','group' , 'organization'].filter((x: any)=> hasPermission(x, 'rr')).length>0;
}

window["hasPermission"]=hasPermission;

export const PERMISSION_DROPDOWNLIST_DATA  = [
    {
        "value": "post:ww",
        "label": "POST [WW]"
    },
    {
        "value": "post:wr",
        "label": "POST [WR]"
    },
    {
        "value": "post:wn",
        "label": "POST [WN]"
    },
    {
        "value": "post:rr",
        "label": "POST [RR]"
    },
    {
        "value": "post:rn",
        "label": "POST [RN]"
    },
    {
        "value": "configuration:ww",
        "label": "CONFIGURATION [WW]"
    },
    {
        "value": "configuration:wr",
        "label": "CONFIGURATION [WR]"
    },
    {
        "value": "configuration:wn",
        "label": "CONFIGURATION [WN]"
    },
    {
        "value": "configuration:rr",
        "label": "CONFIGURATION [RR]"
    },
    {
        "value": "configuration:rn",
        "label": "CONFIGURATION [RN]"
    },
    {
        "value": "poll:ww",
        "label": "POLL [WW]"
    },
    {
        "value": "poll:wr",
        "label": "POLL [WR]"
    },
    {
        "value": "poll:wn",
        "label": "POLL [WN]"
    },
    {
        "value": "poll:rr",
        "label": "POLL [RR]"
    },
    {
        "value": "poll:rn",
        "label": "POLL [RN]"
    },
    {
        "value": "profile:ww",
        "label": "PROFILE [WW]"
    },
    {
        "value": "profile:wr",
        "label": "PROFILE [WR]"
    },
    {
        "value": "profile:wn",
        "label": "PROFILE [WN]"
    },
    {
        "value": "profile:rr",
        "label": "PROFILE [RR]"
    },
    {
        "value": "profile:rn",
        "label": "PROFILE [RN]"
    },
    {
        "value": "training:ww",
        "label": "TRAINING [WW]"
    },
    {
        "value": "training:wr",
        "label": "TRAINING [WR]"
    },
    {
        "value": "training:wn",
        "label": "TRAINING [WN]"
    },
    {
        "value": "training:rr",
        "label": "TRAINING [RR]"
    },
    {
        "value": "training:rn",
        "label": "TRAINING [RN]"
    },
    {
        "value": "quiz:ww",
        "label": "QUIZ [WW]"
    },
    {
        "value": "quiz:wr",
        "label": "QUIZ [WR]"
    },
    {
        "value": "quiz:wn",
        "label": "QUIZ [WN]"
    },
    {
        "value": "quiz:rr",
        "label": "QUIZ [RR]"
    },
    {
        "value": "quiz:rn",
        "label": "QUIZ [RN]"
    },
    {
        "value": "challenge:ww",
        "label": "CHALLENGE [WW]"
    },
    {
        "value": "challenge:wr",
        "label": "CHALLENGE [WR]"
    },
    {
        "value": "challenge:wn",
        "label": "CHALLENGE [WN]"
    },
    {
        "value": "challenge:rr",
        "label": "CHALLENGE [RR]"
    },
    {
        "value": "challenge:rn",
        "label": "CHALLENGE [RN]"
    },
    {
        "value": "kpi:ww",
        "label": "KPI [WW]"
    },
    {
        "value": "kpi:wr",
        "label": "KPI [WR]"
    },
    {
        "value": "kpi:wn",
        "label": "KPI [WN]"
    },
    {
        "value": "kpi:rr",
        "label": "KPI [RR]"
    },
    {
        "value": "kpi:rn",
        "label": "KPI [RN]"
    },
    {
        "value": "organization:ww",
        "label": "ORGANIZATION [WW]"
    },
    {
        "value": "organization:wr",
        "label": "ORGANIZATION [WR]"
    },
    {
        "value": "organization:wn",
        "label": "ORGANIZATION [WN]"
    },
    {
        "value": "organization:rr",
        "label": "ORGANIZATION [RR]"
    },
    {
        "value": "organization:rn",
        "label": "ORGANIZATION [RN]"
    },
    {
        "value": "users:ww",
        "label": "USERS [WW]"
    },
    {
        "value": "users:wr",
        "label": "USERS [WR]"
    },
    {
        "value": "users:wn",
        "label": "USERS [WN]"
    },
    {
        "value": "users:rr",
        "label": "USERS [RR]"
    },
    {
        "value": "users:rn",
        "label": "USERS [RN]"
    },  
    {
        "value": "group:ww",
        "label": "GROUP [WW]"
    },
    {
        "value": "group:wr",
        "label": "GROUP [WR]"
    },
    {
        "value": "group:wn",
        "label": "GROUP [WN]"
    },
    {
        "value": "group:rr",
        "label": "GROUP [RR]"
    },
    {
        "value": "group:rn",
        "label": "GROUP [RN]"
    }
];
