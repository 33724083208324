import { useState } from "react";
import { IndicatorData  } from "../dashboard/dashboard-data";
import { printShortDate, toYYYMMDD } from "../help/dates";
import { computeFontSize, round, round2 } from "../help/formating";
import { t } from "../i18n";
import { Period } from "../types/kpi-model";
import { KpiTrend, KpiTrendModal } from "./KpiTrend";

import './perf-indicators.css';

export function mapResourceNameToLabel(n: string) {
  if(!n || n=="/") {
    return "Siège";
  }
  return n.replace(/\//g, "");
}
function KpiBoxGauge(props: any) {
  let [showTrend, setShowTrend] = useState(false);
  let computeArc = (percent: number, radius: number): string => {
    return " " + radius * 2 * Math.PI * percent / 100 + " " + radius * 2 * Math.PI * (100 - percent) / 100;
  }
  let id: IndicatorData = props.indicatorData;
  let embedded = props.embedded;

  let setDrilledKpi = props.onSelect || (() => { });
 
  let avance = (id.globalAchievment || 0) - 0.01 * (id.targetProrataPercent || 1) * (id.target || 0);
  let ideal = 0.01 * (id.targetProrataPercent || 1) * (id.target || 0);
  let percentToProrata = Math.round(100 * (id.globalAchievment || 0) / ideal);
  return (
    <>

      <div className={"kpi-box-gauge indicator-box1 " + props.className + " " + (embedded && "embedded")}>
        <div className="box-card">
          <div className="card-head">
            <div className="ind-title"><span>{t(id.title|| '')}</span>  
            </div>
            <div className="ind-changetime">{id.resource?.qn&&<span> { mapResourceNameToLabel(id.resource.name)} </span>} <span>{printShortDate(id.changeTime)}</span></div>

            
            {/*mapPariodToLabel(id.kpiDefinition?.targetPeriodTime, "Objectif")*/}
            {mapPariodToLabel(id.kpiDefinition?.seedPeriodTime, "Suivi")}
            {!embedded &&
              <>
                <div className="ind-share">
                    <i title="Paratger" onClick={() => { props.shareKpi(id) }} className="fa fa-share"></i>  </div>
              </>}
          </div>
          <div className="card-body card-body-gauge">
            <svg xmlns='http://www.w3.org/2000/svg' className='gauge' height='250' width='250' viewBox='0 0 250 250'>
            <g transform='rotate(-90 125 125)'>
                <circle cx='125' cy='125' r='105' fill='none' stroke='#dddddf' strokeWidth='1' />
                <circle cx='125' cy='125' r='105' fill='none' stroke='#F16351' strokeWidth='9'
                  strokeDasharray={computeArc(id.targetProrataPercent || 0, 105)} strokeDashoffset='0' strokeLinecap='round' />
                <circle cx='125' cy='125' r='105' fill='none' stroke='#0f056b' strokeWidth='2'
                  strokeDasharray={computeArc(id.achievmentPercent || 0, 105)} strokeDashoffset='0' strokeLinecap='round' />

              </g>
            </svg>
            <div className="gauge-inner-html" onClick={() => setDrilledKpi(id)} >
              <div className="ind-ach">
                <span className="ind-val" style={{ fontSize: computeFontSize(round(id.globalAchievment, id.kpiDefinition?.precision), 150) }}>{round(id.globalAchievment, id.kpiDefinition?.precision)}</span>
                <span className="ind-unit">{id.unit} </span>
                <small className={percentToProrata < 80 ? 'ptp-danger' : percentToProrata < 100 ? 'ptp-alert' : 'ptp-ok'}>{percentToProrata}%</small>
              </div>
              <div className="ind-resource">{id.resource.label}</div>
              <div className="ind-time">{mapPariodSpan(id.period)}</div>
            </div>
          </div>
          <div className="ind-foot-gauge">
            {  /*    <div className="ind-slbl ind-slbl-prorata-percent"  > {parseInt(""+id.achievmentPercent)}%  / {id.targetProrataPercent}% </div> 
                            <div className="ind-slbl ind-slbl-achi"   >  {id.globalAchievment}<small>{id.unit}</small> / {id.target}<small>{id.unit}</small> </div> 
                          */
            }
          </div>


          <div className="ind-foot-gauge-2"> 
          
          <div className="ind-foot-line">
              <div className="ind-foot-lbl">{t("Performance")+" "+mapPariodSfx(id.kpiDefinition?.seedPeriodTime)}</div>
              <div className="ind-foot-val">{round2(id.measure)}<small>{id.unit}</small>
                <i className={"fa fa-arrow-" + id.trend?.toLocaleLowerCase() + " tx-success"}></i>
              </div>
            </div>
            <div className="ind-foot-line">
              <div className="ind-foot-lbl">{t("Objectif Proraté")}</div>
              <div className="ind-foot-val">{round2(0.01 * (id.targetProrataPercent || 1) * (id.target || 0))}<small>{id.unit}</small></div>
            </div>

            <div className={"ind-foot-line " + (avance < 0 ? " ind-foot-warn" : "")}>
              <div className="ind-foot-lbl">{t("Ecart sur la progression Idéale")}</div>
              <div className="ind-foot-val">{round2(avance)}<small>{id.unit}</small></div>
            </div>
            
            <div className="ind-foot-line">
              <div className="ind-foot-lbl">{t("Objectif")  +" "+mapPariodToLibelle(id.kpiDefinition?.targetPeriodTime)}</div>
              <div className="ind-foot-val">{(id.target || 0).toLocaleString("fr")}<small>{id.unit}</small></div>
            </div>
           
          </div>

        </div>
      </div>
      {showTrend && <KpiTrendModal onClose={() => setShowTrend(false)} kpi={id}></KpiTrendModal>}
    </>
  );
}

export default KpiBoxGauge;

function mapPariodSpan(p: Period) {
  return printShortDate(new Date(new Date(p.end || '').getTime() - 3610000));
}

export function mapPariodSfx(periodTime: string | undefined) {
  let exp = "";
  if (periodTime == 'week') {
    exp = "de la semaine";
  } else if (periodTime == 'month') {
    exp = "du mois";
  } else if (periodTime == 'year') {
    exp = "de l'année";
  } else if (periodTime == 'day') {
    exp = "du jour";
  }
  return exp;
}

export function mapPariodToLibelle(periodTime: string | undefined) {
  let exp = "";
  if (periodTime == 'week') {
    exp = "Hebdomadaire";
  } else if (periodTime == 'month') {
    exp = "Mensuel";
  } else if (periodTime == 'year') {
    exp = "Annuel";
  } else if (periodTime == 'day') {
    exp = "Journalier";
  }
  return exp;
}
export function mapPariodToLabel(periodTime: string | undefined, label: string) {

  return <div className="timeperiod-label">{label} <strong>{mapPariodToLibelle(periodTime)}</strong></div>
}
